import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { FormInstance, Input, Select } from 'antd';
import { ApiCredentialsEditor } from './ApiCredentialsEditor/ApiCredentialsEditor';
import { FormItem } from 'components/FormItem/FormItem';
//import { FtpCredentialsEditor } from './FtpCredentialsEditor/FtpCredentialsEditor';
//import { InstitutionCredentialsEditor } from './InstitutionCredentialsEditor/InstitutionCredentialsEditor';
import { ItemEditor } from 'containers/Console/ItemEditor/ItemEditor';
import { RouteBuilder } from 'utils/RouteBuilder';
import { WebCredentialsEditor } from './WebCredentialsEditor/WebCredentialsEditor';
import { Globals } from 'constants/Globals';
import { ApiCredentials, Authentication, Credentials, CredentialsType, WebCredentials } from '@methodset/endpoint-client-ts';
import { RouteComponentProps } from 'react-router-dom';
import { RestUtils } from 'utils/RestUtils';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import './AuthenticationItem.less';

type MatchParams = {
    authenticationId: string
}

export type AuthenticationItemState = {
    status: string,
    authentication: Authentication,
    error?: string,
    loading: boolean,
    isSubmitting: boolean
}

export type AuthenticationItemProps = typeof AuthenticationItem.defaultProps & RouteComponentProps<MatchParams> & {
    className?: string,
    authentication: Authentication
}

export class AuthenticationItem extends PureComponent<AuthenticationItemProps, AuthenticationItemState> {

    static defaultProps = {
        authentication: {} as Authentication
    }

    private formRef = React.createRef<FormInstance>();
    private childRef = React.createRef<any>();

    constructor(props: AuthenticationItemProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            authentication: this.props.authentication,
            error: undefined,
            loading: false,
            isSubmitting: false
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleCredentialsChange = this.handleCredentialsChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleSave(): void {
        this.saveAuthentication(this.state.authentication);
    }

    private handleCancel(): void {
        this.gotoAuthentications();
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const authentication = update(this.state.authentication, {
            name: { $set: name }
        });
        this.setState({ authentication: authentication });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const authentication = update(this.state.authentication, {
            description: { $set: description }
        });
        this.setState({ authentication: authentication });
    }

    private handleTypeChange(credentialsType: CredentialsType): void {
        const authentication = update(this.state.authentication, {
            credentialsType: { $set: credentialsType },
            credentials: { $set: undefined as any }
        });
        this.setState({ authentication: authentication });
    }

    private handleCredentialsChange(credentials: Credentials, authentication?: Authentication): void {
        // If authentication is defined the callback, it will be merged
        // with the current state authentication.
        if (!authentication) {
            authentication = this.state.authentication;
        } else {
            authentication = update(this.state.authentication, {
                $merge: authentication
            });
        }
        authentication = update(authentication, {
            credentials: { $set: credentials }
        })
        this.setState({ authentication: authentication });
    }

    private saveAuthentication(authentication: Authentication): void {
        if (authentication.id) {
            this.updateAuthenticationRequest();
        } else {
            this.createAuthenticationRequest();
        }
    }

    private gotoAuthentications(): void {
        this.props.history.push(RouteBuilder.CONSOLE_CREDENTIALS);
    }

    private createAuthenticationRequest(): Promise<any> {
        this.setState({ isSubmitting: true });
        const authentication = this.state.authentication;
        const request = {
            name: authentication.name,
            description: authentication.description,
            credentials: authentication.credentials,
        };
        return endpointService.createAuthentication(request,
            (response: any) => this.createAuthenticationResponse(response),
            (response: any) => this.saveException(response),
            true
        );
    }

    private createAuthenticationResponse(response: any): void {
        this.setState({ isSubmitting: false });
        this.gotoAuthentications();
    }

    private updateAuthenticationRequest(): Promise<any> {
        this.setState({ isSubmitting: true });
        const authentication = this.state.authentication;
        const request = {
            authenticationId: authentication.id,
            name: authentication.name,
            description: authentication.description,
            credentials: authentication.credentials,
        };
        return endpointService.updateAuthentication(request,
            (response: any) => this.updateAuthenticationResponse(response),
            (response: any) => this.saveException(response),
            true
        );
    }

    private updateAuthenticationResponse(response: any): void {
        this.setState({ isSubmitting: false });
        this.gotoAuthentications();
    }

    private saveException(response: any): void {
        this.setState({
            isSubmitting: false,
            error: RestUtils.getError(response)
        });
    }

    private readAuthenticationRequest(): Promise<any> {
        const authenticationId = this.props.match.params.authenticationId;
        if (!authenticationId || authenticationId === 'create') {
            return Promise.resolve(true);
        }
        const request = {
            authenticationId: authenticationId
        };
        return endpointService.readAuthentication(request,
            (response: any) => this.readAuthenticationResponse(response),
            undefined, true
        );
    }

    private readAuthenticationResponse(response: any): void {
        const authentication = response.data.authentication;
        this.setState({ authentication: authentication });
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readAuthenticationRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        return (
            <ItemEditor
                className={classNames('x-authenticationitem', this.props.className)}
                ref={this.formRef}
                title="Credential"
                status={this.state.status}
                loading={this.state.loading}
                onSave={this.handleSave}
                onCancel={this.handleCancel}
            >
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    ref={this.childRef}
                    formRef={this.formRef}
                    label="Credentials Type"
                    name="type"
                    rules={[{
                        required: true,
                        message: 'Please select a credentials type.'
                    }]}
                >
                    <Select
                        placeholder="Select a credentials type."
                        allowClear={true}
                        value={this.state.authentication.credentialsType}
                        onChange={this.handleTypeChange}
                    >
                        <Select.Option value="API">API Key</Select.Option>
                        <Select.Option value="WEB">Web Site</Select.Option>
                        {/* <Select.Option value="FTP">FTP Server</Select.Option> */}
                        {/* <Select.Option value="INSTITUTION">Financial Institution</Select.Option> */}
                    </Select>
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    ref={this.childRef}
                    formRef={this.formRef}
                    label="Name"
                    name="name"
                    rules={[{
                        required: true,
                        message: 'Please enter a name.'
                    }]}
                >
                    <Input
                        placeholder="Enter a name."
                        value={this.state.authentication.name}
                        onChange={this.handleNameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    ref={this.childRef}
                    formRef={this.formRef}
                    label="Description"
                    name="description"
                >
                    <Input.TextArea
                        placeholder="Enter a description."
                        rows={3}
                        value={this.state.authentication.description}
                        onChange={this.handleDescriptionChange}
                    />
                </FormItem>
                {this.state.authentication.credentialsType === 'API' &&
                    <ApiCredentialsEditor
                        formRef={this.formRef}
                        credentials={this.state.authentication.credentials as ApiCredentials}
                        onChange={this.handleCredentialsChange}
                    />
                }
                {this.state.authentication.credentialsType === 'WEB' &&
                    <WebCredentialsEditor
                        formRef={this.formRef}
                        credentials={this.state.authentication.credentials as WebCredentials}
                        onChange={this.handleCredentialsChange}
                    />
                }
                {/* {this.state.authentication.credentialsType === 'FTP' &&
                    <FtpCredentialsEditor
                        formRef={this.formRef}
                        credentials={this.state.authentication.credentials as FtpCredentials}
                        onChange={this.handleCredentialsChange}
                    />
                } */}
                {/* {this.state.authentication.credentialsType === 'INSTITUTION' &&
                    <InstitutionCredentialsEditor
                        formRef={this.formRef}
                        credentials={this.state.authentication.credentials as InstitutionCredentials}
                        onChange={this.handleCredentialsChange}
                    />
                } */}
                <div className="x-endpoint-error">{this.state.error}</div>
            </ItemEditor>
        );
    }

}
