import { FC, ReactElement } from "react"
import { Select } from "antd"
import { Label } from "components/Label/Label";
import { SpecContent } from "../SpecContent/SpecContent";
import update from 'immutability-helper';
import './ValueEditor.less';

export enum ValueIndex {
    COLUMN_INDEX = 0,
    FUNCTION = 1
}

export type ValueSpec = [ number, string ];
export type ChangeCallback = (spec: ValueSpec) => void;
export type DeleteCallback = (spec: ValueSpec) => void;

type Props = {
    header: string,
    spec: ValueSpec,
    onChange: ChangeCallback,
    onDelete: DeleteCallback
}

export const ValueEditor: FC<Props> = (props: Props): ReactElement => {

    const handleFnChange = (fn: string): void => {
        const spec = update(props.spec, {
            [ValueIndex.FUNCTION]: { $set: fn }
        });
        props.onChange(spec);
    }

    const handleSpecDelete = (): void => {
        props.onDelete(props.spec);
    }

    return (
        <SpecContent
            header={props.header}
            onDelete={handleSpecDelete}
        >
            <div>
                <Label
                    label="Aggregate"
                    placement="left"
                    bold={false}
                >
                    <Select
                        className="x-valueeditor-fn"
                        value={props.spec[ValueIndex.FUNCTION]}
                        onChange={handleFnChange}
                    >
                        <Select.Option key="count" value="COUNT">COUNT</Select.Option>
                        <Select.Option key="counta" value="COUNTA">COUNTA</Select.Option>
                        <Select.Option key="sum" value="SUM">SUM</Select.Option>
                        <Select.Option key="min" value="MIN">MIN</Select.Option>
                        <Select.Option key="max" value="MAX">MAX</Select.Option>
                        <Select.Option key="avg" value="AVG">AVG</Select.Option>
                        <Select.Option key="median" value="MEDIAN">MEDIAN</Select.Option>
                        <Select.Option key="stddev" value="STDDEV">STDDEV</Select.Option>
                        <Select.Option key="head" value="HEAD">HEAD</Select.Option>
                        <Select.Option key="tail" value="TAIL">TAIL</Select.Option>
                    </Select>

                </Label>
            </div>
        </SpecContent>
    )

}
