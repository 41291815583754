import { ReactElement } from 'react';
import { Empty } from 'antd';
import { Calculator, Column, DimensionType, Format, Matrix, Range, RangeComponents, ReferenceParser, Row, Sheet, Vector } from '@methodset/calculator-ts';
import { CartesianWidgetConfiguration, ChartDataset, PolarWidgetConfiguration, WidgetConfiguration, WidgetType } from '@methodset/model-client-ts';
import { BarChartView } from './BarChartView/BarChartView';
import { LineChartView } from './LineChartView/LineChartView';
import { PieChartView } from './PieChartView/PieChartView';
import { ColumnChartView } from './ColumnChartView/ColumnChartView';
import { WidgetUtils } from 'utils/WidgetUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { AreaChartView } from './AreaChartView/AreaChartView';
import { DonutChartView } from './DonutChartView/DonutChartView';
import './ChartWidgetViewer.less';

// A series of data points.
export type DataSeries = {
    xFormat?: Format,
    yFormat?: Format,
    points: DataPoint[]
};

// Chart data is an object with configuration values.
export type DataPoint = { [key: string]: any };

export type ChartWidgetViewerProps = {
    configuration: WidgetConfiguration,
    calculator: Calculator
}

export const ChartWidgetViewer = (props: ChartWidgetViewerProps): ReactElement => {

    const buildCartesianSeries = (configuration: CartesianWidgetConfiguration): DataSeries => {
        const dataSeries: DataSeries = {
            points: []
        };
        const datasets = configuration.datasets;
        for (let i = 0; i < datasets.length; i++) {
            const dataset = datasets[i];
            try {
                const labelRef = ReferenceParser.parse(dataset.labelRangeId, true);
                const valueRef = ReferenceParser.parse(dataset.valueRangeId, true);
                const labelComponents = labelRef!.components as RangeComponents;
                const valueComponents = valueRef!.components as RangeComponents;
                addDataset(dataset, labelComponents, valueComponents, dataSeries.points, dataset.valueHeaders);
                if (i === 0) {
                    dataSeries.xFormat = findFormat(labelComponents?.sheetId, labelComponents?.rangeId);
                    dataSeries.yFormat = findFormat(valueComponents?.sheetId, valueComponents?.rangeId);
                }
            } catch (e) {
                // skip
            }
        }
        return dataSeries;
    }

    const buildPolarSeries = (configuration: PolarWidgetConfiguration): DataSeries => {
        const dataSeries: DataSeries = {
            points: []
        };
        const dataset = configuration.dataset;
        if (!dataset) {
            return dataSeries;
        }
        try {
            const labelRef = ReferenceParser.parse(dataset.labelRangeId, true);
            const valueRef = ReferenceParser.parse(dataset.valueRangeId, true);
            const labelComponents = labelRef!.components as RangeComponents;
            const valueComponents = valueRef!.components as RangeComponents;
            addDataset(dataset, labelComponents, valueComponents, dataSeries.points, false);
            dataSeries.xFormat = findFormat(labelComponents?.sheetId, labelComponents?.rangeId);
            dataSeries.yFormat = findFormat(valueComponents?.sheetId, valueComponents?.rangeId);
        } catch (e) {
            // skip
        }
        return dataSeries;
    }

    const findFormat = (sheetId: string, rangeId: string): Format | undefined => {
        if (!sheetId || !rangeId) {
            return undefined;
        }
        let sheet: Sheet;
        let range: Range;
        try {
            sheet = props.calculator.sheets.get(sheetId);
            range = Range.fromId(sheet, rangeId);
        } catch (e) {
            return undefined;
        }
        const upper = range!.upper;
        const lower = range!.lower;
        let dimension: Row | Column;
        if (upper.col === lower.col) {
            dimension = sheet.getColumn(upper.colId);
        } else if (upper.row === lower.row) {
            dimension = sheet.getRow(upper.rowId);
        } else {
            // This is a multi-dimensional range. First check the column 
            // for a format, and if none, use the first row.
            dimension = sheet.getColumn(upper.colId);
            if (!dimension.format) {
                dimension = sheet.getRow(upper.rowId);
            }
        }
        return dimension.format;
    }

    const addDataset = (dataset: ChartDataset, labelComponents: RangeComponents, valueComponents: RangeComponents,
        dataPoints: DataPoint[], valueHeaders: boolean | undefined): void => {
        try {
            const labelSheet = props.calculator.sheets.get(labelComponents.sheetId);
            const valueSheet = props.calculator.sheets.get(valueComponents.sheetId);
            const xRange = Range.fromId(labelSheet, labelComponents.rangeId);
            const xVector = Vector.fromRange(xRange);
            const xValues = xVector.values.map((value: any) => value.toString());
            const yRange = Range.fromId(valueSheet, valueComponents.rangeId);
            const yMatrix = Matrix.fromRange(yRange);
            const yValues = yMatrix.values;
            if (xVector.dimension === DimensionType.ROW) {
                const start = valueHeaders ? 1 : 0
                for (let c = start; c < xValues.length; c++) {
                    for (let r = 0; r < yMatrix.rows; r++) {
                        const label = valueHeaders ? yValues[r][0] : `${dataset.name} ${yMatrix.rows === 1 ? '' : r + 1}`;
                        const dataPoint: DataPoint = {
                            x: xValues[c],
                            y: yValues[r][c],
                            series: label
                        }
                        dataPoints.push(dataPoint);
                    }
                }
            } else if (xVector.dimension === DimensionType.COLUMN) {
                const offset = valueHeaders ? 1 : 0
                for (let r = 0; r < xValues.length; r++) {
                    for (let c = 0; c < yMatrix.columns; c++) {
                        const label = valueHeaders ? yValues[0][c] : `${dataset.name} ${yMatrix.columns === 1 ? '' : c + 1}`;
                        const dataPoint: DataPoint = {
                            x: xValues[r],
                            y: yValues[r + offset][c],
                            series: label
                        }
                        dataPoints.push(dataPoint);
                    }
                }
            }
        } catch (e) {
            // Invalid range or vector values - skip.
            // TODO: display error message
        }
    }

    const buildCartesianConfiguration = (): CartesianWidgetConfiguration => {
        const configuration = CoreUtils.clone(props.configuration) as CartesianWidgetConfiguration;
        configuration.xAxis.label = WidgetUtils.replaceCellRefs(props.calculator, configuration.xAxis.label);
        configuration.yAxis.label = WidgetUtils.replaceCellRefs(props.calculator, configuration.yAxis.label);
        const datasets = configuration.datasets;
        for (const dataset of datasets) {
            dataset.name = WidgetUtils.replaceCellRefs(props.calculator, dataset.name);
        }
        return configuration;
    }

    const buildPolarConfiguration = (): PolarWidgetConfiguration => {
        const configuration = CoreUtils.clone(props.configuration) as PolarWidgetConfiguration;
        configuration.dataset.name = WidgetUtils.replaceCellRefs(props.calculator, configuration.dataset.name);
        return configuration;
    }

    const buildEmptyChart = (series: DataSeries): ReactElement => {
        return (
            <div className="x-chartwidgetviewer-empty">
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <span>No chart data.</span>
                    }
                />
            </div>
        );
    }
    const chartView = (): ReactElement => {
        let chartView;
        const configuration = props.configuration;
        const params = {
            width: configuration.width ? configuration.width : "100%",
            height: configuration.height ? configuration.height : 350
        };
        const type = configuration.type;
        if (type === WidgetType.AREA_CHART) {
            const config = buildCartesianConfiguration();
            const series = buildCartesianSeries(config);
            chartView = series.points.length > 0 ? <AreaChartView configuration={config as CartesianWidgetConfiguration} series={series} {...params} /> : buildEmptyChart(series);
        } else if (type === WidgetType.BAR_CHART) {
            const config = buildCartesianConfiguration();
            const series = buildCartesianSeries(config);
            chartView = series.points.length > 0 ? <BarChartView configuration={config as CartesianWidgetConfiguration} series={series} {...params} /> : buildEmptyChart(series);
        } else if (type === WidgetType.COLUMN_CHART) {
            const config = buildCartesianConfiguration();
            const series = buildCartesianSeries(config);
            chartView = series.points.length > 0 ? <ColumnChartView configuration={config as CartesianWidgetConfiguration} series={series} {...params} /> : buildEmptyChart(series);
        } else if (type === WidgetType.LINE_CHART) {
            const config = buildCartesianConfiguration();
            const series = buildCartesianSeries(config);
            chartView = series.points.length > 0 ? <LineChartView configuration={config as CartesianWidgetConfiguration} series={series} {...params} /> : buildEmptyChart(series);
        } else if (type === WidgetType.PIE_CHART) {
            const config = buildPolarConfiguration();
            const series = buildPolarSeries(config);
            chartView = series.points.length > 0 ? <PieChartView configuration={config as PolarWidgetConfiguration} series={series} {...params} /> : buildEmptyChart(series);
        } else if (type === WidgetType.DONUT_CHART) {
            const config = buildPolarConfiguration();
            const series = buildPolarSeries(config);
            chartView = series.points.length > 0 ? <DonutChartView configuration={config as PolarWidgetConfiguration} series={series} {...params} /> : buildEmptyChart(series);
        } else {
            chartView = (
                <div>Unsupported chart type '{type}'.</div>
            );
        }
        return chartView;
    }

    return (
        <div className="x-chartwidgetviewer">
            {chartView()}
        </div>
    );
}
