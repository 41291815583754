import { PureComponent, ReactElement } from 'react';
import { Button as Btn, Skeleton, Space } from 'antd';
import { CoreUtils } from 'utils/CoreUtils';
import { Alignment, Direction, Justification, Size, Spacer } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import './LoadSkeleton.less';

export type RetryCallback = () => void;

export type LoadSkeletonProps = typeof LoadSkeleton.defaultProps & {
    className?: string,
    placement?: "top" | "center",
    status?: "loading" | "failed",
    direction?: Direction,
    justification?: Justification,
    alignment?: Alignment,
    count?: number,
    spacer?: Size,
    //spacing?: number,
    children: ReactElement | ReactElement[],
    failedMessage?: string,
    failedLabel?: string,
    onRetry?: RetryCallback
};

export type InputLoadSkeletonProps = {
    length?: "tiny" | "short" | "medium" | "long" | "fill" | number
}

/**
 * Creates a component that shows a loading animation.
 */
export class LoadSkeleton extends PureComponent<LoadSkeletonProps> {

    static defaultProps = {
        failedMessage: "Failed to load data.",
        failedLabel: "Retry",
        placement: "center",
        direction: "horizontal",
        alignment: "top",
        justification: "left",
        status: "loading",
        spacer: "large",
        //spacing: 12,
        count: 1
    }

    static Checkbox = (): ReactElement => {
        return <Skeleton.Input className="x-loadskeleton-checkbox" active={true} />
    }

    static Button = (): ReactElement => {
        return <Skeleton.Button active={true} />
    }

    static Input = (props: InputLoadSkeletonProps): ReactElement => {
        let style;
        let classname;
        if (CoreUtils.isNumber(props.length)) {
            style = { width: props.length };
        } else {
            classname = props.length ? `x-loadskeleton-${props.length}` : "x-loadskeleton-short";
        }
        return <Skeleton.Input className={classname} style={style} active={true} />
    }

    static Avatar = (): ReactElement => {
        return <Skeleton.Avatar active={true} />
    }

    static Image = (): ReactElement => {
        return <Skeleton.Image />
    }

    public render(): ReactElement {
        let view;
        if (this.props.status === "loading") {
            let skeletons = [];
            for (let i = 0; i < this.props.count; i++) {
                //skeletons.push(<div className="x-loadskeleton-row" key={i}>{this.props.children}</div>);
                //skeletons.push(<div className="x-loadskeleton-row" key={i}>{this.props.children}</div>);
                skeletons.push(
                    <Spacer
                        key={i}
                        fill={true}
                        direction={this.props.direction}
                        alignment={this.props.alignment}
                        justification={this.props.justification}
                        size={this.props.spacer}
                    >
                        {this.props.children}
                    </Spacer>
                );
            }
            view = (
                <Spacer
                    className="x-loadskeleton-loading"
                    fill={true}
                    direction={this.props.direction === "horizontal" ? "vertical" : "horizontal"}
                    size={this.props.spacer}
                >
                    {skeletons}
                </Spacer>
            );
        } else if (this.props.status === "failed") {
            view = (
                <div className={classNames("x-loadskeleton-failed", `x-loadskeleton-${this.props.placement}`)}>
                    <div className="x-loadskeleton-message">
                        {this.props.failedMessage}
                    </div>
                    {this.props.onRetry &&
                        <Btn type="primary" onClick={this.props.onRetry}>{this.props.failedLabel}</Btn>
                    }
                </div>
            );
        }
        return (
            <div className={classNames("x-loadskeleton", this.props.className)}>
                {view}
            </div>
        )
    }

}
