import { ForwardedRef, ReactElement } from 'react';
import { Button, Card, Col, Form, FormInstance, Row, Space } from 'antd';
import classNames from 'classnames';
import './FormFrame.less';
import React from 'react';

export type OkCallback = () => void;
export type CancelCallback = () => void;
export type ErrorCallback = () => void;

export type FormFrameProps = {
    className?: string,
    children?: any,
    title?: string,
    description?: string,
    extra?: ReactElement,
    error?: string,
    isLoading?: boolean,
    okLabel?: string,
    cancelLabel?: string,
    hideFooter?: boolean,
    removeForm?: boolean,
    onOk?: OkCallback,
    onCancel?: CancelCallback
    onError?: ErrorCallback
} & Partial<typeof defaultProps>;

const defaultProps = {
    isLoading: false,
    hideFooter: false,
    removeForm: false,
    okLabel: "OK",
    cancelLabel: "Cancel"
}

export const FormFrame = React.forwardRef<FormInstance, FormFrameProps>((props: FormFrameProps, ref?: ForwardedRef<FormInstance>): ReactElement => {

    const { className, children, title, description, extra, error, isLoading, okLabel, cancelLabel, hideFooter, removeForm, onOk, onCancel, onError, ...rest } = props;
    return (
        <Row className={classNames('x-formframe', className)}>
            <Col className="x-formframe-col" {...rest}>
                <div className="x-formframe-top">
                    <div className="x-formframe-title">{title}</div>
                    <div className="x-formframe-extra">{extra}</div>
                </div>
                <div className="x-formframe-body">
                    {description &&
                        <div className="x-formframe-desc">{description}</div>
                    }
                    {removeForm &&
                        <>
                            {children}
                            <div className={classNames({ 'x-formframe-error': error })}>{error}</div>
                        </>
                    }
                    {!removeForm &&
                        <Form
                            ref={ref}
                            onFinish={onOk}
                            onFinishFailed={onError}
                        >
                            {children}
                            <div className={classNames({ 'x-formframe-error': error })}>{error}</div>
                            {(onOk || onCancel) && !hideFooter &&
                                <div className="x-formframe-footer">
                                    <Space
                                        className="x-formframe-footer-actions"
                                    >
                                        <Button
                                            block
                                            onClick={onCancel}
                                        >
                                            {cancelLabel}
                                        </Button>
                                        <Button
                                            block
                                            type="primary"
                                            htmlType="submit"
                                            loading={isLoading}
                                        >
                                            {okLabel}
                                        </Button>
                                    </Space>
                                </div>
                            }
                        </Form>
                    }
                </div>
            </Col>
        </Row>
    )
})

FormFrame.defaultProps = defaultProps;
