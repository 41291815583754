import { ReactElement, useState } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { Label } from 'components/Label/Label';
import { CoreUtils } from 'utils/CoreUtils';
import { AppletInfo } from '@methodset/model-client-ts';
import { Clickable } from 'components/Clickable/Clickable';
import classNames from 'classnames';
import './AppletItem.less';

export type ClickCallback = (appletInfo: AppletInfo, selected: boolean) => void;

export type AppletItemProps = {
    index: number,
    appletInfo: AppletInfo,
    onClick: ClickCallback
}

export const AppletItem = (props: AppletItemProps): ReactElement => {

    const [selected, setSelected] = useState<boolean>(false);

    const handleClick = () => {
        setSelected(!selected);
        props.onClick(props.appletInfo, !selected);
    }

    let i = props.index % Globals.TAG_COLORS.length;
    return (
        <Clickable onClick={handleClick}>
            <div className={classNames("x-appletitem-item", `x-appletitem-selected-${selected ? "on" : "off"}`)}>
                <Spacer direction="vertical" size="small" alignment="top">
                    <div>
                        <div className="x-appletitem-name">{props.appletInfo.name}</div>
                        <div className="x-appletitem-description">{props.appletInfo.description}</div>
                    </div>
                    <div className="x-appletitem-keywords">
                        {props.appletInfo.keywords && props.appletInfo.keywords.length > 0 && props.appletInfo.keywords.map((keyword: string, index: number) => (
                            <Tag key={index} color={Globals.TAG_COLORS[i++ % Globals.TAG_COLORS.length]}>{keyword}</Tag>
                        ))}
                    </div>
                    <div>
                        <Label label="Version">{CoreUtils.toVersion(props.appletInfo.version)}</Label>
                        <Label label="Updated">{CoreUtils.toUpdateTime(props.appletInfo.updateTime)}</Label>
                        <Label label="Model">{props.appletInfo.modelName}</Label>
                    </div>
                </Spacer>
            </div>
        </Clickable>
    )
}
