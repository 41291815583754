import { PureComponent, ReactElement } from 'react';
import { DeleteOutlined, EditOutlined, NumberOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { RestUtils } from 'utils/RestUtils';
import { Model } from '@methodset/model-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import axios from 'axios';
import update from 'immutability-helper';
import modelService from 'services/ModelService';
import './Models.less';

export type ModelsProps = RouteComponentProps & {
    className?: string
}

export type ModelsState = {
    status: string,
    models: any[] // TODO: change to Metadata
}

export class Models extends PureComponent<ModelsProps, ModelsState> {

    constructor(props: ModelsProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            models: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleModelCreate = this.handleModelCreate.bind(this);
        this.handleModelEdit = this.handleModelEdit.bind(this);
        this.handleModelDelete = this.handleModelDelete.bind(this);
        this.handleModelVersion = this.handleModelVersion.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleModelCreate(): void {
        const url = RouteBuilder.model("create", "sheets");
        this.props.history.push(url);
    }

    private handleModelEdit(model: Model): void {
        const url = RouteBuilder.model(model.id, "sheets")
        this.props.history.push(url);
    }

    private handleModelDelete(model: Model): void {
        this.deleteModelRequest(model.id);
    }

    private handleModelVersion(model: Model): void {
        this.createModelVersionRequest(model.id);
    }

    private deleteModelRequest(modelId: string): Promise<any> {
        const request = {
            modelId: modelId
        };
        return modelService.deleteModel(request,
            (response: any) => this.deleteModelResponse(response)
        );
    }

    private deleteModelResponse(response: any): void {
        const modelId = response.data.modelId;
        const index = this.state.models.findIndex(model => model.id === modelId);
        this.setState({
            models: update(this.state.models, {
                $splice: [[index, 1]]
            })
        });
    }

    private createModelVersionRequest(modelId: string): Promise<any> {
        const request = {
            modelId: modelId
        };
        return modelService.createModelVersion(request,
            (response: any) => this.createModelVersionResponse(response)
        );
    }

    private createModelVersionResponse(response: any): void {
        const version = response.data.version;
        const modelId = response.data.modelId;
        const index = this.state.models.findIndex(model => model.id === modelId);
        this.setState({
            models: update(this.state.models, {
                [index]: {
                    version: { $set: version }
                }
            })
        });
    }

    private readModelsRequest(): Promise<any> {
        const request = {};
        return modelService.readModelHeaders(request,
            (response: any) => this.readModelsResponse(response),
            undefined, true
        );
    }

    private readModelsResponse(response: any): void {
        const models = response.data.models;
        this.setState({ models: models });
    }

    private buildColumns(): ColumnsType<any> {
        let i = 0;
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            ellipsis: true,
            width: Globals.TABLE_NAME_WIDTH,
            render: (value: string, record: any) => (
                <Link to={RouteBuilder.model(record.id, "sheets")}>{value}</Link>
            ),
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            dataIndex: 'description',
            render: (value: string, record: any) => {
                return (
                    <span>{record.description ? record.description : Globals.EMPTY_FIELD}</span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'tags',
            title: 'Tags',
            align: 'center',
            dataIndex: 'keywords',
            render: (value: string[], record: any) => {
                if (record.keywords) {
                    return record.keywords.map((keyword: string, index: number) => (
                        <Tag key={index} color={Globals.TAG_COLORS[i++ % Globals.TAG_COLORS.length]}>{keyword}</Tag>
                    ));
                } else {
                    return Globals.EMPTY_FIELD;
                }
            },
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.categoryType, b.categoryType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'version',
            title: 'Latest Version',
            align: 'center',
            dataIndex: 'version',
            width: Globals.TABLE_VERSION_WIDTH,
            render: (value: boolean, record: any) => {
                return (
                    <span>
                        {CoreUtils.toVersion(record.version)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            dataIndex: 'updateTime',
            width: Globals.TABLE_DATE_WIDTH,
            render: (value: any, record: any) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(record.updateTime)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readModelsRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit model",
            callback: this.handleModelEdit
        }, {
            icon: <NumberOutlined />,
            label: "Create new version",
            callback: this.handleModelVersion
        }, {
            icon: <DeleteOutlined />,
            label: "Delete model",
            confirm: "Are you sure you want to delete the model?",
            callback: this.handleModelDelete
        }];
        const columns = this.buildColumns();
        const data = this.state.models;
        return (
            <ItemTable
                title="Models"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={
                    <Spacer>
                        <Button onClick={this.handleModelCreate}>New Model</Button>
                        <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                    </Spacer>
                }
                actions={actions}
                onLoad={this.handleRetryLoad}
            />
        );
    }

}
