import React, { PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CodeOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, message } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteBuilder } from 'utils/RouteBuilder';
import { EntityContext } from 'context/EntityContext';
import authService from 'services/AuthService';
import update from 'immutability-helper';
import './ChangeEmailConfirm.less';

interface FormData {
    confirmationCode?: string
}

export type ChangeEmailConfirmProps = RouteComponentProps & {}

export type ChangeEmailState = {
    errorMessage: string | undefined,
    isSubmitting: boolean,
    formData: FormData
}

export class ChangeEmailConfirm extends PureComponent<ChangeEmailConfirmProps, ChangeEmailState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: ChangeEmailConfirmProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isSubmitting: false,
            formData: {}
        };
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleCodeChange(e: any): void {
        const confirmationCode = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                confirmationCode: { $set: confirmationCode }
            })
        });
    }

    private handleFormFinish(): void {
        this.verifyAttributeRequest();
    }

    private verifyAttributeRequest(): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: true
        });
        const confirmationCode = this.state.formData.confirmationCode;
        if (confirmationCode) {
            authService.verifyAttribute('email', confirmationCode,
                (response: any) => this.verifyAttributeResponse(response),
                (response: any) => this.verifyAttributeException(response)
            );
        }
    }

    private verifyAttributeResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: false
        });
        authService.refreshUser().then((user) => {
            this.context.saveUser(user);
        }).catch((err) => {
            this.context.clearUser();
        });
        message.success('Your email has been updated.');
        this.props.history.go(-2);
    }

    private verifyAttributeException(response: any): void {
        this.setState({
            errorMessage: response.message,
            isSubmitting: false
        });
    }

    public render(): ReactElement {
        return (
            <FormFrame
                ref={this.formRef}
                title="Change Email"
                description="Please enter your confirmation code below."
                error={this.state.errorMessage}
                hideFooter={true}
                onOk={this.handleFormFinish}
            >
                <FormItem
                    formRef={this.formRef}
                    name="code"
                    rules={[{
                        required: true,
                        message: 'Please enter the confirmation code.'
                    }]}
                >
                    <Input
                        id="code"
                        prefix={<CodeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Confirmation code."
                        value={this.state.formData.confirmationCode}
                        onChange={this.handleCodeChange}
                    />
                </FormItem>
                <Button
                    className="x-changeemailconfirm-submit"
                    type="primary"
                    htmlType="submit"
                    loading={this.state.isSubmitting}
                >
                    Submit
                </Button>
                <div className="x-changeemailconfirm-footer">
                    <span className="x-changeemailconfirm-question">Didn't get your confirmation code?</span>
                    <Link to={RouteBuilder.MAIN_CHANGE_EMAIL}>Send again.</Link>
                </div>
            </FormFrame>
        );
    }

}
