import React, { PureComponent, ReactElement } from 'react';
import { FormInstance, Input } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ItemEditor } from 'containers/Console/ItemEditor/ItemEditor';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RestUtils } from 'utils/RestUtils';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { Group } from '@methodset/entity-client-ts';
import entityService from 'services/EntityService';
import authService from 'services/AuthService';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import './GroupAddMember.less';

type MatchParams = {
    groupId: string
}

export type GroupAddMemberProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export type GroupAddMemberState = {
    emailAddress?: string,
    errorMessage: string | undefined,
    isLoading: boolean,
    isCreate: boolean
}

export class GroupAddMember extends PureComponent<GroupAddMemberProps, GroupAddMemberState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();
    private groupId: string;

    constructor(props: GroupAddMemberProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isLoading: false,
            isCreate: false
        };
        this.groupId = props.match.params.groupId;
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
    }

    private handleSaveClick(): void {
        this.addGroupMemberRequest();
    }

    private handleCancelClick(): void {
        this.props.history.goBack();
    }

    private handleEmailChange(e: any): void {
        const emailAddress = e.target.value;
        this.setState({ emailAddress: emailAddress });
    }

    private addGroupMemberRequest(): Promise<any> {
        this.setState({
            errorMessage: undefined,
            isLoading: true
        });
        const request = {
            groupId: this.groupId,
            emailAddress: this.state.emailAddress
        }
        return entityService.addGroupMember(request,
            (response: any) => this.addGroupMemberResponse(response),
            (error: any) => this.addGroupMemberException(error),
            true
        );
    }

    private addGroupMemberResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isLoading: false
        });
        this.props.history.goBack();
    }

    private addGroupMemberException(error: any): void {
        this.setState({
            errorMessage: RestUtils.getError(error),
            isLoading: false
        });
    }

    public render(): ReactElement {
        return (
            <ItemEditor
                className={classNames('x-groupaddmember', this.props.className)}
                ref={this.formRef}
                title="Add Group Member"
                loading={this.state.isLoading}
                okText="Add"
                onSave={this.handleSaveClick}
                onCancel={this.handleCancelClick}
            >
                <div className="x-groupaddmember-intro">Enter the email address of the user you would like to add to your group.</div>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Email"
                    name="email"
                    rules={[{
                        required: true,
                        message: 'Please enter an email address.'
                    }, {
                        type: 'email',
                        message: 'The email address is invalid.'
                    }]}
                >
                    <Input
                        id="email"
                        placeholder="Email address."
                        value={this.state.emailAddress}
                        onChange={this.handleEmailChange}
                    />
                </FormItem>
                <div className="x-groupaddmember-error">{this.state.errorMessage}</div>
            </ItemEditor>
        );
    }

}
