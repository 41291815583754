import React, { MouseEvent, PureComponent, ReactElement } from 'react';
import { DeleteOutlined, EditOutlined, NumberOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, Space, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { InitEmail } from './InitEmail/InitEmail';
import { InitFile } from './InitFile/InitFile';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { TriggerType, WorkflowHeader } from '@methodset/workflow-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils, ColorMap } from 'utils/CoreUtils';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import workflowService from 'services/WorkflowService';
import './Workflows.less';

interface ModalData {
    email: {
        visible: boolean,
        name?: string,
        address?: string
    },
    file: {
        visible: boolean,
        name?: string,
        workflowId?: string
    }
}

export type WorkflowsState = {
    status: string,
    headers: WorkflowHeader[],
    modal: ModalData
}

export type WorkflowsProps = RouteComponentProps & {
    className?: string
}

export class Workflows extends PureComponent<WorkflowsProps, WorkflowsState> {

    private colorMap: ColorMap;

    constructor(props: WorkflowsProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            headers: [],
            modal: {
                email: {
                    visible: false,
                    name: undefined,
                    address: undefined
                },
                file: {
                    visible: false,
                    name: undefined,
                    workflowId: undefined
                }
            } as ModalData
        };
        this.colorMap = CoreUtils.toColorMap([TriggerType.SCHEDULE, TriggerType.EMAIL, TriggerType.FILE]);
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleEnableChange = this.handleEnableChange.bind(this);
        this.handleEmailClick = this.handleEmailClick.bind(this);
        this.handleEmailClose = this.handleEmailClose.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
        this.handleFileClose = this.handleFileClose.bind(this);
        this.handleWorkflowCreate = this.handleWorkflowCreate.bind(this);
        this.handleWorkflowEdit = this.handleWorkflowEdit.bind(this);
        this.handleWorkflowDelete = this.handleWorkflowDelete.bind(this);
        this.handleWorkflowVersion = this.handleWorkflowVersion.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleEnableChange(e: CheckboxChangeEvent): void {
        const workflowId = e.target.id;
        const isEnabled = e.target.checked;
        this.updateEnabledRequest(workflowId!, isEnabled);
    }

    private handleEmailClick(e: MouseEvent<HTMLButtonElement>) {
        const workflowId = e.currentTarget.id;
        const header = this.state.headers.find(header => header.id === workflowId);
        const modal = update(this.state.modal, {
            email: {
                name: { $set: header!.name }
            }
        });
        this.setState({ modal: modal });
        this.readWorkflowEmailRequest(workflowId);
    }

    private handleEmailClose(): void {
        const modal = update(this.state.modal, {
            email: {
                visible: { $set: false },
                address: { $set: undefined },
                name: { $set: undefined }
            }
        });
        this.setState({ modal: modal });
    }

    private handleFileClick(e: MouseEvent<HTMLButtonElement>): void {
        const workflowId = e.currentTarget.id;
        const header = this.state.headers.find(header => header.id === workflowId);
        const modal = update(this.state.modal, {
            file: {
                visible: { $set: true },
                name: { $set: header!.name },
                workflowId: { $set: workflowId }
            }
        });
        this.setState({ modal: modal });
    }

    private handleFileClose(): void {
        const modal = update(this.state.modal, {
            file: {
                visible: { $set: false },
                name: { $set: undefined },
                workflowId: { $set: undefined }
            }
        });
        this.setState({ modal: modal });
    }

    private handleWorkflowCreate(): void {
        const url = RouteBuilder.workflow('create');
        this.props.history.push(url);
    }

    private handleWorkflowEdit(workflow: WorkflowHeader): void {
        const url = RouteBuilder.workflow(workflow.id);
        this.props.history.push(url);
    }

    private handleWorkflowDelete(workflow: WorkflowHeader): void {
        this.deleteWorkflowRequest(workflow.id);
    }

    private handleWorkflowVersion(workflowId: string): void {
        this.versionWorkflowRequest(workflowId);
    }

    private updateEnabledRequest(workflowId: string, isEnabled: boolean): Promise<any> {
        const request = {
            workflowId: workflowId,
            isEnabled: isEnabled
        };
        return workflowService.updateEnabled(request,
            (response: any) => this.updateEnabledResponse(response),
            undefined, false
        );
    }

    private updateEnabledResponse(response: any): void {
        const workflowId = response.data.workflowId;
        const isEnabled = response.data.isEnabled;
        const index = this.state.headers.findIndex(header => header.id === workflowId);
        const headers = update(this.state.headers, {
            [index]: {
                isDisabled: { $set: !isEnabled }
            }
        });
        this.setState({ headers: headers });
    }

    private readWorkflowEmailRequest(workflowId: string): Promise<any> {
        const request = {
            workflowId: workflowId
        };
        return workflowService.readWorkflowEmail(request,
            (response: any) => this.readWorkflowEmailResponse(response),
            (response: any) => this.readWorkflowEmailException(response),
            true
        );
    }

    private readWorkflowEmailResponse(response: any): void {
        const address = response.data.emailAddress;
        const modal = update(this.state.modal, {
            email: {
                visible: { $set: true },
                address: { $set: address }
            }
        });
        this.setState({ modal: modal });
    }

    private readWorkflowEmailException(response: any): void {
        const modal = update(this.state.modal, {
            email: {
                visible: { $set: false },
                address: { $set: undefined },
                name: { $set: undefined }
            }
        });
        this.setState({ modal: modal });
    }

    private readWorkflowHeadersRequest(): Promise<any> {
        const request = {};
        return workflowService.readWorkflowHeaders(request,
            (response: any) => this.readWorkflowHeadersResponse(response),
            undefined, true
        );
    }

    private readWorkflowHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private deleteWorkflowRequest(workflowId: string): Promise<any> {
        const request = {
            workflowId: workflowId
        };
        return workflowService.deleteWorkflow(request,
            (response: any) => this.deleteWorkflowResponse(response)
        );
    }

    private deleteWorkflowResponse(response: any): void {
        const workflowId = response.data.workflowId;
        const index = this.state.headers.findIndex(header => header.id === workflowId);
        this.setState({
            headers: update(this.state.headers, {
                $splice: [[index, 1]]
            })
        });
    }

    private versionWorkflowRequest(workflowId: string): Promise<any> {
        const request = {
            workflowId: workflowId
        };
        return workflowService.createWorkflowVersion(request,
            (response: any) => this.versionWorkflowResponse(response)
        );
    }

    private versionWorkflowResponse(response: any): void {
        const version = response.data.version;
        const workflowId = response.data.workflowId;
        const index = this.state.headers.findIndex(header => header.id === workflowId);
        this.setState({
            headers: update(this.state.headers, {
                [index]: {
                    version: { $set: version }
                }
            })
        });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            ellipsis: true,
            dataIndex: 'name',
            width: Globals.TABLE_NAME_WIDTH,
            render: (value, record) => {
                return (
                    <div className="x-workflows-name">
                        <Link to={RouteBuilder.workflow(record.id)}>{value}</Link>
                        {/* {record.triggerType === 'EMAIL' &&
                            <Button id={record.id} type="link" icon={<MailOutlined />} onClick={this.handleEmailClick}></Button>
                        }
                        {record.triggerType === 'FILE' &&
                            <Button id={record.id} type="link" icon={<CloudUploadOutlined />} onClick={this.handleFileClick}></Button>
                        } */}
                    </div>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'active_version',
            title: 'Active Version',
            align: 'center',
            dataIndex: 'activeVersion',
            width: Globals.TABLE_VERSION_WIDTH,
            render: (value: number, record: any) => {
                return (
                    <span>
                        {CoreUtils.toVersion(value)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'type',
            title: 'Trigger',
            align: 'center',
            dataIndex: 'triggerType',
            render: (value, record) => {
                return (
                    <div>
                        {record.triggerType === 'EMAIL' &&
                            <Button
                                id={record.id}
                                type="link"
                                onClick={this.handleEmailClick}
                            >
                                <Tag color={this.colorMap[value]}>{CoreUtils.toProper(value, '_', ' ')}</Tag>
                            </Button>
                        }
                        {record.triggerType === 'FILE' &&
                            <Button 
                                id={record.id} 
                                type="link" 
                                onClick={this.handleFileClick}
                            >
                                <Tag color={this.colorMap[value]}>{CoreUtils.toProper(value, '_', ' ')}</Tag>
                            </Button>
                        }
                        {record.triggerType === 'SCHEDULE' &&
                            <Tag color={this.colorMap[value]}>{CoreUtils.toProper(value, '_', ' ')}</Tag>
                        }
                    </div>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.triggerType, b.triggerType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'enabled',
            title: 'Enabled',
            align: 'center',
            dataIndex: 'isDisabled',
            render: (value, record) => {
                return (
                    <Checkbox
                        id={record.id}
                        checked={!value}
                        onChange={this.handleEnableChange}
                    />
                );
            }
        }, {
            key: 'version',
            title: 'Latest Version',
            align: 'center',
            width: Globals.TABLE_VERSION_WIDTH,
            dataIndex: 'version',
            render: (value: number, record: any) => {
                return (
                    <span>
                        {CoreUtils.toVersion(value)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            dataIndex: 'updateTime',
            width: Globals.TABLE_DATE_WIDTH,
            render: (value, record) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(value)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private buildData(): WorkflowHeader[] {
        return this.state.headers;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readWorkflowHeadersRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit workflow",
            callback: this.handleWorkflowEdit
        }, {
            icon: <NumberOutlined />,
            label: "Create new version",
            callback: this.handleWorkflowVersion
        }, {
            icon: <DeleteOutlined />,
            label: "Delete workflow",
            confirm: "Are you sure you want to delete the workflow?",
            callback: this.handleWorkflowDelete
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <React.Fragment>
                <ItemTable
                    className={classNames('x-workflows', this.props.className)}
                    title="Workflows"
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={
                        <Space>
                            <Button onClick={this.handleWorkflowCreate}>New Workflow</Button>
                            <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                        </Space>
                    }
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {this.state.modal.email.visible &&
                    <InitEmail
                        name={this.state.modal.email.name!}
                        address={this.state.modal.email.address!}
                        onClose={this.handleEmailClose}
                    />
                }
                {this.state.modal.file.visible &&
                    <InitFile
                        name={this.state.modal.file.name!}
                        workflowId={this.state.modal.file.workflowId!}
                        onClose={this.handleFileClose}
                    />
                }
            </React.Fragment>
        );
    }

}
