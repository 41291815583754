import { ChangeEvent, ReactElement } from 'react';
import { FormInstance, Input, Switch } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import update from 'immutability-helper';
import './RulesEditor.less';

export type RulesData = {
    isEnabled?: boolean,
    domain?: string
    whitelist?: string,
    blacklist?: string,
}

export type ChangeCallback = (data: RulesData) => void;

export type RulesEditorProps = {
    formRef: React.RefObject<FormInstance>,
    rules?: RulesData,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    rules: {} as RulesData
}

export const RulesEditor = (props: RulesEditorProps): ReactElement => {

    const handleEnableChange = (isEnabled: boolean): void => {
        const rules = update(props.rules, {
            isEnabled: { $set: isEnabled }
        });
        props.onChange(rules);
    }

    const handleWhitelistChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const whitelist = e.target.value;
        const rules = update(props.rules, {
            whitelist: { $set: whitelist }
        });
        props.onChange(rules);
    }

    const handleBlacklistChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const blacklist = e.target.value;
        const rules = update(props.rules, {
            blacklist: { $set: blacklist }
        });
        props.onChange(rules);
    }

    const handleDomainChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const domain = e.target.value;
        const rules = update(props.rules, {
            domain: { $set: domain }
        });
        props.onChange(rules);
    }

    const toItems = (value: string | undefined): string[] => {
        return value ? value.split(/[, ]+/) : [];
    }

    return (
        <>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Enable Registration"
                name="enable"
                info="Enables the ability of users to join the organization."
                valuePropName="checked"
            >
                <Switch
                    checked={props.rules.isEnabled}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={handleEnableChange}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Whitelist"
                name="whitelist"
                info="A list of user email addresses that are allowed to join the organization."
                rules={[{
                    validator: (rule: any, whitelist: string) => {
                        const addresses = toItems(whitelist)
                        for (let address of addresses) {
                            if (!address.includes("@")) {
                                return Promise.reject(`Address '${address}' is invalid.`);
                            }
                        }
                        return Promise.resolve();
                    }
                }]}
            >
                <Input
                    id="whitelist"
                    placeholder="Allowed email addresses."
                    value={props.rules.whitelist}
                    onChange={handleWhitelistChange}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Blacklist"
                name="blacklist"
                info="A list of user email addresses that are not allowed to join the organization."
                rules={[{
                    validator: (rule: any, blacklist: string) => {
                        const addresses = toItems(blacklist)
                        for (let address of addresses) {
                            if (!address.includes("@")) {
                                return Promise.reject(`Address '${address}' is invalid.`);
                            }
                        }
                        return Promise.resolve();
                    }
                }]}
            >
                <Input
                    id="blacklist"
                    placeholder="Disallowed email addresses."
                    value={props.rules.blacklist}
                    onChange={handleBlacklistChange}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Domain"
                name="domain"
                info="The domain of the organization, i.e., 'example.com'. This can be used to restrict which user email addresses that can join the organization."
                rules={[{
                    validator: (rule: any, domain: string) => {
                        return !domain || domain.includes(".") ? Promise.resolve() : Promise.reject("Invalid domain format.");
                    }
                }]}
            >
                <Input
                    id="domain"
                    placeholder="Organization domain."
                    value={props.rules.domain}
                    onChange={handleDomainChange}
                />
            </FormItem>
        </>
    )
}

RulesEditor.defaultProps = defaultProps;
