import { FC, ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance, Modal, Select } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Item } from '../ItemLayout/ItemLayout';
import { CoreUtils } from 'utils/CoreUtils';
import update from 'immutability-helper';
import './ItemPosition.less';

export type ItemPositionProps = {
    rows: number,
    cols: number,
    type: string,
    item: Item,
    items: Item[],
    onChange: (item: Item) => void,
    onCancel: () => void
}

export const ItemPosition: FC<ItemPositionProps> = (props: ItemPositionProps): ReactElement => {

    const toKey = (item: Item): string => {
        return `${item.row},${item.col}`;
    }

    const buildSetupSet = (): Set<string> => {
        const set = new Set<string>();
        for (const item of props.items) {
            if (item.row === props.item.row && item.col === props.item.col) {
                // Remove the ref being edited from the set of prohibited locations.
                continue;
            }
            const key = toKey(item);
            set.add(key);
        }
        return set;
    }

    // The form reference.
    const formRef: Ref<FormInstance> = useRef(null);
    // A set of locations used by widget refs. The ref being edited is not
    // included in the list since it is not prohibited from occupying its 
    // own location.
    const refSet = useRef(buildSetupSet());
    // The widget being edited.
    const [item, setItem] = useState<Item>(props.item);

    const handleOk = (): void => {
        formRef.current?.submit();
    }

    const handleCancel = (): void => {
        props.onCancel();
    }

    const handleFormFinish = (): void => {
        props.onChange(item);
    }

    const handleRowChange = (row: number | undefined): void => {
        const ref = update(item, {
            row: { $set: row as any }
        });
        setItem(ref);
    }

    const handleColumnChange = (column: number): void => {
        const ref = update(item, {
            col: { $set: column }
        });
        setItem(ref);
    }

    const handleSpanChange = (span: number): void => {
        const ref = update(item, {
            span: { $set: span }
        });
        setItem(ref);
    }

    const isLocationTaken = (item: Item): boolean => {
        const key = toKey(item);
        return refSet.current.has(key);
    }

    return (
        <Modal
            className="x-widgetposition"
            title={`${CoreUtils.toCapital(props.type)} Position`}
            width={Globals.DIALOG_WIDTH_SHORT}
            onOk={handleOk}
            onCancel={handleCancel}
            visible={true}>
            <Form ref={formRef} onFinish={handleFormFinish}>
                <FormItem
                    // TODO: make sure not 2 refs are in the same position.
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Row"
                    name="row"
                    info={`The row index in which to position the ${props.type} (1-${props.rows}).`}
                    rules={[{
                        required: true,
                        message: 'Please enter a row index.'
                    }, {
                        validator: (rule: any, row: number) => {
                            if (isLocationTaken(item)) {
                                return Promise.reject(`A ${props.type} already exists in location (${item.row + 1}, ${item.col + 1}).`);
                            } else {
                                return Promise.resolve();
                            }
                        }
                    }]}
                >
                    <Select
                        value={item.row}
                        onChange={handleRowChange}
                    >
                        {Array.from(Array(props.rows).keys()).map(index => (
                            <Select.Option key={index} value={index}>{index + 1}</Select.Option>
                        ))}
                    </Select>
                </FormItem>
                <FormItem
                    // TODO: make sure not 2 refs are in the same position.
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Column"
                    name="column"
                    info={`The column index in which to position the ${props.type} (1-${props.cols}).`}
                    rules={[{
                        required: true,
                        message: 'Please select a column index.'
                    }, {
                        validator: (rule: any, col: number) => {
                            if (isLocationTaken(item)) {
                                return Promise.reject(`A ${props.type} already exists in location (${item.row + 1}, ${item.col + 1}).`);
                            } else {
                                return Promise.resolve();
                            }
                        }
                    }]}
                >
                    <Select
                        value={item.col}
                        onChange={handleColumnChange}
                    >
                        {Array.from(Array(props.cols).keys()).map(index => (
                            <Select.Option key={index} value={index}>{index + 1}</Select.Option>
                        ))}
                    </Select>
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Span"
                    name="span"
                    info={`The number of columns the ${props.type} should span (1-${props.cols}).`}
                    rules={[{
                        required: true,
                        message: 'Please select a column span.'
                    }, {
                        validator: (rule: any, span: number) => {
                            const maxSpan = props.cols - item.col;
                            if (span > maxSpan) {
                                return Promise.reject(`The ${props.type} in column ${item.col + 1} must have a span less than or equal to ${maxSpan}.`);
                            } else {
                                return Promise.resolve();
                            }
                        }
                    }]}
                >
                    <Select
                        value={item.span}
                        onChange={handleSpanChange}
                    >
                        {Array.from(Array(props.cols).keys()).map(index => (
                            <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                        ))}
                    </Select>
                </FormItem>
            </Form>
        </Modal>
    )

}
