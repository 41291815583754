import { PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, message, Modal } from 'antd';
import { CalculatorReadyEvent, ExecutionCompleteEvent, Calculator, StatusType, CalculatorDestroyEvent } from '@methodset/calculator-ts';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner'
import { ModelHeading } from './ModelHeading/ModelHeading';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { ModelContext } from 'context/ModelContext';
import { Applet, Model, Widget } from '@methodset/model-client-ts';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ModelCalculator } from './ModelCalculator/ModelCalculator';
import { ModelApplets } from './ModelApplets/ModelApplets';
import { Location } from 'history';
import { WidgetSyncFactory } from 'sync/WidgetSyncFactory';
import { AppletSync } from 'sync/AppletSync';
import { KeyConverter, Profile } from '@methodset/entity-client-ts';
import CacheRoute from 'react-router-cache-route';
import axios from 'axios';
import classNames from 'classnames';
import modelService from 'services/ModelService';
import entityService from 'services/EntityService';
import './ModelItem.less';

// The state of exiting. Since the model may need to be saved
// before exiting, the state must be tracked to allow the save
// to complete before exiting.
type ExitState = "init" | "failed" | "started" | "finished";

type MatchParams = {
    modelId: string
}

// Auto-save interval in seconds.
const SAVE_INTERVAL = 60 * 1000;

export type ModelItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export type ModelItemState = {
    status: string,
    isSaving: boolean,
    exitState: ExitState
}

export class ModelItem extends PureComponent<ModelItemProps, ModelItemState> {

    static contextType = ModelContext;

    private timerId: NodeJS.Timeout | null = null;
    private nextPathname: string | null = null;
    private unmonitorFn: any;
    private profile: Profile | undefined;

    constructor(props: ModelItemProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            isSaving: false,
            exitState: "init"
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleModelSave = this.handleModelSave.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleExitCheck = this.handleExitCheck.bind(this);
        this.handleExitWithSave = this.handleExitWithSave.bind(this);
        this.handleExitNoSave = this.handleExitNoSave.bind(this);
        this.handleExitCancel = this.handleExitCancel.bind(this);
    }

    private handleRetryLoad(): void {
        const modelId = this.props.match.params.modelId;
        this.loadData(modelId);
    }

    private handleModelSave(): void {
        this.saveModelRequest();
    }

    private handleModelChange(model: Model): void {
        if (model.autoSave) {
            this.startAutoSave();
        } else {
            this.stopAutoSave();
        }
    }

    private handleExitCheck(location: Location<any>): false | void {
        if (this.state.exitState === "finished") {
            return;
        }
        // Check if navigating away from the model item editor.
        // If so, save the model.
        const modelId = this.props.match.params.modelId;
        if (location.pathname !== RouteBuilder.model(modelId, "sheets") &&
            location.pathname !== RouteBuilder.model(modelId, "applets")) {
            this.nextPathname = location.pathname;
            if (this.context.model.autoSave) {
                this.saveModelRequest(true);
            } else {
                this.setState({ exitState: "started" });
            }
            return false;
        }
    }

    private handleExitWithSave(): void {
        this.saveModelRequest(true);
    }

    private handleExitNoSave(): void {
        this.setState({ exitState: "finished" }, () => {
            this.props.history.push(this.nextPathname!);
        });
    }

    private handleExitCancel(): void {
        this.setState({ exitState: "init" });
    }

    private readProfileRequest(): Promise<any> {
        const request = {
            asOwner: false
        };
        return entityService.readProfile(request,
            (response: any) => this.readProfileResponse(response),
            undefined, true
        );
    }

    private readProfileResponse(response: any): void {
        const profile = response.data.profile;
        this.profile = KeyConverter.camelToSnake(profile);
    }

    private initModelRequest(modelId: string): Promise<any> {
        if (modelId === "create") {
            return this.createModelRequest();
        } else {
            return this.loadModelRequest(modelId);
        }
    }

    private createModelRequest(): Promise<any> {
        const request = {
            name: "New Model"
        };
        return modelService.createModel(request,
            (response: any) => this.createModelResponse(response),
            undefined, true
        );
    }

    private createModelResponse(response: any): void {
        const model = response.data.model;
        this.setupModel(model);
        // Change the URL to include the new model id.
        this.props.history.push(RouteBuilder.model(model.id));
    }

    private loadModelRequest(modelId: string): Promise<any> {
        // Version will default to "snapshot" since it is the only one that can be edited.
        const request = {
            modelId: modelId
        };
        return modelService.readModel(request,
            (response: any) => this.loadModelResponse(response),
            undefined, true
        );
    }

    private loadModelResponse(response: any): void {
        const model = response.data.model;
        this.setupModel(model);
    }

    private saveModelRequest(isExiting: boolean = false): Promise<any> {
        if (this.state.isSaving) {
            return Promise.resolve();
        }
        this.stopAutoSave();
        this.setState({ isSaving: true });
        const model = this.context.model;
        const calculator = this.context.calculator;
        const request = {
            modelId: calculator.id,
            model: model,
            calculator: Calculator.serialize(calculator)
        };
        return modelService.updateModel(request,
            (response: any) => this.saveModelResponse(response, isExiting),
            (error: Error) => this.saveModelException(error, isExiting),
            true
        );
    }

    private saveModelResponse(response: any, isExiting: boolean): void {
        if (!isExiting) {
            this.setState({ isSaving: false });
            this.startAutoSave();
        } else {
            // Save is complete, now exit.
            this.setState({ exitState: "finished" });
            this.props.history.push(this.nextPathname!);
        }
    }

    private saveModelException(error: Error, isExiting: boolean): void {
        console.log(`Error saving model: ${RestUtils.getError(error)}`);
        if (isExiting) {
            if (this.context.model.autoSave) {
                // Auto-save failed, need to give the user a way to exit.
                // They can choose to try to save again or exit without saving.
                this.setState({ exitState: "failed" });
            } else {
                // Let the user try again.
                this.setState({ exitState: "init" });
            }
        }
        this.setState({ isSaving: false });
        this.startAutoSave();
        message.error("Error saving model.");
    }

    private setupModel(model: Model): void {
        const calculator = Calculator.deserialize(model.calculator!);
        calculator.httpHeaders = RestUtils.getHttpHeaders();
        // Fix the request key since this is a single-user environment.
        calculator.context.requestKey = 0;
        calculator.addCallback("CalculatorReady", (event: CalculatorReadyEvent) => {
            // Show the calculated sheets on startup.
            this.startAutoSave();
            this.setState({ status: Globals.STATUS_READY });
            // Start monitoring URL changes to watch for exit.
            this.unmonitorFn = this.props.history.block(this.handleExitCheck);
        });
        calculator.addCallback("CalculatorDestroy", (event: CalculatorDestroyEvent) => {
            // Stop monitoring URL changes.
            if (this.unmonitorFn) {
                this.unmonitorFn();
            }
        });
        calculator.addCallback("ExecutionComplete", (event: ExecutionCompleteEvent) => {
            if (event.status.code === StatusType.ERROR) {
                message.error(event.status.message);
            }
            //calculator.printState(true);
        });
        // Clear out the json calculator to save memory.
        model.calculator = undefined as any;
        this.context.saveModel(model);
        this.context.saveCalculator(calculator);
        // Register all applets to get model changes.
        this.registerApplets(calculator);
        // Execution will start when all data is loaded.

        // XXX REMOVE
        // Execute the calculations and continue in the complete callback.
        // this.context.calculator.execute();
    }

    private loadData(modelId: string): void {
        const requests = [];
        requests.push(this.initModelRequest(modelId));
        requests.push(this.readProfileRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                // Execute the calculations and continue in the complete callback.
                // When execution is complete, status will changed to ready.
                const calculator = this.context.calculator;
                calculator.setProfile(this.profile);
                calculator.execute();

                // XXX REMOVE
                // // If not already initialized, wait for model to execute.
                // const status = this.context.calculator.status;
                // if (status === Calculator.Status.READY) {
                //     this.setState({ status: Globals.STATUS_READY });
                // }
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-modelitem-loading"
                loadingMessage="Loading model..."
                status={isLoading ? "loading" : "failed"}
                onRetry={this.handleRetryLoad}
            />
        )
    }

    private buildModelView(): ReactElement {
        return (
            <div>
                <ModelHeading
                    isSaving={this.state.isSaving}
                    onSave={this.handleModelSave}
                    onChange={this.handleModelChange}
                    {...this.props}
                />
                <div className="x-modelitem-body">
                    <CacheRoute>
                        <CacheRoute
                            when="always"
                            path={RouteBuilder.CONSOLE_MODEL_SHEETS}
                            render={(props) => <ModelCalculator />}
                        />
                        <CacheRoute
                            when="always"
                            path={RouteBuilder.CONSOLE_MODEL_APPLETS}
                            render={(props) => <ModelApplets />}
                        />
                    </CacheRoute>
                </div>
                {(this.state.exitState === "failed" || this.state.exitState === "started") &&
                    <Modal
                        centered
                        title="Save Model"
                        visible={true}
                        width={Globals.DIALOG_WIDTH_SHORT}
                        onCancel={this.handleExitCancel}
                        footer={(
                            <>
                                <Button onClick={this.handleExitNoSave}>No</Button>
                                <Button type="primary" onClick={this.handleExitWithSave}>Yes</Button>
                            </>
                        )}
                    >
                        <span>
                            Do you want to save the model before exiting?
                        </span>
                    </Modal>
                }
            </div>
        )
    }

    private stopAutoSave(): void {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    private startAutoSave(): void {
        if (this.context.model.autoSave && !this.timerId) {
            this.timerId = setTimeout(() => this.saveModelRequest(), SAVE_INTERVAL);
        }
    }

    private registerApplets(calculator: Calculator): void {
        // Register all applets and widgets to get updated when calculator components (i.e., sheet name) change.
        const registry = calculator.registry;
        const applets: Applet[] = this.context.model.applets;
        for (const applet of applets) {
            const widgets: Widget[] = applet.widgets;
            for (const widget of widgets) {
                const widgetSync = WidgetSyncFactory.createSync(widget.configuration);
                if (widgetSync) {
                    registry.register(widget.id, widget, widgetSync.parser, widgetSync.updater);
                }
            }
            registry.register(applet.id, applet, AppletSync.parser, AppletSync.updater);
        }
    }

    private unregisterApplets(calculator: Calculator): void {
        const registry = calculator.registry;
        const applets: Applet[] = this.context.model.applets;
        for (const applet of applets) {
            const widgets: Widget[] = applet.widgets;
            for (const widget of widgets) {
                registry.unregister(widget.id);
            }
            registry.unregister(applet.id);
        }
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData(this.props.match.params.modelId);
        }
    }

    public componentWillUnmount(): void {
        // Stop auto-save.
        this.stopAutoSave();
        // Close calculator.
        const calculator = this.context.calculator;
        if (calculator) {
            this.unregisterApplets(calculator);
            calculator.close();
        }
        // Stop monitoring URL changes.
        if (this.unmonitorFn) {
            this.unmonitorFn();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildModelView();
        }
        return (
            <div className={classNames('x-modelitem', this.props.className)}>
                {view}
            </div>
        )
    }

}
