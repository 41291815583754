import React, { PureComponent, ReactElement } from 'react';
import { FormInstance, Select } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { IntervalEditor, IntervalPeriod } from './IntervalEditor/IntervalEditor';
import { PointEditor, PointPeriod } from './PointEditor/PointEditor';
import { Globals } from 'constants/Globals';
import { Cron, FrequencyType } from '@methodset/schedule-client-ts';
import { ScheduleTrigger, Trigger } from '@methodset/workflow-client-ts';
import update from 'immutability-helper';
import './ScheduleEditor.less';

export type ChangeCallback = (trigger: Trigger) => void;

export type ScheduleEditorState = {
    frequencyType?: FrequencyType
}

export type ScheduleEditorProps = {
    formRef: React.RefObject<FormInstance>,
    trigger: ScheduleTrigger,
    onChange: ChangeCallback
}

export class ScheduleEditor extends PureComponent<ScheduleEditorProps, ScheduleEditorState> {

    constructor(props: ScheduleEditorProps) {
        super(props);
        this.state = {
            frequencyType: props.trigger.cron ? props.trigger.cron.frequencyType : undefined
        };
        this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
        this.handleCronChange = this.handleCronChange.bind(this);
    }

    private handleFrequencyChange(frequencyType: FrequencyType): void {
        this.setState({
            frequencyType: frequencyType
        });
        const trigger = update(this.props.trigger, {
            cron: { $set: undefined as any }
        })
        this.props.onChange(trigger);
    }

    private handleCronChange(cron: Cron): void {
        const trigger = update(this.props.trigger, {
            cron: { $set: cron }
        })
        this.props.onChange(trigger);
    }

    private hasTimePoint(frequencyType?: FrequencyType): boolean {
        return frequencyType === FrequencyType.YEAR ||
            frequencyType === FrequencyType.BIANNUAL ||
            frequencyType === FrequencyType.QUARTER ||
            frequencyType === FrequencyType.MONTH ||
            frequencyType === FrequencyType.DAY;
    }

    private hasTimeInterval(frequencyType?: FrequencyType): boolean {
        return frequencyType === FrequencyType.HOUR || frequencyType === FrequencyType.MINUTE;
    }

    public render(): ReactElement {
        return (
            <div>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Frequency"
                    name="frequency"
                    info="The frequency of the schedule."
                    rules={[{
                        required: true,
                        message: 'Please enter a frequency.'
                    }]}
                >
                    <Select
                        placeholder="Select a frequency."
                        value={this.state.frequencyType}
                        onChange={this.handleFrequencyChange}
                    >
                        <Select.Option value={FrequencyType.YEAR}>Year</Select.Option>
                        <Select.Option value={FrequencyType.BIANNUAL}>Bi-Annual</Select.Option>
                        <Select.Option value={FrequencyType.QUARTER}>Quarter</Select.Option>
                        <Select.Option value={FrequencyType.MONTH}>Month</Select.Option>
                        <Select.Option value={FrequencyType.DAY}>Day</Select.Option>
                        <Select.Option value={FrequencyType.HOUR}>Hour</Select.Option>
                        <Select.Option value={FrequencyType.MINUTE}>Minute</Select.Option>
                    </Select>
                </FormItem>
                {this.hasTimePoint(this.state.frequencyType) &&
                    <PointEditor
                        formRef={this.props.formRef}
                        layout={Globals.FORM_LAYOUT}
                        period={this.state.frequencyType as PointPeriod}
                        cron={this.props.trigger.cron}
                        onChange={this.handleCronChange}
                    />
                }
                {this.hasTimeInterval(this.state.frequencyType) &&
                    <IntervalEditor
                        formRef={this.props.formRef}
                        layout={Globals.FORM_LAYOUT}
                        period={this.state.frequencyType as IntervalPeriod}
                        cron={this.props.trigger.cron}
                        onChange={this.handleCronChange}
                    />
                }
            </div>
        );
    }

}
