import { ReferenceParser } from "@methodset/calculator-ts";
import { ListWidgetConfiguration, Widget } from '@methodset/model-client-ts';

export class ListWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as ListWidgetConfiguration;
        const columnSets = configuration.columnSets;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.rangeId, true));
        refs.push(...ReferenceParser.extract(configuration.rowLink?.variable, true));
        for (const columnSet of columnSets) {
            refs.push(...ReferenceParser.extract(columnSet.name));
        }
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as ListWidgetConfiguration;
        const columnSets = configuration.columnSets;
        configuration.rangeId = ReferenceParser.update(configuration.rangeId, target, replacement, true);
        if (configuration.rowLink) {
            configuration.rowLink.variable = ReferenceParser.update(configuration.rowLink.variable, target, replacement, true);
        }
        for (const columnSet of columnSets) {
            columnSet.name = ReferenceParser.update(columnSet.name, target, replacement);
        }
    }

}
