import { FC, ReactElement, ReactNode } from 'react';
import { Menu } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { DateFormatType, DateTimeFormatType, Format, NumberFormatType, StringFormatType, TimeFormatType } from '@methodset/calculator-ts';
import classNames from 'classnames';
import './FormatMenu.less';

export type ChangeCallback = (format?: Format) => void;

export type FormatMenuProps = {
    format?: Format,
    onChange: ChangeCallback
}

export const FormatMenu: FC<FormatMenuProps> = (props: FormatMenuProps): ReactElement => {

    const handleFormat = (e: any): void => {
        let format: Format | undefined;
        switch (e.key) {
            case "none":
                format = undefined;
                break;
            case "string:general":
                format = StringFormatType.GENERAL;
                break;
            case "number:integer":
                format = NumberFormatType.INTEGER;
                break;
            case "number:decimal-2":
                format = NumberFormatType.DECIMAL_2;
                break;
            case "number:decimal-4":
                format = NumberFormatType.DECIMAL_4;
                break;
            case "number:percent-2":
                format = NumberFormatType.PERCENT_2;
                break;
            case "number:currency-us":
                format = NumberFormatType.CURRENCY_US;
                break;
            case "number:currency-us-integer":
                format = NumberFormatType.CURRENCY_US_INTEGER;
                break;
            case "number:currency-us-thousands":
                format = NumberFormatType.CURRENCY_US_THOUSANDS;
                break;
            case "number:currency-us-millions":
                format = NumberFormatType.CURRENCY_US_MILLIONS;
                break;
            case "number:financial-us":
                format = NumberFormatType.FINANCIAL_US;
                break;
            case "number:accounting-us":
                format = NumberFormatType.ACCOUNTING_US;
                break;
            case "date:iso":
                format = DateFormatType.ISO;
                break;
            case "date:short":
                format = DateFormatType.SHORT;
                break;
            case "date:medium":
                format = DateFormatType.MEDIUM;
                break;
            case "date:long":
                format = DateFormatType.LONG;
                break;
            case "time:iso":
                format = TimeFormatType.ISO;
                break;
            case "time:short":
                format = TimeFormatType.SHORT;
                break;
            case "time:medium":
                format = TimeFormatType.MEDIUM;
                break;
            case "time:long":
                format = TimeFormatType.LONG;
                break;
            case "time:short-24":
                format = TimeFormatType.SHORT_24;
                break;
            case "time:medium-24":
                format = TimeFormatType.MEDIUM_24;
                break;
            case "time:long-24":
                format = TimeFormatType.LONG_24;
                break;
            case "datetime:iso":
                format = DateTimeFormatType.ISO;
                break;
            case "datetime:short":
                format = DateTimeFormatType.SHORT;
                break;
            case "datetime:medium":
                format = DateTimeFormatType.MEDIUM;
                break;
            case "datetime:long":
                format = DateTimeFormatType.LONG;
                break;
            default:
                format = undefined;
        }
        props.onChange(format);
    }

    const toFormatKey = (format: Format | undefined): string | null => {
        return !format ? "none" : [format.type, format.name].join(":");
    }

    const formatConfigs = (): any[] => {
        return [
            ["none", "None"],
            ["string:general", "String"],
            ["div-1"],
            ["number:integer", "Integer", "1"],
            ["number:decimal-2", "Decimal", "1.23"],
            ["number:decimal-4", "Decimal", "1.2345"],
            ["number:percent-2", "Percent", "1.23%"],
            ["number:currency-us", "Currency", "$1.23"],
            ["number:currency-us-integer", "Currency", "$1"],
            ["number:currency-us-thousands", "Currency", "$1.23K"],
            ["number:currency-us-millions", "Currency", "$1.23M"],
            ["number:financial-us", "Financial", "(1.23)"],
            ["number:accounting-us", "Accounting", "$(1.23)"],
            ["div-2"],
            ["date:iso", "Date", "2021-30-10"],
            ["date:short", "Date", "10/30/2021"],
            ["date:medium", "Date", "Oct 30, 2021"],
            ["date:long", "Date", "October 30, 2021"],
            ["div-3"],
            ["time:iso", "Time", "01:23:45.000-04:00"],
            ["time:short", "Time", "1:23 PM"],
            ["time:medium", "Time", "1:23:45 PM"],
            ["time:long", "Time", "1:23:45 EDT"],
            ["div-4"],
            ["time:short-24", "Time 24", "13:23"],
            ["time:medium-24", "Time 24", "13:23:45"],
            ["time:long-24", "Time 24", "13:23:45 EDT"],
            ["div-5"],
            ["datetime:iso", "Date Time", "2021-10-30T01:23:45.000-04:00"],
            ["datetime:short", "Date Time", "10/30/2021 1:23 PM"],
            ["datetime:medium", "Date Time", "Oct 30, 2021 1:23 PM"],
            ["datetime:long", "Date Time", "October 30, 2021 1:23 EDT"]
        ];
    }

    const buildFormats = (): ReactNode[] => {
        const configs = formatConfigs();
        const formatKey = toFormatKey(props.format);
        return configs.map(config => {
            if (config.length === 1) {
                return <Menu.Divider key={config[0]} />
            } else {
                return (
                    <Menu.Item key={config[0]} onClick={handleFormat}>
                        <div className="x-formatmenu-format">
                            <span>
                                <CheckOutlined className={classNames("x-formatmenu-check", { "x-formatmenu-check-hide": formatKey !== config[0] })} />
                                {config[1]}
                            </span>
                            <span className="x-formatmenu-example">
                                {config[2]}
                            </span>
                        </div>
                    </Menu.Item>
                )
            }
        });
    }

    return (
        <Menu.ItemGroup>
            {buildFormats()}
        </Menu.ItemGroup>
    );

}
