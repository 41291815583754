import { PureComponent, ReactElement } from 'react';
import { Card, Col, Image, Row } from 'antd';
import { blue, green, purple } from '@ant-design/colors';
import { ResponsiveBox } from 'components/ResponsiveBox/ResponsiveBox';
import { CheckSquareFilled } from '@ant-design/icons';
import { Justify } from 'components/Justify/Justify';
import { Spacer } from 'components/Spacer/Spacer';
import { Pricing } from '../Pricing/Pricing';
import { Link } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import main_image from 'images/calculator-magnifying-glass-table.jpg';
import starting_image from 'images/3d-illustration-green-sprout-with-golden-coins.jpg';
import building_image from 'images/3d-render-tax-payment-financial-business-concept.jpg';
import retirement_image from 'images/3d-render-wallet-with-plastic-cards-gold-coins.jpg';
import advisor_fees_image from 'images/advisor_fees_inside.png';
import './Personal.less';

export class Personal extends PureComponent {

    public render(): ReactElement {
        return (
            <div className="x-personal">
                <ResponsiveBox
                    className="x-personal-overview"
                    //xxl={{ className: 'x-personal-overview-xxl' }}
                    //xl={{ className: 'x-personal-overview-xl' }}
                    //lg={{ className: 'x-personal-overview-lg' }}
                    md={{ className: 'x-personal-overview-md' }}
                    //sm={{ className: 'x-personal-overview-sm' }}
                    xs={{ className: 'x-personal-overview-xs' }}
                >
                    <Row gutter={8}>
                        <Col
                            lg={{ span: 10 }}
                            xs={{ span: 24 }}
                        >
                            <Row>
                                <Col span={22} offset={1}>
                                    <div className="x-personal-overview-title">
                                        A Modern Approach to Financial Management
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={22} offset={1}>
                                    <div className="x-personal-overview-subtitle">
                                        {/* Tools and advice for self-directed investors, at a fraction of the cost. */}
                                        Tools and guidance at a fraction of the cost.
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            lg={{ span: 14 }}
                            xs={{ span: 24 }}
                        >
                            <div>
                                <img className="x-personal-overview-graphic" src={main_image} alt="platform_overview" />
                            </div>
                        </Col>
                    </Row>
                </ResponsiveBox>
                <div className="x-personal-description">
                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                        >
                            <p>
                                Typical financial managers charge a 1% fee based on assets under management (AUM).
                                Over the course of a lifetime, that can put a serious dent into your net worth.
                                We charge a low subscription fee that is not based on how much money you have,
                                but on how much value we are providing for you.
                            </p>
                            <p>
                                Our state-of-the-art digital tools combined with pay-as-needed expert human advice will
                                help you track your finances, avoid mistakes, and make smart decisions both before and
                                after retirement. Using our platform, you can tailor your digital experience
                                based on your unique situation by choosing from a catalog of calculators and
                                monitors that incorporate your real-time account data with information about
                                you and your family. Our mission is to utilize the latest in investment best-practices
                                and any changes to tax laws and regulations to provide a low-cost alternative to
                                expensive financial firms.
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="x-personal-section">
                    <Row gutter={[32, 20]}>
                        <Col
                            md={{ span: 8, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <Card
                                className="x-personal-blurb"
                                cover={
                                    <img
                                        className="x-personal-usecase-image"
                                        src={starting_image}
                                        alt="starting"
                                    />
                                }
                            >
                                <Card.Meta
                                    title="Starting Out"
                                    description={
                                        <span>
                                            Investing early and taking advantage of compounding is key to generating
                                            wealth. Getting setup correctly from the start and growing a low-cost, diversified
                                            portfolio is the best way to maximize your returns.
                                        </span>
                                    }
                                />
                            </Card>
                        </Col>
                        <Col
                            md={{ span: 8, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <Card
                                className="x-personal-blurb"
                                cover={
                                    <img
                                        className="x-personal-usecase-image"
                                        src={building_image}
                                        alt="building"
                                    />
                                }
                            >
                                <Card.Meta
                                    title="Building Wealth"
                                    description={
                                        <span>
                                            Whether maxing out your 401(k), setting up a 529 plan for your children,
                                            or deciding whether to refinance a mortgage, our tools will help you make
                                            the right decisions to ensure the best financial outcomes.
                                        </span>
                                    }
                                />
                            </Card>
                        </Col>
                        <Col
                            md={{ span: 8, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <Card
                                className="x-personal-blurb"
                                cover={
                                    <img
                                        className="x-personal-usecase-image"
                                        src={retirement_image}
                                        alt="retirement"
                                    />
                                }
                            >
                                <Card.Meta
                                    title="Retirement"
                                    description={
                                        <span>
                                            Keeping track of your spending is crucial to making sure that your
                                            money lasts. Our calculators will automatically determine if you
                                            are on course based on your portfolio balance and market conditions.
                                        </span>
                                    }
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>

                <div className="x-personal-section">
                    <Justify justification="center">
                        <div>
                            <Spacer className="x-personal-checkbox">
                                <CheckSquareFilled />
                                <span>Geared towards self-directed investors that may need periodic advice.</span>
                            </Spacer>
                            <Spacer className="x-personal-checkbox">
                                <CheckSquareFilled />
                                <span>Integrates with your bank and investment accounts for real-time analysis.</span>
                            </Spacer>
                            <Spacer className="x-personal-checkbox">
                                <CheckSquareFilled />
                                <span>Pay for a financial advisor from our network only when you need it.</span>
                            </Spacer>
                            <Spacer className="x-personal-checkbox">
                                <CheckSquareFilled />
                                <span>Calculate withdrawal rates during retirement to make sure your money lasts using proven models.</span>
                            </Spacer>
                            <Spacer className="x-personal-checkbox">
                                <CheckSquareFilled />
                                <span>Get practical advice and actionable recommendations based on financial best-practices and research.</span>
                            </Spacer>
                            <Spacer className="x-personal-checkbox">
                                <CheckSquareFilled />
                                <span>Keep up with the latest tax regulations to take advantages of changes.</span>
                            </Spacer>
                            <Spacer className="x-personal-checkbox">
                                <CheckSquareFilled />
                                <span>See your recurring payments to keep track of the services you are subscribed to.</span>
                            </Spacer>
                            <Spacer className="x-personal-checkbox">
                                <CheckSquareFilled />
                                <span>Track mortgage rates and get notified when refinancing is most beneficial.</span>
                            </Spacer>
                            <Spacer className="x-personal-checkbox">
                                <CheckSquareFilled />
                                <span>Many more tools are available to analyze, track, and model your finances, customized to you.</span>
                            </Spacer>
                        </div>
                    </Justify>
                </div>

                <div className="x-personal-section">
                    <span className="x-personal-title">
                        The Cost of Fees
                    </span>
                    <p>
                        Take a hypothetical use-case where a person starts with $50,000 and invests $15K per year
                        with increases each year based on the target inflation rate. Over a 30 year period, your net worth at
                        retirement can be reduced by 15% due to advisor fees alone - that is over $200K!
                    </p>
                    <div className="x-personal-center">
                        <Image className="x-personal-frame" src={advisor_fees_image} />
                    </div>
                </div>

                <div className="x-personal-section">
                    <div className="x-personal-try">
                        <Link to={RouteBuilder.MAIN_WAITLIST}>Try our digital tools FREE for 2 months!</Link>
                    </div>
                    <Row justify="center" gutter={[64, 20]}>
                        <Col>
                            <Pricing
                                color={blue}
                                title="Digital Tools"
                                //price="$12 per month"
                                price="Low monthly rate"
                                description="State-of-the-art digital tools keep you on track."
                                bullets={[
                                    "Track and monitor all your accounts.",
                                    "Actionable advice and recommendations.",
                                    "Select from a catalog of apps that apply to your specific situation."
                                ]}
                            />
                        </Col>
                        <Col>
                            <Pricing
                                color={green}
                                title="Personal Advisor"
                                //price="$500 per hour, pro-rated"
                                price="Pay only when needed"
                                description="Get advice from a financial expert as needed to help you properly manage your finances."
                                bullets={[
                                    "One-on-one advice for initial setup and periodic checkups.",
                                    "Email support for specific questions.",
                                    "Customized plans."
                                ]}
                            />
                        </Col>
                        <Col>
                            <Pricing
                                color={purple}
                                title="For Business"
                                price="Contact us for a quote"
                                description="Customized calculations using your own data."
                                bullets={[
                                    "Connect to your own data.",
                                    "Custom calculators to perform analysis.",
                                    "Workflows to process data and run models.",
                                    "Real-time interactive dashboards."
                                ]}
                            />
                        </Col>
                    </Row>
                    <div className="x-personal-business">
                        <Link to={RouteBuilder.MAIN_BUSINESS}>Learn more about our business solutions.</Link>
                    </div>
                </div>
            </div>
        )
    }

}
