import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Input, Select } from 'antd';
import { Phone, PhoneType } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { CoreUtils } from 'utils/CoreUtils';
import { Spacer } from 'components/Spacer/Spacer';
import update from 'immutability-helper';
import './PhoneEditor.less';

export type ChangeCallback = (phone: Phone) => void;

export type PhoneProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    phone?: Phone,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    phone: {}
}

export const PhoneEditor = (props: PhoneProps): ReactElement => {

    const handleNumberChange = (number: string): void => {
        const phone = update(props.phone, {
            number: { $set: number }
        });
        props.onChange(phone);
    }

    const handleTypeChange = (type: PhoneType): void => {
        const phone = update(props.phone, {
            type: { $set: type }
        });
        props.onChange(phone);
    }

    return (
        <Spacer>
            <FormItem
                {...Globals.FORM_LAYOUT}
                className="x-phoneeditor-number"
                formRef={props.formRef}
                label="Phone Number"
                name={`${props.id}-number`}
                // rules={[{
                //     required: true,
                //     message: 'Please enter a number.'
                // }]}
            >
                <Input
                    placeholder="Phone number."
                    value={props.phone.number}
                    onChange={(e) => handleNumberChange(e.target.value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                className="x-phoneeditor-type"
                formRef={props.formRef}
                label="Type"
                name={`${props.id}-type`}
            >
                <Select
                    allowClear={true}
                    value={props.phone.type}
                    onChange={(type) => handleTypeChange(type)}
                >
                    {CoreUtils.enumToKeys(PhoneType).map(key => (
                        <Select.Option key={key} value={key}>{CoreUtils.toProper(key)}</Select.Option>
                    ))}
                </Select>
            </FormItem>
        </Spacer>
    )
}

PhoneEditor.defaultProps = defaultProps;
