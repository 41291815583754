import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { Button, FormInstance } from 'antd';
import { Person } from '@methodset/entity-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { PersonEditor } from '../PersonEditor/PersonEditor';
import { FormDivider } from 'components/FormDivider/FormDivider';
import update from 'immutability-helper';
import './ChildrenEditor.less';

export type ChangeCallback = (children: Person[]) => void;

export type ChildrenEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    children?: Person[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    children: [] as Person[]
}

export const ChildrenEditor = (props: ChildrenEditorProps): ReactElement => {

    const handleChildAdd = (): void => {
        const child = {} as Person;
        const children = update(props.children, {
            $push: [child]
        });
        props.onChange(children);
    }

    const handleChildRemove = (index: number): void => {
        const children = update(props.children, {
            $splice: [[index, 1]]
        });
        props.onChange(children);
    }

    const handleChildChange = (child: Person, index: number): void => {
        const children = update(props.children, {
            [index]: { $set: child }
        });
        props.onChange(children);

    }

    return (
        <Spacer direction="vertical">
            {props.children.map((child, index) => (
                <div key={index}>
                    <FormDivider
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        label={`Child ${index+1}`}
                        fill={true}
                        bold={true}
                        colon={false}
                        inline={true}
                        onDelete={() => handleChildRemove(index)}
                    />
                    <PersonEditor
                        key={index}
                        formRef={props.formRef}
                        id={`child-${index+1}`}
                        person={child}
                        onChange={(child) => handleChildChange(child, index)}
                    />
                </div>
            ))}
            <Button onClick={() => handleChildAdd()}>Add Child</Button>
        </Spacer>
    )
}

ChildrenEditor.defaultProps = defaultProps;
