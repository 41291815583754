import { ReactElement, Ref, useContext, useRef, useState } from 'react';
import { Button, Form, FormInstance, Select, Space } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { Group } from '@methodset/entity-client-ts';
import './SwitchGroup.less';

export type SwitchGroupProps = RouteComponentProps & {}

export const SwitchGroup = (props: SwitchGroupProps): ReactElement => {

    const context = useContext(EntityContext);
    const formRef: Ref<FormInstance> = useRef(null);
    const [groupId, setGroupId] = useState<string | undefined>(context.groupId);

    const handleGroupChange = (groupId: string): void => {
        setGroupId(groupId);
    }

    const handleFormFinish = (): void => {
        context.saveGroupId(groupId);
        props.history.goBack();
    }

    const handleCancelClick = (): void => {
        props.history.goBack();
    }

    return (
        <FormFrame
            ref={formRef}
            title="Switch Accounts"
            description="Select a group member whose account you would like to view."
            okLabel="Switch"
            onCancel={handleCancelClick}
            onOk={handleFormFinish}
        >
            <FormItem
                formRef={formRef}
                name="group"
            >
                <Select
                    value={context.groups.length > 0 ? groupId : undefined}
                    onSelect={handleGroupChange}
                >
                    {context.groups.map((group: Group, index: number) => (
                        <Select.Option
                            key={group.id}
                            value={group.id ? group.id : index}
                        >
                            {group.name}
                        </Select.Option>
                    ))}
                </Select>
            </FormItem>
        </FormFrame>
    );

}
