import { SpacingType } from "@methodset/model-client-ts";

export class Globals {

    public static readonly COMPANY_NAME = 'MethodSet, Inc.';
    public static readonly DOMAIN_NAME = 'methodset.com';

    // Google Analytics tracking.
    public static readonly GOOGLE_ANALYTICS_TRACKING_ID = 'G-JP684LJ9P2';

    // Cognito ids.
    public static readonly DEV_COGNITO_USER_POOL_ID = 'us-west-2_l1X1mbKW2';
    public static readonly DEV_COGNITO_APP_CLIENT_ID = '1k0aj06jvhdga3a71j4m0qm3dd';
    public static readonly PROD_COGNITO_USER_POOL_ID = 'us-west-2_RC1xO6Qno';
    public static readonly PROD_COGNITO_APP_CLIENT_ID = '77qi9a6ku1hda6t8hnoqdu5qmr';

    public static readonly APPLET_MENU_SIZE = "small";
    public static readonly APPLET_MENU_SHAPE = "circle";
    public static readonly APPLET_GUTTER_ROW = 10;
    public static readonly APPLET_GUTTER_COL = 10;
    public static readonly FORM_WIDTH_LEFT = 14;
    public static readonly FORM_WIDTH_RIGHT = 10;
    public static readonly FORM_GUTTER_ROW = 24;
    public static readonly FORM_GUTTER_COL = 32;
    public static readonly WIDGET_GUTTER_ROW = 5;
    public static readonly WIDGET_GUTTER_COL = 5;

    public static readonly EMPTY_FIELD = "---";
    public static readonly DIALOG_WIDTH_STANDARD = 475;
    public static readonly DIALOG_WIDTH_STACKED = 350;
    public static readonly DIALOG_WIDTH_SHORT = Globals.DIALOG_WIDTH_STACKED;
    public static readonly DIALOG_WIDTH = Globals.DIALOG_WIDTH_STACKED;
    public static readonly DIALOG_WIDTH_DOUBLE = (2 * Globals.DIALOG_WIDTH_STACKED) + Globals.FORM_GUTTER_COL;

    public static readonly TABLE_NAME_WIDTH = 300;
    public static readonly TABLE_DATE_WIDTH = 200;
    public static readonly TABLE_VERSION_WIDTH = 200;

    public static readonly SNAPSHOT_VERSION = 0;

    // Number of columns in applet/widget layout.
    public static readonly LAYOUT_COLUMNS = 12;
    // Maximum number of rows in layout.
    public static readonly LAYOUT_ROWS = 24;
    // The column scale factor between the native grid (24 columns) and the layout.
    public static readonly LAYOUT_SCALE = 24 / Globals.LAYOUT_COLUMNS;

    // Converts a spacing size to spacing value.
    public static spacing(spacing: SpacingType | undefined): number {
        switch (spacing) {
            case SpacingType.XXS:
                return 1;
            case SpacingType.XS:
                return 3;
            case SpacingType.SM:
                return 5;
            case SpacingType.MED:
                return 8;
            case SpacingType.LG:
                return 12;
            case SpacingType.XL:
                return 16;
            case SpacingType.XXL:
                return 20;
            default:
                return 0;
        }
    }

    // Standard layout (label to the left of the form).
    public static readonly FORM_LAYOUT_STANDARD = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    };

    // Stacked layout (label above form item).
    public static readonly FORM_LAYOUT_STACKED = {
        labelCol: {
            xs: { span: 24 }
        },
        wrapperCol: {
            xs: { span: 24 }
        }
    };

    // Label/value layout for equal column width forms.
    public static readonly FORM_LAYOUT = Globals.FORM_LAYOUT_STACKED;

    public static readonly WIDE_FORM_LAYOUT = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 21 }
        }
    };

    // Label/value layout for 3 column forms where one takes 2 column widths.
    public static readonly FORM_LAYOUT_2 = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 21 }
        }
    };
    public static readonly ENVIRONMENT = 'dev';
    public static readonly MODEL_EXECUTION_QUEUE = 'model-execution-queue';
    public static readonly PROCESS_EXECUTION_QUEUE = 'process-execution-queue';
    public static readonly DATE_TIME_FORMAT = 'YYYY-MM-DD h =mm =ss A';
    public static readonly DATE_FORMAT = 'YYYY-MM-DD';
    public static readonly TIME_FORMAT = 'h =mm =ss A';

    // Loading status.
    public static readonly STATUS_INIT = 'INIT';
    public static readonly STATUS_LOADING = 'LOADING';
    public static readonly STATUS_READY = 'READY';
    public static readonly STATUS_FAILED = 'FAILED';
    public static readonly STATUS_ERROR = 'ERROR';

    // Spinner messages.
    public static readonly MESSAGE_LOADING = 'Loading...';
    public static readonly MESSAGE_SAVING = 'Saving...';
    public static readonly MESSAGE_DELETING = 'Deleting...';
    public static readonly MESSAGE_CALCULATING = 'Calculating...';
    public static readonly MESSAGE_ADDING = 'Adding...';
    public static readonly MESSAGE_REMOVING = 'Removing...';
    public static readonly MESSAGE_DOWNLOADING = 'Downloading...';

    // Calculator errors.
    public static readonly ERROR_REF = "#REF!";
    public static readonly ERROR_SYN = "#SYN!";
    public static readonly ERROR_ERR = "#ERR!";

    // Chart and widget values.
    public static readonly CHART_LABEL_SIZE = 16;
    public static readonly CHART_MARGINS = {
        top: 10,
        right: 20,
        left: 20,
        bottom: 30
    };

    // Tag colors.
    public static readonly TAG_COLORS = [
        "magenta",
        "blue",
        "green",
        "red",
        "purple",
        "orange",
        "gold",
        "lime",
        "volcano",
        "geekblue",
        "cyan"
    ];

    public static readonly STANDARD_COLORS = [
        "blue",
        "green",
        "purple",
        "red",
        "orange",
        "pink",
        "yellow",
        "cyan"
    ];

    // Service names.
    public static readonly SERVICE_BROWSER = "browser";
    public static readonly SERVICE_ENDPOINT = "endpoint";
    public static readonly SERVICE_ENTITY = "entity";
    public static readonly SERVICE_MODEL = "model";
    public static readonly SERVICE_SCHEDULE = "schedule";
    public static readonly SERVICE_TIMESERIES = "timeseries";
    public static readonly SERVICE_WORKFLOW = "workflow";

};
