import { ReactElement } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Calculator, ErrorUtils, FormulaError, Range, RangeComponents, ReferenceParser } from '@methodset/calculator-ts';
import { TableWidgetConfiguration } from '@methodset/model-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import './TableWidgetViewer.less';

export type TableWidgetViewerProps = {
    calculator: Calculator,
    configuration: TableWidgetConfiguration
}

export const TableWidgetViewer = (props: TableWidgetViewerProps): ReactElement => {

    const buildColumns = (values: any[][]): ColumnsType<any> => {
        const columns: ColumnsType<any> = [];
        if (values.length === 0) {
            return columns;
        }
        const row = values[0];
        for (let i = 0; i < row.length; i++) {
            const header = row[i];
            const column = {
                key: i,
                title: header,
                dataIndex: i.toString(),
                className: "x-tablewidgetviewer-cell"
            }
            columns.push(column);
        }
        return columns;
    }

    const buildData = (dataValues: any[][]): any[] | undefined => {
        const start = props.configuration.hasHeaders ? 1 : 0;
        const matrix = [];
        for (let r = start; r < dataValues.length; r++) {
            const row = dataValues[r];
            const data = { key: `row-${r}`, row: r } as any;
            for (let c = 0; c < row.length; c++) {
                const index = c.toString();
                let value = row[c];
                if (!CoreUtils.isEmpty(value)) {
                    if (ErrorUtils.isError(value)) {
                        data[index] = value.type;
                    } else {
                        value = value.toString();
                        data[index] = value;
                    }
                }
            }
            matrix.push(data);
        }
        return isEmpty(matrix) ? undefined : matrix;
    }

    const isEmpty = (matrix: any[][]): boolean => {
        if (matrix.length === 1) {
            const row = matrix[0];
            for (let r = 0; r < row.length; r++) {
                const value = row[r];
                if ((r === 0 && !FormulaError.isError(value)) || (r > 0 && value !== undefined)) {
                    // If the root cell has an error and the rest of the matrix 
                    // could not be generated, then there are no table contents.
                    return false;
                }
            }
        } else {
            return false;
        }
        return true;
    }

    const rangeValues = (rangeId: string | undefined, formatted: boolean): any[][] | undefined => {
        if (!rangeId) {
            return undefined;
        }
        const ref = ReferenceParser.parse(rangeId, true);
        const components = ref!.components as RangeComponents;
        const sheet = props.calculator.sheets.get(components.sheetId);
        const range = Range.fromId(sheet, components.rangeId);
        return range.values(formatted);
    }

    const buildView = (): ReactElement => {
        let dataValues: any[][] | undefined;
        try {
            dataValues = rangeValues(props.configuration.rangeId, true);
        } catch (e) {
            // Invalid data sheet or range - skip.
            return <>Error</>;
        }
        return (
            <Table
                className="x-tablewidgetviewer-table"
                pagination={false}
                bordered={true}
                size="middle"
                columns={buildColumns(dataValues!)}
                dataSource={buildData(dataValues!)}
                showHeader={props.configuration.hasHeaders}
            />
        );
    }

    return (
        <div className="x-tablewidgetviewer">
            {buildView()}
        </div>
    );
}
