import { ReactElement } from 'react';
import classNames from 'classnames';
import './Spacer.less';

export type Size = "none" | "xsmall" | "small" | "medium" | "large" | "xlarge";
export type Direction = "horizontal" | "vertical";
export type Alignment = "top" | "middle" | "bottom";
export type Justification = "left" | "center" | "right" | "between" | "around";

export type SpacerProps = {
    className?: string,
    children?: any,
    fill?: boolean,
    size?: Size,
    direction?: Direction,
    alignment?: Alignment,
    justification?: Justification,
    // Optional to allow as child to form item.
    // Set the value to the same value as the child.
    value?: any
} & typeof defaultProps;

const defaultProps = {
    fill: false,
    size: "medium",
    direction: "horizontal",
    alignment: "top",
    justification: "left"
}

export const Spacer = (props: SpacerProps): ReactElement => {
    return (
        <div
            className={classNames(
                "x-spacer",
                `x-spacer-${props.size}`,
                `x-spacer-${props.direction}`,
                `x-spacer-${props.alignment}`,
                `x-spacer-${props.justification}`,
                {"x-spacer-fill": props.fill},
                props.className
            )}
        >
            {props.children}
        </div>
    )
}

Spacer.defaultProps = defaultProps;
