import { PureComponent, ReactElement } from 'react';
import { SettingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Row, Select } from 'antd';
import { Tip } from 'components/Tip/Tip';
import { Globals } from 'constants/Globals';
import { ModelContext } from 'context/ModelContext';
import { VariableSpec } from '@methodset/endpoint-client-ts';
import { Applet } from '@methodset/model-client-ts';
import { AppletInputs } from './AppletInputs/AppletInputs';
import { AppletEditor } from './AppletEditor/AppletEditor';
import { AppletProperties } from './AppletProperties/AppletProperties';
import { RestUtils } from 'utils/RestUtils';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import axios from 'axios';
import modelService from 'services/ModelService';
import update from 'immutability-helper';
import './ModelApplets.less';

export type ModelAppletsProps = typeof ModelApplets.defaultProps & {
}

export type ModelAppletsState = {
    // The loading status.
    status: string,
    // The applet being edited.
    activeApplet?: Applet,
    // True if editing an applet.
    isEditingApplet: boolean,
    // True if editing widget specs.
    isEditingSpecs: boolean,
    // Resolved variable specs.
    variableSpecs?: VariableSpec[]
}

export class ModelApplets extends PureComponent<ModelAppletsProps, ModelAppletsState> {

    static defaultProps = {
    }

    static contextType = ModelContext;

    constructor(props: ModelAppletsProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            activeApplet: undefined,
            isEditingApplet: false,
            isEditingSpecs: false
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleSpecsEdit = this.handleSpecsEdit.bind(this);
        this.handleSpecsChange = this.handleSpecsChange.bind(this);
        this.handleSpecsCancel = this.handleSpecsCancel.bind(this);
        this.handleAppletSelect = this.handleAppletSelect.bind(this);
        this.handleAppletCreate = this.handleAppletCreate.bind(this);
        this.handleAppletChange = this.handleAppletChange.bind(this);
        this.handleAppletCancel = this.handleAppletCancel.bind(this);
        this.handleAppletRemove = this.handleAppletRemove.bind(this);
        this.handleAppletView = this.handleAppletView.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleSpecsEdit(): void {
        this.setState({ isEditingSpecs: true });
    }

    private handleSpecsChange(variableSpecs: VariableSpec[]): void {
        const model = update(this.context.model, {
            variableSpecs: { $set: variableSpecs }
        });
        this.context.saveModel(model);
        this.setState({ isEditingSpecs: false });
        this.readVariableSpecsRequest(model.id, model.version);
    }

    private readVariableSpecsRequest(id: string, version?: number): Promise<any> {
        // Do a check to see if any of the variables need to be resolved.
        const specs = this.context.model.variableSpecs;
        const index = specs.findIndex((spec: VariableSpec) => spec.queryId !== undefined);
        if (index === -1) {
            return Promise.resolve();
        }
        const request = {
            modelId: id,
            modelVersion: version
        };
        return modelService.readVariableSpecs(request,
            (response: any) => this.readVariableSpecsResponse(response),
            undefined, true
        );
    }

    private readVariableSpecsResponse(response: any): void {
        const variableSpecs = response.data.variableSpecs;
        this.setState({ variableSpecs: variableSpecs });
    }

    private handleSpecsCancel(): void {
        this.setState({ isEditingSpecs: false });
    }

    private handleAppletSelect(appletId: string): void {
        const applets: Applet[] = this.context.model.applets;
        const applet = applets.find(applet => applet.id === appletId);
        if (applet) {
            this.setState({ activeApplet: applet });
        }
    }

    private handleAppletCreate(): void {
        this.setState({ isEditingApplet: true });
    }

    private handleAppletChange(applet: Applet): void {
        const applets: Applet[] = this.context.model.applets;
        const index = applets.findIndex(a => a.id === applet.id);
        let model;
        if (index === -1) {
            model = update(this.context.model, {
                applets: {
                    $push: [applet]
                }
            });
        } else {
            model = update(this.context.model, {
                applets: {
                    [index]: { $set: applet }
                }
            });
        }
        this.context.saveModel(model);
        this.setState({
            activeApplet: applet,
            isEditingApplet: false
        });
    }

    private handleAppletCancel(): void {
        this.setState({
            isEditingApplet: false
        });
    }

    private handleAppletRemove(applet: Applet): void {
        let applets: Applet[] = this.context.model.applets;
        const index = applets.findIndex(a => a.id === applet.id);
        if (index === -1) {
            // Sanity check.
            return;
        }
        const model = update(this.context.model, {
            applets: {
                $splice: [[index, 1]]
            }
        });
        this.context.saveModel(model);
        applets = model.applets;
        if (applets.length === 0) {
            this.setState({ activeApplet: undefined });
        } else {
            const applet = applets[index === applets.length ? index - 1 : index];
            this.setState({ activeApplet: applet });
        }
    }

    private handleAppletView(appletId: string): void {
        const applets: Applet[] = this.context.model.applets;
        const applet = applets.find(a => a.id === appletId);
        if (applet) {
            this.setState({ activeApplet: applet });
        }
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-modelitem-loading"
                status={isLoading ? "loading" : "failed"}
                loadingMessage="Loading applets..."
                failedMessage="Failed to resolve variables."
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private buildAppletsView(): ReactElement {
        const applets: Applet[] = this.context.model.applets;
        const actions = (
            <div className="x-modelapplets-actions">
                <Select
                    className="x-modelapplets-actions-select"
                    value={this.state.activeApplet?.id}
                    placeholder="Select an applet to edit."
                    onChange={this.handleAppletSelect}
                >
                    {applets.map(applet => (
                        <Select.Option key={applet.id} value={applet.id}>{applet.name}</Select.Option>
                    ))}
                </Select>
                <Tip title="Create a new applet.">
                    <Button icon={<PlusOutlined />} onClick={this.handleAppletCreate} />
                </Tip>
                <Tip title="Configure applet input parameters.">
                    <Button icon={<SettingOutlined />} onClick={this.handleSpecsEdit} />
                </Tip>
            </div>
        );
        const calculator = this.context.calculator;
        return (
            <div>
                {actions}
                {applets.length === 0 &&
                    <div className="x-modelapplets-empty">
                        <Empty

                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>No applets created.</span>
                            }
                        />
                    </div>
                }
                {applets.length > 0 && this.state.activeApplet &&
                    <Row>
                        <Col
                            span={this.state.activeApplet.span * Globals.LAYOUT_SCALE}
                            offset={(24 - (this.state.activeApplet.span * Globals.LAYOUT_SCALE)) / 2}>
                            <AppletEditor
                                key={this.state.activeApplet.id}
                                model={this.context.model}
                                applet={this.state.activeApplet}
                                calculator={calculator}
                                variableSpecs={this.state.variableSpecs}
                                onChange={this.handleAppletChange}
                                onRemove={this.handleAppletRemove}
                            />
                        </Col>
                    </Row>
                }
                {this.state.isEditingApplet &&
                    <AppletProperties
                        onChange={this.handleAppletChange}
                        onCancel={this.handleAppletCancel}
                    />
                }
                {this.state.isEditingSpecs &&
                    <AppletInputs
                        variableSpecs={this.context.model.variableSpecs}
                        onChange={this.handleSpecsChange}
                        onCancel={this.handleSpecsCancel}
                    />
                }
            </div>
        )
    }

    private loadData(): void {
        const model = this.context.model;
        const requests = [];
        requests.push(this.readVariableSpecsRequest(model.id, model.version));
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        //this.registerApplets();
        const applets: Applet[] = this.context.model.applets;
        const activeApplet = applets.length > 0 ? applets[0] : undefined;
        this.setState({ activeApplet: activeApplet });
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public componentWillUnmount(): void {
        //this.unregisterApplets();
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildAppletsView();
        }
        return (
            <div className="x-modelapplets">
                {view}
            </div>
        );
    }

}
