import React, { PureComponent, ReactElement } from 'react';
import { Button, Checkbox, Collapse, Form, FormInstance, Space } from 'antd';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { Address, Contacts, Income, Pension, Person, Profile, Retirement, Taxes } from '@methodset/entity-client-ts';
import { PersonEditor } from './PersonEditor/PersonEditor';
import { ChildrenEditor } from './ChildrenEditor/ChildrenEditor';
import { RealEstateEditor } from './RealEstateEditor/RealEstateEditor';
import { IncomeEditor } from './IncomeEditor/IncomeEditor';
import { RetirementEditor } from './RetirementEditor/RetirementEditor';
import { TaxesEditor } from './TaxesEditor/TaxesEditor';
import { ContactsEditor } from './ContactsEditor/ContactsEditor';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PensionsEditor } from './PensionsEditor/PensionsEditor';
import axios from 'axios';
import entityService from 'services/EntityService';
import update from 'immutability-helper';
import './EditProfile.less';
import { SpouseEditor } from './SpouseEditor/SpouseEditor';

export type EditProfileProps = RouteComponentProps & {
}

export type EditProfileState = {
    status: string,
    errorMessage?: string,
    isSubmitting: boolean,
    hasSpouse: boolean,
    profile: Profile
}

export class EditProfile extends PureComponent<EditProfileProps, EditProfileState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: EditProfileProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            errorMessage: undefined,
            isSubmitting: false,
            hasSpouse: false,
            profile: {} as Profile
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleSelfChange = this.handleSelfChange.bind(this);
        this.handleSpouseToggle = this.handleSpouseToggle.bind(this);
        this.handleSpouseChange = this.handleSpouseChange.bind(this);
        this.handleContactsChange = this.handleContactsChange.bind(this);
        this.handleChildrenChange = this.handleChildrenChange.bind(this);
        this.handleIncomeChange = this.handleIncomeChange.bind(this);
        this.handlePensionsChange = this.handlePensionsChange.bind(this);
        this.handleRealEstateChange = this.handleRealEstateChange.bind(this);
        this.handleTaxesChange = this.handleTaxesChange.bind(this);
        this.handleRetirementChange = this.handleRetirementChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
        this.handleFormError = this.handleFormError.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleSelfChange(self: Person): void {
        const profile = update(this.state.profile, {
            self: { $set: self }
        });
        this.setState({ profile: profile });
    }

    private handleContactsChange(contacts: Contacts): void {
        const profile = update(this.state.profile, {
            contacts: { $set: contacts }
        });
        this.setState({ profile: profile });
    }


    private handleSpouseToggle(e: CheckboxChangeEvent): void {
        const hasSpouse = e.target.checked;
        this.setState({ hasSpouse: hasSpouse });
        if (!hasSpouse) {
            const profile = update(this.state.profile, {
                spouse: { $set: undefined }
            });
            this.setState({ profile: profile });
        }
    }

    private handleSpouseChange(spouse: Person): void {
        const profile = update(this.state.profile, {
            spouse: { $set: spouse }
        });
        this.setState({ profile: profile });
    }

    private handleChildrenChange(children: Person[]): void {
        const profile = update(this.state.profile, {
            children: { $set: children }
        });
        this.setState({ profile: profile });
    }

    private handleIncomeChange(income: Income): void {
        const profile = update(this.state.profile, {
            income: { $set: income }
        });
        this.setState({ profile: profile });
    }

    private handlePensionsChange(pensions: Pension[]): void {
        const profile = update(this.state.profile, {
            pensions: { $set: pensions }
        });
        this.setState({ profile: profile });
    }

    private handleRealEstateChange(realEstate: Address[]): void {
        const profile = update(this.state.profile, {
            realEstate: { $set: realEstate }
        });
        this.setState({ profile: profile });
    }

    private handleTaxesChange(taxes: Taxes): void {
        const profile = update(this.state.profile, {
            taxes: { $set: taxes }
        });
        this.setState({ profile: profile });
    }

    private handleRetirementChange(retirement: Retirement): void {
        const profile = update(this.state.profile, {
            retirement: { $set: retirement }
        });
        this.setState({ profile: profile });
    }

    private handleFormFinish(): void {
        this.updateProfileRequest();
    }

    private handleFormError(e: any): void {
        const errorMessage = "There are errors in the form, please fix."
        this.setState({ errorMessage: errorMessage });
    }

    private handleCancelClick(): void {
        this.props.history.goBack();
    }

    private readProfileRequest(): Promise<any> {
        const request = {
            asOwner: true
        };
        return entityService.readProfile(request,
            (response: any) => this.readProfileResponse(response),
            undefined, true
        );
    }

    private readProfileResponse(response: any): void {
        const profile = response.data.profile;
        const hasSpouse = !!profile.spouse;
        this.setState({
            profile: profile,
            hasSpouse: hasSpouse
        });
    }

    private updateProfileRequest(): Promise<any> {
        this.setState({
            isSubmitting: true,
            errorMessage: undefined
        });
        const request = {
            profile: this.state.profile
        };
        return entityService.updateProfile(request,
            (response: any) => this.updateProfileResponse(response),
            (response: any) => this.updateProfileException(response),
            true
        );
    }

    private updateProfileResponse(response: any): void {
        this.setState({ isSubmitting: false });
        this.props.history.goBack();
    }

    private updateProfileException(response: any): void {
        const errorMessage = RestUtils.getError(response);
        this.setState({
            isSubmitting: false,
            errorMessage: errorMessage
        });
    }

    private loadData() {
        const requests = [];
        requests.push(this.readProfileRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={8}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load profile."
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    private buildFormView(): ReactElement {
        return (
            <Form
                ref={this.formRef}
                onFinish={this.handleFormFinish}
                onFinishFailed={this.handleFormError}
            >
                <Collapse defaultActiveKey={["personal"]} accordion={true}>
                    <Collapse.Panel key="personal" header="Personal" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter your personal information.
                        </div>
                        <PersonEditor
                            formRef={this.formRef}
                            id="self"
                            person={this.state.profile.self}
                            onChange={this.handleSelfChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="contacts" header="Contacts" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter your address and phone number.
                        </div>
                        <ContactsEditor
                            formRef={this.formRef}
                            id="contacts"
                            contacts={this.state.profile.contacts}
                            onChange={this.handleContactsChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="spouse" header="Spouse" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter information about your spouse.
                        </div>
                        <Checkbox
                            className="x-editprofile-spouse"
                            checked={this.state.hasSpouse}
                            onChange={this.handleSpouseToggle}
                        >
                            I have a spouse.
                        </Checkbox>
                        {this.state.hasSpouse &&
                            <SpouseEditor
                                formRef={this.formRef}
                                id="spouse"
                                spouse={this.state.profile.spouse}
                                onChange={this.handleSpouseChange}
                            />
                        }
                    </Collapse.Panel>
                    <Collapse.Panel key="children" header="Children" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter information about your children.
                        </div>
                        <ChildrenEditor
                            formRef={this.formRef}
                            id="children"
                            children={this.state.profile.children}
                            onChange={this.handleChildrenChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="income" header="Income" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter information about your income.
                        </div>
                        <IncomeEditor
                            formRef={this.formRef}
                            id="income"
                            income={this.state.profile.income}
                            onChange={this.handleIncomeChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="pensions" header="Pensions" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter information about your pensions.
                        </div>
                        <PensionsEditor
                            formRef={this.formRef}
                            id="pensions"
                            pensions={this.state.profile.pensions}
                            onChange={this.handlePensionsChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="real-estate" header="Real Estate" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter the addresses of the real estate you own.
                        </div>
                        <RealEstateEditor
                            formRef={this.formRef}
                            id="real-estate"
                            realEstate={this.state.profile.realEstate}
                            onChange={this.handleRealEstateChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="taxes" header="Taxes" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter information about your taxes.
                        </div>
                        <TaxesEditor
                            formRef={this.formRef}
                            id="taxes"
                            taxes={this.state.profile.taxes}
                            onChange={this.handleTaxesChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="retirement" header="Retirement" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter information about your retirement.
                        </div>
                        <RetirementEditor
                            formRef={this.formRef}
                            id="retirement"
                            retirement={this.state.profile.retirement}
                            onChange={this.handleRetirementChange}
                        />
                    </Collapse.Panel>
                </Collapse>
                <Space
                    className="x-editprofile-footer"
                >
                    <Button
                        block
                        onClick={this.handleCancelClick}
                    >
                        Cancel
                    </Button>
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={this.state.isSubmitting}
                    >
                        Update
                    </Button>
                </Space>
            </Form>
        );
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildFormView();
        }
        return (
            <FormFrame
                className="x-editprofile"
                title="Update Profile"
                error={this.state.errorMessage}
                removeForm={true}
            >
                {view}
            </FormFrame>
        );
    }

}
