import { ReactElement, useContext, useEffect, useState } from 'react';
import { Col, Empty, Row, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { Board, AppletInfo, CategoryType } from '@methodset/model-client-ts';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { AppletItem } from './AppletItem/AppletItem';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationContext } from 'context/ApplicationContext';
import modelService from 'services/ModelService';
import axios from 'axios';
import update from 'immutability-helper';
import './AppletStore.less';
import { CoreUtils } from 'utils/CoreUtils';
import { Justify } from 'components/Justify/Justify';

// export type InstallCallback = (appletRef: AppletRef) => void;
// export type DoneCallback = () => void;

const ALL = "ALL" as CategoryType;

export type AppletStoreProps = RouteComponentProps & {
    // onInstall: InstallCallback,
    // onDone: DoneCallback
}

export const AppletStore = (props: AppletStoreProps): ReactElement => {

    const context = useContext(ApplicationContext);

    const [status, setStatus] = useState<string>(Globals.STATUS_INIT);
    const [category, setCategory] = useState<CategoryType>(ALL);
    const [appletInfos, setAppletInfos] = useState<AppletInfo[]>([]);

    useEffect(() => {
        if (status !== Globals.STATUS_READY) {
            loadData();
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleCategoryChange = (category: CategoryType): void => {
        setCategory(category);
    }

    const handleAppletInstall = (board: Board): void => {
        const boards = context.boards;
        const updated = update(boards, {
            $push: [board]
        });
        context.saveBoards(updated);
    }

    const readAppletInfosRequest = (): Promise<any> => {
        const request = {};
        return modelService.readAppletInfos(request,
            (response: any) => readAppletInfosResponse(response),
            undefined, true
        );
    }

    const readAppletInfosResponse = (response: any): void => {
        const infos = response.data.infos;
        setAppletInfos(infos);
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readAppletInfosRequest());
        setStatus(Globals.STATUS_LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(Globals.STATUS_READY);
            } else {
                setStatus(Globals.STATUS_FAILED);
            }
        }));
    }

    const findBoard = (appletId: string): Board | undefined => {
        const appletEntries = context.boards;
        return appletEntries.find(entry => entry.appletId === appletId);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <Row
                gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}
            >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => (
                    <Col
                        key={index}
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        xxl={{ span: 8 }}
                    >
                        <div className="x-appletstore-item">
                            <LoadSkeleton
                                //count={1}
                                direction="vertical"
                                status={isLoading ? "loading" : "failed"}
                                onRetry={() => handleRetryLoad()}
                            >
                                <LoadSkeleton.Input length="medium" />
                                <LoadSkeleton.Input length="fill" />
                                <LoadSkeleton.Input length="short" />
                                <LoadSkeleton.Input length="short" />
                            </LoadSkeleton>
                        </div>
                    </Col>
                ))}
            </Row>
        );
    }

    const buildAppletsView = (): ReactElement => {
        const infos = appletInfos.filter(applet => category === ALL || category === applet.category);
        return (
            <>
                <Justify
                    className="x-appletstore-category"
                    justification="center"
                >
                    <Select
                        className="x-appletstore-select"
                        value={category}
                        defaultValue={ALL}
                        onChange={(category) => handleCategoryChange(category)}
                    >
                        <Select.Option value={ALL}>All</Select.Option>
                        {CoreUtils.enumToKeys(CategoryType).map(type => (
                            <Select.Option value={type}>{CoreUtils.toProper(type, "_")}</Select.Option>
                        ))}
                    </Select>
                </Justify>
                {infos.length === 0 &&
                    <div className="x-appletstore-empty">
                        <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE} 
                            description="No applets available."
                        />
                    </div>
                }
                {infos.length > 0 &&
                    <Row gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}>
                        {infos.map((applet, index) => (
                            <Col
                                key={applet.id}
                                xs={{ span: 24 }}
                                lg={{ span: 12 }}
                                xxl={{ span: 8 }}
                            >
                                <AppletItem
                                    index={index}
                                    appletInfo={applet}
                                    board={findBoard(applet.id)}
                                    onInstall={(entry) => handleAppletInstall(entry)}
                                />
                            </Col>
                        ))}
                    </Row>
                }
            </>
        )
    }

    let view;
    if (status === Globals.STATUS_LOADING) {
        view = buildLoadingView(true);
    } else if (status === Globals.STATUS_FAILED) {
        view = buildLoadingView(false);
    } else if (status === Globals.STATUS_READY) {
        view = buildAppletsView();
    }
    return (
        <div className="x-appletstore">
            {view}
        </div>
    );

}
