import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Input, Switch } from 'antd';
import { Pension } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import update from 'immutability-helper';
import './PensionEditor.less';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';

export type ChangeCallback = (pension: Pension) => void;

export type PensionEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    pension?: Pension,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    pension: {}
}

export const PensionEditor = (props: PensionEditorProps): ReactElement => {

    const handleNameChange = (name: string): void => {
        const pension = update(props.pension, {
            name: { $set: name }
        });
        props.onChange(pension);
    }

    const handleStartAgeChange = (startAge: number): void => {
        const pension = update(props.pension, {
            startAge: { $set: startAge }
        });
        props.onChange(pension);
    }

    const handleMonthlyPaymentChange = (monthlyPayment: number): void => {
        const pension = update(props.pension, {
            monthlyPayment: { $set: monthlyPayment }
        });
        props.onChange(pension);
    }

    const handleAnnualContributionChange = (annualContribution: number): void => {
        const pension = update(props.pension, {
            annualContribution: { $set: annualContribution }
        });
        props.onChange(pension);
    }

    const handleCostOfLivingAdjustmentsChange = (costOfLivingAdjustments: boolean): void => {
        const pension = update(props.pension, {
            costOfLivingAdjustments: { $set: costOfLivingAdjustments }
        });
        props.onChange(pension);
    }

    return (
        <>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Pension Name"
                name={`${props.id}-name`}
                rules={[{
                    required: true,
                    message: 'Please enter a pension name.'
                }]}
            >
                <Input
                    placeholder="Name."
                    value={props.pension.name}
                    onChange={(e) => handleNameChange(e.target.value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Start Age"
                name={`${props.id}-start-age`}
                rules={[{
                    required: true,
                    message: 'Please enter a start age.'
                }]}
            >
                <IntegerInput
                    fill={true}
                    placeholder="Start age."
                    value={props.pension.startAge}
                    onChange={(value) => handleStartAgeChange(value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Monthly Payment"
                name={`${props.id}-monthly-payment`}
                rules={[{
                    required: true,
                    message: 'Please enter a monthly payment.'
                }]}
            >
                <IntegerInput
                    fill={true}
                    placeholder="Start age."
                    value={props.pension.monthlyPayment}
                    onChange={(value) => handleMonthlyPaymentChange(value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Annual Contribution"
                name={`${props.id}-annual-contribution`}
            >
                <IntegerInput
                    fill={true}
                    placeholder="Annual contribution."
                    value={props.pension.annualContribution}
                    onChange={(value) => handleAnnualContributionChange(value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Cost of Living Adjustments"
                name={`${props.id}-cola`}
                valuePropName="checked"
                rules={[{
                    required: true,
                    message: 'Specify if there is a cost of living adjustment.'
                }]}
            >
                <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={props.pension.costOfLivingAdjustments}
                    onChange={(value) => handleCostOfLivingAdjustmentsChange(value)}
                />
            </FormItem>
        </>
    )
}

PensionEditor.defaultProps = defaultProps;
