import { AuthenticationDetails, CognitoUserPool, CognitoUser, CookieStorage, CognitoUserAttribute, CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { CoreUtils } from 'utils/CoreUtils';
import { Globals } from 'constants/Globals';
import { User } from '@methodset/entity-client-ts';
import { PropertyUtils } from '@methodset/commons-shared-ts';

class AuthService {

    private cognitoUserPoolId: string;
    private cognitoAppClientId: string;
    private userPool: CognitoUserPool;
    private cognitoUser: CognitoUser | null;

    private static _instance: AuthService;
    public static instance(): AuthService {
        if (!this._instance) {
            this._instance = new AuthService();
        }
        return this._instance;
    }

    private constructor() {
        if (PropertyUtils.isLocal() || PropertyUtils.isDevelopment()) {
            this.cognitoUserPoolId = Globals.DEV_COGNITO_USER_POOL_ID;
            this.cognitoAppClientId = Globals.DEV_COGNITO_APP_CLIENT_ID;
        } else if (PropertyUtils.isProduction()) {
            this.cognitoUserPoolId = Globals.PROD_COGNITO_USER_POOL_ID;
            this.cognitoAppClientId = Globals.PROD_COGNITO_APP_CLIENT_ID;
        } else {
            const env = PropertyUtils.getEnv();
            throw new Error(`Environment ${env} is not supported.`);
        }
        this.userPool = this.newUserPool();
        this.cognitoUser = this.userPool.getCurrentUser();
    }

    public validatePassword(password: string | undefined): string | undefined {
        if (!password || password.length === 0) {
            return 'Password required.';
        } else if (password.length < 6) {
            return 'Password must have at least 6 characters.';
        } else if (password.toUpperCase() === password) {
            return 'Password must have at least one lower case letter.';
        } else if (password.toLowerCase() === password) {
            return 'Password must have at least one upper case letter.';
        } else if (!/\d/.test(password)) {
            return 'Password must have at least one number.';
        }
        return undefined;
    }

    public async readUser(): Promise<User> {
        const session = await this.readUserSession();
        return this.sessionToUser(session);
    }

    public async readIdToken(): Promise<string> {
        const session = await this.readUserSession();
        return session.getIdToken().getJwtToken();
    }

    public async readAccessToken(): Promise<string> {
        const session = await this.readUserSession();
        return session.getAccessToken().getJwtToken();
    }

    public async readRefreshToken(): Promise<CognitoRefreshToken> {
        const session = await this.readUserSession();
        return session.getRefreshToken();
    }

    public async readUserSession(): Promise<CognitoUserSession> {
        return new Promise((resolve, reject) => {
            const cognitoUser = this.getCurrentUser();
            if (!cognitoUser) {
                reject(new Error("User is not logged in."));
            } else {
                cognitoUser.getSession((err: any, session: any) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });
            }
        });
    }

    public getCurrentUser(): CognitoUser | null {
        if (!this.cognitoUser) {
            this.cognitoUser = this.userPool.getCurrentUser();
        }
        return this.cognitoUser;
    }

    public getCognitoUser(username: string): CognitoUser {
        if (this.cognitoUser && this.cognitoUser.getUsername() === username) {
            return this.cognitoUser;
        }
        const config = PropertyUtils.isLocal() ? { domain: 'localhost', secure: false } : { domain: `.${CoreUtils.hostname()}` };
        const userData = {
            Username: username,
            Pool: this.userPool,
            Storage: new CookieStorage(config)
        };
        return new CognitoUser(userData);
    }

    public async refreshSession(token: CognitoRefreshToken): Promise<CognitoUserSession> {
        return new Promise((resolve, reject) => {
            const cognitoUser = this.getCurrentUser();
            if (!cognitoUser) {
                reject(new Error("User is not logged in."));
            } else {
                cognitoUser.refreshSession(token, (err: any, session: any) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });    
            }
        });
    }

    public async refreshUser(): Promise<User> {
        const token = await this.readRefreshToken();
        const session = await this.refreshSession(token);
        return this.sessionToUser(session);
    }

    public sessionToUser(session: any): User {
        const payload = session.idToken.payload;
        // Only need a few fields from user object.
        const user = {
            id: payload['cognito:username'],
            organizationId: payload['custom:organization'],
            tenantId: payload['custom:tenant'],
            emailAddress: payload.email,
            firstName: payload.given_name,
            lastName: payload.family_name
        };
        return user as User;
    }

    public clearUser(): void {
        const cognitoUser = this.userPool.getCurrentUser();
        if (cognitoUser) {
            // TODO: clear session?
        }
        //this.dispatch(clearUser());
        this.cognitoUser = null;
    }

    public newUserPool(): CognitoUserPool {
        const config = PropertyUtils.isLocal() ? { domain: 'localhost', secure: false } : { domain: `.${CoreUtils.hostname()}` };
        const poolData = {
            UserPoolId: this.cognitoUserPoolId,
            ClientId: this.cognitoAppClientId,
            Storage: new CookieStorage(config)
        };
        return new CognitoUserPool(poolData);
    }

    public loginUser(username: string, password: string, onSuccess: Function, onFailure: Function, onNewPassword: Function): void {
        const authenticationData = {
            Username: username,
            Password: password
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        const cognitoUser = this.getCognitoUser(username);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result: CognitoUserSession) {
                if (onSuccess) {
                    onSuccess(result);
                }
            },
            onFailure: function (err: any) {
                if (onFailure) {
                    onFailure(err);
                }
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                if (onNewPassword) {
                    // Need to use this cognito user because it has the session.
                    onNewPassword(cognitoUser, userAttributes, requiredAttributes);
                }
            }
        });
    }

    public newPassword(cognitoUser: CognitoUser, newPassword: string, userAttributes: any, onSuccess: Function, onFailure: Function): void {
        delete userAttributes.email_verified;
        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: function (result: any) {
                if (onSuccess) {
                    onSuccess(result);
                }
            },
            onFailure: function (err: any) {
                if (onFailure) {
                    onFailure(err);
                }
            }
        });
    }

    public logoutUser(onSuccess: Function, onFailure: Function): void {
        const cognitoUser = this.getCurrentUser();
        if (!cognitoUser) {
            // User already logged out.
            if (onSuccess) {
                onSuccess("SUCCESS");
            }
            return;
        }
        const self = this;
        cognitoUser.globalSignOut({
            onSuccess: function (result: any) {
                if (onSuccess) {
                    onSuccess(result);
                }
            },
            onFailure: function (err: any) {
                if (err.code === 'PasswordResetRequiredException') {
                    self.clearUser();
                }
                if (onFailure) {
                    onFailure(err);
                }
            }
        });
    }

    public changePassword(oldPassword: string, newPassword: string, onSuccess: Function, onFailure: Function): void {
        const cognitoUser = this.getCurrentUser();
        if (!cognitoUser) {
            onFailure(new Error("User is not logged in."));
            return;
        }
        cognitoUser.changePassword(oldPassword, newPassword, function (err: any, result: any) {
            if (err) {
                if (onFailure) {
                    onFailure(err);
                }
            } else {
                if (onSuccess) {
                    onSuccess(result);
                }
            }
        });
    }

    public confirmRegistration(username: string, confirmationCode: string, onSuccess: Function, onFailure: Function): void {
        const cognitoUser = this.getCognitoUser(username);
        cognitoUser.confirmRegistration(confirmationCode, true, function (err: any, result: any) {
            if (err) {
                if (onFailure) {
                    onFailure(err);
                }
            } else {
                if (onSuccess) {
                    onSuccess(result);
                }
            }
        });
    }

    public resendConfirmationCode(username: string, onSuccess: Function, onFailure: Function): void {
        const cognitoUser = this.getCognitoUser(username);
        cognitoUser.resendConfirmationCode(function (err: any, result: any) {
            if (err) {
                if (onFailure) {
                    onFailure(err);
                }
            } else {
                if (onSuccess) {
                    onSuccess(result);
                }
            }
        });
    }

    public resetPassword(username: string, onSuccess: Function, onFailure: Function): void {
        const cognitoUser = this.getCognitoUser(username);
        cognitoUser.forgotPassword({
            onSuccess: (result: any) => {
                if (onSuccess) {
                    onSuccess(result);
                }
            },
            onFailure: (err: any) => {
                if (onFailure) {
                    onFailure(err);
                }
            }
        });
    }

    public confirmPassword(username: string, confirmationCode: string, newPassword: string, onSuccess: Function, onFailure: Function): void {
        const cognitoUser = this.getCognitoUser(username);
        cognitoUser.confirmPassword(confirmationCode, newPassword, {
            onSuccess: (result: any) => {
                if (onSuccess) {
                    onSuccess(result);
                }
            },
            onFailure: (err: any) => {
                if (onFailure) {
                    onFailure(err);
                }
            }
        });
    }

    public updateAttributes(attributes: any, onSuccess: Function, onFailure: Function): void {
        const cognitoUser = this.getCurrentUser();
        if (!cognitoUser) {
            onFailure(new Error("User is not logged in."));
            return;
        }
        const userAttributes = [];
        for (const key in attributes) {
            const attribute = {
                Name: key,
                Value: attributes[key]
            };
            const userAttribute = new CognitoUserAttribute(attribute);
            userAttributes.push(userAttribute);
        }
        cognitoUser.updateAttributes(userAttributes, function (err, result) {
            if (err) {
                if (onFailure) {
                    onFailure(err);
                }
            } else {
                if (onSuccess) {
                    onSuccess(result);
                }
            }
        });
    }

    public verifyAttribute(attributeName: string, confirmationCode: string, onSuccess: Function, onFailure: Function): void {
        const cognitoUser = this.getCurrentUser();
        if (!cognitoUser) {
            onFailure(new Error("User is not logged in."));
            return;
        }
        cognitoUser.verifyAttribute(attributeName, confirmationCode, {
            onSuccess: (result) => {
                if (onSuccess) {
                    onSuccess(result);
                }
            },
            onFailure: (err) => {
                if (onFailure) {
                    onFailure(err);
                }
            }
        });
    }

}

export default AuthService.instance();
