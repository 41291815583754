import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RouteComponentProps } from 'react-router-dom';
import { RestUtils } from 'utils/RestUtils';
import authService from 'services/AuthService';
import update from 'immutability-helper';
import './ResetPassword.less';

interface FormData {
    emailAddress?: string
}

export type ResetPasswordProps = RouteComponentProps & {}

export type ResetPasswordState = {
    errorMessage?: string,
    isSubmitting: boolean,
    formData: FormData
}

export class ResetPassword extends PureComponent<ResetPasswordProps, ResetPasswordState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: ResetPasswordProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isSubmitting: false,
            formData: {}
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>): void {
        const emailAddress = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                emailAddress: { $set: emailAddress }
            })
        });
    }

    private handleFormFinish(): void {
        const emailAddress = this.state.formData.emailAddress!;
        this.resetPasswordRequest(emailAddress);
    }

    private resetPasswordRequest(emailAddress: string): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: true
        });
        authService.resetPassword(emailAddress,
            (response: any) => this.resetPasswordResponse(response, emailAddress),
            (error: Error) => this.resetPasswordException(error)
        );
    }

    private resetPasswordResponse(response: any, emailAddress: string): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: false
        });
        this.props.history.push(RouteBuilder.resetPasswordConfirm(emailAddress));
    }

    private resetPasswordException(error: Error): void {
        this.setState({
            errorMessage: RestUtils.getError(error.message),
            isSubmitting: false
        });
    }

    public render(): ReactElement {
        return (
            <FormFrame
                ref={this.formRef}
                title="Reset Password"
                description="Please enter your email and a confirmation code will be sent to you in order to
                    verify your address. You will then be asked to enter the confirmation code, after which
                    you will be able to reset your password."
                error={this.state.errorMessage}
                hideFooter={true}
                onOk={this.handleFormFinish}
            >
                <FormItem
                    formRef={this.formRef}
                    name="email"
                    noLabel={true}
                    rules={[{
                        required: true,
                        message: 'Please enter your email.'
                    }, {
                        type: 'email',
                        message: 'The email address is invalid.'
                    }]}
                >
                    <Input
                        id="email"
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email address."
                        value={this.state.formData.emailAddress}
                        onChange={this.handleEmailChange}
                    />
                </FormItem>
                <Button
                    className="x-resetpassword-submit"
                    type="primary"
                    htmlType="submit"
                    loading={this.state.isSubmitting}
                >
                    Send Confirmation Code
                </Button>
            </FormFrame>
        );
    }

}
