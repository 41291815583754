import { PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EditOutlined, LockOutlined, SyncOutlined, UserDeleteOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { Button, Space, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RestUtils } from 'utils/RestUtils';
import { ColorMap, CoreUtils } from 'utils/CoreUtils';
import { Group, User, UserStatus } from '@methodset/entity-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { EntityContext } from 'context/EntityContext';
import { Spacer } from 'components/Spacer/Spacer';
import { RouteBuilder } from 'utils/RouteBuilder';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import entityService from 'services/EntityService';
import './Groups.less';

export type GroupsState = {
    status: string,
    isCreate: boolean,
    group: Group
}

export type GroupsProps = RouteComponentProps & {
    className?: string
}

export class Groups extends PureComponent<GroupsProps, GroupsState> {

    static contextType = EntityContext;

    private colorMap: ColorMap;

    constructor(props: GroupsProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            isCreate: false,
            group: {} as Group,
        };
        this.colorMap = CoreUtils.toColorMap(CoreUtils.enumToKeys(UserStatus));
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleMembershipsClick = this.handleMembershipsClick.bind(this);
        this.handleAddMemberClick = this.handleAddMemberClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleDeleteClick(user: User): void {
        this.removeGroupMemberRequest(user.id);
    }

    private handleMembershipsClick(): void {
        this.props.history.push(RouteBuilder.CONSOLE_GROUP_MEMBERSHIPS);
    }

    private handleAddMemberClick(): void {
        const groupId = this.state.group.id;
        this.props.history.push(RouteBuilder.groupAddMember(groupId));
    }

    private handleEditClick(): void {
        const groupId = this.state.group.id;
        this.props.history.push(RouteBuilder.groupProperties(groupId));
    }

    private readGroupRequest(): Promise<any> {
        const user = this.context.user;
        // Get the default group.
        const request = {
            groupId: user.id
        };
        return entityService.readGroup(request,
            (response: any) => this.readGroupResponse(response),
            undefined, true
        );
    }

    private readGroupResponse(response: any): void {
        const group = response.data.group;
        this.setState({ group: group });
    }

    private removeGroupMemberRequest(userId: string): Promise<any> {
        const user = this.context.user;
        const request = {
            groupId: user.id,
            userId: userId
        };
        return entityService.removeGroupMember(request,
            (response: any) => this.removeGroupMemberResponse(response),
            undefined, false
        );
    }

    private removeGroupMemberResponse(response: any): void {
        const userId = response.data.userId;
        const index = this.state.group.users.findIndex(user => user.id === userId);
        const group = update(this.state.group, {
            users: {
                $splice: [[index, 1]]
            }
        })
        this.setState({ group: group });
    }

    private buildData(): User[] {
        return this.state.group.users;
    }

    private buildColumns(): ColumnsType<any> {
        const user = this.context.user;
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Member Name',
            dataIndex: 'name',
            render: (value, record) => {
                return (
                    <Spacer alignment="middle">
                        <span>{`${record.firstName} ${record.lastName}`}</span>
                        {record.id === this.state.group.id &&
                            <LockOutlined />
                        }
                    </Spacer>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            //defaultSortOrder: 'ascend'
        }, {
            key: 'email',
            title: 'Email',
            align: 'left',
            dataIndex: 'emailAddress',
            render: (value, record) => {
                return (
                    <span>{value}</span>
                );
            }
        }, {
            key: 'status',
            title: 'Status',
            align: 'center',
            dataIndex: 'userStatus',
            render: (value, record) => {
                return (
                    <Tag color={this.colorMap[value]}>{CoreUtils.toProper(value, '_', ' ')}</Tag>
                );
            }
        }, {
            key: 'utime',
            title: 'Update Time',
            align: 'center',
            dataIndex: 'updateTime',
            render: (value, record) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(value)}
                    </span>
                );
            }
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readGroupRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const user = this.context.user;
        const actions = [{
            icon: <UserDeleteOutlined />,
            label: "Remove member",
            confirm: "Are you sure you want to remove the member?",
            disabled: (group: Group) => {
                return user.id === group.id;
            },
            callback: this.handleDeleteClick
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <ItemTable
                className={classNames('x-groups', this.props.className)}
                title="My Group"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={
                    <Space>
                        <Button onClick={this.handleAddMemberClick}>Add Member</Button>
                        <Button onClick={this.handleMembershipsClick}>Memberships</Button>
                        <Button icon={<EditOutlined />} onClick={this.handleEditClick}></Button>
                        <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                    </Space>
                }
                actions={actions}
                onLoad={this.handleRetryLoad}
            />
        );
    }

}
