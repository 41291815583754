import { ReactElement } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tip } from 'components/Tip/Tip';
import { TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';
import './Info.less';

export type InfoProps = {
    className?: string,
    children?: any,
    label: string | ReactElement,
    info?: string,
    location?: TooltipPlacement,
    bold?: boolean,
    colon?: boolean,
    required?: boolean
} & typeof defaultProps;

const defaultProps = {
    location: "topRight",
    bold: false,
    colon: false,
    required: false
}

export const Info = (props: InfoProps): ReactElement => {

    const findLabelParts = (): any[] => {
        if (typeof props.label === "string") {
            const index = props.label.lastIndexOf(" ");
            if (index !== -1) {
                return [props.label.substring(0, index + 1), props.label.substring(index)];
            } else {
                return ["", props.label];
            }
        } else {
            return ["", props.label];
        }
    }

    // Split the string label to make sure the info icon is next to the last 
    // part of the string and not placed on a different line.
    const parts = findLabelParts();

    return (
        <div className={classNames("x-info", props.className)}>
            <label className={classNames({ 'x-info-bold': props.bold }, { 'x-info-required': props.required })}>
                {parts[0]}
            </label>
            <span className={
                classNames("x-info-last",
                    { 'x-info-bold': props.bold },
                    { 'x-info-colon': props.colon }
                )}
            >
                {parts[1]}
                {props.info &&
                    <Tip placement={props.location} mouseEnterDelay={0.1} title={props.info}>
                        <InfoCircleOutlined className="x-info-icon" />
                    </Tip>
                }
            </span>
        </div>
    )
}

Info.defaultProps = defaultProps;
