import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Input } from 'antd';
import { LumpSum } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import update from 'immutability-helper';
import './LumpSumEditor.less';

export type ChangeCallback = (lumpSum: LumpSum) => void;

export type LumpSumEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    lumpSum?: LumpSum,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    lumpSum: {}
}

export const LumpSumEditor = (props: LumpSumEditorProps): ReactElement => {

    const handleNameChange = (name: string): void => {
        const lumpSum = update(props.lumpSum, {
            name: { $set: name }
        });
        props.onChange(lumpSum);
    }

    const handleAmountChange = (amount: number): void => {
        const lumpSum = update(props.lumpSum, {
            amount: { $set: amount }
        });
        props.onChange(lumpSum);
    }

    return (
        <>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Name"
                name={`${props.id}-name`}
                rules={[{
                    required: true,
                    message: 'Please enter the name.'
                }]}
            >
                <Input
                    placeholder="Name."
                    value={props.lumpSum.name}
                    onChange={(e) => handleNameChange(e.target.value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Annual Amount"
                name={`${props.id}-amount`}
                rules={[{
                    required: true,
                    message: 'Please enter the annual amount.'
                }]}
            >
                <IntegerInput
                    fill={true}
                    placeholder="Amount."
                    value={props.lumpSum.amount}
                    onChange={(amount) => handleAmountChange(amount)}
                />
            </FormItem>
        </>
    )
}

LumpSumEditor.defaultProps = defaultProps;
