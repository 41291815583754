import React, { PureComponent, ReactElement } from 'react';
import update from 'immutability-helper';

const isXxl = () => window.innerWidth >= 1400;
const isXl = () => window.innerWidth >= 1200;
const isLg = () => window.innerWidth >= 992;
const isMd = () => window.innerWidth >= 768;
const isSm = () => window.innerWidth >= 576;
const isXs = () => window.innerWidth >= 0;

export type ResizeCallback = () => void;

export interface Size {
    className?: string,
    style?: object
}

export type ResponsiveBoxProps = {
    children: any,
    className?: string,
    style?: object,
    xs?: Size,
    sm?: Size,
    md?: Size,
    lg?: Size,
    xl?: Size,
    xxl?: Size,
    onResize?: ResizeCallback
}

/**
 * Creates one resize callback on the window. ResponsiveBox 
 * instances can then register their own onResize callbacks.
 */
class Resizer {

    private nextId: number = 1;
    private callbacks: { [key: number]: ResizeCallback } = {};

    constructor() {
        this.nextId = 1;
        this.callbacks = {};
        this.handleResize = this.handleResize.bind(this);
        window.onresize = this.handleResize;
    }

    public addCallback(callback: ResizeCallback): number {
        const id = this.nextId++;
        this.callbacks[id] = callback;
        return id;
    }

    public removeCallback(key: number): void {
        delete this.callbacks[key];
    }

    private handleResize(): void {
        for (const key in this.callbacks) {
            const callback = this.callbacks[key];
            callback();
        }
    }

};

export class ResponsiveBox extends PureComponent<ResponsiveBoxProps> {

    static resizer = new Resizer();

    private id?: number;

    constructor(props: ResponsiveBoxProps) {
        super(props);
        this.handleResize = this.handleResize.bind(this);
    }

    private handleResize(): void {
        this.forceUpdate();
        if (this.props.onResize) {
            this.props.onResize();
        }
    }

    private process(size: Size, classNames: string[], styles: object) {
        const { className, style } = size;
        if (className) {
            classNames.push(className);
        }
        return style ?
            update(styles, {
                $merge: style
            }) : styles;
    }

    public componentDidMount(): void {
        this.id = ResponsiveBox.resizer.addCallback(this.handleResize);
    }

    public componentWillUnmount(): void {
        ResponsiveBox.resizer.removeCallback(this.id!);
    }

    public render(): ReactElement {
        const { xs, sm, md, lg, xl, xxl, children, onResize, className, style, ...rest } = this.props;
        let classNames = this.props.className ? [this.props.className] : [];
        let styles = this.props.style ? this.props.style : {};
        if (isXxl() && xxl) {
            styles = this.process(xxl, classNames, styles);
        } else if (isXl() && xl) {
            styles = this.process(xl, classNames, styles);
        } else if (isLg() && lg) {
            styles = this.process(lg, classNames, styles);
        } else if (isMd() && md) {
            styles = this.process(md, classNames, styles);
        } else if (isSm() && sm) {
            styles = this.process(sm, classNames, styles);
        } else if (isXs() && xs) {
            styles = this.process(xs, classNames, styles);
        }
        const classes = classNames.join(' ');
        return React.cloneElement(children, { className: classes, style: styles, ...rest });
    }

};
