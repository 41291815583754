import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { Cell, ReferenceParser, Sheet } from '@methodset/calculator-ts';
import { ActiveCell, ModelContext } from 'context/ModelContext';
import { Label } from 'components/Label/Label';
import { EmitterEvent } from 'utils/EventEmitter';
import './FunctionCell.less';

export type AddCallback = (activeCell: ActiveCell) => void;
export type EditCallback = (cell: Cell) => void;

export type FunctionCellProps = {
    // The name of the function that generates the block.
    fn: string,
    onAdd: AddCallback,
    onEdit: EditCallback
}

export const FunctionCell: FC<FunctionCellProps> = (props: FunctionCellProps): ReactElement => {

    const context = useContext(ModelContext);

    const [activeCell, setActiveCell] = useState<ActiveCell>();

    const handleCellFocusChangeEvent = (event: EmitterEvent): void => {
        setActiveCell(event.data);
    }

    useEffect(() => {
        setActiveCell(context.active.cell);
        context.addCallback!("CellFocusChange", handleCellFocusChangeEvent);
        return () => {
            context.removeCallback!("CellFocusChange", handleCellFocusChangeEvent);
        }
    }, []);

    const handleAddClick = (): void => {
        props.onAdd(activeCell!);
    }

    const handleEditClick = (cell: Cell): void => {
        props.onEdit(cell);
    }

    const buildView = (): ReactElement => {
        if (!activeCell) {
            return (
                <span>No cell active.</span>
            )
        }
        const sheet = activeCell.sheet;
        const cellId = activeCell.id;
        const cell = sheet.getCell(cellId, false);
        let fnCell;
        let inBlock;
        if (cell) {
            inBlock = cell.isInBlock();
            fnCell = cell.sheet.tracker.fnCell(cell, props.fn);
        }
        let refId;
        if (sheet.id === Sheet.PARAMETERS_SHEET) {
            refId = activeCell.variable
        } else {
            refId = `${ReferenceParser.quote(sheet.id)}!${cellId}`;
        }
        return (
            <div className="x-functioncell-view">
                <div>{refId}</div>
                {fnCell &&
                    <Button
                        disabled={inBlock || !fnCell.primary}
                        onClick={() => handleEditClick(cell)}
                    >
                        Edit
                    </Button>
                }
                {!fnCell &&
                    <Button
                        disabled={inBlock || (cell && cell.isBlockOwner())}
                        onClick={() => handleAddClick()}
                    >
                        Add
                    </Button>
                }
            </div>
        )
    }

    return (
        <div className="x-functioncell">
            <Label label="Active Cell" placement="top">
                {buildView()}
            </Label>
        </div>
    )

}
