import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Input, Menu } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ConfigurationType, ConfigurationSpec, CredentialsConfigurationSpec, IoType } from '@methodset/endpoint-client-ts';
import { CREDENTIALS_MAP } from '../../Specs/SpecsEditor/SpecEditor';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import './ExpressionInput.less';

type VarNames = { [key: string]: string };

export type DeleteCallback = (spec: ConfigurationSpec) => void;
export type ChangeCallback = (type: IoType, value?: string) => void;

export type ExpressionInputProps = {
    // The class to apply to the element.
    className?: string,
    // The configuration spec.
    spec: ConfigurationSpec,
    // The type of the current input. Note that 
    // value and type are separated (vs using Data)
    // so that an empty value will trigger validation
    // errors for required values.
    type?: IoType,
    // The value of the current input. Used for validation.
    value?: any,
    // The list of specs that can be used as variables. 
    // If defined, display the variables dropdown.
    variableSpecs?: ConfigurationSpec[],
    // The child component.
    children?: any,
    // True to show the expression value.
    showExpression?: boolean,
    // If defined, allows the input to be deleted.
    onDelete?: DeleteCallback,
    // Called when the expression or value changes.
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    showExpression: true,
    asFormula: false
}

export const ExpressionInput = (props: ExpressionInputProps): ReactElement => {

    const inputRef = useRef<Input>(null);
    const [isExpression, setIsExpression] = useState(props.type === IoType.EXPRESSION);
    const [varNames, setVarNames] = useState<VarNames>({});
    const [prevSpecs, setPrevSpecs] = useState<ConfigurationSpec[] | null>(null);

    const handleExpressionChange = (e: ChangeEvent<HTMLInputElement>): void => {
        let value = e.target.value;
        props.onChange(IoType.EXPRESSION, value);
    }

    const handleVariableInsert = (info: any): void => {
        const variable = info.key;
        const start = inputRef.current!.input.selectionStart;
        let pos = start ? start : 0;
        let curr = inputRef.current!.input.value;
        let value = `${curr.slice(0, pos)}\${${variable}}${curr.slice(pos)}`;
        inputRef.current!.input.value = value;
        pos += variable.length + 3;
        inputRef.current!.setSelectionRange(pos, pos);
        props.onChange(IoType.EXPRESSION, value);
    }

    const handleCheckToggle = (e: CheckboxChangeEvent): void => {
        const checked = e.target.checked;
        sendUpdate(checked);
    }

    const sendUpdate = (checked: boolean): void => {
        const type = checked ? IoType.EXPRESSION : props.spec.ioType;
        setIsExpression(checked);
        props.onChange(type, undefined);
    }

    const isComplete = (variable: ConfigurationSpec): boolean => {
        return !!variable.key && !!variable.name && !!variable.type;
    }

    const hasVariables = (variables?: ConfigurationSpec[]): boolean => {
        if (!variables || variables.length === 0) {
            if (isExpression && props.variableSpecs) {
                sendUpdate(false);
            }
            return false;
        }
        for (const variable of variables) {
            if (isComplete(variable)) {
                return true;
            }
        }
        if (isExpression && props.variableSpecs) {
            sendUpdate(false);
        }
        return false;
    }

    const buildVariables = (variables?: ConfigurationSpec[]): VarNames => {
        if (variables === prevSpecs) {
            return varNames;
        }
        const vars: VarNames = {};
        if (!variables) {
            return vars;
        }
        for (let variable of variables) {
            if (!isComplete(variable)) {
                continue;
            }
            if (variable.type === ConfigurationType.CREDENTIALS) {
                const spec = variable as CredentialsConfigurationSpec;
                const names = CREDENTIALS_MAP[spec.credentialsType];
                for (let name of names) {
                    const key = `${variable.key}.${name}`;
                    const label = `${variable.name} - ${CoreUtils.toCapital(name)}`
                    vars[key] = label;
                }
            } else {
                vars[variable.key] = variable.name;
            }
        }
        setPrevSpecs(variables);
        setVarNames(vars);
        return vars;
    }

    const enableExpression = hasVariables(props.variableSpecs);

    return (
        <div className={classNames("x-expressioninput", props.className)}>
            {isExpression &&
                <Spacer alignment="top">
                    <Input
                        ref={inputRef}
                        value={props.value}
                        onChange={handleExpressionChange}
                    />
                    {props.onDelete &&
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => props.onDelete!(props.spec)}
                        />
                    }
                </Spacer>
            }
            {!isExpression &&
                <Spacer alignment="top">
                    {props.children}
                    {props.onDelete &&
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => props.onDelete!(props.spec)}
                        />
                    }
                </Spacer>
            }
            {props.showExpression &&
                <div className="x-expressioninput-header">
                    <Checkbox
                        className="x-expressioninput-toggle"
                        disabled={!enableExpression || !props.variableSpecs}
                        checked={isExpression}
                        onChange={handleCheckToggle}
                    >
                        Use Expression
                    </Checkbox>
                    <Dropdown
                        disabled={!enableExpression || !props.variableSpecs || !isExpression}
                        overlay={(
                            <Menu onClick={handleVariableInsert}>
                                {Object.entries(buildVariables(props.variableSpecs))?.map(([key, name]) => (
                                    <Menu.Item key={key}>
                                        {name}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        )}
                        trigger={['click']}
                    >
                        <Button 
                            className="x-expressioninput-link" 
                            type="link" 
                            onClick={(e) => e.preventDefault()}
                        >
                            Insert Variable <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            }
        </div>
    )
}

ExpressionInput.defaultProps = defaultProps;
