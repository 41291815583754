import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormInstance, Input, Result, Row } from 'antd';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ResponsiveBox } from 'components/ResponsiveBox/ResponsiveBox';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { Justify } from 'components/Justify/Justify';
import { RestUtils } from 'utils/RestUtils';
import { FormData } from '../Services/Services';
import { RequestType } from '@methodset/entity-client-ts';
import update from 'immutability-helper';
import entityService from 'services/EntityService';
import './Waitlist.less';

export type WaitlistProps = RouteComponentProps & {}

export type WaitlistState = {
    errorMessage?: string,
    isSubmitting: boolean
    formData: FormData,
}

export class Waitlist extends PureComponent<WaitlistProps, WaitlistState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: WaitlistProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isSubmitting: false,
            formData: {
                requestType: RequestType.WAITLIST
            }
        };
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleCommentsChange = this.handleCommentsChange.bind(this);
        //this.handlePilotChange = this.handlePilotChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleFirstNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const firstName = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                firstName: { $set: firstName }
            })
        });
    }

    private handleLastNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const lastName = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                lastName: { $set: lastName }
            })
        });
    }

    private handleCompanyNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const companyName = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                companyName: { $set: companyName }
            })
        });
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>): void {
        const emailAddress = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                emailAddress: { $set: emailAddress }
            })
        });
    }

    private handleCommentsChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const comments = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                comments: { $set: comments }
            })
        });
    }

    // private handlePilotChange(e: CheckboxChangeEvent): void {
    //     const isPilot = e.target.checked;
    //     this.setState({
    //         formData: update(this.state.formData, {
    //             isPilot: { $set: isPilot }
    //         })
    //     });
    // }

    private handleFormFinish(): void {
        if (this.state.isSubmitting) {
            return;
        }
        this.saveWaitlistRequest();
    }

    private isSubmitted(): boolean {
        const query = this.props.location.search;
        const params = new URLSearchParams(query);
        const state = params.get("state");
        return state === "submitted";
    }

    private saveWaitlistRequest(): Promise<any> {
        this.setState({ isSubmitting: true });
        const request = {
            ...this.state.formData
        };
        return entityService.createRequest(request,
            (response: any) => this.createWaitlistResponse(response),
            (error: Error) => this.createWaitlistException(error),
            true
        );
    }

    private createWaitlistResponse(response: any): void {
        this.setState({ isSubmitting: false });
        this.props.history.push(RouteBuilder.requestSubmitted(RouteBuilder.MAIN_WAITLIST));
    }

    private createWaitlistException(error: Error,): void {
        this.setState({
            errorMessage: RestUtils.getError(error),
            isSubmitting: false
        });
    }

    private buildSuccessView(): ReactElement {
        return (
            <Result
                status="success"
                title="Request Submitted"
                subTitle="Thank you for submitting your request. We will notify you when the platform is available for general use."
                extra={(
                    <Button
                        type="primary"
                        onClick={() => this.props.history.push(RouteBuilder.HOME)}
                    >
                        Return to Home
                    </Button>
                )}
            />
        )
    }

    private buildFormView(): ReactElement {
        return (
            <FormFrame
                className="x-waitlist-form"
                title="Waitlist Request"
                error={this.state.errorMessage}
            >
                <Form
                    ref={this.formRef}
                    onFinish={this.handleFormFinish}
                >
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="First Name"
                        name="first-name"
                        rules={[{
                            required: true,
                            message: 'Please enter your first name.'
                        }]}
                    >
                        <Input
                            id="first"
                            placeholder="First name."
                            value={this.state.formData.firstName}
                            onChange={this.handleFirstNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Last Name"
                        name="last-name"
                        rules={[{
                            required: true,
                            message: 'Please enter your last name.'
                        }]}
                    >
                        <Input
                            id="last"
                            placeholder="Last name."
                            value={this.state.formData.lastName}
                            onChange={this.handleLastNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Company Name"
                        name="company-name"
                        rules={[{
                            required: true,
                            message: 'Please enter your company name.'
                        }]}
                    >
                        <Input
                            id="company"
                            placeholder="Company name."
                            value={this.state.formData.companyName}
                            onChange={this.handleCompanyNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Email"
                        name="email"
                        rules={[{
                            required: true,
                            message: 'Please enter your email address.'
                        }, {
                            type: 'email',
                            message: 'The email address is invalid.'
                        }]}
                    >
                        <Input
                            id="email"
                            placeholder="Email address."
                            value={this.state.formData.emailAddress}
                            onChange={this.handleEmailChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Comments"
                        name="comments"
                    >
                        <Input.TextArea
                            placeholder="Comments or questions."
                            rows={3}
                            value={this.state.formData.comments}
                            onChange={this.handleCommentsChange}
                        />
                    </FormItem>
                    {/* <Checkbox onChange={this.handlePilotChange}>
                        I would like to be part of the Pilot Program.
                    </Checkbox> */}
                    <Justify className="x-waitlist-footer" justification="right">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.isSubmitting}
                        >
                            Submit
                        </Button>
                    </Justify>
                </Form>
            </FormFrame>
        )
    }

    public render(): ReactElement {
        return (
            <ResponsiveBox
                lg={{ className: 'x-waitlist-lg' }}
                sm={{ className: 'x-waitlist-sm' }}
                xs={{ className: 'x-waitlist-xs' }}
            >
                <Row
                    justify="center"
                    align="top"
                >
                    <Col
                        className="x-waitlist-content"
                        lg={{ span: 14 }}
                        xs={{ span: 24 }}
                    >
                        {/* <p className="x-waitlist-instruction">
                            Thank you for your interest in the platform. Please enter your information if you 
                            would like to be notified when the platform becomes generally available.
                        </p> */}
                        <p className="x-waitlist-instruction">
                            Thank you for your interest in the platform. We are not currently open to the public,
                            but please enter your information if you would like to be notified when the platform
                            becomes generally available.
                        </p>
                        {/* <div className="x-waitlist-heading">Pilot Program</div>
                        <p className="x-waitlist-instruction">
                            We are currently accepting requests to be part of our Pilot Program. This will
                            involve working with our team so that they may understand your particular use cases and
                            can build any required components for you. The pilot will be free for participants and we
                            will setup and manage all your data during the pilot period. You can choose to op-out at any
                            time. Please let us know if you are interested in participating by checking the box at the
                            bottom of the form.
                        </p> */}
                    </Col>
                    <Col
                        className="x-waitlist-content"
                        lg={{ span: 10 }}
                        xs={{ span: 24 }}
                    >
                        {this.isSubmitted() &&
                            this.buildSuccessView()
                        }
                        {!this.isSubmitted() &&
                            this.buildFormView()
                        }
                    </Col>
                </Row>
            </ResponsiveBox>
        )
    }

}
