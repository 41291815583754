import { PureComponent, ReactElement } from 'react';
import { Select } from 'antd';
import { Globals } from 'constants/Globals';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import { QueryHeader, PrivacyType } from '@methodset/endpoint-client-ts';
import axios from 'axios';
import classNames from 'classnames';
import endpointService from 'services/EndpointService';
import './QueryPicker.less';

export type ChangeCallback = (queryId: string) => void;

export type QueryPickerState = {
    status: string,
    headers: QueryHeader[]
}

export type QueryPickerProps = {
    className?: string,
    queryId?: string,
    onChange: ChangeCallback
}

export class QueryPicker extends PureComponent<QueryPickerProps, QueryPickerState> {

    constructor(props: QueryPickerProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            headers: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleQueryChange(queryId: string): void {
        this.props.onChange(queryId);
    }

    private readQueriesRequest(): Promise<any> {
        const request = {
        };
        return endpointService.readQueryHeaders(request,
            (response: any) => this.readQueryHeadersResponse(response),
            undefined, true
        );
    }

    private readQueryHeadersResponse(response: any): void {
        const queries = response.data.headers;
        this.setState({ headers: queries });
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readQueriesRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-querypicker-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load datasets."
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private buildOptGroups(): ReactElement[] {
        const headers = this.state.headers;
        const groups = [];
        let i = 0;
        while (i < headers.length) {
            const header = headers[i];
            const type = header.privacyType!;
            const data = this.buildOptions(headers, i, type);
            const options = data[0];
            if (options.length > 0) {
                const group = (
                    <Select.OptGroup
                        key={i}
                        label={type}
                    >
                        {options}
                    </Select.OptGroup>
                );
                groups.push(group);
            }
            i = data[1];
        }
        return groups;
    }

    private buildOptions(headers: QueryHeader[], i: number, type: PrivacyType): [ReactElement[], number] {
        const options = [];
        while (i < headers.length) {
            const header = headers[i];
            if (type === header.privacyType) {
                const option = (
                    <Select.Option
                        key={header.id}
                        value={header.id}
                    >
                        {header.name}
                    </Select.Option>
                );
                options.push(option);
                i++;
            } else {
                break;
            }
        }
        return [options, i];
    }

    private buildDatasetView(): ReactElement {
        return (
            <Select
                placeholder="Select a dataset."
                value={this.props.queryId}
                onChange={this.handleQueryChange}
            >
                {this.buildOptGroups()}
            </Select>
        );
    }

    public componentDidMount(): void {
        this.loadData();
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildDatasetView();
        }
        return (
            <div className={classNames('x-querypicker', this.props.className)}>
                {view}
            </div>
        );
    }

}
