import { PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, NumberOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { QueryHeader, PrivacyType } from '@methodset/endpoint-client-ts';
import axios from 'axios';
import classNames from 'classnames';
import endpointService from 'services/EndpointService';
import update from 'immutability-helper';
import './Datasets.less';

export type QueriesProps = RouteComponentProps & {
    className?: string
}

export type QueriesState = {
    status: string,
    headers: QueryHeader[],
    privacyType: PrivacyType
}

export class Queries extends PureComponent<QueriesProps, QueriesState> {

    constructor(props: QueriesProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            headers: [],
            privacyType: PrivacyType.PRIVATE
        };
        this.disableAction = this.disableAction.bind(this);
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handlePrivacyChange = this.handlePrivacyChange.bind(this);
        this.handleQueryCreate = this.handleQueryCreate.bind(this);
        this.handleQueryEdit = this.handleQueryEdit.bind(this);
        this.handleQueryDelete = this.handleQueryDelete.bind(this);
        this.handleQueryVersion = this.handleQueryVersion.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData(this.state.privacyType);
    }

    private handleRefreshClick(): void {
        this.loadData(this.state.privacyType);
    }

    private handlePrivacyChange(e: RadioChangeEvent): void {
        const privacyType = e.target.value;
        this.setState({ privacyType: privacyType });
        this.loadData(privacyType);
    }

    private handleQueryCreate(): void {
        const url = RouteBuilder.query('create');
        this.props.history.push(url);
    }

    private handleQueryEdit(query: QueryHeader): void {
        const url = RouteBuilder.query(query.id);
        this.props.history.push(url);
    }

    private handleQueryDelete(query: QueryHeader): void {
        this.deleteQueryRequest(query.id);
    }

    private handleQueryVersion(queryId: string): void {
        this.createQueryVersionRequest(queryId);
    }

    private readQueryHeadersRequest(privacyType: PrivacyType): Promise<any> {
        const request = {
            privacyType: privacyType
        };
        return endpointService.readQueryHeaders(request,
            (response: any) => this.readQueryHeadersResponse(response),
            undefined, true
        );
    }

    private readQueryHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private deleteQueryRequest(queryId: string): Promise<any> {
        const request = {
            queryId: queryId
        };
        return endpointService.deleteUserQuery(request,
            (response: any) => this.deleteQueryResponse(response)
        );
    }

    private deleteQueryResponse(response: any): void {
        const queryId = response.data.queryId;
        const index = this.state.headers.findIndex(header => header.id === queryId);
        this.setState({
            headers: update(this.state.headers, {
                $splice: [[index, 1]]
            })
        });
    }

    private createQueryVersionRequest(queryId: string): Promise<any> {
        const request = {
            queryId: queryId
        };
        return endpointService.createQueryVersion(request,
            (response: any) => this.createQueryVersionResponse(response)
        );
    }

    private createQueryVersionResponse(response: any): void {
        const version = response.data.version;
        const queryId = response.data.queryId;
        const index = this.state.headers.findIndex(header => header.id === queryId);
        this.setState({
            headers: update(this.state.headers, {
                [index]: {
                    version: { $set: version }
                }
            })
        });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            ellipsis: true,
            width: Globals.TABLE_NAME_WIDTH,
            render: (query: QueryHeader) => {
                if (this.disableAction()) {
                    return <span>{query.name}</span>
                } else {
                    return <Link to={RouteBuilder.query(query.id)}>{query.name}</Link>
                }
            },
            sorter: (a: any, b: any) => {
                const diff = CoreUtils.compareStrings(a.name, b.name);
                return diff === 0 ? CoreUtils.compareStrings(a.connectorType, b.connectorType) : diff;
            },
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            render: (query: QueryHeader) => {
                return (
                    <span>{query.description ? query.description : Globals.EMPTY_FIELD}</span>
                );
            }
        }, {
            key: 'version',
            title: 'Latest Version',
            align: 'center',
            width: Globals.TABLE_VERSION_WIDTH,
            render: (query: QueryHeader) => {
                return (
                    <span>
                        {CoreUtils.toVersion(query.version)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            width: Globals.TABLE_DATE_WIDTH,
            render: (query: QueryHeader) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(query.updateTime)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private buildData(): any {
        return this.state.headers;
    }

    private disableAction(): boolean {
        return this.state.privacyType === PrivacyType.PUBLIC;
    }

    private loadData(privacyType: PrivacyType): void {
        const requests = [];
        requests.push(this.readQueryHeadersRequest(privacyType));
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData(this.state.privacyType);
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit dataset",
            callback: this.handleQueryEdit,
            disabled: this.disableAction
        }, {
            icon: <NumberOutlined />,
            label: "Create new version",
            callback: this.handleQueryVersion
        }, {
            icon: <DeleteOutlined />,
            label: "Delete dataset",
            confirm: "Are you sure you want to delete the dataset?",
            callback: this.handleQueryDelete
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <ItemTable
                className={classNames('x-queries', this.props.className)}
                title="Dataset"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={
                    <Space>
                        <Button onClick={this.handleQueryCreate}>New Dataset</Button>
                        <Radio.Group value={this.state.privacyType} onChange={this.handlePrivacyChange}>
                            <Radio.Button value={PrivacyType.PUBLIC}>
                                Public
                            </Radio.Button>
                            <Radio.Button value={PrivacyType.PRIVATE}>
                                Private
                            </Radio.Button>
                        </Radio.Group>
                        <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                    </Space>
                }
                actions={actions}
                disabled={this.disableAction}
                onLoad={this.handleRetryLoad}
            />
        );
    }

}
