import { Calculator } from '@methodset/calculator-ts';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { Applet, PanelWidgetConfiguration } from '@methodset/model-client-ts';
import { AppletViewer } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/AppletViewer';
import { Globals } from 'constants/Globals';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { RestUtils } from 'utils/RestUtils';
import { Configuration } from '@methodset/endpoint-client-ts';
import { Card } from 'antd';
import { ModelContext } from 'context/ModelContext';
import axios from 'axios';
import classNames from 'classnames';
import modelService from 'services/ModelService';
import './PanelWidgetViewer.less';

export type PanelWidgetViewerProps = {
    calculator: Calculator,
    configuration: PanelWidgetConfiguration,
    modelId: string,
    version?: number,
    appletConfiguration?: Configuration,
};

export const PanelWidgetViewer = (props: PanelWidgetViewerProps): ReactElement => {

    const context = useContext(ModelContext);

    const [status, setStatus] = useState<string>(Globals.STATUS_INIT);
    const [applet, setApplet] = useState<Applet | undefined>();

    useEffect(() => {
        if (status !== Globals.STATUS_READY) {
            loadData();
        }
    }, []);

    const loadData = (): void => {
        if (context) {
            // Editing mode, model loaded.
            const appletId = props.configuration.appletId;
            const applet = context.model?.applets.find(applet => applet.id === appletId);
            if (applet) {
                setApplet(applet);
                setStatus(Globals.STATUS_READY);
            } else {
                setStatus(Globals.STATUS_FAILED);
            }
        } else {
            // Viewing mode, fetch applet.
            const requests = [];
            requests.push(readAppletRequest());
            setStatus(Globals.STATUS_LOADING);
            axios.all(requests).then(axios.spread((r1) => {
                if (RestUtils.isOk(r1)) {
                    setStatus(Globals.STATUS_READY);
                } else {
                    setStatus(Globals.STATUS_FAILED);
                }
            }));
        }
    }

    const readAppletRequest = (): Promise<any> => {
        const request = {
            modelId: props.modelId,
            modelVersion: props.version,
            appletId: props.configuration.appletId
        };
        return modelService.readApplets(request,
            (response: any) => readAppletResponse(response),
            (response: any) => readAppletException(response),
            true
        );
    }

    const readAppletResponse = (response: any): void => {
        const applet = response.data.applet;
        setApplet(applet);
    }

    const readAppletException = (response: any): void => {
        return;
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                className="x-panelwidgetviewer-skeleton"
                count={4}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load applet."
                onRetry={loadData}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    const buildAppletsView = (): ReactElement => {
        if (!applet) {
            return (
                <div>Could not load applet.</div>
            )
        }
        const view = (
            <AppletViewer
                key={applet.id}
                applet={applet}
                calculator={props.calculator}
                modelId={props.modelId}
                version={props.version}
                appletConfiguration={props.appletConfiguration}
                isEmbedded={true}
            />
        )
        if (props.configuration.title) {
            return (
                <Card
                    className={classNames({ "x-panelwidgetviewer-shadow": props.configuration.shadow })}
                    size="small"
                    bordered={true}
                    title={props.configuration.title}
                >
                    {view}
                </Card>
            )
        } else {
            return (
                <div className={classNames("x-panelwidgetviewer", { "x-panelwidgetviewer-shadow": props.configuration.shadow })}>
                    {view}
                </div>
            )
        }
    }

    const buildView = (): ReactElement => {
        if (status === Globals.STATUS_LOADING) {
            return buildLoadingView(true);
        } else if (status === Globals.STATUS_FAILED) {
            return buildLoadingView(false);
        } else if (status === Globals.STATUS_READY) {
            return buildAppletsView();
        } else {
            // STATUS_INIT
            return <></>;
        }
    }

    return buildView();

}
