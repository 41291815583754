import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { FormInstance, Input, Switch } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { AppletSelector } from '../../Selectors/AppletSelector';
import { WidgetType, PanelWidgetConfiguration } from '@methodset/model-client-ts';
import update from 'immutability-helper';
import './PanelWidgetEditor.less';

export type ChangeCallback = (configuration: PanelWidgetConfiguration) => void;

export type PanelWidgetEditorProps = typeof PanelWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    calculator: Calculator,
    configuration?: PanelWidgetConfiguration,
    onChange: ChangeCallback
}

export class PanelWidgetEditor extends PureComponent<PanelWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.PANEL,
    } as PanelWidgetConfiguration;

    static defaultProps = {
        configuration: PanelWidgetEditor.DefaultConfiguration
    }

    constructor(props: PanelWidgetEditorProps) {
        super(props);
        this.handleAppletChange = this.handleAppletChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleShadowToggle = this.handleShadowToggle.bind(this);
    }

    private handleAppletChange(appletId: string): void {
        const configuration = update(this.props.configuration, {
            appletId: { $set: appletId }
        });
        this.props.onChange(configuration);
    }

    private handleTitleChange(e: ChangeEvent<HTMLInputElement>): void {
        const title = e.target.value;
        const configuration = update(this.props.configuration, {
            title: { $set: title }
        });
        this.props.onChange(configuration);
    }

    private handleShadowToggle(shadow: boolean): void {
        const configuration = update(this.props.configuration, {
            shadow: { $set: shadow },
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === PanelWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <>
                {this.props.extra}
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Applet"
                    name="applet"
                    info="The applet to display inside the panel."
                    valuePropName="appletId"
                    rules={[{
                        required: true,
                        message: 'Please select an applet.'
                    }]}
                >
                    <AppletSelector
                        appletId={this.props.configuration.appletId}
                        onChange={this.handleAppletChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Title"
                    name="title"
                    info="Text to include at the top of the panel."
                >
                    <Input
                        value={this.props.configuration.title}
                        onChange={this.handleTitleChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Add Shadow"
                    name="shadow"
                    info="Add a shadow to the outside of the panel."
                    valuePropName="checked"
                >
                    <Switch
                        checked={!!this.props.configuration.shadow}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={this.handleShadowToggle}
                    />
                </FormItem>
            </>
        );
    }

}
