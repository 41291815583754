import { PureComponent, ReactElement, useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Space, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { YodleeProvider } from '@methodset/endpoint-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import axios from 'axios';
import update from 'immutability-helper';
import endpointService from 'services/EndpointService';
import './Accounts.less';

export type AccountsProps = RouteComponentProps;

export const Accounts = (props: AccountsProps): ReactElement => {

    const [status, setStatus] = useState<string>(Globals.STATUS_INIT);
    const [providers, setProviders] = useState<YodleeProvider[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleAccountCreate = (): void => {
        const url = RouteBuilder.applicationAccount("create");
        props.history.push(url);
    }

    const handleAccountEdit = (provider: YodleeProvider): void => {
        const url = RouteBuilder.applicationAccount(provider.id)
        props.history.push(url);
    }

    const handleAccountDelete = (provider: YodleeProvider): void => {
        deleteYodleeProviderRequest(provider.id);
    }

    const readYodleeProvidersRequest = (): Promise<any> => {
        const request = {};
        return endpointService.readYodleeProviders(request,
            (response: any) => readYodleeProvidersResponse(response),
            undefined, true
        );
    }

    const readYodleeProvidersResponse = (response: any): void => {
        setProviders(response.data.providers);
    }

    const deleteYodleeProviderRequest = (providerId: string): Promise<any> => {
        const request = {
            providerId: providerId
        };
        return endpointService.deleteYodleeProvider(request,
            (response: any) => deleteYodleeProviderResponse(response)
        );
    }

    const deleteYodleeProviderResponse = (response: any): void => {
        const providerId = response.data.providerId;
        const index = providers.findIndex(provider => provider.id === providerId);
        const updated = update(providers, {
            $splice: [[index, 1]]
        });
        setProviders(updated);
    }

    const buildColumns = (): ColumnsType<any> => {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            render: (name, provider) => {
                return <Link to={RouteBuilder.applicationAccount(provider.id)}>{name}</Link>
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'utime',
            title: 'Last Updated',
            width: Globals.TABLE_DATE_WIDTH,
            align: 'center',
            dataIndex: 'updateTime',
            render: (time, provider) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(time)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readYodleeProvidersRequest());
        setStatus(Globals.STATUS_LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(Globals.STATUS_READY);
            } else {
                setStatus(Globals.STATUS_FAILED);
            }
        }));
    }

    const actions = [{
        icon: <EditOutlined />,
        label: "Edit account link",
        callback: handleAccountEdit
    }, {
        icon: <DeleteOutlined />,
        label: "Remove account link",
        confirm: "Are you sure you want to remove the account link?",
        callback: handleAccountDelete
    }];
    const columns = buildColumns();
    const data = providers;
    return (
        <ItemTable
            className="x-accounts"
            title="Accounts"
            status={status}
            columns={columns}
            items={data}
            extra={
                <Space>
                    <Button type="primary" onClick={() => handleAccountCreate()}>Link Provider</Button>
                </Space>
            }
            actions={actions}
            showHeader={false}
            pagination={false}
            onLoad={handleRetryLoad}
        />
    );

}
