import { PureComponent, ReactElement } from 'react';
import { Card, Col, List, Row } from 'antd';
import { ResponsiveBox } from 'components/ResponsiveBox/ResponsiveBox';
import { CheckSquareFilled } from '@ant-design/icons';
import { Justify } from 'components/Justify/Justify';
import { Spacer } from 'components/Spacer/Spacer';
import platform_overview_image from 'images/platform_overview.png';
import screenshot_dashboard_image from 'images/screenshot_dashboard-2022-12-19.png';
import screenshot_model_image from 'images/screenshot_model-2022-12-19.png';
import screenshot_dataset_image from 'images/screenshot_dataset-2022-10-14.png';
import screenshot_workflow_image from 'images/screenshot_workflow-2022-10-14.png';
import computer_gear_image from 'images/computer_gear.png';
import data_monitoring_image from 'images/data_monitoring.png';
import data_share_image from 'images/data_share.png';
import './Business.less';

export class Business extends PureComponent {

    public render(): ReactElement {
        return (
            <div className="x-business">
                <ResponsiveBox
                    className="x-business-overview"
                    //xxl={{ className: 'x-business-overview-xxl' }}
                    //xl={{ className: 'x-business-overview-xl' }}
                    //lg={{ className: 'x-business-overview-lg' }}
                    md={{ className: 'x-business-overview-md' }}
                    //sm={{ className: 'x-business-overview-sm' }}
                    xs={{ className: 'x-business-overview-xs' }}
                >
                    <Row gutter={8}>
                        <Col
                            lg={{ span: 10 }}
                            xs={{ span: 24 }}
                        >
                            <Row>
                                <Col span={22} offset={1}>
                                <div className="x-business-overview-title">
                                    Financial Reporting Platform
                                </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={22} offset={1}>
                                <div className="x-business-overview-subtitle">
                                    Customized preparation, analysis, and presentation.
                                </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            lg={{ span: 14 }}
                            xs={{ span: 24 }}
                        >
                            <div>
                            <img className="x-business-overview-graphic" src={platform_overview_image} alt="platform_overview" />
                            </div>
                        </Col>
                    </Row>
                </ResponsiveBox>
                <div className="x-business-description">
                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                        >
                            <p>
                                MethodSet is a platform that can be used to easily build custom financial reporting
                                applications for business, clients, or individuals. Building blocks can be assembled
                                to automate the preparation of data, create custom calculators and analysis, and 
                                enable interactive visualizations. The platform lives 100% in the cloud,
                                so there is no hardware to provision, allowing you to get up and running quickly.
                            </p>
                        </Col>
                    </Row>
                </div>
                <div className="x-business-section">
                    <div className="x-business-title">Prepare, Analyze, and Present</div>
                    <Row gutter={[32, 20]}>
                        <Col
                            md={{ span: 8, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <Card
                                className="x-business-blurb"
                                cover={
                                    <img
                                        className="x-business-usecase-image"
                                        src={computer_gear_image}
                                        alt="computer_gear"
                                    />
                                }
                            >
                                <Card.Meta
                                    title="Prepare: Aggregate Data"
                                    description={
                                        <span>
                                            Combine and clean data from provider APIs, bank accounts, emails, files, databases,
                                            or scraped from web pages. Create real-time or stored datasets.
                                        </span>
                                    }
                                />
                            </Card>
                        </Col>
                        <Col
                            md={{ span: 8, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <Card
                                className="x-business-blurb"
                                cover={
                                    <img
                                        className="x-business-usecase-image"
                                        src={data_share_image}
                                        alt="data_share"
                                    />
                                }
                            >
                                <Card.Meta
                                    title="Analyze: Perform Calculations"
                                    description={
                                        <span>
                                            Model and analyze financial data with the integrated calculator. Use formulas and
                                            functions to create computed results, just like a spreadsheet.
                                        </span>
                                    }
                                />
                            </Card>
                        </Col>
                        <Col
                            md={{ span: 8, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <Card
                                className="x-business-blurb"
                                cover={
                                    <img
                                        className="x-business-usecase-image"
                                        src={data_monitoring_image}
                                        alt="data_monitoring"
                                    />
                                }
                            >
                                <Card.Meta
                                    title="Present: Display Results"
                                    description={
                                        <span>
                                            Stay on top of the latest financial data with dashboards and embedded widgets. Continually
                                            track changes with real-time updates and notifications.
                                        </span>
                                    }
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="x-business-section">
                    <Justify justification="center">
                        <div>
                            <Spacer className="x-business-checkbox">
                                <CheckSquareFilled />
                                <span>Connect to banking and investment data to get real-time views of account information.</span>
                            </Spacer>
                            <Spacer className="x-business-checkbox">
                                <CheckSquareFilled />
                                <span>Build custom models to combine data sources and perform forecasting and scenario analysis.</span>
                            </Spacer>
                            <Spacer className="x-business-checkbox">
                                <CheckSquareFilled />
                                <span>Automate data workflows and replace existing Excel-based analysis and reporting.</span>
                            </Spacer>
                            <Spacer className="x-business-checkbox">
                                <CheckSquareFilled />
                                <span>Prepare data for compliance, regulatory, and reconciliation processing.</span>
                            </Spacer>
                            <Spacer className="x-business-checkbox">
                                <CheckSquareFilled />
                                <span>Increase stakeholder transparency by sharing custom dashboards and real-time data views.</span>
                            </Spacer>
                            <Spacer className="x-business-checkbox">
                                <CheckSquareFilled />
                                <span>Create custom reports for clients using their specific account data.</span>
                            </Spacer>
                            <Spacer className="x-business-checkbox">
                                <CheckSquareFilled />
                                <span>Run workflows to snapshot and store timeseries data to track trends and history.</span>
                            </Spacer>
                            <Spacer className="x-business-checkbox">
                                <CheckSquareFilled />
                                <span>Monitor important data points and computed values, and get alerted when conditions are met.</span>
                            </Spacer>
                        </div>
                    </Justify>
                </div>
                <div className="x-business-section">
                    <div className="x-business-title">Features</div>
                    <Row className="x-business-feature-section" gutter={[20, 20]}>
                        <Col
                            md={{ span: 6, offset: 0, order: 1 }}
                            sm={{ span: 24, offset: 0, order: 1 }}
                        >
                            <List
                                className="x-business-feature-list"
                                size="large"
                                header={(
                                    <div className="x-business-feature-title">Datasets</div>
                                )}
                                dataSource={[
                                    "Clean and prepare data through a comprehensive set of processors, transformers, and filters.",
                                    "Import datasets and use in models, workflows, and visualizations.",
                                    "Combine data sources to enrich data records for further analysis."
                                ]}
                                renderItem={item => (
                                    <List.Item className="x-business-feature-item">
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col
                            md={{ span: 18, offset: 0, order: 2 }}
                            sm={{ span: 24, offset: 0, order: 2 }}
                        >
                            <img className="x-business-feature-image" src={screenshot_dataset_image} alt="dataset" />
                        </Col>
                    </Row>
                    <Row className="x-business-feature-section" gutter={[20, 20]}>
                        <Col
                            md={{ span: 6, offset: 0, order: 2 }}
                            sm={{ span: 24, offset: 0, order: 1 }}
                        >
                            <List
                                className="x-business-feature-list"
                                size="large"
                                header={(
                                    <div className="x-business-feature-title">Models</div>
                                )}
                                dataSource={[
                                    "Use the powerful integrated calculator to perform custom computations on data.",
                                    "Easily import data into models by connecting to external data sources and providers.",
                                    "Define variables and use in formulas and queries to affect computations."
                                ]}
                                renderItem={item => (
                                    <List.Item className="x-business-feature-item">
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col
                            md={{ span: 18, offset: 0, order: 1 }}
                            sm={{ span: 24, offset: 0, order: 2 }}
                        >
                            <img className="x-business-feature-image" src={screenshot_model_image} alt="model" />
                        </Col>
                    </Row>
                    <Row className="x-business-feature-section" gutter={[20, 20]}>
                        <Col
                            md={{ span: 6, offset: 0, order: 1 }}
                            sm={{ span: 24, offset: 0, order: 1 }}
                        >
                            <List
                                className="x-business-feature-list"
                                size="large"
                                header={(
                                    <div className="x-business-feature-title">Workflows</div>
                                )}
                                dataSource={[
                                    "Build and trigger workflows to automate data pipelines.",
                                    "Store data in our managed databases, with no infrastructure to setup.",
                                    "Create timeseries to track trends, history, and data changes."
                                ]}
                                renderItem={item => (
                                    <List.Item className="x-business-feature-item">
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col
                            md={{ span: 18, offset: 0, order: 2 }}
                            sm={{ span: 24, offset: 0, order: 2 }}
                        >
                            <img className="x-business-feature-image" src={screenshot_workflow_image} alt="workflow" />
                        </Col>
                    </Row>
                    <Row className="x-business-feature-section" gutter={[20, 20]}>
                        <Col
                            md={{ span: 6, offset: 0, order: 2 }}
                            sm={{ span: 24, offset: 0, order: 1 }}
                        >
                            <List
                                className="x-business-feature-list"
                                size="large"
                                header={(
                                    <div className="x-business-feature-title">Presentation</div>
                                )}
                                dataSource={[
                                    "Build views to combine numerical and textual data based on datasets and computed values.",
                                    "Configure to continuously update for real-time data views.",
                                    "Create dashboards or embed into existing web pages."
                                ]}
                                renderItem={item => (
                                    <List.Item className="x-business-feature-item">
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col
                            md={{ span: 18, offset: 0, order: 1 }}
                            sm={{ span: 24, offset: 0, order: 2 }}
                        >
                            <img className="x-business-feature-image" src={screenshot_dashboard_image} alt="dashboard" />
                        </Col>
                    </Row>
                </div>
                {/* <div className="x-business-section">
                    <div className="x-business-title">Overview Demo</div>
                    <div>
                        Check out the demo below to see the main features in action.
                    </div>
                    <div className="x-business-video">
                        <iframe src="https://www.youtube.com/embed/OVYlyhyT8BU" title="Overview Demo" allow="fullscreen"></iframe>
                    </div>
                </div> */}
            </div>
        )
    }

}
