import React, { PureComponent } from 'react';
import { FormInstance, Input } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ItemEditor } from 'containers/Console/ItemEditor/ItemEditor';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RestUtils } from 'utils/RestUtils';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { Group } from '@methodset/entity-client-ts';
import entityService from 'services/EntityService';
import authService from 'services/AuthService';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import './GroupProperties.less';

type MatchParams = {
    groupId: string
}

export type GroupPropertiesProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export type GroupPropertiesState = {
    status: string,
    group: Group,
    errorMessage: string | undefined,
    isLoading: boolean,
    isCreate: boolean
}

export class GroupProperties extends PureComponent<GroupPropertiesProps, GroupPropertiesState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();
    private groupId: string;

    constructor(props: GroupPropertiesProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            group: {} as Group,
            errorMessage: undefined,
            isLoading: false,
            isCreate: false
        };
        this.groupId = props.match.params.groupId;
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    }

    handleSaveClick(): void {
        if (this.groupId === 'create') {
            this.createGroupRequest();
        } else {
            this.updateGroupRequest();
        }
    }

    handleCancelClick(): void {
        this.props.history.goBack();
    }

    handleNameChange(e: any): void {
        const name = e.target.value;
        const group = update(this.state.group, {
            name: { $set: name }
        })
        this.setState({ group: group });
    }

    handleDescriptionChange(e: any): void {
        const description = e.target.value;
        const group = update(this.state.group, {
            description: { $set: description }
        })
        this.setState({ group: group });
    }

    readGroupRequest(): Promise<any> {
        if (this.groupId === 'create') {
            return Promise.resolve(true);
        }
        const request = {
            groupId: this.groupId
        };
        return entityService.readGroup(request,
            (response: any) => this.readGroupResponse(response),
            undefined, true
        );
    }

    readGroupResponse(response: any): void {
        let group = response.data.group;
        this.setState({ group: group });
    }

    createGroupRequest(): Promise<any> {
        this.setState({
            errorMessage: undefined,
            isLoading: true
        });
        const request = {
            name: this.state.group.name,
            description: this.state.group.description
        }
        return entityService.createGroup(request,
            (response: any) => this.createGroupResponse(response),
            (error: any) => this.createGroupException(error),
            true
        );
    }

    createGroupResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isLoading: false
        });
        // When a group is created, the user will be added
        // to it. The user identity needs to be refreshed
        // to include the new Cognito group.
        authService.refreshUser().then((user) => {
            this.context.saveUser(user);
        }).catch((err) => {
            this.context.clearUser();
        });
        const group = response.data.group;
        this.context.addGroup(group);
        this.props.history.push(RouteBuilder.CONSOLE_GROUPS);
    }

    createGroupException(error: any): void {
        this.setState({
            errorMessage: RestUtils.getError(error),
            isLoading: false
        });
    }

    updateGroupRequest(): Promise<any> {
        this.setState({
            errorMessage: undefined,
            isLoading: true
        });
        const request = {
            groupId: this.state.group.id,
            name: this.state.group.name,
            description: this.state.group.description,
        }
        return entityService.updateGroup(request,
            (response: any) => this.updateGroupResponse(response),
            (error: any) => this.updateGroupException(error),
            true
        );
    }

    updateGroupResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isLoading: false
        });
        this.props.history.goBack();
    }

    updateGroupException(error: any): void {
        this.setState({
            errorMessage: RestUtils.getError(error),
            isLoading: false
        });
    }

    loadData(): void {
        const requests = [];
        requests.push(this.readGroupRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    render() {
        return (
            <ItemEditor
                className={classNames('x-groupproperties', this.props.className)}
                ref={this.formRef}
                title="Group"
                status={this.state.status}
                loading={this.state.isLoading}
                okText={this.groupId === 'create' ? 'Create' : 'Update'}
                onSave={this.handleSaveClick}
                onCancel={this.handleCancelClick}
            >
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Name"
                    name="name"
                    rules={[{
                        required: true,
                        message: 'Please enter group name.'
                    }]}
                >
                    <Input
                        id="name"
                        placeholder="Group name."
                        value={this.state.group.name}
                        onChange={this.handleNameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Description"
                    name="description"
                >
                    <Input.TextArea
                        id="description"
                        rows={3}
                        placeholder="Description."
                        value={this.state.group.description}
                        onChange={this.handleDescriptionChange}
                    />
                </FormItem>
                <div className="x-groupproperties-error">{this.state.errorMessage}</div>
            </ItemEditor>
        );
    }

}
