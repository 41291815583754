import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Col, Form, FormInstance, Input, Row, Select, Switch } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { Options } from './Options/Options';
import { Parameter } from '@methodset/calculator-ts';
import { Boolean, Date, Number, String, Time } from '@methodset/commons-shared-ts';
import { ConfigurationSpec, ConfigurationType, CredentialsConfigurationSpec, CredentialsType, IoType, Option, OptionConfigurationSpec, Query, RequirementType, VariableSpec } from '@methodset/endpoint-client-ts';
import { EditorHeader } from '../../EditorHeader/EditorHeader';
import { FilterInput } from 'components/FilterInput/FilterInput';
import { ConfigurationUtils } from 'utils/ConfigurationUtils';
import { QueryPicker } from 'containers/Console/ConfigurationEditor/QuerySelector/QueryPicker/QueryPicker';
import { QueryVersionPicker } from 'containers/Console/ConfigurationEditor/QuerySelector/QueryVersionPicker/QueryVersionPicker';
import { QueryLoader } from 'containers/Console/ConfigurationEditor/QuerySelector/QueryLoader/QueryLoader';
import update from 'immutability-helper';
import './SpecEditor.less';

export enum SpecType {
    REFERENCE,
    CONFIGURATION
}

export const CREDENTIALS_MAP: { [key: string]: string[] } = {
    API: ['key'],
    FTP: ['host', 'port', 'username', 'password'],
    Web: ['username', 'password']
};

export type CancelCallback = () => void;
export type DoneCallback = (variable: VariableSpec, index: number) => void;

export type SpecEditorProps = typeof SpecEditor.defaultProps & {
    className?: string,
    specType?: SpecType,
    parameters?: Parameter[],
    spec?: VariableSpec,
    specs: VariableSpec[],
    index: number,
    excludes: ConfigurationType[],
    onCancel: CancelCallback,
    onDone: DoneCallback
}

export type SpecEditorState = {
    spec: VariableSpec,
    query?: Query,
    useRef?: boolean
}

export class SpecEditor extends PureComponent<SpecEditorProps, SpecEditorState> {

    static defaultProps = {
        specType: SpecType.CONFIGURATION
    }

    private formRef = React.createRef<FormInstance>();

    constructor(props: SpecEditorProps) {
        super(props);
        this.state = {
            useRef: !!props.spec && !!props.spec.queryId,
            spec: props.spec ? props.spec : {
                type: undefined as any,
                ioType: undefined as any,
                key: undefined as any,
                name: undefined as any,
                description: undefined as any,
                requirementType: RequirementType.REQUIRED,
                defaultData: {}
            } as ConfigurationSpec
        }
        this.handleReferenceChange = this.handleReferenceChange.bind(this);
        this.handleCredentialsChange = this.handleCredentialsChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleOptionTypeChange = this.handleOptionTypeChange.bind(this);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleRequiredChange = this.handleRequiredChange.bind(this);
        this.handleOptionsChange = this.handleOptionsChange.bind(this);
        this.handleEditDone = this.handleEditDone.bind(this);
        this.handleEditError = this.handleEditError.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
        this.handleQueryLoaded = this.handleQueryLoaded.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleSpecKeyChange = this.handleSpecKeyChange.bind(this);
    }

    private handleReferenceChange(useRef: boolean): void {
        if (!useRef) {
            // Not using a reference, clear out ref fields.
            const spec = update(this.state.spec, {
                queryId: { $set: undefined },
                version: { $set: undefined },
                specKey: { $set: undefined }
            });
            this.setState({
                spec: spec,
                useRef: useRef
            });
        } else {
            // Using a reference, clear the type (will get set as the reference type).
            const spec = update(this.state.spec, {
                type: { $set: undefined as any },
            });
            this.setState({
                spec: spec,
                useRef: useRef
            });
        }
    }

    private handleCredentialsChange(credentialsType: string): void {
        const spec = update(this.state.spec as CredentialsConfigurationSpec, {
            credentialsType: { $set: credentialsType as CredentialsType }
        });
        this.setState({ spec: spec });
    }

    private handleVariableChange(variable: string | undefined): void {
        let spec;
        if (variable && this.props.parameters) {
            const parameter = this.props.parameters.find(parameter => parameter.variable === variable);
            if (parameter) {
                const type = this.guessType(parameter);
                spec = ConfigurationUtils.createConfigurationSpec(
                    type,
                    variable,
                    parameter.name,
                    parameter.description
                )!;
            } else {
                spec = update(this.state.spec, {
                    key: { $set: variable as any }
                });
            }
        } else {
            spec = update(this.state.spec, {
                key: { $set: variable as any }
            });
        }
        this.setState({ spec: spec });
    }

    private guessType(parameter: Parameter): ConfigurationType {
        const value = parameter.value;
        if (String.isString(value)) {
            return ConfigurationType.TEXT;
        } else if (Number.isNumber(value)) {
            return ConfigurationType.NUMBER;
        } else if (Boolean.isBoolean(value)) {
            return ConfigurationType.BOOLEAN;
        } else if (Date.isDate(value)) {
            return ConfigurationType.DATE;
        } else if (Time.isTime(value)) {
            return ConfigurationType.TIME;
        } else {
            return ConfigurationType.TEXT;
        }
    }

    private handleRequiredChange(isRequired: boolean): void {
        const requirementType = isRequired ? RequirementType.REQUIRED : RequirementType.OPTIONAL;
        const spec = update(this.state.spec, {
            requirementType: { $set: requirementType }
        });
        this.setState({ spec: spec });
    }

    private handleTypeChange(type: ConfigurationType): void {
        const spec = ConfigurationUtils.createConfigurationSpec(
            type,
            this.state.spec.key,
            this.state.spec.name,
            this.state.spec.description
        )!;
        this.setState({ spec: spec });
    }

    private handleOptionTypeChange(type: IoType): void {
        const spec = update(this.state.spec, {
            ioType: { $set: type }
        });
        this.setState({ spec: spec });
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const spec = update(this.state.spec, {
            name: { $set: label }
        });
        this.setState({ spec: spec });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const spec = update(this.state.spec, {
            description: { $set: description }
        });
        this.setState({ spec: spec });
    }

    private handleEditDone(): void {
        this.props.onDone(this.state.spec, this.props.index);
    }

    private handleEditError(e: any): void {
    }

    private handleEditCancel(): void {
        this.props.onCancel();
    }

    private handleOptionsChange(options: Option[]): void {
        const spec = update(this.state.spec as OptionConfigurationSpec, {
            options: { $set: options }
        });
        this.setState({ spec: spec });
    }

    private handleQueryLoaded(query: Query): void {
        this.setState({ query: query });
    }

    private handleQueryChange(queryId: string): void {
        const spec = update(this.state.spec, {
            queryId: { $set: queryId },
            //version: { $set: Globals.SNAPSHOT_VERSION },
            specKey: { $set: undefined }
        });
        this.setState({ spec: spec });
    }

    private handleVersionChange(version: number): void {
        const spec = update(this.state.spec, {
            version: { $set: version },
            specKey: { $set: undefined }
        });
        this.setState({ spec: spec });
    }

    private handleSpecKeyChange(specKey: string): void {
        let spec = this.state.spec;
        const query = this.state.query;
        if (query) {
            const specs = query.configurationSpecs;
            const refSpec = specs.find(spec => spec.key === specKey);
            if (refSpec) {
                // Transfer reference data to the target spec.
                spec = update(spec, {
                    ioType: { $set: refSpec.ioType },
                    type: { $set: refSpec.type },
                    name: { $set: refSpec.name },
                    description: { $set: refSpec.description },
                });
            }
        }
        spec = update(spec, {
            specKey: { $set: specKey }
        });
        this.setState({ spec: spec });
    }

    private isParameterUsed(parameter: Parameter): boolean {
        return this.props.specs.findIndex(spec => spec.key === parameter.variable) !== -1;
    }

    public render(): ReactElement {
        return (
            <Form
                ref={this.formRef}
                onFinish={this.handleEditDone}
                onFinishFailed={this.handleEditError}
            >
                <EditorHeader
                    title="Variable Editor"
                    onCancel={this.handleEditCancel}
                />
                <Row gutter={16}>
                    <Col span={12}>
                        {this.props.specType === SpecType.REFERENCE &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name={`spec-ref-${this.props.index}`}
                                label="Use Reference"
                                info="Specify whether to use another variable's type and data as a template. Most helpful when the field represents the options type so that they can be used for the variable input ."
                            >
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    checked={this.state.useRef}
                                    onChange={this.handleReferenceChange}
                                />
                            </FormItem>
                        }
                    </Col>
                </Row>
                <Row gutter={[Globals.FORM_GUTTER_COL, Globals.FORM_GUTTER_ROW]}>
                    <Col span={12}>
                        {!this.props.parameters &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name={`spec-variable-${this.props.index}`}
                                label="Variable"
                                info="The variable to be used in expressions, e.g., x_value. The name can include numbers, letters, underscores and dashes."
                                rules={[{
                                    required: true,
                                    message: `Enter a variable.`
                                }, {
                                    validator: (rule: any, value: string) => {
                                        // Make sure variable names are unique and don't dup another.
                                        const idx = this.props.specs.findIndex(variable => variable.key === value);
                                        return (idx !== -1 && idx !== this.props.index) ?
                                            Promise.reject("Variable already exists.") :
                                            Promise.resolve();
                                    }
                                }]}
                            >
                                <FilterInput
                                    placeholder="Variable."
                                    tester={(value: string) => /^[a-zA-Z]+[a-zA-Z0-9_]*$/.test(value)}
                                    value={this.state.spec.key}
                                    onChange={this.handleVariableChange}
                                />
                            </FormItem>
                        }
                        {this.props.parameters &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name={`spec-variable-${this.props.index}`}
                                label="Variable"
                                info="The variable to be used in expressions, e.g., x_value. The name can include numbers, letters, underscores and dashes."
                                rules={[{
                                    required: true,
                                    message: `Enter a variable.`
                                }, {
                                    validator: (rule: any, value: string) => {
                                        // Make sure variable names are unique and don't dup another.
                                        const idx = this.props.specs.findIndex(spec => spec.key === value);
                                        return (idx !== -1 && idx !== this.props.index) ?
                                            Promise.reject("Variable already exists.") :
                                            Promise.resolve();
                                    }
                                }]}
                            >
                                <Select
                                    placeholder="Variable."
                                    value={this.state.spec.key}
                                    onChange={this.handleVariableChange}
                                >
                                    {this.props.parameters.map(parameter => (
                                        <Select.Option
                                            key={parameter.variable}
                                            value={parameter.variable}
                                            disabled={this.isParameterUsed(parameter)}
                                        >
                                            {parameter.variable}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        }
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            name={`spec-type-${this.props.index}`}
                            label="Type"
                            info="The type of the variable."
                            rules={[{
                                required: true,
                                message: "Select a variable type."
                            }]}
                        >
                            <Select
                                placeholder="Variable type."
                                value={this.state.spec.type}
                                disabled={this.state.useRef && this.props.specType === SpecType.REFERENCE}
                                onChange={this.handleTypeChange}>
                                {!this.props.excludes.includes(ConfigurationType.TEXT) &&
                                    <Select.Option value={ConfigurationType.TEXT}>Text</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.NUMBER) &&
                                    <Select.Option value={ConfigurationType.NUMBER}>Number</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.BOOLEAN) &&
                                    <Select.Option value={ConfigurationType.BOOLEAN}>Boolean</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.DATE) &&
                                    <Select.Option value={ConfigurationType.DATE}>Date</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.TIME) &&
                                    <Select.Option value={ConfigurationType.TIME}>Time</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.TIME_ZONE) &&
                                    <Select.Option value={ConfigurationType.TIME_ZONE}>Time Zone</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.OPTION) &&
                                    <Select.Option value={ConfigurationType.OPTION}>Option</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.MULTI_OPTION) &&
                                    <Select.Option value={ConfigurationType.MULTI_OPTION}>Multi Option</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.CREDENTIALS) &&
                                    <Select.Option value={ConfigurationType.CREDENTIALS}>Credentials</Select.Option>
                                }
                            </Select>
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Name"
                            info={`The friendly name for the variable used for identification.`}
                            name={`spec-name-${this.props.index}`}
                            rules={[{
                                required: true,
                                message: "Select a variable name."
                            }]}
                        >
                            <Input
                                placeholder="Variable name."
                                value={this.state.spec.name}
                                onChange={this.handleNameChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Description"
                            info="The description of the variable."
                            name={`spec-description-${this.props.index}`}
                        >
                            <Input.TextArea
                                placeholder="Variable description."
                                rows={3}
                                value={this.state.spec.description}
                                onChange={this.handleDescriptionChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Required"
                            name="required"
                            info="Tells if the variable requires a value."
                            valuePropName="checked"
                        >
                            <Switch
                                checked={this.state.spec.requirementType === RequirementType.REQUIRED}
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onChange={this.handleRequiredChange}
                            />
                        </FormItem>
                        {this.state.spec.type === ConfigurationType.CREDENTIALS &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="Credentials Type"
                                info="The credentials type for which to select a field for the variable."
                                name={`spec-credentials-${this.props.index}`}
                                rules={[{
                                    required: true,
                                    message: 'Select a credentials type.'
                                }]}
                            >
                                <Select
                                    placeholder="Credentials type."
                                    value={(this.state.spec as CredentialsConfigurationSpec).credentialsType}
                                    onChange={this.handleCredentialsChange}
                                >
                                    {Object.entries(CREDENTIALS_MAP).map(([key, names]) => (
                                        <Select.Option key={key.toLowerCase()} value={key.toUpperCase()}>{key}</Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        }
                    </Col>
                    <Col span={12}>
                    {!this.state.useRef && this.state.spec.type === ConfigurationType.OPTION &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name={`option-type-${this.props.index}`}
                                label="Option Type"
                                info="The type of the option values."
                                rules={[{
                                    required: true,
                                    message: "Select an option type."
                                }]}
                            >
                                <Select
                                    placeholder="Option type."
                                    value={this.state.spec.ioType}
                                    onChange={this.handleOptionTypeChange}>
                                    <Select.Option value={IoType.TEXT}>Text</Select.Option>
                                    <Select.Option value={IoType.NUMBER}>Number</Select.Option>
                                </Select>
                            </FormItem>
                        }
                        {!this.state.useRef && this.state.spec.type === ConfigurationType.MULTI_OPTION &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name={`option-type-${this.props.index}`}
                                label="Option Type"
                                info="The type of the option values."
                                rules={[{
                                    required: true,
                                    message: "Select an option type."
                                }]}
                            >
                                <Select
                                    placeholder="Option type."
                                    value={this.state.spec.ioType}
                                    onChange={this.handleOptionTypeChange}>
                                    <Select.Option value={IoType.TEXT_ARRAY}>Text</Select.Option>
                                    <Select.Option value={IoType.NUMBER_ARRAY}>Number</Select.Option>
                                </Select>
                            </FormItem>
                        }

                        {!this.state.useRef && (this.state.spec.type === ConfigurationType.OPTION || this.state.spec.type === ConfigurationType.MULTI_OPTION) &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                //noLabel={true}
                                label="Options"
                                info="The options for the value."
                                name={`spec-options-${this.props.index}`}
                                valuePropName="options"
                                rules={[{
                                    required: true,
                                    message: ''
                                }, {
                                    validator: (rule: any, options: Option[]) => {
                                        for (let i = 0; i < options.length; i++) {
                                            const option = options[i];
                                            if (!option.value) {
                                                return Promise.reject('Please enter all option fields.');
                                            }
                                        }
                                        return Promise.resolve();
                                    }
                                }]}
                            >
                                <Options
                                    options={(this.state.spec as OptionConfigurationSpec).options}
                                    onChange={this.handleOptionsChange}
                                />
                            </FormItem>
                        }
                        {this.state.useRef && this.props.specType === SpecType.REFERENCE &&
                            <>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.formRef}
                                    label="Dataset"
                                    name="dataset"
                                    info="The dataset to use as a template."
                                    valuePropName="queryId"
                                    justification="center"
                                    rules={[{
                                        required: true,
                                        message: `Please select a dataset.`
                                    }]}
                                >
                                    <QueryPicker
                                        queryId={this.state.spec.queryId}
                                        onChange={this.handleQueryChange}
                                    />
                                </FormItem>
                                {this.state.spec.queryId &&
                                    <QueryVersionPicker
                                        formRef={this.formRef}
                                        queryId={this.state.spec.queryId}
                                        version={this.state.spec.version}
                                        onChange={this.handleVersionChange}
                                    />
                                }
                                <QueryLoader
                                    formRef={this.formRef}
                                    visible={!!this.state.query}
                                    queryId={this.state.spec.queryId}
                                    queryVersion={this.state.spec.version}
                                    onLoaded={this.handleQueryLoaded}
                                />
                                {this.state.query &&
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.formRef}
                                        label="Field"
                                        name="field"
                                        info="The dataset's field to use as the template for its type and values."
                                        rules={[{
                                            required: true,
                                            message: `Please select a field.`
                                        }]}
                                    >
                                        <Select
                                            placeholder="Reference field."
                                            value={this.state.spec.specKey}
                                            onChange={this.handleSpecKeyChange}
                                        >
                                            {this.state.query.configurationSpecs
                                                .filter(spec => !this.props.excludes.includes(spec.type))
                                                .map(spec => (
                                                    <Select.Option key={spec.key} value={spec.key}>{spec.name}</Select.Option>
                                                ))}
                                        </Select>
                                    </FormItem>
                                }
                            </>
                        }
                    </Col>
                </Row>
            </Form >
        );
    }
}
