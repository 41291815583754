import { PureComponent, ReactElement } from 'react';
import { FormInstance, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import { FormItem } from 'components/FormItem/FormItem';
import axios from 'axios';
import classNames from 'classnames';
import endpointService from 'services/EndpointService';
import './QueryVersionPicker.less';
import { CoreUtils } from 'utils/CoreUtils';
//import { CoreUtils } from '@methodset/calculator-ts';

export type ChangeCallback = (version: number) => void;

export type QueryVersionPickerState = {
    status: string,
    versions: number[]
}

export type QueryVersionPickerProps = {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    queryId?: string,
    version?: number,
    onChange: ChangeCallback
}

export class QueryVersionPicker extends PureComponent<QueryVersionPickerProps, QueryVersionPickerState> {

    constructor(props: QueryVersionPickerProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            versions: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleVersionChange(version: number): void {
        this.props.onChange(version);
    }

    private readQueryVersionsRequest(): Promise<any> {
        if (!this.props.queryId) {
            return Promise.resolve(true);
        }
        const request = {
            queryId: this.props.queryId
        };
        return endpointService.readQueryVersions(request,
            (response: any) => this.readQueryVersionsResponse(response),
            undefined, true
        );
    }

    private readQueryVersionsResponse(response: any): void {
        let versions = response.data.versions;
        if (!versions || versions.length === 0) {
            versions = [0];
        }
        if (!CoreUtils.isEmpty(this.props.version) && !versions.includes(this.props.version)) {
            // Sanity check, unset version if not in list of available versions.
            this.props.onChange(undefined as any);
        }
        this.setState({ versions: versions });

    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readQueryVersionsRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={this.props.formRef}
            >
                <LoadSpinner
                    className="x-queryversionpicker-loading"
                    status={isLoading ? "loading" : "failed"}
                    failedMessage="Failed to load versions."
                    onRetry={this.handleRetryLoad}
                />
            </FormItem>
        );
    }

    private buildModelView(): ReactElement {
        return (
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={this.props.formRef}
                //labelLocation="top"
                label="Version"
                name="version"
                info="The version to use."
                rules={[{
                    required: true,
                    message: 'Please select a version.'
                }]}
            >
                <Select
                    placeholder="Select a version."
                    value={this.props.version}
                    onChange={this.handleVersionChange}
                >
                    {this.state.versions.map(version => (
                        <Select.Option key={version} value={version}>{CoreUtils.toVersion(version)}</Select.Option>
                    ))}
                </Select>
            </FormItem>
        );
    }

    public componentDidMount(): void {
        if (this.props.queryId) {
            this.loadData();
        } else {
            this.setState({ status: Globals.STATUS_READY });
        }
    }

    public componentDidUpdate(prevProps: QueryVersionPickerProps): void {
        if (prevProps.queryId !== this.props.queryId) {
            //this.setState({ status: Globals.STATUS_INIT });
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildModelView();
        }
        return (
            <div className={classNames('x-queryversionpicker', this.props.className)}>
                {view}
            </div>
        );
    }

}
