import { ReactElement, Ref, useContext, useRef, useState } from 'react';
import { Button, Form, FormInstance, Input, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ItemEditor } from 'containers/Console/ItemEditor/ItemEditor';
import { RestUtils } from 'utils/RestUtils';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import entityService from 'services/EntityService';
import './AddMember.less';
import { FormFrame } from 'components/FormFrame/FormFrame';

type MatchParams = {
    groupId: string
}

export type AddMemberProps = RouteComponentProps<MatchParams>;

export const AddMember = (props: AddMemberProps): ReactElement => {

    const context = useContext(EntityContext);

    const [emailAddress, setEmailAddress] = useState<string | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formRef: Ref<FormInstance> = useRef(null);

    const handleSaveClick = (): void => {
        addGroupMemberRequest();
    }

    const handleCancelClick = (): void => {
        props.history.goBack();
    }

    const handleEmailChange = (e: any): void => {
        const emailAddress = e.target.value;
        setEmailAddress(emailAddress);
    }

    const addGroupMemberRequest = (): Promise<any> => {
        setErrorMessage(undefined);
        setIsLoading(true);
        const request = {
            groupId: props.match.params.groupId,
            emailAddress: emailAddress
        }
        return entityService.addGroupMember(request,
            (response: any) => addGroupMemberResponse(response),
            (error: any) => addGroupMemberException(error),
            true
        );
    }

    const addGroupMemberResponse = (response: any): void => {
        setErrorMessage(undefined);
        setIsLoading(false);
        props.history.goBack();
    }

    const addGroupMemberException = (error: any): void => {
        setErrorMessage(RestUtils.getError(error));
        setIsLoading(false);
    }

    return (
        <div className="x-addmember">
            <FormFrame
                ref={formRef}
                className="x-addmember-body"
                title="Add Member"
                description="Enter the email address of the user you would like to add to your group."
                error={errorMessage}
                isLoading={isLoading}
                okLabel="Add"
                onCancel={handleCancelClick}
                onOk={handleSaveClick}
            >
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Email"
                    name="email"
                    rules={[{
                        required: true,
                        message: 'Please enter an email address.'
                    }, {
                        type: 'email',
                        message: 'The email address is invalid.'
                    }]}
                >
                    <Input
                        id="email"
                        placeholder="Email address."
                        value={emailAddress}
                        onChange={handleEmailChange}
                    />
                </FormItem>
            </FormFrame>
        </div>
    );

}
