import { ReactElement, useEffect, useRef } from 'react';
import { Formatter } from '@methodset/calculator-ts';
import { Constants } from 'components/Constants';
import { DataPoint, DataSeries } from '../ChartWidgetViewer';
import { Globals } from 'constants/Globals';
import { CartesianWidgetConfiguration } from '@methodset/model-client-ts';
import { Line } from '@ant-design/charts';
import { CoreUtils } from 'utils/CoreUtils';
import { ChartUtils } from 'utils/ChartUtils';
import classNames from 'classnames';
import './LineChartView.less';

const MAX_POINTS = 150;
const LARGE_CHART = 0;

export type LineChartProps = {
    className?: string,
    series: DataSeries,
    configuration: CartesianWidgetConfiguration,
    height?: number | string,
    width?: number | string
};

export const LineChartView = (props: LineChartProps): ReactElement => {

    const animation = useRef({});

    // Turn off animation for data refreshes.
    useEffect(() => { animation.current = false }, []);

    /**
     * Prune data for large sets.
     * 
     * @param dataPoints The data points in the chart.
     * @returns The pruned data points.
     */
    const adjustData = (dataPoints: DataPoint[]): DataPoint[] => {
        const length = dataPoints.length;
        if (length > MAX_POINTS) {
            const div = Math.floor(length / MAX_POINTS) + 1;
            if (div > 1) {
                const points: DataPoint[] = [];
                let j = 0;
                for (let i = 0; i < length; i += div) {
                    points[j++] = dataPoints[i];
                }
                return points;
            }
        }
        return dataPoints;
    }

    /**
     * Tells if the chart has enough data points to be considered large.
     * 
     * @param dataPoints The data points in the chart.
     * @returns True if the chart is large, false otherwise.
     */
    const isLargeChart = (dataPoints: DataPoint[]): boolean => {
        return dataPoints.length > LARGE_CHART;
    }

    /**
     * Calculates the points to place at values on the chart.
     * 
     * @param dataPoints The data points in the chart.
     * @returns A point CSS style.
     */
    const calcPoint = (dataPoints: DataPoint[]): any => {
        const index = ChartUtils.colorIndex(dataPoints)
        return {
            size: 3,
            shape: "circle",
            style: (dataPoint: DataPoint) => {
                return {
                    fill: "white",
                    stroke: Constants.CHART_COLOR(index[dataPoint.series]),
                    lineWidth: 1,
                    fillOpacity: 1
                }
            }
        };
    }

    // If there are too many data points, eliminate the dots to avoid clutter.
    const data = adjustData(props.series.points);
    const smooth = isLargeChart(data) ? false : true;
    const point = isLargeChart(data) ? undefined : calcPoint(data);
    const [min, max, precision] = ChartUtils.calcLimits(data);
    const [xOffset, yOffset] = ChartUtils.calcOffsets(props.series.yFormat, max);

    return (
        <div className={classNames('x-linechartview', props.className)} style={{ width: props.width, height: props.height }}>
            <Line
                animation={animation.current}
                data={data}
                xField="x"
                yField="y"
                seriesField="series"
                autoFit={true}
                smooth={smooth}
                point={point}
                color={Constants.CHART_COLORS}
                legend={{
                    layout: "horizontal",
                    position: "top"
                }}
                xAxis={{
                    title: {
                        text: props.configuration.xAxis.label,
                        offset: !!props.configuration.xAxis.label ? xOffset : undefined,
                        style: {
                            fontSize: Globals.CHART_LABEL_SIZE
                        }
                    },
                    label: {
                        formatter: (text: string, item: any, index: number) => {
                            if (props.series.xFormat) {
                                return Formatter.format(text, props.series.xFormat);
                            } else {
                                return text;
                                //return value.toFixed(precision);
                            }
                        }
                    }
                }}
                yAxis={{
                    title: {
                        text: props.configuration.yAxis.label,
                        offset: !!props.configuration.yAxis.label ? yOffset : undefined,
                        style: {
                            fontSize: Globals.CHART_LABEL_SIZE
                        }
                    },
                    min: CoreUtils.isNumber(min) ? min : undefined,
                    max: CoreUtils.isNumber(max) ? max : undefined,
                    label: {
                        formatter: (text: string, item: any, index: number) => {
                            if (props.series.yFormat) {
                                return Formatter.format(text, props.series.yFormat);
                            } else {
                                return text;
                            }
                        }
                    }
                }}
            />
        </div>
    )
}
