import { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Globals } from 'constants/Globals';
import { Applet, Board } from '@methodset/model-client-ts';
import { RestUtils } from 'utils/RestUtils';
import { Configuration, VariableSpec } from '@methodset/endpoint-client-ts';
import { AppletViewer } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/AppletViewer';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { Calculator } from '@methodset/calculator-ts';
import axios from 'axios';
import modelService from 'services/ModelService';
import './BoardViewer.less';
import { CoreUtils } from 'utils/CoreUtils';

interface BoardData {
    board: Board,
    applet: Applet,
    calculator: Calculator,
    variableSpecs: VariableSpec[]
}

type MatchParams = {
    boardId: string
}

export type BoardViewerProps = RouteComponentProps<MatchParams>;

export const BoardViewer = (props: BoardViewerProps): ReactElement => {

    const [status, setStatus] = useState<string>(Globals.STATUS_INIT);
    const [boardData, setBoardData] = useState<BoardData | undefined>();

    useEffect(() => {
        loadData();
    }, [props.match.params.boardId]);

    const loadData = (): void => {
        const requests = [];
        requests.push(loadBoardRequest());
        setStatus(Globals.STATUS_LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(Globals.STATUS_READY);
            } else {
                setStatus(Globals.STATUS_FAILED);
            }
        }));
    }

    const handleRetryLoad = (): void => {
        loadData();
    }

    const loadBoardRequest = (): Promise<any> => {
        const boardId = props.match.params.boardId;
        const request = {
            boardId: boardId
        };
        return modelService.loadBoard(request,
            (response: any) => loadBoardResponse(response),
            undefined, true
        );
    }

    const loadBoardResponse = (response: any): void => {
        const data = response.data;
        const calculator = Calculator.deserialize(data.calculator);
        const board = data.board;
        const applet = data.applet;
        const variableSpecs = data.variableSpecs;
        const boardData = {
            board: board,
            applet: applet,
            calculator: calculator,
            variableSpecs: variableSpecs
        }
        calculator.httpHeaders = RestUtils.getHttpHeaders();
        calculator.context.requestKey = 0;
        setBoardData(boardData);
        executeCalculator(calculator, data.board.configuration);
    }

    const executeCalculator = (calculator: Calculator, configuration: Configuration | undefined): void => {
        // Suspend calculator updates while setting parameters.
        calculator.suspend();
        if (configuration) {
            overrideParameters(configuration, calculator);
        }
        // Unsuspend and execute with the new parameter values.
        calculator.execute();
    }

    const overrideParameters = (configuration: Configuration, calculator: Calculator): void => {
        const parameters = calculator.parameters;
        for (const [key, data] of Object.entries(configuration)) {
            if (!CoreUtils.isEmpty(data.value) || data.formula) {
                const parameter = parameters.get(key, false);
                if (parameter) {
                    if (data.formula) {
                        parameter.formula = data.formula;
                    } else {
                        parameter.value = data.value;
                    }
                }
            }
        }
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSpinner
                className="x-boardviewer-loading"
                status={isLoading ? "loading" : "failed"}
                onRetry={handleRetryLoad}
            />
        );
    }

    const buildBoardView = (): ReactElement => {
        const data = boardData!
        return (
            <AppletViewer
                key={data.applet.id}
                applet={data.applet}
                calculator={data.calculator}
                modelId={data.board.modelId}
                version={data.board.version}
                appletConfiguration={data.board.configuration}
                variableSpecs={data.variableSpecs}
            />
        )
    }

    let view;
    if (status === Globals.STATUS_LOADING) {
        view = buildLoadingView(true);
    } else if (status === Globals.STATUS_FAILED) {
        view = buildLoadingView(false);
    } else if (status === Globals.STATUS_READY) {
        view = buildBoardView();
    }
    return (
        <div className="x-boardviewer">
            {view}
        </div>
    )

}

