import React, { Component, ReactElement } from 'react';
import { FormInstance } from 'antd';
import { Globals } from 'constants/Globals';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import { FormItem } from 'components/FormItem/FormItem';
import { Justify } from 'components/Justify/Justify';
import { Query } from '@methodset/endpoint-client-ts';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import './QueryLoader.less';

export type LoadedCallback = (query: Query) => void;

export type QueryLoaderProps = {
    // Reference to form object.
    formRef: React.RefObject<FormInstance>,
    visible: boolean,
    queryId: string | undefined,
    queryVersion: number | undefined
    onLoaded: LoadedCallback
}

export type QueryLoaderState = {
    // Status of loading data.
    status: string,
    // True if loading data.
    loading: boolean
}

export class QueryLoader extends Component<QueryLoaderProps, QueryLoaderState> {

    constructor(props: QueryLoaderProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            loading: false,
        }
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData(this.props.queryId, this.props.queryVersion);
    }

    private readQueryRequest(queryId: string, queryVersion: number | undefined): Promise<any> {
        if (!queryId) {
            return Promise.resolve();
        }
        const request = {
            queryId: queryId,
            version: queryVersion
        };
        return endpointService.readQuery(request,
            (response: any) => this.readQueryResponse(response),
            undefined, true
        );
    }

    private readQueryResponse(response: any): void {
        const query = response.data.query;
        this.props.onLoaded(query);
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={this.props.formRef}
            >
                <LoadSpinner
                    className="x-queryselector-loading"
                    status={isLoading ? "loading" : "failed"}
                    failedMessage="Failed to load configuration."
                    onRetry={this.handleRetryLoad}
                />
            </FormItem>
        );
    }

    private loadData(queryId: string | undefined, queryVersion: number | undefined) {
        if (!queryId) {
            return;
        }
        const requests = [];
        requests.push(this.readQueryRequest(queryId, queryVersion));
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            // If response is ok, the parent will be notified and the
            // parent will dismount this component. Do not update by 
            // setting state since an unmounted component would be updated, 
            // causing a React warning.
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount() {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData(this.props.queryId, this.props.queryVersion);
        }
    }

    public shouldComponentUpdate(nextProps: QueryLoaderProps): boolean {
        if (nextProps.queryId !== this.props.queryId || nextProps.queryVersion !== this.props.queryVersion) {
            this.loadData(nextProps.queryId, nextProps.queryVersion);
            return false;
        } else {
            return true;
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = null;
        } else if (!this.props.visible) {
            return <></>;
        }
        return (
            <Justify justification="center">
                {view}
            </Justify>
        );
    }

}
