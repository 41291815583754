import { ReactElement, useEffect, useRef } from 'react';
import { Formatter } from '@methodset/calculator-ts';
import { Constants } from 'components/Constants';
import { DataSeries } from '../ChartWidgetViewer';
import { Globals } from 'constants/Globals';
import { CartesianWidgetConfiguration } from '@methodset/model-client-ts';
import { Area } from '@ant-design/charts';
import { CoreUtils } from 'utils/CoreUtils';
import { ChartUtils } from 'utils/ChartUtils';
import classNames from 'classnames';
import './AreaChartView.less';

export type AreaChartProps = {
    className?: string,
    series: DataSeries,
    configuration: CartesianWidgetConfiguration,
    height?: number | string,
    width?: number | string
};

export const AreaChartView = (props: AreaChartProps): ReactElement => {

    const animation = useRef({});

    // Turn off animation for data refreshes.
    useEffect(() => { animation.current = false }, []);

    const [min, max, precision] = ChartUtils.calcLimits(props.series.points, true);
    const [xOffset, yOffset] = ChartUtils.calcOffsets(props.series.yFormat, max);

    return (
        <div className={classNames('x-areachartview', props.className)} style={{ width: props.width, height: props.height }}>
            <Area
                animation={animation.current}
                data={props.series.points}
                xField="x"
                yField="y"
                seriesField="series"
                autoFit={true}
                color={Constants.CHART_COLORS}
                legend={{
                    layout: "horizontal",
                    position: "top"
                }}
                xAxis={{
                    title: {
                        text: props.configuration.xAxis.label,
                        offset: !!props.configuration.xAxis.label ? xOffset : undefined,
                        style: {
                            fontSize: Globals.CHART_LABEL_SIZE
                        }
                    },
                    label: {
                        formatter: (text: string, item: any, index: number) => {
                            if (props.series.xFormat) {
                                return Formatter.format(text, props.series.xFormat);
                            } else {
                                return text;
                                //return value.toFixed(precision);
                            }
                        }
                    }
                }}
                yAxis={{
                    title: {
                        text: props.configuration.yAxis.label,
                        offset: !!props.configuration.yAxis.label ? yOffset : undefined,
                        style: {
                            fontSize: Globals.CHART_LABEL_SIZE
                        }
                    },
                    min: CoreUtils.isNumber(min) ? min : undefined,
                    max: CoreUtils.isNumber(max) ? max : undefined,
                    label: {
                        formatter: (text: string, item: any, index: number) => {
                            if (props.series.yFormat) {
                                return Formatter.format(text, props.series.yFormat);
                            } else {
                                return text;
                            }
                        }
                    }
                }}
            />
        </div>
    )
}
