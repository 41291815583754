import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Input, Select } from 'antd';
import { Address, AddressType, StateType } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { CoreUtils } from 'utils/CoreUtils';
import { Spacer } from 'components/Spacer/Spacer';
import update from 'immutability-helper';
import './AddressEditor.less';

export type ChangeCallback = (address: Address) => void;

export type AddressEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    address?: Address,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    address: {}
}

export const AddressEditor = (props: AddressEditorProps): ReactElement => {

    const handleStreet1Change = (street1: string): void => {
        const address = update(props.address, {
            street1: { $set: street1 }
        });
        props.onChange(address);
    }

    const handleStreet2Change = (street2: string): void => {
        const address = update(props.address, {
            street2: { $set: street2 }
        });
        props.onChange(address);
    }

    const handleCityChange = (city: string): void => {
        const address = update(props.address, {
            city: { $set: city }
        });
        props.onChange(address);
    }

    const handleStateChange = (state: StateType): void => {
        const address = update(props.address, {
            state: { $set: state }
        });
        props.onChange(address);
    }

    const handleZipCodeChange = (zipCode: string): void => {
        const address = update(props.address, {
            zipCode: { $set: zipCode }
        });
        props.onChange(address);
    }

    const handleTypeChange = (type: AddressType): void => {
        const address = update(props.address, {
            type: { $set: type }
        });
        props.onChange(address);
    }

    return (
        <>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Street 1"
                name={`${props.id}-street1`}
                rules={[{
                    required: true,
                    message: 'Please enter a street.'
                }]}
            >
                <Input
                    placeholder="Street 1."
                    value={props.address.street1}
                    onChange={(e) => handleStreet1Change(e.target.value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Street 2"
                name={`${props.id}-street2`}
            >
                <Input
                    placeholder="Street 2."
                    value={props.address.street2}
                    onChange={(e) => handleStreet2Change(e.target.value)}
                />
            </FormItem>
            <Spacer fill>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    className="x-addresseditor-city"
                    formRef={props.formRef}
                    label="City"
                    name={`${props.id}-city`}
                    rules={[{
                        required: true,
                        message: 'Please enter a city.'
                    }]}
                >
                    <Input
                        placeholder="City."
                        value={props.address.city}
                        onChange={(e) => handleCityChange(e.target.value)}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    className="x-addresseditor-state"
                    formRef={props.formRef}
                    label="State"
                    name={`${props.id}-state`}
                    rules={[{
                        required: true,
                        message: 'Please select a state.'
                    }]}
                >
                    <Select
                        allowClear={true}
                        value={props.address.state}
                        onChange={(state) => handleStateChange(state)}
                    >
                        {CoreUtils.enumToKeys(StateType).map(key => (
                            <Select.Option key={key} value={key}>{key}</Select.Option>
                        ))}
                    </Select>
                </FormItem>
            </Spacer>
            <Spacer fill>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    className="x-addresseditor-zipcode"
                    formRef={props.formRef}
                    label="Zip Code"
                    name={`${props.id}-zipcode`}
                    rules={[{
                        required: true,
                        message: 'Please enter a zip code.'
                    }]}
                >
                    <Input
                        placeholder="Zip code."
                        value={props.address.zipCode}
                        onChange={(e) => handleZipCodeChange(e.target.value)}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    className="x-addresseditor-type"
                    formRef={props.formRef}
                    label="Type"
                    name={`${props.id}-type`}
                >
                    <Select
                        allowClear={true}
                        value={props.address.type}
                        onChange={(type) => handleTypeChange(type)}
                    >
                        {CoreUtils.enumToKeys(AddressType).map(key => (
                            <Select.Option key={key} value={key}>{CoreUtils.toProper(key)}</Select.Option>
                        ))}
                    </Select>
                </FormItem>
            </Spacer>
        </>
    )
}

AddressEditor.defaultProps = defaultProps;
