import { Calculator } from '@methodset/calculator-ts';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { Applet, Model, ModelSetup, SwitchOption, SwitchWidgetConfiguration } from '@methodset/model-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { AppletViewer } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/AppletViewer';
import { Globals } from 'constants/Globals';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { RestUtils } from 'utils/RestUtils';
import axios from 'axios';
import modelService from 'services/ModelService';
import './SwitchWidgetViewer.less';
import { Configuration, VariableSpec } from '@methodset/endpoint-client-ts';

export type SwitchWidgetViewerProps = {
    calculator: Calculator,
    configuration: SwitchWidgetConfiguration,
    modelId: string,
    version?: number
    // model: Model,
    // modelSetup?: ModelSetup
    appletConfiguration?: Configuration,
    variableSpecs?: VariableSpec[]
};

export const SwitchWidgetViewer = (props: SwitchWidgetViewerProps): ReactElement => {

    const [status, setStatus] = useState<string>(Globals.STATUS_INIT);
    const [applets, setApplets] = useState<Applet[]>([]);

    useEffect(() => {
        if (status !== Globals.STATUS_READY) {
            loadData();
        }
    }, []);

    const loadData = (): void => {
        const requests = [];
        requests.push(readAppletsRequest());
        setStatus(Globals.STATUS_LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(Globals.STATUS_READY);
            } else {
                setStatus(Globals.STATUS_FAILED);
            }
        }));
    }

    const readAppletsRequest = (): Promise<any> => {
        // Figure out which applets are not yet loaded in the model.
        // Fetch them so all switch applets are available.
        const options = props.configuration.options;
        //  const loaded = props.model.applets.map(applet => applet.id);
        // const wanted = options.map(option => option.appletId);
        // const appletIds = wanted.filter(id => !loaded.includes(id));
        const appletIds = options.map(option => option.appletId);
        const request = {
            modelId: props.modelId,
            modelVersion: props.version,
            // modelId: props.model.id,
            // modelVersion: props.model.version,
            appletIds: appletIds
        };
        return modelService.readApplets(request,
            (response: any) => readAppletsResponse(response),
            (response: any) => readAppletsException(response),
            true
        );
    }

    const readAppletsResponse = (response: any): void => {
        const applets = response.data.applets;
        setApplets(applets);
    }

    const readAppletsException = (response: any): void => {
        return;
    }

    const parameterValue = (): any => {
        const parameters = props.calculator.parameters;
        const parameter = parameters.get(props.configuration.variable, false);
        if (!parameter) {
            return undefined;
        }
        const value = parameter.value;
        return !CoreUtils.isEmpty(value) ? value.toString() : undefined;
    }

    const activeOption = (): SwitchOption | undefined => {
        let value = parameterValue();
        if (CoreUtils.isEmpty(value)) {
            return undefined;
        }
        return props.configuration.options.find(option => option.value === value);
    }

    const findApplet = (appletId: string | undefined): Applet | undefined => {
        if (!appletId) {
            return undefined;
        }
        return applets.find(applet => applet.id === appletId);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                className="x-switchwidgetviewer-skeleton"
                count={4}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load applet."
                onRetry={loadData}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    const buildAppletsView = (): ReactElement => {
        const option = activeOption();
        const applet = findApplet(option?.appletId);
        if (option && applet) {
            return (
                <AppletViewer
                    key={option.appletId}
                    //model={props.model}
                    applet={applet}
                    calculator={props.calculator}
                    modelId={props.modelId}
                    version={props.version}
                    appletConfiguration={props.appletConfiguration}
                    variableSpecs={props.variableSpecs}
                    //modelSetup={props.modelSetup}
                    isEmbedded={true}
                />
            )
        } else {
            const value = parameterValue();
            if (CoreUtils.isEmpty(value)) {
                return (
                    <div>{`ERROR: Applet variable '${props.configuration.variable}' does not exist.`}</div>
                )
            } else {
                return (
                    <div>{`ERROR: Applet mapping does not exist for variable value '${value}'.`}</div>
                )
            }
        }
    }

    const buildView = (): ReactElement => {
        if (status === Globals.STATUS_LOADING) {
            return buildLoadingView(true);
        } else if (status === Globals.STATUS_FAILED) {
            return buildLoadingView(false);
        } else if (status === Globals.STATUS_READY) {
            return buildAppletsView();
        } else {
            // STATUS_INIT
            return <></>;
        }
    }

    return buildView();

}
