import React, { PureComponent } from 'react';
import { Form, FormInstance } from 'antd';
import { Input } from 'antd';
import { Modal } from 'antd';
import { Switch } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ModelContext } from 'context/ModelContext';
import { CoreUtils } from 'utils/CoreUtils';
import { Model } from '@methodset/model-client-ts';
import classNames from 'classnames';
import update from 'immutability-helper';
import './ModelProperties.less';

export type ChangeCallback = (model: Model) => void;
export type CancelCallback = () => void;

export type ModelPropertiesProps = {
    className?: string,
    visible: boolean,
    model: Model,
    onChange: ChangeCallback,
    onCancel: CancelCallback
};

export type ModelPropertiesState = {
    model: Model,
    keywords?: string
};

export class ModelProperties extends PureComponent<ModelPropertiesProps, ModelPropertiesState> {

    static contextType = ModelContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: ModelPropertiesProps) {
        super(props);
        this.state = {
            model: CoreUtils.clone(props.model),
            keywords: CoreUtils.listToString(this.props.model.keywords)
        };
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
        this.handleAutoSaveChange = this.handleAutoSaveChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleNameChange(e: any): void {
        const name = e.target.value;
        const model = update(this.state.model, {
            name: { $set: name }
        });
        this.setState({ model: model });
    }

    private handleDescriptionChange(e: any): void {
        const description = e.target.value;
        const model = update(this.state.model, {
            description: { $set: description }
        });
        this.setState({ model: model });
    }

    private handleKeywordsChange(e: any): void {
        const keywords = e.target.value;
        this.setState({ keywords: keywords });
    }

    private handleAutoSaveChange(checked: boolean): void {
        const model = update(this.state.model, {
            autoSave: { $set: checked }
        });
        this.setState({ model: model });
    }

    private handleOk() {
        this.formRef.current?.submit();
    }

    private handleCancel(): void {
        this.props.onCancel();
    }

    private handleFormFinish(): void {
        // Convert keywords string to list.
        const model = update(this.state.model, {
            keywords: { $set: CoreUtils.stringToList(this.state.keywords) }
        });
        this.props.onChange(model);
    }

    render() {
        return (
            <Modal
                className={classNames('x-modelproperties', this.props.className)}
                width={Globals.DIALOG_WIDTH}
                title="Properties Editor"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                visible={this.props.visible}
            >
                <Form
                    ref={this.formRef}
                    onFinish={this.handleFormFinish}
                >
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Name"
                        name="name"
                        info="The name of the model."
                        rules={[{
                            required: true,
                            message: 'Please enter a name.'
                        }]}
                    >
                        <Input
                            placeholder="Model name."
                            value={this.state.model.name}
                            onChange={this.handleNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Description"
                        name="description"
                        info="The description of the model."
                    >
                        <Input.TextArea
                            placeholder="Model description."
                            rows={5}
                            value={this.state.model.description}
                            onChange={this.handleDescriptionChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Keywords"
                        name="keywords"
                        info="Keywords used to describe the model."
                    >
                        <Input
                            placeholder="Keywords."
                            value={this.state.keywords}
                            onChange={this.handleKeywordsChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Automatic Save"
                        name="auto-save"
                        info="Periodically save the model."
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            checked={this.state.model.autoSave}
                            onChange={this.handleAutoSaveChange}
                        />
                    </FormItem>
                </Form>
            </Modal>
        );
    }

}
