import { ReferenceParser } from "@methodset/calculator-ts";
import { SelectWidgetConfiguration, Widget } from '@methodset/model-client-ts';

export class SelectWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as SelectWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.label));
        refs.push(...ReferenceParser.extract(configuration.variable, true));
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as SelectWidgetConfiguration;
        configuration.label = ReferenceParser.update(configuration.label, target, replacement);
        configuration.variable = ReferenceParser.update(configuration.label, target, replacement, true);
    }

}
