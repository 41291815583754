import { ReactElement, useEffect } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Calculator, ErrorUtils, FormulaError } from '@methodset/calculator-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { DisplayType, ListWidgetConfiguration } from '@methodset/model-client-ts';
import { ValueDisplay } from 'components/ValueDisplay/ValueDisplay';
import { AlignType } from 'rc-table/lib/interface';
import { WidgetUtils } from 'utils/WidgetUtils';
import classNames from 'classnames';
import './ListWidgetViewer.less';

export type ListWidgetViewerProps = {
    calculator: Calculator,
    configuration: ListWidgetConfiguration
}

export const ListWidgetViewer = (props: ListWidgetViewerProps): ReactElement => {

    useEffect(() => {
        const values = getLinkValues();
        if (values && values.length > 0) {
            handleLinkClicked(values[0][0]);
        }
    }, []);

    const handleLinkClicked = (value: any): void => {
        const variable = props.configuration.rowLink?.variable;
        if (!CoreUtils.isEmpty(variable)) {
            const parameters = props.calculator.parameters;
            const parameter = parameters.get(variable!, false);
            if (!parameter) {
                // TODO: error
                return;
            }
            parameter.value = value;
        }
    }

    const buildColumns = (values: any[][]): ColumnsType<any> => {
        const columnSets = props.configuration.columnSets;
        const columns: ColumnsType<any> = [];
        if (columnSets.length === 0) {
            return columns;
        }
        for (let i = 0; i < columnSets.length; i++) {
            const columnSet = columnSets[i];
            const columnDef = columnSet.columnDefs[0];
            const column = {
                key: i,
                title: columnSet.name,
                dataIndex: i.toString(),
                align: CoreUtils.toLower(columnDef.justification) as AlignType,
                className: "x-listwidgetviewer-cell"
            }
            columns.push(column);
        }
        return columns;
    }

    const buildData = (dataValues: any[][]): any[] | undefined => {
        const columnSets = props.configuration.columnSets;
        const matrix = [];
        for (let r = 0; r < dataValues.length; r++) {
            const row = dataValues[r];
            const data = { key: `row-${r}`, row: r } as any;
            let c = 0;
            for (const columnSet of columnSets) {
                const item = (
                    <div>
                        {columnSet.columnDefs.map(def => {
                            let value = row[def.index - 1];
                            if (!CoreUtils.isEmpty(value)) {
                                if (ErrorUtils.isError(value)) {
                                    value = value.type;
                                } else {
                                    value = value.toString();
                                }
                                return (
                                    <div
                                        key={def.index}
                                        className={
                                            classNames(
                                                "x-listwidgetviewer-item",
                                                { [`x-widgetviewer-size-${CoreUtils.toLower(def.size)}`]: !!def.size },
                                                { [`x-widgetviewer-color-${CoreUtils.toLower(def.color)}`]: !!def.color },
                                                { [`x-widgetviewer-weight-${CoreUtils.toLower(def.weight)}`]: !!def.weight },
                                                { [`x-widgetviewer-justification-${CoreUtils.toLower(def.justification)}`]: !!def.justification }
                                            )
                                        }>
                                        {(!def.display || def.display === DisplayType.NORMAL) &&
                                            <>{value}</>
                                        }
                                        {def.display && def.display !== DisplayType.NORMAL &&
                                            <ValueDisplay type="sign" value={value} />
                                        }
                                    </div>
                                )
                            }
                        })}
                    </div>
                );
                data[c++] = item;
            }
            matrix.push(data);
        }
        return isEmpty(matrix, dataValues) ? undefined : matrix;
    }

    const isEmpty = (matrix: any[][], values: any[][]): boolean => {
        if (matrix.length === 1) {
            const columnSets = props.configuration.columnSets;
            for (let i = 0; i < columnSets.length; i++) {
                const columnSet = columnSets[i];
                const columnDefs = columnSet.columnDefs;
                for (let j = 0; j < columnDefs.length; j++) {
                    const columnDef = columnDefs[j];
                    const index = columnDef.index;
                    const value = values[0][index - 1];
                    if (!FormulaError.isError(value) && value !== undefined) {
                        // If the root cell has an error and the rest of the matrix 
                        // could not be generated, then there are no table contents.
                        return false;
                    }
                }
            }
            return true;
        } else {
            return false;
        }
    }

    const getLinkValues = (): any[][] | undefined => {
        const rowLink = props.configuration.rowLink;
        if (rowLink) {
            const rangeId = props.configuration.rangeId;
            const range = WidgetUtils.buildVector(props.calculator, rangeId, rowLink.index, true);
            return range ? range.values(false) : undefined;
        } else {
            return undefined;
        }
    }

    const getDataValues = (): any[][] | undefined => {
        const rangeId = props.configuration.rangeId
        const range = WidgetUtils.buildRange(props.calculator, rangeId, true);
        return range ? range.values(true) : undefined;
    }

    const handleRowClick = (linkValues: any[][], index: number): void => {
        if (index < 0 || index >= linkValues.length) {
            return;
        }
        const value = linkValues[index][0];
        handleLinkClicked(value);
    }

    const buildView = (): ReactElement => {
        let dataValues: any[][] | undefined;
        let linkValues: any[][] | undefined;
        try {
            dataValues = getDataValues();
            linkValues = getLinkValues();
        } catch (e) {
            // Invalid data sheet or range - skip.
            return <>Error</>;
        }
        // Add a clickable row if value range is specified.
        const onClickableRow = (record: any, index?: number) => {
            return {
                style: { cursor: "pointer" },
                onClick: () => handleRowClick(linkValues!, index!)
            }
        }
        return (
            <div>
                <Table
                    pagination={false}
                    size="small"
                    columns={buildColumns(dataValues!)}
                    onRow={linkValues ? onClickableRow : undefined}
                    dataSource={buildData(dataValues!)}
                    showHeader={!!props.configuration.hasHeaders}
                />
            </div>
        );
    }

    return (
        <div className="x-listwidgetviewer">
            {buildView()}
        </div>
    );
}
