import { ChangeEvent, FC, ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance, Input, Modal, Select, Switch } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Applet, CategoryType } from '@methodset/model-client-ts';
import { v4 as uuid } from "uuid";
import update from 'immutability-helper';
import './AppletProperties.less';
import { CoreUtils } from 'utils/CoreUtils';

export type AppletPropertiesProps = {
    applet?: Applet,
    onChange: (applet: Applet) => void,
    onCancel: () => void
}

export const AppletProperties: FC<AppletPropertiesProps> = (props: AppletPropertiesProps): ReactElement => {

    // The form reference.
    const formRef: Ref<FormInstance> = useRef(null);
    // The applet being edited.
    const [applet, setApplet] = useState<Applet>(
        props.applet ?
            props.applet : {
                id: uuid(),
                name: undefined as any,
                title: undefined as any,
                span: 6,
                widgets: [],
                published: false
            }
    );

    const handleOk = (): void => {
        formRef.current?.submit();
    }

    const handleCancel = (): void => {
        props.onCancel();
    }

    const handleFormFinish = (): void => {
        if (!applet.title) {
            // Copy the name into the title if it is not set.
            const updated = update(applet, {
                title: { $set: applet.name }
            });
            props.onChange(updated);
        } else {
            props.onChange(applet);
        }
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const name = e.target.value;
        const updated = update(applet, {
            name: { $set: name }
        });
        setApplet(updated);
    }

    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const description = e.target.value;
        const updated = update(applet, {
            description: { $set: description }
        });
        setApplet(updated);
    }

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const title = e.target.value;
        const updated = update(applet, {
            title: { $set: title }
        });
        setApplet(updated);
    }

    const handleSpanChange = (span: number): void => {
        const updated = update(applet, {
            span: { $set: span }
        });
        setApplet(updated);
    }

    const handleCategoryChange = (category: CategoryType): void => {
        const updated = update(applet, {
            category: { $set: category }
        });
        setApplet(updated);
    }

    const handlePublishChange = (published: boolean): void => {
        const updated = update(applet, {
            published: { $set: published }
        });
        setApplet(updated);
    }

    return (
        <Modal
            className="x-appletproperties"
            title="Applet Properties"
            width={Globals.DIALOG_WIDTH_SHORT}
            onOk={handleOk}
            onCancel={handleCancel}
            visible={true}>
            <Form ref={formRef} onFinish={handleFormFinish}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Name"
                    name="name"
                    info="The name of the applet displayed in the inventory."
                    rules={[{
                        required: true,
                        message: 'Please enter a name.'
                    }]}
                >
                    <Input
                        placeholder="Enter a name."
                        value={applet.name}
                        onChange={handleNameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Description"
                    name="description"
                    info="The description of the applet."
                >
                    <Input.TextArea
                        placeholder="Enter a description."
                        rows={3}
                        value={applet.description}
                        onChange={handleDescriptionChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Title"
                    name="title"
                    info="The title displayed on the applet header. If not specified, the applet name will be used."
                >
                    <Input
                        placeholder="Enter a title."
                        value={applet.title}
                        onChange={handleTitleChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Width"
                    name="span"
                    info={`The default number of columns the applet should span (1-${Globals.LAYOUT_COLUMNS}).`}
                    rules={[{
                        required: true,
                        message: "Please select a default column width."
                    }]}
                >
                    <Select
                        value={applet.span}
                        onChange={handleSpanChange}
                    >
                        {Array.from(Array(Globals.LAYOUT_COLUMNS).keys()).map(index => (
                            <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                        ))}
                    </Select>
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Category"
                    name="category"
                >
                    <Select
                        allowClear={true}
                        value={applet.category}
                        onChange={(category) => handleCategoryChange(category)}
                    >
                        {CoreUtils.enumToKeys(CategoryType).map(key => (
                            <Select.Option value={key}>{CoreUtils.toProper(key, "_")}</Select.Option>
                        ))}
                    </Select>
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Published"
                    name="published"
                    info="Enable to make available for inclusion in dashboards."
                    valuePropName="checked"
                >
                    <Switch
                        checked={applet.published}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={handlePublishChange}
                    />
                </FormItem>
            </Form>
        </Modal>
    )

}
