import { useEffect, useRef } from 'react';
import { measureTextWidth, Pie } from '@ant-design/charts';
import { Constants } from 'components/Constants';
import { DataSeries } from '../ChartWidgetViewer';
import { PolarWidgetConfiguration } from '@methodset/model-client-ts';
import classNames from 'classnames';
import './DonutChartView.less';

export type DonutChartViewProps = {
    className?: string,
    series: DataSeries,
    configuration: PolarWidgetConfiguration,
    height?: number | string,
    width?: number | string
};

export const DonutChartView = (props: DonutChartViewProps) => {

    const animation = useRef({});

    // Turn off animation for data refreshes.
    useEffect(() => { animation.current = false }, []);

    const renderStatistic = (containerWidth: number, text: string, style: any): string => {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2
        let scale = 1;
        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }
        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    return (
        <div className={classNames('x-donutview', props.className)} style={{ width: props.width, height: props.height }}>
            <Pie
                appendPadding={10}
                radius={1}
                innerRadius={0.64}
                label={{
                    type: "outer",
                    content: "{percentage}"
                    //content: "{name} {percentage}"
                    // type: 'inner',
                    // offset: '-50%',
                    // style: {
                    //     textAlign: 'center',
                    // },
                    // autoRotate: false,
                    // //content: '{value}',
                    // content: ""
                }}
                statistic={{
                    title: {
                        offsetY: -4,
                        customHtml: (container, view, datum) => {
                            return "";
                        }
                    },
                    content: {
                        offsetY: 4,
                        style: {
                            fontSize: '32px',
                        },
                        customHtml: (container: any, view: any, datum: any, data: any) => {
                            const { width } = container.getBoundingClientRect();
                            //const text = datum ? `$ ${datum.value}` : `$ ${data.reduce((r: any, d: any) => r + d.value, 0)}`;
                            const text = "";
                            return renderStatistic(width, text, {
                                fontSize: 32
                            });
                        }
                    }
                }}
                interactions={[
                    {
                        type: 'element-selected',
                    },
                    {
                        type: 'element-active',
                    },
                    {
                        type: 'pie-statistic-active',
                    }
                ]}
                animation={animation.current}
                data={props.series.points}
                colorField="x"
                angleField="y"
                autoFit={true}
                color={Constants.CHART_COLORS}
                padding={24}
            />
        </div>
    )
}
