import { ReactElement, useContext } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { CoreUtils } from 'utils/CoreUtils';
import { Board } from '@methodset/model-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { ApplicationContext } from 'context/ApplicationContext';
import update from 'immutability-helper';
import modelService from 'services/ModelService';
import './Applets.less';

export type AppletsProps = RouteComponentProps;

export const Applets = (props: AppletsProps): ReactElement => {

    const context = useContext(ApplicationContext);

    const handleBoardDelete = (board: Board): void => {
        deleteBoardRequest(board.id);
    }

    const deleteBoardRequest = (boardId: string): Promise<any> => {
        const request = {
            boardId: boardId
        };
        return modelService.deleteBoard(request,
            (response: any) => deleteBoardResponse(response)
        );
    }

    const deleteBoardResponse = (response: any): void => {
        const boards = context.boards;
        const boardId = response.data.boardId;
        const index = boards.findIndex((board: Board) => board.id === boardId);
        // Sanity check.
        if (index === -1) {
            return;
        }
        const updated = update(boards, {
            $splice: [[index, 1]]
        });
        context.saveBoards(updated);
    }

    const buildColumns = (): ColumnsType<any> => {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            ellipsis: true,
            width: Globals.TABLE_NAME_WIDTH,
            render: (name, board) => {
                return (
                    <Link to={RouteBuilder.applicationBoard(board.id)}>{board.name}</Link>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'version',
            title: 'Version',
            align: 'center',
            width: Globals.TABLE_VERSION_WIDTH,
            render: (version, board) => {
                return (
                    <span>
                        {CoreUtils.toVersion(board.version)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    const buildData = (): any[] => {
        return context.boards;
    }

    const actions = [{
        icon: <DeleteOutlined />,
        label: "Delete applet",
        confirm: "Are you sure you want to delete the applet?",
        callback: handleBoardDelete
    }];
    const columns = buildColumns();
    const data = buildData();

    return (
        <ItemTable
            showHeader={false}
            columns={columns}
            items={data}
            actions={actions}
            pagination={false}
        />
    );

}

