import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { CalculatorOutlined, DiffOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent, Space } from 'antd';
import { InlineText } from 'components/InlineText/InlineText';
import { ModelProperties } from './ModelProperties/ModelProperties';
import { Tip } from 'components/Tip/Tip';
import { ModelContext } from 'context/ModelContext';
import { RouteComponentProps } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Model } from '@methodset/model-client-ts';
import classNames from 'classnames';
import './ModelHeading.less';

export type SaveCallback = () => void;
export type ChangeCallback = (model: Model) => void;

type MatchParams = {
    modelId: string
}

export type ModelHeadingProps = typeof ModelHeading.defaultProps & RouteComponentProps<MatchParams> & {
    actions?: ReactNode,
    className?: string,
    isSaving: boolean,
    onSave: SaveCallback,
    onChange: ChangeCallback
}

export type ModelHeadingState = {
    isEditing: boolean,
    activeView: string | undefined
}

export class ModelHeading extends PureComponent<ModelHeadingProps, ModelHeadingState> {

    static contextType = ModelContext;

    static defaultProps = {
        isSaving: false
    }

    constructor(props: ModelHeadingProps) {
        super(props);
        this.state = {
            isEditing: false,
            activeView: this.findActiveView()
        };
        this.handleViewChange = this.handleViewChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleModelEdit = this.handleModelEdit.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleModelCancel = this.handleModelCancel.bind(this);
    }

    private handleViewChange(e: RadioChangeEvent): void {
        const modelId = this.props.match.params.modelId;
        const viewType = e.target.value;
        this.props.history.push(RouteBuilder.model(modelId, viewType));
    }

    private handleNameChange(name: string): void {
        const model = this.context.model;
        const calculator = this.context.calculator;
        model.name = name;
        calculator.properties.name = name;
    }

    private handleModelEdit(): void {
        this.setState({ isEditing: true });
    }

    private handleModelChange(model: Model): void {
        const calculator = this.context.calculator;
        calculator.properties.name = model.name;
        calculator.properties.description = model.description;
        this.context.saveModel(model);
        this.props.onChange(model);
        this.setState({ isEditing: false });
    }

    private handleModelCancel(): void {
        this.setState({ isEditing: false });
    }

    private findActiveView(): string | undefined {
        let path = this.props.location.pathname;
        let pattern = /(.*)\/(sheets|applets)$/;
        let regex = pattern.exec(path);
        return regex && regex.length > 2 ? regex[2] : undefined;
    }

    private buildButtons() {
        return (
            <React.Fragment>
                <Space>
                    {this.props.isSaving &&
                        <span className="x-modelheading-saving">Saving...</span>
                    }
                    <Tip title="Save model changes.">
                        <Button onClick={this.props.onSave}>Save</Button>
                    </Tip>
                    <Radio.Group buttonStyle="solid" defaultValue={this.state.activeView} onChange={this.handleViewChange}>
                        <Radio.Button value="sheets">
                            <Space>
                                <CalculatorOutlined />
                                <span>Sheets</span>
                            </Space>
                        </Radio.Button>
                        <Radio.Button value="applets">
                            <Space>
                                <DiffOutlined />
                                <span>Applets</span>
                            </Space>
                        </Radio.Button>
                    </Radio.Group>
                    <Tip title="Edit model properties.">
                        <Button icon={<EditOutlined />} onClick={this.handleModelEdit} />
                    </Tip>
                </Space>
                {this.props.actions}
            </React.Fragment>
        )
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-modelheading', this.props.className)}>
                <div className="x-modelheading-title">
                    <InlineText
                        className={classNames('x-modelheading-text')}
                        value={this.context.model.name}
                        onChange={this.handleNameChange}
                    />
                </div>
                <div className="x-modelheading-buttons">
                    {this.buildButtons()}
                </div>
                {this.state.isEditing &&
                    <ModelProperties
                        visible={this.state.isEditing}
                        model={this.context.model}
                        onChange={this.handleModelChange}
                        onCancel={this.handleModelCancel}
                    />
                }
            </div>
        )
    }

}
