import { ReactElement } from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Info } from 'components/Info/Info';
import { Spacer } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import './Label.less';

export type Location = "top" | "left";

export type LabelProps = {
    className?: string,
    children?: any,
    label: string | ReactElement,
    extra?: string | ReactElement,
    info?: string,
    placement?: Location,
    location?: TooltipPlacement,
    bold?: boolean,
    colon?: boolean,
    required?: boolean,
    fill?: boolean
} & typeof defaultProps;

const defaultProps = {
    placement: "left",
    location: "topRight",
    bold: true,
    colon: true,
    required: false
}

export const Label = (props: LabelProps): ReactElement => {

    const size = props.placement === "left" ? "none" : "xsmall";
    const alignment = props.placement === "left" ? "middle" : "top";
    const justification = props.placement === "left" ? "left" : "center";
    const direction = props.placement === "top" ? "vertical" : "horizontal";

    return (
        <Spacer
            fill={props.fill}
            size={size}
            alignment={alignment}
            justification={justification}
            direction={direction}
            className={classNames("x-label", props.className)}
        >
            <div className={
                classNames("x-label-info", { "x-label-extra-top": !!props.extra && props.placement === "top" })}
            >
                <Info
                    label={props.label}
                    info={props.info}
                    location={props.location}
                    bold={props.bold}
                    colon={props.label !== "" && props.placement === "left" && props.colon}
                    required={props.required}
                />
                <div>
                    {props.extra &&
                        <div className="x-label-extra">{props.extra}</div>
                    }
                </div>
            </div>
            {props.children &&
                <>
                    {props.children}
                </>
            }
        </Spacer>
    )
}

Label.defaultProps = defaultProps;
