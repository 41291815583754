import { PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { LockOutlined, SyncOutlined, UsergroupDeleteOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { Group } from '@methodset/entity-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { EntityContext } from 'context/EntityContext';
import { Spacer } from 'components/Spacer/Spacer';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import entityService from 'services/EntityService';
import './GroupMemberships.less';

export type GroupMembershipsState = {
    status: string,
    isCreate: boolean,
    groups: Group[]
}

export type GroupMembershipsProps = RouteComponentProps & {
}

export class GroupMemberships extends PureComponent<GroupMembershipsProps, GroupMembershipsState> {

    static contextType = EntityContext;

    constructor(props: GroupMembershipsProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            isCreate: false,
            groups: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleSwitchClick = this.handleSwitchClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleSwitchClick(group: Group): void {
        const groupId = group.id;
        this.context.saveGroupId(groupId);
    }

    private handleDeleteClick(group: Group): void {
        const groupId = group.id;
        this.removeGroupMemberRequest(groupId);
    }

    private readGroupsRequest(): Promise<any> {
        const request = {};
        return entityService.readGroups(request,
            (response: any) => this.readGroupsResponse(response),
            undefined, true
        );
    }

    private readGroupsResponse(response: any): void {
        const groups = response.data.groups;
        this.setState({ groups: groups });
    }

    private removeGroupMemberRequest(groupId: string): Promise<any> {
        const user = this.context.user;
        const request = {
            groupId: groupId,
            userId: user.id
        };
        return entityService.removeGroupMember(request,
            (response: any) => this.removeGroupMemberResponse(response),
            undefined, false
        );
    }

    private removeGroupMemberResponse(response: any): void {
        const groupId = response.data.groupId;
        const index = this.state.groups.findIndex(group => group.id === groupId);
        const groups = update(this.state.groups, {
            $splice: [[index, 1]]
        })
        this.setState({ groups: groups });
        this.context.removeGroup(groupId);
    }

    private buildData(): Group[] {
        return this.state.groups;
    }

    private buildColumns(): ColumnsType<any> {
        const user = this.context.user;
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Group Name',
            dataIndex: 'name',
            render: (value, record) => {
                return (
                    <Spacer alignment="middle">
                        <span>{value}</span>
                        {user.id === record.id &&
                            <LockOutlined />
                        }
                    </Spacer>
                )
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            //defaultSortOrder: 'ascend'
        }, {
            key: 'ctime',
            title: 'Create Time',
            align: 'center',
            dataIndex: 'createTime',
            render: (value, record) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(value)}
                    </span>
                );
            }
        }, {
            key: 'utime',
            title: 'Update Time',
            align: 'center',
            dataIndex: 'updateTime',
            render: (value, record) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(value)}
                    </span>
                );
            }
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readGroupsRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <UserSwitchOutlined />,
            label: "Switch Groups",
            callback: this.handleSwitchClick
        },{
            icon: <UsergroupDeleteOutlined />,
            label: "Delete membership",
            confirm: "Are you sure you want to be removed from the group?",
            disabled: (group: Group) => {
                return this.context.user?.id === group.id;
            },
            callback: this.handleDeleteClick
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <ItemTable
                className="x-groupmemberships"
                title="Group Memberships"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={
                    <Space>
                        <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                    </Space>
                }
                actions={actions}
                onLoad={this.handleRetryLoad}
            />
        );
    }

}
