import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Col, FormInstance, Input, Row, Select } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { JustificationType, SelectOption, SelectType, SelectWidgetConfiguration, WidgetType } from '@methodset/model-client-ts';
import { VariableSelector } from 'containers/Components/Widgets/Selectors/VariableSelector';
import { SelectOptions } from './SelectOptions/SelectOptions';
import update from 'immutability-helper';
import './SelectWidgetEditor.less';
import { JustificationSelector } from '../../Selectors/JustificationSelector';

export type ChangeCallback = (configuration: SelectWidgetConfiguration) => void;

export type SelectWidgetEditorProps = typeof SelectWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    calculator: Calculator,
    configuration?: SelectWidgetConfiguration,
    onChange: ChangeCallback
}

export class SelectWidgetEditor extends PureComponent<SelectWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.SELECT,
        layout: SelectType.BUTTONS,
        variable: undefined as any,
        label: undefined,
        options: [],
        justification: JustificationType.LEFT
    } as SelectWidgetConfiguration;

    static defaultProps = {
        configuration: SelectWidgetEditor.DefaultConfiguration
    }

    constructor(props: SelectWidgetEditorProps) {
        super(props);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleJustificationChange = this.handleJustificationChange.bind(this);
        this.handleOptionsChange = this.handleOptionsChange.bind(this);
    }

    private handleLayoutChange(layout: SelectType): void {
        const configuration = update(this.props.configuration, {
            layout: { $set: layout }
        });
        this.props.onChange(configuration);
    }

    private handleVariableChange(variable: string): void {
        const configuration = update(this.props.configuration, {
            variable: { $set: variable }
        });
        this.props.onChange(configuration);
    }

    private handleLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const configuration = update(this.props.configuration, {
            label: { $set: label }
        });
        this.props.onChange(configuration);
    }

    private handleJustificationChange(justification: JustificationType): void {
        const configuration = update(this.props.configuration, {
            justification: { $set: justification }
        });
        this.props.onChange(configuration);
    }

    private handleOptionsChange(options: SelectOption[]): void {
        const configuration = update(this.props.configuration, {
            options: { $set: options }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === SelectWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={10}>
                    {this.props.extra}
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Layout"
                        name="layout"
                        info="The layout format for the widget."
                        rules={[{
                            required: true,
                            message: 'Please select a layout.'
                        }]}
                    >
                        <Select
                            value={this.props.configuration.layout}
                            onChange={this.handleLayoutChange}
                        >
                            <Select.Option key={SelectType.BUTTONS} value={SelectType.BUTTONS}>Buttons</Select.Option>
                            <Select.Option key={SelectType.DROPDOWN} value={SelectType.DROPDOWN}>Dropdown</Select.Option>
                            <Select.Option key={SelectType.RADIO} value={SelectType.RADIO}>Radio</Select.Option>
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Variable"
                        name="variable"
                        info="The variable to change when a selection is chosen."
                        valuePropName="variable"
                        rules={[{
                            required: true,
                            message: 'Please select a variable.'
                        }]}
                    >
                        <VariableSelector
                            calculator={this.props.calculator}
                            variable={this.props.configuration.variable}
                            onChange={this.handleVariableChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Label"
                        name="label"
                        info="A label to add to the widget."
                    >
                        <Input
                            value={this.props.configuration.label}
                            onChange={this.handleLabelChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Justification"
                        name="justification"
                        info="Justification of the widget."
                    >
                        <JustificationSelector
                            value={this.props.configuration.justification}
                            onChange={this.handleJustificationChange}
                        />
                    </FormItem>
                </Col>
                <Col span={14}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Options"
                        name="options"
                        info="The options that map a selection to a variable."
                        valuePropName="options"
                        rules={[{
                            required: true,
                            message: ''
                        }, {
                            validator: (rule: any, options: SelectOption[]) => {
                                for (let i = 0; i < options.length; i++) {
                                    const option = options[i];
                                    if (!option.label || !option.value) {
                                        return Promise.reject('Please enter all option fields.');
                                    }
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        <SelectOptions
                            options={this.props.configuration.options}
                            onChange={this.handleOptionsChange}
                        />
                    </FormItem>
                </Col>
            </Row>
        );
    }

}
