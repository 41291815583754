import { PureComponent, ReactElement } from 'react';
import { Card, Empty, Row } from 'antd';
import { Applet, Widget, WidgetType } from '@methodset/model-client-ts';
import { Configuration, VariableSpec } from '@methodset/endpoint-client-ts';
import { WidgetViewer } from 'containers/Components/Widgets/WidgetViewer/WidgetViewer';
import { ViewItem } from 'containers/Console/Models/ModelItem/ModelApplets/AppletEditor/ItemLayout/ItemLayout';
import { Calculator } from '@methodset/calculator-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import './AppletViewer.less';

export type AppletViewerProps = {
    //model: Model,
    applet: Applet,
    calculator: Calculator,
    extra?: ReactElement,
    //modelSetup?: ModelSetup,
    modelId: string,
    version?: number
    appletConfiguration?: Configuration,
    variableSpecs?: VariableSpec[],
    isEmbedded?: boolean
}

export class AppletViewer extends PureComponent<AppletViewerProps> {

    static defaultProps = {
        isEmbedded: false
    }

    constructor(props: AppletViewerProps) {
        super(props);
        this.handleExecutionComplete = this.handleExecutionComplete.bind(this);
    }

    private findRootWidgets(): Widget[] {
        const childIds = new Set<string>();
        const widgets = this.props.applet.widgets;
        for (const widget of widgets) {
            const type = widget.configuration.type;
            // Find child widgets of row and columns.
            if (type === WidgetType.ROW || type === WidgetType.COLUMN) {
                const configuration = widget.configuration as any;
                const widgetIds = configuration.widgetIds;
                for (const widgetId of widgetIds) {
                    childIds.add(widgetId);
                }
            }
        }
        const rootWidgets = [];
        for (const widget of widgets) {
            if (!childIds.has(widget.id)) {
                rootWidgets.push(widget);
            }
        }
        return rootWidgets;
    }

    private buildItems(): ViewItem[] {
        const calculator = this.props.calculator;
        const widgets = this.findRootWidgets();
        return widgets.map(widget => {
            return {
                row: widget.row,
                col: widget.col,
                span: widget.span,
                element: (
                    <WidgetViewer
                        key={widget.id}
                        //model={this.props.model}
                        applet={this.props.applet}
                        widget={widget}
                        calculator={calculator}
                        //modelSetup={this.props.modelSetup}
                        modelId={this.props.modelId}
                        version={this.props.version}
                        appletConfiguration={this.props.appletConfiguration}
                        variableSpecs={this.props.variableSpecs}
                        //variableSpecs={this.props.model.variableSpecs}
                        showHeader={false}
                    />
                )
            }
        });
    }

    private handleExecutionComplete(): void {
        this.forceUpdate();
    }

    private buildWidgetsView(): ReactElement {
        const items = this.buildItems();
        return (
            <Row className="x-appletviewer-view">
                {items.map(item => item.element)}
            </Row>
        )
    }

    public componentDidMount(): void {
        this.props.calculator.addCallback("ExecutionComplete", this.handleExecutionComplete);
    }

    public componentWillUnmount(): void {
        this.props.calculator.removeCallback("ExecutionComplete", this.handleExecutionComplete);
    }

    public render(): ReactElement {
        const title = WidgetUtils.replaceCellRefs(this.props.calculator, this.props.applet.title);
        return (
            <>
                {!this.props.isEmbedded &&
                    <Card
                        className="x-appletviewer"
                        size="small"
                        bordered={true}
                        title={title}
                        extra={this.props.extra}
                    >
                        {this.props.applet.widgets.length === 0 &&
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span>No widgets added.</span>
                                }
                            />
                        }
                        {this.props.applet.widgets.length > 0 &&
                            this.buildWidgetsView()
                        }
                    </Card>
                }
                {this.props.isEmbedded && this.props.applet.widgets.length > 0 &&
                    this.buildWidgetsView()
                }
            </>
        );
    }

}
