import { PureComponent, ReactElement } from 'react';
import { Button } from 'antd';
import { Image } from 'components/Image/Image';
import { RouteBuilder } from 'utils/RouteBuilder';
import { EntityContext } from 'context/EntityContext';
import { RouteComponentProps } from 'react-router-dom';
import authService from 'services/AuthService';
import logo from 'images/methodset_blue_logo.png';
import gears from 'images/gears_32x32.png';
import './Header.less';
import { PropertyUtils } from '@methodset/commons-shared-ts';

export type HeaderProps = typeof Header.defaultProps & RouteComponentProps & {
    //showActions: boolean
}

export class Header extends PureComponent<HeaderProps> {

    static contextType = EntityContext;
    static defaultProps = {
        //showActions: true
    };

    constructor(props: HeaderProps) {
        super(props);
        this.handleHomeClick = this.handleHomeClick.bind(this);
        this.handleDemosClick = this.handleDemosClick.bind(this);
        this.handleServicesClick = this.handleServicesClick.bind(this);
        this.handleWaitlistClick = this.handleWaitlistClick.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    private handleHomeClick(): void {
        const isLoggedIn = this.isLoggedIn();
        if (isLoggedIn) {
            this.props.history.push(RouteBuilder.CONSOLE);
        } else {
            this.props.history.push(RouteBuilder.HOME);
        }
    }

    private handleDemosClick(): void {
        this.props.history.push(RouteBuilder.MAIN_DEMOS);
    }

    private handleServicesClick(): void {
        this.props.history.push(RouteBuilder.MAIN_SERVICES);
    }

    private handleWaitlistClick(): void {
        this.props.history.push(RouteBuilder.MAIN_WAITLIST);
    }

    private handleLoginClick(): void {
        this.props.history.push(RouteBuilder.user(this.props, RouteBuilder.USER_LOGIN));
    }

    private handleLogoutClick(): void {
        authService.logoutUser(
            () => this.handleLogout(),
            () => this.handleLogout()
        );
    }

    private handleLogout(): void {
        authService.clearUser();
        this.context.clearUser();
        this.props.history.push(RouteBuilder.HOME);
    }

    private isLoggedIn(): boolean {
        return this.context.user ? true : false;
    }

    public render(): ReactElement {
        const isLoggedIn = this.isLoggedIn();
        return (
            <div className="x-header">
                <span>
                    <Image src={gears} className="x-header-gears" alt="gears" />
                    <Image src={logo} className="x-header-logo" alt="logo" onClick={this.handleHomeClick} />
                </span>
                {PropertyUtils.isProduction() &&
                    <span>
                        <Button className="x-header-action" type="link" onClick={this.handleServicesClick}>Contact</Button>
                        <Button className="x-header-action" type="link" onClick={this.handleWaitlistClick}>Login</Button>
                    </span>
                }
                {!PropertyUtils.isProduction() &&
                    <span>
                        <Button className="x-header-action" type="link" onClick={this.handleServicesClick}>Contact</Button>
                        {!isLoggedIn &&
                            <Button className="x-header-action" type="link" onClick={this.handleLoginClick}>Login</Button>
                        }
                        {isLoggedIn &&
                            <Button className="x-header-action" type="link" onClick={this.handleLogoutClick}>Logout</Button>
                        }
                    </span>
                }
            </div>
        );
    }

}
