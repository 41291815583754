import { ReactElement, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LockOutlined, UserDeleteOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { Button, Space, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { Group, User } from '@methodset/entity-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { EntityContext } from 'context/EntityContext';
import { Spacer } from 'components/Spacer/Spacer';
import { RouteBuilder } from 'utils/RouteBuilder';
import axios from 'axios';
import update from 'immutability-helper';
import entityService from 'services/EntityService';
import './Family.less';

export type FamilyProps = RouteComponentProps;

export const Family = (props: FamilyProps): ReactElement => {

    const context = useContext(EntityContext);

    const [status, setStatus] = useState<string>(Globals.STATUS_INIT);
    const [group, setGroup] = useState<Group>({} as Group);

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleRemoveMemberClick = (user: User): void => {
        removeGroupMemberRequest(user.id);
    }

    const handleAddMemberClick = (): void => {
        props.history.push(RouteBuilder.APPLICATION_FAMILY_ADD_MEMBER);
    }

    const readGroupRequest = (): Promise<any> => {
        const user = context.user!;
        // Get the default group.
        const request = {
            groupId: user.id
        };
        return entityService.readGroup(request,
            (response: any) => readGroupResponse(response),
            undefined, true
        );
    }

    const readGroupResponse = (response: any): void => {
        const group = response.data.group;
        setGroup(group);
    }

    const removeGroupMemberRequest = (userId: string): Promise<any> => {
        const user = context.user!;
        const request = {
            groupId: user.id,
            userId: userId
        };
        return entityService.removeGroupMember(request,
            (response: any) => removeGroupMemberResponse(response),
            undefined, false
        );
    }

    const removeGroupMemberResponse = (response: any): void => {
        const userId = response.data.userId;
        const index = group.users.findIndex(user => user.id === userId);
        const updated = update(group, {
            users: {
                $splice: [[index, 1]]
            }
        });
        setGroup(updated);
    }

    const buildData = (): User[] => {
        return group.users;
    }

    const buildColumns = (): ColumnsType<any> => {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Member Name',
            dataIndex: 'name',
            render: (name, user) => {
                return (
                    <Spacer alignment="middle">
                        <span>{`${user.firstName} ${user.lastName}`}</span>
                        {user.id === group.id &&
                            <LockOutlined />
                        }
                    </Spacer>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            //defaultSortOrder: 'ascend'
        }, {
            key: 'email',
            title: 'Email',
            align: 'left',
            dataIndex: 'emailAddress',
            render: (email, user) => {
                return (
                    <span>{email}</span>
                );
            }
        }, {
            key: 'utime',
            title: 'Added',
            align: 'center',
            dataIndex: 'updateTime',
            render: (time, user) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(time)}
                    </span>
                );
            }
        }];
        return columns;
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readGroupRequest());
        setStatus(Globals.STATUS_LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(Globals.STATUS_READY);
            } else {
                setStatus(Globals.STATUS_FAILED);
            }
        }));
    }

    const user = context.user!;
    const actions = [{
        icon: <UserDeleteOutlined />,
        label: "Remove member",
        confirm: "Are you sure you want to remove the member?",
        disabled: (group: Group) => {
            return user.id === group.id;
        },
        callback: handleRemoveMemberClick
    }];
    const columns = buildColumns();
    const data = buildData();
    return (
        <ItemTable
            className="x-family"
            status={status}
            showHeader={false}
            columns={columns}
            items={data}
            extra={
                <Space>
                    <Button type="primary" onClick={() => handleAddMemberClick()}>Add Member</Button>
                </Space>
            }
            actions={actions}
            pagination={false}
            onLoad={handleRetryLoad}
        />
    );

}
