import { PureComponent, ReactElement } from 'react';
import './Demos.less';

export class Demos extends PureComponent {

    public render(): ReactElement {
        return (
            <div className="x-demos">
                <div className="x-demos-heading">
                    Demos
                </div>
                <div className="x-demos-video">
                    Overview
                </div>
                <div className="x-demos-video">
                    Dashboards
                </div>
                <div className="x-demos-video">
                    Models
                </div>
                <div className="x-demos-video">
                    Datasets
                </div>
                <div className="x-demos-video">
                    Workflows
                </div>
                <div className="x-demos-video">
                    APIs
                </div>
                <div className="x-demos-video">
                    Credentials
                </div>
                <div className="x-demos-video">
                    Teams
                </div>
            </div>
        )
    }

}
