import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Col, Row } from 'antd';
import { PageNotFound } from 'containers/Components/PageNotFound/PageNotFound';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Login } from 'containers/Main/User/Login/Login';
import { Register } from 'containers/Main/User/Register/Register';
import { RegisterConfirm } from 'containers/Main/User/RegisterConfirm/RegisterConfirm';
import { RegisterResend } from 'containers/Main/User/RegisterResend/RegisterResend';
import { Organization } from 'containers/Main/User/Organization/Organization';
import { ChangePassword } from 'containers/Main/User/ChangePassword/ChangePassword';
import { ChangeEmail } from 'containers/Main/User/ChangeEmail/ChangeEmail';
import { ChangeEmailConfirm } from 'containers/Main/User/ChangeEmailConfirm/ChangeEmailConfirm';
import { ResetPassword } from 'containers/Main/User/ResetPassword/ResetPassword';
import { ResetPasswordConfirm } from 'containers/Main/User/ResetPasswordConfirm/ResetPasswordConfirm';
import { EditProfile } from 'containers/Main/User/EditProfile/EditProfile';
import { SwitchGroup } from 'containers/Main/User/SwitchGroup/SwitchGroup';
import { Align } from 'components/Align/Align';
import research_image from 'images/close-up-pen-market-research.jpg';
import './User.less';

export const User = (): ReactElement => {

    return (
        <Row className="x-user" gutter={[0, 32]}>
            <Col
                lg={{ span: 14, order: 1 }}
                xs={{ span: 24, order: 2 }}
            >
                <Align alignment="center">
                    <img className="x-user-content x-user-image" src={research_image} alt="finance" />
                </Align>
            </Col>
            <Col
                lg={{ span: 10, order: 2 }}
                xs={{ span: 24, order: 1 }}
            >
                <div className="x-user-content">
                    <Switch>
                        <Route exact path={RouteBuilder.APPLICATION_LOGIN} component={Login} />
                        <Route exact path={RouteBuilder.APPLICATION_REGISTER} component={Register} />
                        <Route exact path={RouteBuilder.APPLICATION_REGISTER_CONFIRM} component={RegisterConfirm} />
                        <Route exact path={RouteBuilder.APPLICATION_REGISTER_RESEND} component={RegisterResend} />
                        <Route exact path={RouteBuilder.APPLICATION_ORGANIZATION} component={Organization} />
                        <Route exact path={RouteBuilder.APPLICATION_CHANGE_PASSWORD} component={ChangePassword} />
                        <Route exact path={RouteBuilder.APPLICATION_CHANGE_EMAIL} component={ChangeEmail} />
                        <Route exact path={RouteBuilder.APPLICATION_CHANGE_EMAIL_CONFIRM} component={ChangeEmailConfirm} />
                        <Route exact path={RouteBuilder.APPLICATION_RESET_PASSWORD} component={ResetPassword} />
                        <Route exact path={RouteBuilder.APPLICATION_RESET_PASSWORD_CONFIRM} component={ResetPasswordConfirm} />
                        <Route exact path={RouteBuilder.APPLICATION_EDIT_PROFILE} component={EditProfile} />
                        <Route exact path={RouteBuilder.APPLICATION_SWITCH_GROUP} component={SwitchGroup} />
                        <Route render={() => <PageNotFound />} />
                    </Switch>
                </div>
            </Col>
        </Row>
    );

}
