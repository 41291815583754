import { PureComponent, ReactElement } from 'react';
import { Button, Modal } from 'antd';
import { InputLink } from '@methodset/model-client-ts';
import { InputLinks } from './InputsLinks/InputLinks';
import { ModelMap, SpecMap } from '../../DashboardItem';
import './DashboardLinks.less';

export type ChangeCallback = (inputLinks: InputLink[]) => void;
export type CancelCallback = () => void;

export type DashboardLinksProps = typeof DashboardLinks.defaultProps & {
    specMap: SpecMap,
    modelMap: ModelMap,
    inputLinks: InputLink[],
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export type DashboardLinksState = {
    isEditing: boolean,
    inputLinks: InputLink[]
}

export class DashboardLinks extends PureComponent<DashboardLinksProps, DashboardLinksState> {

    static defaultProps = {
    }

    constructor(props: DashboardLinksProps) {
        super(props);
        this.state = {
            isEditing: false,
            inputLinks: props.inputLinks
        };
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleLinksChange = this.handleLinksChange.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
    }

    private handleOkClick(): void {
        this.props.onChange(this.state.inputLinks);
    }

    private handleLinksChange(inputLinks: InputLink[]): void {
        this.setState({ inputLinks: inputLinks });
    }

    private handleEditChange(isEditing: boolean): void {
        this.setState({ isEditing: isEditing });
    }

    public render(): ReactElement {
        return (
            <Modal
                wrapClassName="x-dashboardlinks"
                centered
                title="Linked Inputs"
                visible={true}
                width={900}
                footer={(
                    <>
                        <Button disabled={this.state.isEditing} onClick={this.props.onCancel}>Cancel</Button>
                        <Button type="primary" disabled={this.state.isEditing} onClick={this.handleOkClick}>OK</Button>
                    </>
                )}
            >
                <InputLinks
                    specMap={this.props.specMap}
                    modelMap={this.props.modelMap}
                    inputLinks={this.state.inputLinks}
                    onEdit={this.handleEditChange}
                    onChange={this.handleLinksChange}
                />
            </Modal>
        );
    }

}
