import { ReactElement } from 'react';
import { Button, FormInstance, Select } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Component, Flow, FlowType, InputSpec, IoMap, IoType, LoopFlow, OutputPath, OutputSpec, Processor } from '@methodset/endpoint-client-ts';
import { CompProcMap, KeySet } from '../IoMapEditor';
import { Spacer } from 'components/Spacer/Spacer';
import { DeleteOutlined } from '@ant-design/icons';
import { OutputComponents } from '../../FlowEditor';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import update from 'immutability-helper';
import './OutputPathsEditor.less';

export type ChangeCallback = (outputPaths: OutputPath[]) => void;

export type OutputPathsEditorProps = {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    outputPaths: OutputPath[],
    parentFlow?: Flow,
    ioMap: IoMap,
    inputSpec: InputSpec,
    keySet: KeySet | null,
    outputComponents: OutputComponents,
    compProcMap: CompProcMap,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

export const OutputPathsEditor = (props: OutputPathsEditorProps): ReactElement => {

    const handleComponentChange = (componentId: string, index: number): void => {
        const outputPaths = update(props.outputPaths, {
            [index]: {
                componentId: { $set: componentId }
            }
        });
        props.onChange(outputPaths);
    }

    const handleFieldChange = (outputKey: string, index: number): void => {
        const outputPaths = update(props.outputPaths, {
            [index]: {
                outputKey: { $set: outputKey }
            }
        });
        props.onChange(outputPaths);
    }

    const handleIterationChange = (iteration: number | undefined, index: number): void => {
        const outputPaths = update(props.outputPaths, {
            [index]: {
                iteration: { $set: iteration }
            }
        });
        props.onChange(outputPaths);
    }

    const handlePathAdd = (): void => {
        const outputPaths = update(props.outputPaths, {
            $push: [{
                outputKey: undefined as any
            }]
        });
        props.onChange(outputPaths);
    }

    const handlePathDelete = (index: number): void => {
        const outputPaths = update(props.outputPaths, {
            $splice: [[index, 1]]
        });
        props.onChange(outputPaths);

    }

    const findOutputPath = (inputSpec: InputSpec, componentIndex: number): OutputPath | null => {
        const outputMapping = props.ioMap[inputSpec.key];
        if (!outputMapping) {
            return null;
        }
        const outputPaths = outputMapping.outputPaths;
        return outputPaths && componentIndex < outputPaths.length ? outputPaths[componentIndex] : null;
    }

    const findProcessor = (inputSpec: InputSpec, componentIndex: number): Processor | null => {
        const outputPath = findOutputPath(inputSpec, componentIndex);
        return outputPath && outputPath.componentId ? props.compProcMap[outputPath.componentId] : null;
    }

    const hasMatchingType = (inputSpec: InputSpec, outputSpec: OutputSpec, index: number): boolean => {
        // Check if the prev output spec can be mapped to tne next input spec.
        // TODO: add subtype to list and check that type
        if (inputSpec.types[0] === IoType.LIST) {
            return true;
        }
        if (!props.keySet) {
            return false;
        }
        const keyMap = props.keySet[index];
        const specKeys = keyMap[inputSpec.key];
        return specKeys && specKeys.has(outputSpec.key);
    }

    const isComponentUsed = (component: Component): boolean => {
        for (const outputPath of props.outputPaths) {
            // Map flow types can use the same component, but specify different iterations.
            if (outputPath.componentId === component.id && props.parentFlow?.type !== FlowType.LOOP) {
                return true;
            }
        }
        return false;
    }

    const isIterationUsed = (iteration?: number): boolean => {
        if (CoreUtils.isEmpty(iteration)) {
            return false;
        }
        for (const outputPath of props.outputPaths) {
            if (outputPath.iteration === iteration) {
                return true;
            }
        }
        return false;
    }

    return (
        <Spacer className="x-outputpatheditor" direction="vertical" alignment="top" size="large">
            {props.outputPaths.map((outputPath, index) => (
                <Spacer
                    key={index}
                    className="x-outputpatheditor-path"
                    direction="vertical"
                    alignment="top"
                    size="small"
                >
                    {props.outputComponents.isArray &&
                        <FormItem
                            formRef={props.formRef}
                            name={`iomap-${index}-processor-${props.inputSpec.key}`}
                            noLabel={true}
                            noError={true}
                            rules={[{
                                required: true,
                                message: "Please select an output processor."
                            }]}
                            postfix={(
                                <Button
                                    className={classNames({ "x-outputpatheditor-hide": index === 0 })}
                                    icon={<DeleteOutlined />}
                                    onClick={() => handlePathDelete(index)}
                                />
                            )}
                        >
                            <Select
                                placeholder="Select a processor."
                                allowClear={true}
                                value={outputPath.componentId}
                                onChange={(componentId) => handleComponentChange(componentId, index)}
                            >
                                {props.outputComponents.components.map((component, index) => (
                                    <Select.Option
                                        key={component.id}
                                        value={component.id}
                                        disabled={isComponentUsed(component)}
                                    >
                                        {component.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    }
                    <FormItem
                        formRef={props.formRef}
                        noLabel={true}
                        noError={true}
                        name={`iomap-${index}-field-${props.inputSpec.key}`}
                        rules={[{
                            required: true,
                            message: "Please select an output field."
                        }]}
                    >
                        <Select
                            placeholder="Select an output field."
                            allowClear={true}
                            value={outputPath.outputKey}
                            onChange={(specKey) => handleFieldChange(specKey, index)}
                        >
                            {findProcessor(props.inputSpec, index)?.outputSpecs.map((outputSpec) => (
                                <Select.Option
                                    key={outputSpec.key}
                                    value={outputSpec.key}
                                    disabled={!hasMatchingType(props.inputSpec, outputSpec, index)}
                                >
                                    {outputSpec.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    {/* {props.parentFlow && props.parentFlow.type === FlowType.LOOP &&
                        <FormItem
                            formRef={props.formRef}
                            noLabel={true}
                            noError={true}
                            name={`iomap-${index}-iteration-${props.inputSpec.key}`}
                            rules={[{
                                required: true,
                                message: "Please select an iteration number."
                            }]}
                        >
                            <Select
                                placeholder="Select a loop iteration."
                                allowClear={true}
                                value={outputPath.iteration}
                                onChange={(iteration) => handleIterationChange(iteration, index)}
                            >
                                {(props.parentFlow as LoopFlow).configurations.map((configuration, index) => (
                                    <Select.Option
                                        key={index}
                                        value={index}
                                        disabled={isIterationUsed(index)}
                                    >
                                        {index + 1}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    } */}
                </Spacer>
            ))}
            {props.inputSpec.types[0] === IoType.LIST &&
                <Button
                    onClick={handlePathAdd}
                >
                    {`Add To ${props.inputSpec.name}`}
                </Button>
            }
        </Spacer >
    )
}

OutputPathsEditor.defaultProps = defaultProps;
