import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './Panel.less';

export type PanelProps = {
    className?: string,
    children?: any,
    title?: ReactNode
} & typeof defaultProps;

const defaultProps = {
}

export const Panel = (props: PanelProps): ReactElement => {
    return (
        <div className={classNames("x-panel", props.className)}>
            {props.title &&
                <div className="x-panel-title">{props.title}</div>
            }
            {props.children}
        </div>
    )
}

Panel.defaultProps = defaultProps;
