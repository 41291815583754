import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, FormInstance, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EntityContext } from 'context/EntityContext';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RulesData, RulesEditor } from './RulesEditor/RulesEditor';
import entityService from 'services/EntityService';
import authService from 'services/AuthService';
import update from 'immutability-helper';
import './Organization.less';
import { AccessRules } from '@methodset/entity-client-ts';

interface FormData {
    organizationId?: string;
    tenantId?: string;
    name?: string;
    description?: string;
    rules?: RulesData;
}

export type OrganizationProps = RouteComponentProps & {}

export type OrganizationState = {
    errorMessage?: string,
    isSubmitting: boolean,
    formData: FormData,
    doJoin: boolean
}

export class Organization extends PureComponent<OrganizationProps, OrganizationState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: OrganizationProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isSubmitting: false,
            formData: {},
            doJoin: false
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleRulesChange = this.handleRulesChange.bind(this);
        this.handleJoinChange = this.handleJoinChange.bind(this);
        this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
        this.handleTenantChange = this.handleTenantChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                name: { $set: name }
            })
        });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                description: { $set: description }
            })
        });
    }

    private handleRulesChange(rules: RulesData): void {
        this.setState({
            formData: update(this.state.formData, {
                rules: { $set: rules }
            })
        });
    }

    private handleJoinChange(e: CheckboxChangeEvent): void {
        const doJoin = e.target.checked;
        this.setState({ doJoin: doJoin });
    }

    private handleOrganizationChange(e: any): void {
        const organizationId = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                organizationId: { $set: organizationId }
            })
        });
    }

    private handleTenantChange(e: any): void {
        const tenantId = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                tenantId: { $set: tenantId }
            })
        });
    }

    private handleFormFinish(): void {
        if (!this.formRef.current) {
            this.setState({ errorMessage: 'Form reference not set.' });
            return;
        }
        this.formRef.current.validateFields().then(() => {
            if (this.state.doJoin) {
                const request = {
                    organizationId: this.state.formData.organizationId,
                    tenantId: this.state.formData.tenantId
                };
                this.joinOrganizationRequest(request);
            } else {
                const request = {
                    name: this.state.formData.name,
                    description: this.state.formData.description,
                    rules: {
                        isEnabled: this.state.formData.rules?.isEnabled,
                        whitelist: this.toItems(this.state.formData.rules?.whitelist),
                        blacklist: this.toItems(this.state.formData.rules?.blacklist),
                        domain: this.state.formData.rules?.domain
                    } as AccessRules
                };
                this.createOrganizationRequest(request);
            }
        });
    }

    private createOrganizationRequest(request: any): Promise<any> {
        this.setState({
            errorMessage: undefined,
            isSubmitting: true
        });
        return entityService.createOrganization(request,
            (response: any) => this.createOrganizationResponse(response),
            (response: any) => this.responseException(response),
            true
        );
    }

    private createOrganizationResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: false
        });
        authService.refreshUser().then((user) => {
            this.context.saveUser(user);
            this.props.history.push(RouteBuilder.CONSOLE);
        }).catch((err) => {
            this.context.clearUser();
        });
    }

    private joinOrganizationRequest(request: any): Promise<any> {
        this.setState({
            errorMessage: undefined,
            isSubmitting: true
        });
        return entityService.joinOrganization(request,
            (response: any) => this.joinOrganizationResponse(response),
            (response: any) => this.responseException(response),
            true
        );
    }

    private joinOrganizationResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: false
        });
        authService.refreshUser().then((user) => {
            this.context.saveUser(user);
            this.props.history.push(RouteBuilder.CONSOLE);
        }).catch((err) => {
            this.context.clearUser();
        });
    }

    private responseException(response: any): void {
        this.setState({
            errorMessage: RestUtils.getError(response),
            isSubmitting: false
        });
    }

    private toItems(value: string | undefined): string[] {
        return value ? value.split(/[, ]+/) : [];
    }

    private buildOrganizationForm(): ReactElement {
        return (
            <FormFrame
                ref={this.formRef}
                title={`${this.state.doJoin ? "Join" : "Create"} Organization`}
                error={this.state.errorMessage}
                hideFooter={true}
                onOk={this.handleFormFinish}
            >
                {!this.state.doJoin &&
                    <>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Name"
                            name="name"
                            info="The name of the organization."
                            rules={[{
                                required: true,
                                message: 'Please enter the organization name.'
                            }]}
                        >
                            <Input
                                id="name"
                                placeholder="Organization name."
                                value={this.state.formData.name}
                                onChange={this.handleNameChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Description"
                            name="description"
                            info="The description of the organization."
                        >
                            <Input.TextArea
                                id="description"
                                placeholder="Organization description."
                                value={this.state.formData.description}
                                onChange={this.handleDescriptionChange}
                            />
                        </FormItem>
                        <RulesEditor
                            formRef={this.formRef}
                            rules={this.state.formData.rules}
                            onChange={this.handleRulesChange}
                        />
                    </>
                }
                {this.state.doJoin &&
                    <>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Organization Id"
                            name="organization"
                            info="Join an existing organization by entering the id that has been given to you by a member of your organization."
                            rules={[{
                                required: true,
                                message: 'Please enter your organization id.'
                            }]}
                        >
                            <Input
                                id="organization"
                                placeholder="Organization id."
                                value={this.state.formData.organizationId}
                                onChange={this.handleOrganizationChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Tenant Id"
                            name="tenant"
                            info="Join a tenant in the organization by entering the id that has been given to you by a member of your organization."
                            rules={[{
                                required: true,
                                message: 'Please enter your tenant id.'
                            }]}
                        >
                            <Input
                                id="tenant"
                                placeholder="Tenant id."
                                value={this.state.formData.tenantId}
                                onChange={this.handleTenantChange}
                            />
                        </FormItem>
                    </>
                }
                <div className="x-organization-footer">
                    <Checkbox onChange={this.handleJoinChange}>
                        Join an existing organization.
                    </Checkbox>
                    <Button
                        className="x-organization-submit"
                        type="primary"
                        htmlType="submit"
                        loading={this.state.isSubmitting}
                    >
                        {this.state.doJoin ? "Join" : "Create"}
                    </Button>
                </div>
            </FormFrame>
        );
    }

    public render(): ReactElement {
        const view = this.buildOrganizationForm();
        return (
            view
        );
    }

}
