import { PureComponent, ReactElement } from 'react';
import { Button, Empty } from 'antd';
import { Coordinate, ElementChangeType, Parameter, ParametersChangeEvent, Sheet } from '@methodset/calculator-ts';
import { ParameterData, ParameterProperties } from './ParameterProperties/ParameterProperties';
import { ModelContext } from 'context/ModelContext';
import { ParameterItem } from './ParameterItem/ParameterItem';
import classNames from 'classnames';
import './Parameters.less';

export type ParametersProps = {
    className?: string,
    //formulaEditor?: FormulaEditor
}

export type ParametersState = {
    isEditing: boolean,
    isFormatting: boolean
}

export class Parameters extends PureComponent<ParametersProps, ParametersState> {

    static contextType = ModelContext;

    constructor(props: ParametersProps) {
        super(props);
        this.state = {
            isEditing: false,
            isFormatting: false
        };
        this.handleParameterNew = this.handleParameterNew.bind(this);
        this.handleParameterCancel = this.handleParameterCancel.bind(this);
        this.handleParameterAdd = this.handleParameterAdd.bind(this);
        this.handleParameterRemove = this.handleParameterRemove.bind(this);
        this.handleParametersChange = this.handleParametersChange.bind(this);
    }

    private handleParameterNew(): void {
        this.setState({ isEditing: true });
    }

    private handleParameterCancel(): void {
        this.setState({ isEditing: false });
    }

    private handleParameterAdd(data: ParameterData): void {
        // Addition of parameter will trigger a parameter list change event.
        const calculator = this.context.calculator;
        calculator.parameters.add(data.variable, data.name, data.description);
    }

    private handleParameterRemove(parameter: Parameter): void {
        // Removal will trigger a parameter list event.
        const calculator = this.context.calculator;
        calculator.parameters.remove(parameter.variable);
    }

    private handleParametersChange(event: ParametersChangeEvent) {
        // Called when the parameters list changes.
        const activeCell = this.context.active.cell;
        try {
            if (event.type === ElementChangeType.ADD) {
                const row = event.parameter.row;
                this.setState({ isEditing: false });
                this.focusParameter(row);
            } else if (event.type === ElementChangeType.REMOVE) {
                const calculator = this.context.calculator;
                if (calculator.parameters.length > 0 && activeCell && activeCell.sheet.id === Sheet.PARAMETERS_SHEET) {
                    let row = event.parameter.row;
                    let coord = Coordinate.fromCellId(activeCell.id);
                    if (coord.row > row) {
                        row = coord.row - 1;
                    } else {
                        row = coord.row;
                    }
                    this.forceUpdate();
                    this.focusParameter(row);
                } else {
                    // Delete, but non-parameter has current focus, so do not re-focus.
                    this.forceUpdate();
                }
            } else if (event.type === ElementChangeType.MOVE) {
                this.forceUpdate();
            }
        } catch (e) {
            return;
        }
    }

    private focusParameter(row: number): void {
        // Put the event on the queue so that it executes after
        // any DOM modifications.
        setTimeout(() => {
            const cellId = `A${row + 1}`
            const el = document.getElementById(`parameter-${cellId}`);
            if (el) {
                el.focus();
            }
        }, 0);
    }

    public componentDidMount() {
        const parameters = this.context.calculator.parameters;
        parameters.addCallback("Change", this.handleParametersChange);
    }

    public componentWillUnmount() {
        const parameters = this.context.calculator.parameters;
        parameters.removeCallback("Change", this.handleParametersChange);
    }

    public render(): ReactElement {
        let parameters = this.context.calculator.parameters;
        return (
            <div id="parameters" className={classNames('x-parameters', this.props.className)}>
                {parameters.length === 0 &&
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>No variables.</span>}
                    />
                }
                <div className="x-parameters-items">
                    {parameters.length > 0 && parameters.map((parameter: Parameter, index: number) =>
                        <ParameterItem
                            key={parameter.variable}
                            index={index}
                            parameter={parameter}
                            onDelete={this.handleParameterRemove}
                        />
                    )}
                </div>
                <div className="x-parameters-add">
                    <Button onClick={this.handleParameterNew}>
                        Add
                    </Button>
                </div>
                {this.state.isEditing &&
                    <ParameterProperties
                        data={{}}
                        onChange={this.handleParameterAdd}
                        onCancel={this.handleParameterCancel}
                    />
                }
            </div>
        )
    }

}
