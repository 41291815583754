import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Globals } from 'constants/Globals';
import { ItemEditor } from 'containers/Console/ItemEditor/ItemEditor';
import { WorkflowEditor } from './WorkflowEditor/WorkflowEditor';
import { Workflow } from '@methodset/workflow-client-ts';
import classNames from 'classnames';
import './WorkflowItem.less';

type MatchParams = {
    workflowId: string
}

export type WorkflowItemState = {
    status: string,
    errorMessage: string | null
    loading: boolean
}

export type WorkflowItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export class WorkflowItem extends PureComponent<WorkflowItemProps, WorkflowItemState> {

    private workflowRef = React.createRef<WorkflowEditor>();

    constructor(props: WorkflowItemProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            errorMessage: null,
            loading: false
        };
        this.handleEditorSave = this.handleEditorSave.bind(this);
        this.handleEditorCancel = this.handleEditorCancel.bind(this);
        this.handleEditorError = this.handleEditorError.bind(this);
        this.handleWorkflowSaved = this.handleWorkflowSaved.bind(this);
        this.handleWorkflowTouch = this.handleWorkflowTouch.bind(this);
        this.handleWorkflowError = this.handleWorkflowError.bind(this);
    }

    private handleEditorSave(values: any): void {
        const callback = (): void => {
            this.setState({
                errorMessage: null,
                loading: true
            });
        }
        this.workflowRef.current!.saveWorkflow(callback);
    }

    private handleEditorCancel(): void {
        this.props.history.push(RouteBuilder.CONSOLE_WORKFLOWS);
    }

    private handleEditorError(details: any): void {
        const errorMessage = 'Please fill in all required fields.';
        this.setState({ errorMessage: errorMessage });
    }

    private handleWorkflowSaved(workflow: Workflow): void {
        this.setState({
            loading: false
        });
        this.props.history.push(RouteBuilder.CONSOLE_WORKFLOWS);
    }

    private handleWorkflowTouch(): void {
        if (this.state.errorMessage) {
            this.setState({ errorMessage: null });
        }
    }

    private handleWorkflowError(error: Error): void {
        this.setState({
            loading: false,
            errorMessage: error.message
        });
    }

    render() {
        const workflowId = this.props.match.params.workflowId;
        return (
            <ItemEditor
                className={classNames('x-workflowitem', this.props.className)}
                title="Workflow"
                loading={this.state.loading}
                onSave={this.handleEditorSave}
                onCancel={this.handleEditorCancel}
                onError={this.handleEditorError}
            >
                <WorkflowEditor
                    ref={this.workflowRef}
                    workflowId={workflowId}
                    onSaved={this.handleWorkflowSaved}
                    onError={this.handleWorkflowError}
                    onTouch={this.handleWorkflowTouch}
                />
                <div className="x-workflowitem-error">{this.state.errorMessage}</div>
            </ItemEditor>
        );
    }

}
