import React, { PureComponent, ReactElement } from 'react';
import { Checkbox, FormInstance } from 'antd';
import { Select } from 'antd';
import { TimePicker } from 'antd';
import { DaysOfWeekSelector } from 'components/DaysOfWeekSelector/DaysOfWeekSelector';
import { FormItem } from 'components/FormItem/FormItem';
import { TimezoneSelector } from 'components/TimezoneSelector/TimezoneSelector';
import { Constants } from 'components/Constants';
import { Cron, FrequencyType, TimeInterval } from '@methodset/schedule-client-ts';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import moment from 'moment';
import update from 'immutability-helper';
import './IntervalEditor.less';

export type IntervalPeriod = FrequencyType.HOUR | FrequencyType.MINUTE;
export type ChangeCallback = (cron: Cron) => void;

export type IntervalEditorProps = typeof IntervalEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    period: IntervalPeriod
    cron: Cron,
    layout: any
    onChange: ChangeCallback
}

export class IntervalEditor extends PureComponent<IntervalEditorProps> {

    static defaultProps = {
        cron: {
            frequencyType: undefined as any,
            daysOfWeek: [] as number[],
            timeInterval: {
                period: undefined as any,
                startTime: undefined as any,
                endTime: undefined as any,
                timezone: "UTC"
            } as TimeInterval
        } as Cron
    }

    private startOfDay: string;
    private endOfDay: string;

    constructor(props: IntervalEditorProps) {
        super(props);
        this.startOfDay = "12:00 am";
        this.endOfDay = "11:59 pm"
        this.handleAllChange = this.handleAllChange.bind(this);
        this.handleDaysOfWeekChange = this.handleDaysOfWeekChange.bind(this);
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
        this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
        this.handleIntervalChange = this.handleIntervalChange.bind(this);
    }

    private handleAllChange(e: CheckboxChangeEvent): void {
        const checked = e.target.checked;
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            timeInterval: {
                startTime: { $set: (checked ? this.startOfDay : undefined) as any },
                endTime: { $set: (checked ? this.endOfDay : undefined) as any }
            }
        });
        this.props.onChange(cron);
    }

    private handleDaysOfWeekChange(daysOfWeek: number[]): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            daysOfWeek: { $set: daysOfWeek }
        });
        this.props.onChange(cron);
    }

    private handleStartTimeChange(startTime: moment.Moment | null): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            timeInterval: {
                startTime: { $set: CoreUtils.toStringTime(startTime) }
            }
        });
        this.props.onChange(cron);
    }

    private handleEndTimeChange(endTime: moment.Moment | null): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            timeInterval: {
                endTime: { $set: CoreUtils.toStringTime(endTime) }
            }
        });
        this.props.onChange(cron);
    }

    private handleTimezoneChange(timezone: string): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            timeInterval: {
                timezone: { $set: timezone }
            }
        });
        this.props.onChange(cron);
    }

    private handleIntervalChange(period: number): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            timeInterval: {
                period: { $set: period }
            }
        });
        this.props.onChange(cron);
    }

    private buildIntervalOptions(values: number[]): ReactElement[] {
        let options: ReactElement[] = [];
        for (let i = 0; i < values.length; i++) {
            options.push(
                <Select.Option key={i} value={values[i]}>{values[i]}</Select.Option>
            );
        }
        return options;
    }

    private isAllDay(): boolean {
        const startTime = this.props.cron.timeInterval!.startTime;
        const endTime = this.props.cron.timeInterval!.endTime;
        return this.startOfDay === startTime && this.endOfDay === endTime;
    }

    public componentDidMount(): void {
        if (this.props.cron === IntervalEditor.defaultProps.cron) {
            // Update if cron set with defaults.
            this.props.onChange(this.props.cron);
        }
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-intervaleditor', this.props.className)}>
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="Days of Week"
                    name="daysofweek"
                    info="The days of week."
                    rules={[{
                        required: true,
                        message: 'Please enter one or more months.'
                    }]}
                >
                    <DaysOfWeekSelector
                        value={this.props.cron.daysOfWeek}
                        onChange={this.handleDaysOfWeekChange}
                    />
                </FormItem>
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="Start Time"
                    name="starttime"
                    info="The start time."
                    rules={[{
                        required: true,
                        message: 'Please enter a start time.'
                    }]}
                    postfix={
                        <Checkbox
                            checked={this.isAllDay()}
                            onChange={this.handleAllChange}>
                            All day
                        </Checkbox>
                    }
                >
                    <TimePicker
                        className="x-intervaleditor-starttime"
                        use12Hours
                        format={Constants.TIME_ISO_FORMAT}
                        value={CoreUtils.toMomentTime(this.props.cron.timeInterval!.startTime)}
                        onChange={this.handleStartTimeChange}
                    />
                </FormItem>
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="End Time"
                    name="endtime"
                    info="The end time."
                    rules={[{
                        required: true,
                        message: 'Please enter an end time.'
                    }]}
                >
                    <TimePicker
                        use12Hours
                        format={Constants.TIME_ISO_FORMAT}
                        value={CoreUtils.toMomentTime(this.props.cron.timeInterval!.endTime)}
                        onChange={this.handleEndTimeChange}
                    />
                </FormItem>
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="Time zone"
                    name="timezone"
                    info="The time zone."
                    rules={[{
                        required: true,
                        message: 'Please enter a timezone.'
                    }]}
                >
                    <TimezoneSelector
                        className="x-intervaleditor-zone"
                        value={this.props.cron.timeInterval!.timezone}
                        onChange={this.handleTimezoneChange}
                    />
                </FormItem>
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="Interval"
                    name="interval"
                    info="The time interval."
                    rules={[{
                        required: true,
                        message: 'Please enter an interval.'
                    }]}
                    postfix={
                        <span>{this.props.period === FrequencyType.HOUR ? "hours" : "minutes"}</span>
                    }
                >
                    <Select
                        className="x-intervaleditor-interval"
                        value={this.props.cron.timeInterval!.period}
                        onChange={this.handleIntervalChange}
                    >
                        {
                            this.props.period === FrequencyType.HOUR ?
                                this.buildIntervalOptions([1, 2, 3, 4, 6, 8, 12]) :
                                this.buildIntervalOptions([1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30])
                        }
                    </Select>
                </FormItem>
            </div>
        )
    }

}
