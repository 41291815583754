import { PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Empty, message, Modal } from 'antd';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner'
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { ChangeType, DashboardHeading } from './DashboardHeading/DashboardHeading';
import { Configuration, VariableSpec } from '@methodset/endpoint-client-ts';
import { Applet, AppletRef, AppletSetup, Dashboard, InputKey, InputLink, Model, ModelRef, ModelSetup } from '@methodset/model-client-ts';
import { Calculator } from '@methodset/calculator-ts';
import { CloseOutlined, DragOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Location } from 'history';
import { Spacer } from 'components/Spacer/Spacer';
import { WidgetUtils } from 'utils/WidgetUtils';
import { ConfigurationDialog } from 'containers/Console/ConfigurationDialog/ConfigurationDialog';
import { CoreUtils } from 'utils/CoreUtils';
import { Item, ItemLayout, ViewItem } from 'containers/Console/Models/ModelItem/ModelApplets/AppletEditor/ItemLayout/ItemLayout';
import { AppletViewer } from './AppletViewer/AppletViewer';
import { ItemPosition } from 'containers/Console/Models/ModelItem/ModelApplets/AppletEditor/ItemPosition/ItemPosition';
import { AppletInventory } from './AppletInventory/AppletInventory';
import axios from 'axios';
import modelService from 'services/ModelService';
import update from 'immutability-helper';
import './DashboardItem.less';

// Map of model/version/key to spec.
export type SpecMap = { [key: string]: VariableSpec };
// Map of model/version/key to all model/version/spec in group.
export type LinkMap = { [key: string]: string[] };
// Map of model/version to model.
export type ModelMap = { [key: string]: Model };
// Map of model/version/applet to applet.
export type AppletMap = { [key: string]: Applet };
// Map of model/version to calculator.
export type CalculatorMap = { [key: string]: Calculator };

// A column definition. Each column is defined by the first
// applet ref. Its width is determined by its starting column
// index and the next applet ref in the same row.
interface ColDef {
    setup: AppletSetup;
    min: number;
    max: number;
}

// A value to use, combined with the version, to assign to component keys.
interface Key {
    value: number;
}

// The state of exiting. Since the model may need to be saved
// before exiting, the state must be tracked to allow the save
// to complete before exiting.
type ExitState = "init" | "failed" | "started" | "finished";

type MatchParams = {
    dashboardId: string
}

export type DashboardItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export type DashboardItemState = {
    // Data load status.
    status: string,
    // The dashboard.
    dashboard?: Dashboard,
    // True if is adding applets.
    isAdding: boolean,
    // The error is add applets to dashboard failed.
    addError?: Error,
    // The list of models in use.
    //models: Model[],
    // The model setup used when editing inputs.
    modelSetup?: ModelSetup,
    // Applet setup used when editing applet position.
    appletSetup?: AppletSetup,
    // The columns of applet setups used for applet layout.
    columnSetups: AppletSetup[][],
    // The maximum row number in the dashboard.
    maxRow: number,
    // True when dashboard is saving to storage.
    isSaving: boolean,
    // The state of exiting the dashboard page.
    exitState: ExitState
}

// The number of columns in the dashboard grid layout.
const NUMBER_COLUMNS = Globals.LAYOUT_COLUMNS;
// The number of rows in the dashboard grid layout.
const NUMBER_ROWS = Globals.LAYOUT_ROWS;
// Auto-save interval in seconds.
const SAVE_INTERVAL = 60 * 1000;

export class DashboardItem extends PureComponent<DashboardItemProps, DashboardItemState> {

    // The timer for auto-saves.
    private timerId: NodeJS.Timeout | null = null;
    // The target URL when checking for save on exit.
    private nextPathname: string | null = null;
    // The function to unblock URL monitoring.
    private unmonitorFn: any;
    // True if changes need to be saved, false otherwise.
    private isDirty: boolean = false;
    // Map of specs with link changes.
    private specMap: SpecMap = {};
    // Map of specs without link changes.
    private origSpecMap: SpecMap = {};
    // All permutations of links.
    private linkMap: LinkMap = {};
    // Map of models.
    private modelMap: ModelMap = {};
    // Map of applets.
    private appletMap: AppletMap = {};
    // Map of calculators.
    private calculatorMap: CalculatorMap = {};

    constructor(props: DashboardItemProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            isAdding: false,
            appletSetup: undefined,
            modelSetup: undefined,
            columnSetups: this.emptyColumnSetups(),
            maxRow: 0,
            isSaving: false,
            exitState: "init"
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleAppletsInstall = this.handleAppletsInstall.bind(this);
        this.handleAppletsShow = this.handleAppletsShow.bind(this);
        this.handleAppletsCancel = this.handleAppletsCancel.bind(this);
        this.handleAppletRefresh = this.handleAppletRefresh.bind(this);
        this.handleAppletsRefresh = this.handleAppletsRefresh.bind(this);
        this.handleAppletRemove = this.handleAppletRemove.bind(this);
        this.handlePositionEdit = this.handlePositionEdit.bind(this);
        this.handlePositionCancel = this.handlePositionCancel.bind(this);
        this.handlePositionChange = this.handlePositionChange.bind(this);
        this.handleDashboardSave = this.handleDashboardSave.bind(this);
        this.handleDashboardChange = this.handleDashboardChange.bind(this);
        this.handleInputsEdit = this.handleInputsEdit.bind(this);
        this.handleInputsChange = this.handleInputsChange.bind(this);
        this.handleInputsCancel = this.handleInputsCancel.bind(this);
        this.handleExitCheck = this.handleExitCheck.bind(this);
        this.handleExitWithSave = this.handleExitWithSave.bind(this);
        this.handleExitNoSave = this.handleExitNoSave.bind(this);
        this.handleExitCancel = this.handleExitCancel.bind(this);
    }

    private handleRetryLoad(): void {
        const dashboardId = this.props.match.params.dashboardId;
        this.loadData(dashboardId);
    }

    private handleAppletsInstall(appletRefs: AppletRef[]): void {
        const dashboard = this.state.dashboard!;
        this.addApplets(dashboard, appletRefs);
    }

    private handleAppletsShow(): void {
        this.setState({ isAdding: true });
    }

    private handleAppletsCancel(): void {
        this.setState({ isAdding: false });
    }

    private handlePositionEdit(appletSetup: AppletSetup): void {
        this.setState({ appletSetup: appletSetup });
    }

    private handlePositionChange(item: Item): void {
        if (!this.state.dashboard) {
            return;
        }
        const appletSetup = this.state.appletSetup!;
        const appletSetups = this.state.dashboard.appletSetups;
        const index = appletSetups.findIndex(setup => setup.appletId === appletSetup.appletId);
        if (index === -1) {
            return;
        }
        const dashboard = update(this.state.dashboard, {
            appletSetups: {
                [index]: {
                    row: { $set: item.row },
                    col: { $set: item.col },
                    span: { $set: item.span }
                }
            }
        });
        this.setupColumns(dashboard);
        this.setState({
            appletSetup: undefined,
            dashboard: dashboard
        });
        this.isDirty = true;
    }

    private handlePositionCancel(): void {
        this.setState({ appletSetup: undefined });
    }

    private handleDashboardSave(): void {
        this.updateDashboardRequest(false);
    }

    private handleDashboardChange(value: InputLink[] | Dashboard, type: ChangeType): void {
        if (type === ChangeType.LINKS) {
            const dashboard = this.state.dashboard!;
            const inputLinks = value as InputLink[];
            this.updateLinks(dashboard, inputLinks);
        } else if (type === ChangeType.PROPERTIES) {
            const dashboard = value as Dashboard;
            this.addProperties(dashboard);
        }
        this.isDirty = true;
    }

    private handleAppletRefresh(appletSetup: AppletSetup): void {
        const calculator = this.findCalculator(appletSetup.modelId, appletSetup.version);
        if (calculator) {
            calculator.runQueries();
        }
    }

    private handleAppletsRefresh(): void {
        const calculators = Object.values(this.calculatorMap);
        for (const calculator of calculators) {
            calculator.runQueries();
        }
    }

    private handleAppletRemove(appletSetup: AppletSetup): void {
        this.removeApplet(this.state.dashboard!, appletSetup);
        this.isDirty = true;
    }

    private handleInputsEdit(model: Model): void {
        const modelSetup = this.findModelSetup(this.state.dashboard!, model.id, model.version);
        this.setState({ modelSetup: modelSetup });
    }

    private handleInputsChange(configuration: Configuration): void {
        let modelSetup = this.state.modelSetup!;
        let index = this.state.dashboard!.modelSetups.findIndex(setup =>
            setup.modelId === modelSetup.modelId &&
            setup.version === modelSetup.version
        );
        if (index === -1) {
            return;
        }
        let dashboard = update(this.state.dashboard!, {
            modelSetups: {
                [index]: {
                    configuration: { $set: configuration }
                }
            }
        });
        const models: Model[] = [];
        const key = WidgetUtils.toKey(modelSetup.modelId, modelSetup.version);
        const model = this.modelMap[key];
        models.push(model);

        // Update all other configurations that have links from it.
        const modelSetups = dashboard.modelSetups;
        const entries = Object.entries(configuration);
        for (const [specKey, dataValue] of entries) {
            const key = WidgetUtils.toKey(modelSetup.modelId, modelSetup.version, specKey);
            // Check if the spec has any links.
            const links = this.linkMap[key];
            if (links) {
                for (const link of links) {
                    const [targetModelId, targetVersion, targetSpecKey] = WidgetUtils.parseKey(link);
                    index = modelSetups.findIndex(setup => setup.modelId === targetModelId && setup.version === targetVersion);
                    if (index !== -1) {
                        const targetConfiguration = modelSetups[index].configuration;
                        targetConfiguration[targetSpecKey!] = configuration[specKey];
                        dashboard = update(dashboard, {
                            modelSetups: {
                                [index]: {
                                    configuration: { $set: targetConfiguration }
                                }
                            }
                        });
                    }
                    const key = WidgetUtils.toKey(targetModelId, targetVersion);
                    const model = this.modelMap[key];
                    if (!models.includes(model)) {
                        models.push(model);
                    }
                }
            }
        }
        this.setState({
            dashboard: dashboard,
            modelSetup: undefined
        });
        // Update any model that has had a configuration changed.
        for (const model of models) {
            this.refreshModel(dashboard, model);
        }
        this.isDirty = true;
    }

    private handleInputsCancel(): void {
        this.setState({ modelSetup: undefined });
    }

    private handleExitCheck(location: Location<any>): false | void {
        if (this.state.exitState === "finished") {
            return;
        }
        // Check if navigating away from the dashboard item editor.
        // If so, save the dashboard.
        const dashboardId = this.props.match.params.dashboardId;
        if (location.pathname !== RouteBuilder.dashboard(dashboardId)) {
            if (!this.isDirty) {
                // Nothing needs to be saved.
                return;
            }
            // Store URL to navigate to after save.
            this.nextPathname = location.pathname;
            if (this.state.dashboard!.autoSave) {
                // Auto-save enabled, save dashboard.
                this.updateDashboardRequest(false, true);
            } else {
                // Ask the user if they want to save the dashboard.
                this.setState({ exitState: "started" });
            }
            return false;
        } else {
            // Internal page URL change, not navigating away.
            return;
        }
    }

    private handleExitWithSave(): void {
        this.updateDashboardRequest(false, true);
    }

    private handleExitNoSave(): void {
        this.setState({ exitState: "finished" }, () => {
            this.props.history.push(this.nextPathname!);
        });
    }

    private handleExitCancel(): void {
        this.setState({ exitState: "init" });
    }

    private addApplets(dashboard: Dashboard, appletRefs: AppletRef[]): void {
        // If applets were added to dashboard, get the configurations.
        // Exclude the models that are already loaded.
        const models = Object.values(this.modelMap);
        const excludeRefs = models.map(model => {
            return {
                modelId: model.id,
                version: model.version
            }
        });
        this.loadAppletsRequest(dashboard, appletRefs, excludeRefs);
    }

    private removeApplet(dashboard: Dashboard, removedSetup: AppletSetup): void {
        const needsModel = this.needsModel(dashboard, removedSetup);
        // Model may still be needed if another applet is using it.
        if (!needsModel) {
            const calculator = this.findCalculator(removedSetup.modelId, removedSetup.version);
            calculator.close();
            const model = this.findModel(removedSetup.modelId, removedSetup.version);
            this.removeFromSpecMap(model);
            this.removeFromModelMap(model);
            this.removeFromCalculatorMap(model);
            dashboard = this.removeModelSetup(dashboard, removedSetup);
        }
        dashboard = this.validateLinks(dashboard, dashboard.inputLinks);
        dashboard = this.removeAppletSetup(dashboard, removedSetup);
        this.buildLinkMap(dashboard.inputLinks);
        this.removeFromAppletMap(removedSetup);
        this.setState({ dashboard: dashboard });
    }

    private updateLinks(dashboard: Dashboard, inputLinks: InputLink[]): void {
        const models = Object.values(this.modelMap);
        dashboard = this.setupInputLinks(dashboard, inputLinks, models);
        dashboard = this.setupDefaultVariables(dashboard, models);
        this.buildLinkMap(inputLinks);
        dashboard = update(dashboard, {
            inputLinks: { $set: inputLinks }
        });
        this.setState({ dashboard: dashboard });
        this.refreshModels(dashboard);
    }

    private addProperties(dashboard: Dashboard): void {
        this.setState({ dashboard: dashboard });
    }

    private emptyColumnSetups(): AppletSetup[][] {
        let size = NUMBER_COLUMNS;
        const columnSetups = [];
        while (size--) {
            columnSetups.push([]);
        }
        return columnSetups;
    }

    private stopAutoSave(): void {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    private startAutoSave(): void {
        if (this.state.dashboard?.autoSave && !this.timerId) {
            this.timerId = setTimeout(() => this.updateDashboardRequest(), SAVE_INTERVAL);
        }
    }

    private setupColumns(dashboard: Dashboard, addSetups?: AppletSetup[]): void {
        // Add the applet refs into their respective columns.
        const columnSetups = this.emptyColumnSetups();
        const appletSetups = dashboard.appletSetups;
        for (const appletSetup of appletSetups) {
            // Sanitize data.
            if (CoreUtils.isEmpty(appletSetup.col)) {
                appletSetup.col = 0;
            } else if (appletSetup.col > NUMBER_COLUMNS - 1) {
                appletSetup.col = NUMBER_COLUMNS - 1;
            }
            if (CoreUtils.isEmpty(appletSetup.row)) {
                appletSetup.row = 0;
            } else if (appletSetup.row > NUMBER_ROWS - 1) {
                appletSetup.col = NUMBER_ROWS - 1;
            }
            if (CoreUtils.isEmpty(appletSetup.span)) {
                appletSetup.span = 4;
            } else if (appletSetup.span > columnSetups.length) {
                appletSetup.span = columnSetups.length;
            }
            const col = appletSetup.col;
            columnSetups[col].push(appletSetup);
        }
        // Sort the columns by index.
        for (const columnRef of columnSetups) {
            columnRef.sort((a, b) => a.row - b.row);
        }
        // Shift refs that have the same coordinate.
        for (const columnRef of columnSetups) {
            this.shiftIdenticalLocations(columnRef);
        }
        // Find the maximum row index containing a ref in all the rows.
        let maxRow = 0;
        for (const columnRef of columnSetups) {
            for (const appletRef of columnRef) {
                if (appletRef.row > maxRow) {
                    maxRow = appletRef.row;
                }
            }
        }
        this.setState({
            columnSetups: columnSetups,
            maxRow: maxRow
        });
    }

    private shiftIdenticalLocations(columnSetups: AppletSetup[]): void {
        if (columnSetups.length === 0) {
            return;
        }
        let prev = columnSetups[0].row;
        for (let i = 1; i < columnSetups.length; i++) {
            if (columnSetups[i].row <= prev) {
                columnSetups[i].row = prev + 1;
            }
            prev = columnSetups[i].row;
        }
    }

    private refreshModels(dashboard: Dashboard): void {
        const models = Object.values(this.modelMap);
        for (const model of models) {
            this.refreshModel(dashboard, model);
        }
    }

    private refreshModel(dashboard: Dashboard, model: Model): void {
        const calculator = this.findCalculator(model.id, model.version);
        const modelSetup = this.findModelSetup(dashboard, model.id, model.version);
        const configuration = modelSetup?.configuration;
        this.executeCalculator(calculator, configuration);
    }

    private executeCalculator(calculator: Calculator, configuration: Configuration | undefined): void {
        // Suspend calculator updates while setting parameters.
        calculator.suspend();
        if (configuration) {
            this.overrideParameters(configuration, calculator);
        }
        // Unsuspend and execute with the new parameter values.
        calculator.execute();
    }

    private overrideParameters(configuration: Configuration, calculator: Calculator): void {
        const parameters = calculator.parameters;
        for (const [key, data] of Object.entries(configuration)) {
            if (!CoreUtils.isEmpty(data.value) || data.formula) {
                const parameter = parameters.get(key, false);
                if (parameter) {
                    if (data.formula) {
                        parameter.formula = data.formula;
                    } else {
                        parameter.value = data.value;
                    }
                }
            }
        }
    }

    private initDashboardRequest(dashboardId: string): Promise<any> {
        if (dashboardId === "create") {
            return this.createDashboardRequest();
        } else {
            return this.loadDashboardRequest(dashboardId);
        }
    }

    private createDashboardRequest(): Promise<any> {
        const request = {
            name: "New Dashboard"
        };
        return modelService.createDashboard(request,
            (response: any) => this.createDashboardResponse(response),
            undefined, true
        );
    }

    private createDashboardResponse(response: any): void {
        const dashboard = response.data.dashboard;
        this.setState({ dashboard: dashboard });
        // Change the URL to include the new dashboard id.
        this.props.history.push(RouteBuilder.dashboard(dashboard.id));
    }

    private updateDashboardRequest(isTimer: boolean = true, isExiting: boolean = false): Promise<any> {
        if (this.state.isSaving) {
            return Promise.resolve();
        }
        if (!this.isDirty && !isExiting && isTimer) {
            // Auto-save and no changes, reset the timer.
            //console.log("Not dirty, restarting timer.");
            this.stopAutoSave();
            this.startAutoSave();
            return Promise.resolve();
        }
        // Stop the auto-save if enabled.
        this.stopAutoSave();
        this.setState({ isSaving: true });
        const dashboard = this.state.dashboard!;
        const request = {
            dashboardId: dashboard.id,
            name: dashboard.name,
            description: dashboard.description,
            autoSave: dashboard.autoSave,
            inputLinks: dashboard.inputLinks,
            modelSetups: dashboard.modelSetups,
            appletSetups: dashboard.appletSetups
        };
        return modelService.updateDashboard(request,
            (response: any) => this.updateDashboardResponse(response, isExiting),
            (error: Error) => this.updateDashboardException(error, isExiting),
            true
        );
    }

    private updateDashboardResponse(response: any, isExiting: boolean): void {
        if (!isExiting) {
            const dashboard = response.data.dashboard;
            this.setState({
                dashboard: dashboard,
                isSaving: false
            });
            // Restart the auto-save if enabled.
            this.startAutoSave();
            this.isDirty = false;
        } else {
            // Save is complete, now exit.
            this.setState({ exitState: "finished" });
            this.props.history.push(this.nextPathname!);
        }
    }

    private updateDashboardException(error: Error, isExiting: boolean): void {
        console.log(`Error saving dashboard: ${RestUtils.getError(error)}`);
        if (isExiting) {
            if (this.state.dashboard?.autoSave) {
                // Auto-save failed, need to give the user a way to exit.
                // They can choose to try to save again or exit without saving.
                this.setState({ exitState: "failed" });
            } else {
                // Let the user try again.
                this.setState({ exitState: "init" });
            }
        }
        this.setState({ isSaving: false });
        this.startAutoSave();
        message.error("Error saving dashboard.");
    }

    private loadAppletsRequest(dashboard: Dashboard, appletRefs: AppletRef[], excludeRefs: ModelRef[]): Promise<any> {
        const request = {
            appletRefs: appletRefs,
            excludeRefs: excludeRefs
        };
        return modelService.loadApplets(request,
            (response: any) => this.loadAppletsResponse(response, dashboard),
            (response: any) => this.loadAppletsException(response),
            false
        );
    }

    private loadAppletsResponse(response: any, dashboard: Dashboard): void {
        const models = response.data.models;
        for (const model of models) {
            dashboard = this.addModelSetups(dashboard, models);
            dashboard = this.addAppletSetups(dashboard, model, model.applets)
        }
        dashboard = this.setupApplets(dashboard, models);
        this.setState({
            dashboard: dashboard,
            isAdding: false
        });
        this.isDirty = true;
    }

    private loadAppletsException(response: any): void {
        const message = RestUtils.getError(response);
        this.setState({ addError: new Error(message) });
    }

    private loadDashboardRequest(dashboardId: string): Promise<any> {
        const request = {
            dashboardId: dashboardId,
        };
        return modelService.loadDashboard(request,
            (response: any) => this.loadDashboardResponse(response),
            undefined, true
        );
    }

    private loadDashboardResponse(response: any): void {
        const models = response.data.models;
        let dashboard = response.data.dashboard;
        dashboard = this.setupApplets(dashboard, models);
        this.setState({ dashboard: dashboard });
    }

    private addToSpecMap(models: Model[]): void {
        // Setup the map of all variable specs.
        this.specMap = models.reduce((map: SpecMap, model: Model) => {
            model.variableSpecs.forEach(variableSpec => {
                const key = WidgetUtils.toKey(model.id, model.version, variableSpec.key);
                map[key] = variableSpec;
            });
            return map;
        }, this.specMap);
    }

    private removeFromSpecMap(model: Model): void {
        const variableSpecs = model.variableSpecs;
        for (const variableSpec of variableSpecs) {
            const key = WidgetUtils.toKey(model.id, model.version, variableSpec.key);
            delete this.specMap[key];
            // Remove the original if there is one.
            delete this.origSpecMap[key];
        }
    }

    private buildLinkMap(inputLinks: InputLink[]): void {
        this.linkMap = {};
        for (const inputLink of inputLinks) {
            let inputKey = inputLink.inputKey;
            let linkedKeys = inputLink.linkedKeys;
            this.addLinkPermutation(inputKey, linkedKeys);
            for (let i = 0; i < linkedKeys.length; i++) {
                let swap = linkedKeys[i];
                linkedKeys[i] = inputKey;
                inputKey = swap;
                this.addLinkPermutation(inputKey, linkedKeys);
                swap = linkedKeys[i];
                linkedKeys[i] = inputKey;
                inputKey = swap;
            }
        }
    }

    private addLinkPermutation(inputKey: InputKey, linkedKeys: InputKey[]): void {
        const links = [];
        const key = WidgetUtils.toKey(inputKey.modelId, inputKey.version, inputKey.specKey);
        for (const linkedKey of linkedKeys) {
            const link = WidgetUtils.toKey(linkedKey.modelId, linkedKey.version, linkedKey.specKey);
            links.push(link);
        }
        this.linkMap[key] = links;
    }

    private addToAppletMap(models: Model[]): void {
        // Setup the map of all applets.
        this.appletMap = models.reduce((map: AppletMap, model: Model) => {
            model.applets.forEach(applet => {
                const key = applet.id;
                map[key] = applet;
            });
            return map;
        }, this.appletMap);
    }

    private removeFromAppletMap(appletSetup: AppletSetup): void {
        const key = appletSetup.appletId;
        delete this.appletMap[key];
    }

    private addToCalculatorMap(models: Model[]): void {
        // Setup the map of all calculators.
        this.calculatorMap = models.reduce((map: CalculatorMap, model: Model) => {
            // If no calculator attached, the client already has it (server removed from model).
            if (model.calculator) {
                const key = WidgetUtils.toKey(model.id, model.version);
                const calculator = Calculator.deserialize(model.calculator);
                calculator.httpHeaders = RestUtils.getHttpHeaders();
                // Attach to map.
                map[key] = calculator;
            }
            return map;
        }, this.calculatorMap);
    }

    private removeFromCalculatorMap(model: Model): void {
        const key = WidgetUtils.toKey(model.id, model.version);
        delete this.calculatorMap[key];
    }

    private addToModelMap(models: Model[]): void {
        // Setup the map of all models.
        this.modelMap = models.reduce((map: ModelMap, model: Model) => {
            if (model.calculator) {
                const key = WidgetUtils.toKey(model.id, model.version);
                map[key] = model;
            }
            return map;
        }, this.modelMap);
    }

    private removeFromModelMap(model: Model): void {
        const key = WidgetUtils.toKey(model.id, model.version);
        delete this.modelMap[key];
    }

    private addAppletSetups(dashboard: Dashboard, model: Model, applets: Applet[]): Dashboard {
        let maxRow = this.state.maxRow;
        for (const applet of applets) {
            let appletSetup = this.findAppletSetup(dashboard, applet.id);
            if (!appletSetup) {
                const appletSetup: AppletSetup = {
                    modelId: model.id,
                    version: model.version,
                    appletId: applet.id,
                    col: 0,
                    row: maxRow++,
                    span: applet.span
                }
                dashboard = update(dashboard, {
                    appletSetups: {
                        $push: [appletSetup]
                    }
                });
            }
        }
        return dashboard;
    }

    private removeAppletSetup(dashboard: Dashboard, removedSetup: AppletSetup): Dashboard {
        let appletSetups = dashboard.appletSetups;
        // Remove the widget from the dashboard.
        let index = appletSetups.findIndex(setup =>
            setup.appletId === removedSetup.appletId
        );
        if (index !== -1) {
            dashboard = update(dashboard, {
                appletSetups: {
                    $splice: [[index, 1]]
                }
            });
        }
        return dashboard;
    }

    private removeModelSetup(dashboard: Dashboard, removedSetup: AppletSetup): Dashboard {
        let modelSetups = dashboard.modelSetups;
        // Check if the model is needed anymore.
        // Remove the model from the dashboard.
        const index = modelSetups.findIndex(setup =>
            setup.modelId === removedSetup.modelId &&
            setup.version === removedSetup.version
        );
        if (index !== -1) {
            dashboard = update(dashboard, {
                modelSetups: {
                    $splice: [[index, 1]]
                }
            });
        }
        return dashboard;
    }

    private needsModel(dashboard: Dashboard, removedSetup: AppletSetup): boolean {
        const appletSetups = dashboard.appletSetups;
        let needsModel = false;
        for (const appletSetup of appletSetups) {
            if (appletSetup.appletId === removedSetup.appletId) {
                // This setup is being removed.
                continue;
            }
            if (appletSetup.modelId === removedSetup.modelId && appletSetup.version === removedSetup.version) {
                // The model is still needed by another widget.
                return true;
            }
        }
        return needsModel;
    }

    private setupApplets(dashboard: Dashboard, models: Model[]): Dashboard {
        this.addToSpecMap(models);
        this.addToAppletMap(models);
        this.addToModelMap(models);
        this.addToCalculatorMap(models);
        dashboard = this.setupInputLinks(dashboard, dashboard.inputLinks, models);
        dashboard = this.setupDefaultVariables(dashboard, models);
        this.buildLinkMap(dashboard.inputLinks);
        // Execute any new models.
        for (const model of models) {
            // A model with a calculator is a new one. If the calculator
            // is not attached to the model, it means that it was removed
            // by the server since the client says it already has it.
            if (model.calculator) {
                const key = WidgetUtils.toKey(model.id, model.version);
                const calculator = this.calculatorMap[key];
                const modelSetup = this.findModelSetup(dashboard, model.id, model.version);
                const configuration = modelSetup?.configuration;
                this.executeCalculator(calculator, configuration);
            }
        }
        this.setupColumns(dashboard);
        return dashboard;
    }

    private setupDefaultVariables(dashboard: Dashboard, models: Model[]): Dashboard {
        const modelSetups = dashboard.modelSetups;
        for (const model of models) {
            // Create the configuration if there are specs.
            let index = modelSetups.findIndex(modelSetup =>
                modelSetup.modelId === model.id &&
                modelSetup.version === model.version
            );
            if (index === -1) {
                continue;
            }
            const variables: { [key: string]: boolean } = {};
            const modelSetup = modelSetups[index];
            const configuration = modelSetup.configuration;
            const variableSpecs = model.variableSpecs;
            const calculator = this.findCalculator(model.id, model.version);
            // See if each spec has a configuration value. If not, 
            // use the value in the parameter that is being overridden.
            for (const variableSpec of variableSpecs) {
                const variable = variableSpec.key;
                let data = configuration[variable];
                if (!data) {
                    // There is no variable, get the value from 
                    // the parameter and set as the default.
                    const parameters = calculator.parameters;
                    const parameter = parameters.get(variable, false);
                    if (parameter) {
                        // Direct update since this is a nested structure.
                        data = {
                            type: variableSpec.ioType,
                            value: parameter.value
                        }
                        dashboard = update(dashboard, {
                            modelSetups: {
                                [index]: {
                                    configuration: {
                                        [variable]: { $set: data }
                                    }
                                }
                            }
                        });
                    }
                }
                variables[variable] = true;
            }
            // Remove any configuration values that no longer have a spec.
            for (const variable of Object.keys(configuration)) {
                if (!variables[variable]) {
                    dashboard = update(dashboard, {
                        modelSetups: {
                            [index]: {
                                configuration: {
                                    $unset: [variable]
                                }
                            }
                        }
                    });
                }
            }
        }
        return dashboard;
    }

    private findApplet(appletSetup: AppletSetup): Applet {
        //const key = WidgetUtils.toKey(widgetSetup.modelId, widgetSetup.version, widgetSetup.widgetId)
        const key = appletSetup.appletId
        return this.appletMap[key];
    }

    private findCalculator(modelId: string, version?: number): Calculator {
        const key = WidgetUtils.toKey(modelId, version);
        return this.calculatorMap[key];
    }

    private findModelSetup(dashboard: Dashboard, modelId: string, version?: number): ModelSetup | undefined {
        const modelSetups = dashboard.modelSetups;
        return modelSetups.find(setup =>
            setup.modelId === modelId &&
            setup.version === version
        );
    }

    private findAppletSetup(dashboard: Dashboard, appletId: string): AppletSetup | undefined {
        const appletSetups = dashboard.appletSetups;
        return appletSetups.find(setup =>
            setup.appletId === appletId
        );
    }

    private findVariableSpecs(modelSetup: ModelSetup): VariableSpec[] {
        const key = WidgetUtils.toKey(modelSetup.modelId, modelSetup.version);
        const model = this.modelMap[key];
        return model.variableSpecs ? model.variableSpecs : [];
    }

    private findModel(modelId: string, version?: number): Model {
        const key = WidgetUtils.toKey(modelId, version);
        return this.modelMap[key];
    }

    private findSpec(inputKey: InputKey): VariableSpec {
        const key = WidgetUtils.toKey(inputKey.modelId, inputKey.version, inputKey.specKey);
        return this.specMap[key];
    }

    private setupInputLinks(dashboard: Dashboard, inputLinks: InputLink[], models: Model[]): Dashboard {
        dashboard = this.validateLinks(dashboard, inputLinks);
        dashboard = this.copyLinkedVariables(dashboard, inputLinks, models);
        return dashboard;
    }

    private validateLinks(dashboard: Dashboard, inputLinks: InputLink[]): Dashboard {
        // Remove any links that no longer point to a spec key.
        let i = 0;
        while (i < inputLinks.length) {
            // Check "from" link.
            const inputKey = inputLinks[i].inputKey;
            const spec = this.findSpec(inputKey);
            if (!spec) {
                inputLinks = update(inputLinks, {
                    $splice: [[i, 1]]
                });
            } else {
                // Check "to" links.
                let linkedKeys = inputLinks[i].linkedKeys;
                let j = 0;
                while (j < linkedKeys.length) {
                    const linkedKey = linkedKeys[j];
                    const spec = this.findSpec(linkedKey);
                    if (!spec) {
                        linkedKeys = update(linkedKeys, {
                            $splice: [[j, 1]]
                        });
                    } else {
                        j++;
                    }
                }
                // Check if all linked keys were removed.
                if (linkedKeys.length === 0) {
                    inputLinks = update(inputLinks, {
                        $splice: [[i, 1]]
                    });
                } else {
                    inputLinks = update(inputLinks, {
                        [i]: {
                            linkedKeys: { $set: linkedKeys }
                        }
                    });
                    i++;
                }
            }
        }
        dashboard = update(dashboard, {
            inputLinks: { $set: inputLinks }
        });
        return dashboard;
    }

    private addModelSetups(dashboard: Dashboard, models: Model[]): Dashboard {
        // Fill default variables with parameter values.
        for (const model of models) {
            const variableSpecs = model.variableSpecs;
            if (variableSpecs.length === 0) {
                // No parameters to override.
                continue;
            }
            // Create the configuration if there are specs.
            let modelSetup = this.findModelSetup(dashboard, model.id, model.version);
            // Add a new model setup.
            if (!modelSetup) {
                modelSetup = {
                    modelId: model.id,
                    version: model.version,
                    configuration: {}
                }
                dashboard = update(dashboard, {
                    modelSetups: {
                        $push: [modelSetup]
                    }
                });
            }
        }
        return dashboard;
    }

    private resetSpecs(): void {
        const entries = Object.entries(this.origSpecMap);
        for (const [key, spec] of entries) {
            const [modelId, version, specKey] = WidgetUtils.parseKey(key);
            const modelKey = WidgetUtils.toKey(modelId, version);
            const model = this.modelMap[modelKey];
            const variableSpecs = model.variableSpecs;
            const index = variableSpecs.findIndex(spec => spec.key === specKey);
            if (index !== -1) {
                variableSpecs[index] = spec;
            }
        }
        this.origSpecMap = {};
    }

    private copyLinkedVariables(dashboard: Dashboard, inputLinks: InputLink[], models: Model[]): Dashboard {
        // Set the specs back to the original values.
        this.resetSpecs();
        for (const inputLink of inputLinks) {
            const inputKey = inputLink.inputKey;
            const modelSetup = this.findModelSetup(dashboard, inputKey.modelId, inputKey.version);
            const sourceSpec = this.findSpec(inputKey);
            const sourceConfiguration = modelSetup?.configuration;
            // Find the specs that will be linked from the source.
            // Those specs need to be removed so that the use is
            // not able to set them anymore. Values will come from
            // the "from" link.
            const linkedKeys = inputLink.linkedKeys;
            for (const linkedKey of linkedKeys) {
                let index = dashboard.modelSetups.findIndex(setup =>
                    setup.modelId === linkedKey.modelId &&
                    setup.version === linkedKey.version
                )
                if (index === -1) {
                    continue;
                }
                const modelSetup = dashboard.modelSetups[index];
                const targetSpec = this.findSpec(linkedKey);
                const targetConfiguration = modelSetup?.configuration;
                if (!sourceConfiguration || !targetConfiguration || !sourceSpec || !targetSpec) {
                    continue;
                }
                // Copy the configuration value.
                dashboard = update(dashboard, {
                    modelSetups: {
                        [index]: {
                            configuration: {
                                [targetSpec.key]: { $set: sourceConfiguration[sourceSpec.key] }
                            }
                        }
                    }
                });

                // Save the original spec in case link is removed so it can be restored.
                const origKey = WidgetUtils.toKey(linkedKey.modelId, linkedKey.version, linkedKey.specKey);
                this.origSpecMap[origKey] = targetSpec;

                const key = WidgetUtils.toKey(linkedKey.modelId, linkedKey.version);
                let model = this.modelMap[key];
                index = model.variableSpecs.findIndex(spec => spec.key === linkedKey.specKey);
                if (index === -1) {
                    continue;
                }
                // Make a copy of the spec and transfer original name/description.
                const updatedSpec = CoreUtils.clone(sourceSpec);
                updatedSpec.key = targetSpec.key;
                updatedSpec.name = targetSpec.name;
                updatedSpec.description = targetSpec.description;
                updatedSpec.requirementType = targetSpec.requirementType;
                model = update(model, {
                    variableSpecs: {
                        [index]: { $set: updatedSpec }
                    }
                });
                // Update the models map.
                this.modelMap[key] = model;
            }
        }
        return dashboard;
    }

    private loadData(dashboardId: string): void {
        const requests = [];
        requests.push(this.initDashboardRequest(dashboardId));
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                // Start monitoring URL changes to watch for exit.
                this.unmonitorFn = this.props.history.block(this.handleExitCheck);
                this.startAutoSave();
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-dashboarditem-loading"
                loadingMessage="Loading dashboard..."
                status={isLoading ? "loading" : "failed"}
                onRetry={this.handleRetryLoad}
            />
        )
    }

    private buildAppletView(appletSetup: AppletSetup): ReactElement | undefined {
        const applet = this.findApplet(appletSetup);
        const calculator = this.findCalculator(appletSetup.modelId, appletSetup.version);
        if (!applet || !calculator) {
            // Sanity check. Applet may have been deleted from mode..
            return undefined;
        }
        const items: ItemSpec[] = [{
            icon: <SyncOutlined />,
            label: "Refresh applet",
            onSelect: (appletSetup: AppletSetup) => this.handleAppletRefresh(appletSetup)
        }, {
            icon: <DragOutlined />,
            label: "Position applet",
            onSelect: (appletSetup: AppletSetup) => this.handlePositionEdit(appletSetup)
        }, {
            icon: <CloseOutlined />,
            label: "Remove applet",
            confirm: "Are you sure you want to remove the applet?",
            onSelect: (appletSetup: AppletSetup) => this.handleAppletRemove(appletSetup)
        }];
        const model = this.findModel(appletSetup.modelId, appletSetup.version);
        const modelSetup = this.findModelSetup(this.state.dashboard!, model.id, model.version);

        const extra = (
            <Spacer>
                {model.variableSpecs.length > 0 &&
                    <Button
                        icon={<EditOutlined />}
                        size={Globals.APPLET_MENU_SIZE}
                        shape={Globals.APPLET_MENU_SHAPE}
                        onClick={() => this.handleInputsEdit(model)}
                    />
                }
                <MenuButton
                    items={items}
                    data={appletSetup}
                    size={Globals.APPLET_MENU_SIZE}
                    shape={Globals.APPLET_MENU_SHAPE}
                />
            </Spacer>
        );
        return (
            <AppletViewer
                key={appletSetup.appletId}
                //model={model}
                applet={applet}
                calculator={calculator}
                extra={extra}
                //modelSetup={modelSetup}
                modelId={model.id}
                version={model.version}
                appletConfiguration={modelSetup?.configuration}
                variableSpecs={model.variableSpecs}
            />
        )
    }

    private buildItems(appletSetups: AppletSetup[]): ViewItem[] {
        const viewItems = appletSetups.map(appletSetup => {
            const element = this.buildAppletView(appletSetup);
            if (!element) {
                return undefined;
            }
            return {
                row: appletSetup.row,
                col: appletSetup.col,
                span: appletSetup.span,
                element: element
            }
        });
        // Remove the items that have no applet found.
        return viewItems.filter(item => !!item) as ViewItem[];
    }

    private buildDashboardView(): ReactElement {
        const appletSetups = this.state.dashboard?.appletSetups;
        return (
            <>
                {!this.state.isAdding &&
                    <>
                        <DashboardHeading
                            dashboard={this.state.dashboard!}
                            specMap={this.specMap}
                            modelMap={this.modelMap}
                            isSaving={this.state.isSaving}
                            onAdd={this.handleAppletsShow}
                            onSave={this.handleDashboardSave}
                            onRefresh={this.handleAppletsRefresh}
                            onChange={this.handleDashboardChange}
                        />
                        <div className="x-dashboarditem-applets">
                            {(!appletSetups || appletSetups.length === 0) &&
                                <Empty
                                    className="x-dashboarditem-empty"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <span>No applets added.</span>
                                    }
                                />
                            }
                            {appletSetups && appletSetups.length > 0 &&
                                <ItemLayout items={this.buildItems(appletSetups)} />
                            }
                        </div>
                    </>
                }
                {this.state.isAdding &&
                    <AppletInventory
                        addError={this.state.addError}
                        onInstall={this.handleAppletsInstall}
                        onCancel={this.handleAppletsCancel}
                    />
                }
                {this.state.modelSetup &&
                    <ConfigurationDialog
                        title="Inputs"
                        configuration={this.state.modelSetup.configuration}
                        configurationSpecs={this.findVariableSpecs(this.state.modelSetup)}
                        authentications={[]}
                        onChange={this.handleInputsChange}
                        onCancel={this.handleInputsCancel}
                    />}
                {this.state.dashboard && this.state.appletSetup &&
                    <ItemPosition
                        type="applet"
                        rows={Math.max(Globals.LAYOUT_ROWS, this.state.maxRow + 1)}
                        cols={Globals.LAYOUT_COLUMNS}
                        items={this.state.dashboard.appletSetups}
                        item={this.state.appletSetup}
                        onChange={this.handlePositionChange}
                        onCancel={this.handlePositionCancel}
                    />
                }
                {(this.state.exitState === "failed" || this.state.exitState === "started") &&
                    <Modal
                        centered
                        title="Save Dashboard"
                        visible={true}
                        width={Globals.DIALOG_WIDTH_SHORT}
                        onCancel={this.handleExitCancel}
                        footer={(
                            <>
                                <Button onClick={this.handleExitNoSave}>No</Button>
                                <Button type="primary" onClick={this.handleExitWithSave}>Yes</Button>
                            </>
                        )}
                    >
                        <span>
                            Do you want to save the dashboard before exiting?
                        </span>
                    </Modal>
                }
            </>
        )
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData(this.props.match.params.dashboardId);
        }
    }

    public componentWillUnmount(): void {
        // Stop auto-save.
        this.stopAutoSave();
        // Close calculators.
        const calculators = Object.values(this.calculatorMap);
        for (const calculator of calculators) {
            calculator.close();
        }
        // Stop monitoring URL changes.
        if (this.unmonitorFn) {
            this.unmonitorFn();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildDashboardView();
        }
        return (
            <div className="x-dashboarditem">
                {view}
            </div>
        )
    }

}
