import React, { MouseEvent, PureComponent, ReactElement } from 'react';
import classNames from 'classnames';
import './Clickable.less';

export type ClickCallback = (data: any, event: MouseEvent<HTMLElement>) => void;

export type ClickableProps = typeof Clickable.defaultProps & {
    data?: any,
    cursor: string,
    children: any,
    onClick: ClickCallback
}

/**
 * Simple wrapper component that allows the props data
 * to be passed back in the click method. To use, move
 * the click handler from the wrapped component to the
 * clickable wrapper. Additionally, pass in a data
 * prop into the clickable. This can be any data that
 * will be passed back into the click handler.
 */
export class Clickable extends PureComponent<ClickableProps> {

    static defaultProps = {
        cursor: "pointer"
    }

    constructor(props: ClickableProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    private handleClick(e: MouseEvent<HTMLElement>): void {
        this.props.onClick(this.props.data, e);
    }

    public render(): ReactElement {
        const style = {
            cursor: this.props.cursor
        };
        if (this.props.children && this.props.children.props) {
            // Add a new click handler to the HTML component.
            const className = classNames("x-clickable", this.props.children.props.className);
            return React.cloneElement(this.props.children, { className: className, style: style, onClick: this.handleClick });
        } else {
            // No HTML component, create a div to capture clicks.
            return (
                <div className="x-clickable" style={style} onClick={this.handleClick}>{this.props.children}</div>
            )
        }
    }
}
