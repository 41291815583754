import React, { FocusEvent, PureComponent, ReactElement } from 'react';
import { DatePicker, TimePicker, Radio, Select, Space, RadioChangeEvent } from 'antd';
import { Constants } from 'components/Constants';
import { CoreUtils } from 'utils/CoreUtils';
import { Date, Time } from '@methodset/commons-shared-ts';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import { Spacer } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import moment from 'moment';
import './DateTimeSelector.less';

const YTD_SIMPLE = "ytd";
const YTD_FORMULA = "=ytd()";

const NOW_SIMPLE = "now";
const NOW_FORMULA = "=now()";
const ISOTIME_PATTERN = new RegExp("[0-9]{2}:[0-9]{2}");
const TIME_OFFSET_FORMULA_PATTERN = new RegExp("=timeadd\\(now\\(\\),\\s*'(h|m)',\\s*(\\+|-)?([0-9]+)\\)");
const TIME_OFFSET_SIMPLE_PATTERN = new RegExp("^([0-9]+)([hm])$");

const TODAY_SIMPLE = "today";
const TODAY_FORMULA = "=today()";
const ISODATE_PATTERN = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{2}");
const DATE_ADD_FORMULA_PATTERN = new RegExp("=dateadd\\(today\\(\\),\\s*'(Y|M|D)',\\s*(\\+|-)?([0-9]+)\\)");
const DATE_OFFSET_SIMPLE_PATTERN = new RegExp("^([0-9]+)([ymd])$");

export class DateTimeSelectorUtils {

    private static toUnit(abbrev: string): string {
        switch (abbrev) {
            case "Y":
                return "Year";
            case "M":
                return "Month";
            case "D":
                return "Day";
            case "h":
                return "Hour";
            case "m":
                return "Minute";
            case "s":
                return "Second";
            default:
                return "";
        }
    }

    public static toString(value: any, syntax: SyntaxType = "simple"): string {
        if (CoreUtils.isEmpty(value)) {
            return "";
        } else if (value instanceof Date || value instanceof Time) {
            return value.toIso();
        } else if (typeof value === "string") {
            if (value === NOW_FORMULA || value === NOW_SIMPLE) {
                return "Current Time";
            } else if (value === TODAY_FORMULA || value === TODAY_SIMPLE) {
                return "Current Date";
            } else if (value.match(YTD_SIMPLE) || value.match(YTD_FORMULA)) {
                return "Stat of Year";
            } else if (value.match(ISOTIME_PATTERN) || value.match(ISODATE_PATTERN)) {
                return value;
            } else if (syntax === "formula") {
                let match = value.match(TIME_OFFSET_FORMULA_PATTERN);
                if (!match) {
                    match = value.match(DATE_ADD_FORMULA_PATTERN);
                }
                if (match) {
                    //const sign = match[2];
                    const offset = parseInt(match[3]);
                    let unit = DateTimeSelectorUtils.toUnit(match[1]);
                    if (offset > 1) {
                        unit += "s";
                    }
                    return `Back ${offset} ${unit}`;
                }
            } else if (syntax === "simple") {
                let match = value.match(TIME_OFFSET_SIMPLE_PATTERN);
                if (!match) {
                    match = value.match(DATE_OFFSET_SIMPLE_PATTERN);
                }
                if (match) {
                    //const sign = match[2];
                    const offset = parseInt(match[3]);
                    let unit = DateTimeSelectorUtils.toUnit(match[1]);
                    if (offset > 1) {
                        unit += "s";
                    }

                    return `Back ${offset} ${unit}`;
                }
            }
        }
        return "Invalid value.";
    }

}

interface DateTimeData {
    type?: ValueType | "none",
    absolute?: moment.Moment | null,
    relative?: {
        sign?: string,
        offset?: number,
        unit?: string
    },
    custom?: {}
}

// Return formulas (i.e., =today()) or use the simple date format (i.e., 1d).
export type SyntaxType = "formula" | "simple";
// The date/time selection value options to display.
export type ValueType = "current" | "ytd" | "relative" | "absolute";

export type ChangeCallback = (value: string, type: ValueType) => void;

export type DateTimeSelectorProps = typeof DateTimeSelector.defaultProps & {
    className?: string,
    // The value, either an ISO value, a formula (starting with '='), or a native Date or Time.
    value: string | Date | Time,
    // If set to 'simple', dates that are offsets will use a simple syntax (i.e., =1d).
    // If set to 'function', dates that are offsets will use a function syntax (i.e., =dateadd(today(), 'D', -1)).
    syntax?: SyntaxType,
    // A list of date options to show.
    options?: ValueType[],
    // True to show both date and time, false to show just date.
    showTime?: boolean,
    // Called when the value changes.
    onChange: ChangeCallback
}

export class DateTimeSelector extends PureComponent<DateTimeSelectorProps> {

    static defaultProps = {
        syntax: "simple",
        showTime: false,
        options: ["current", "ytd", "relative", "absolute"]
    }

    private selectRef = React.createRef<any>();
    private data: any;
    private isOpen: boolean = false;

    constructor(props: DateTimeSelectorProps) {
        super(props);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSignChange = this.handleSignChange.bind(this);
        this.handleOffsetChange = this.handleOffsetChange.bind(this);
        this.handleUnitChange = this.handleUnitChange.bind(this);
        this.handlePickerChange = this.handlePickerChange.bind(this);
        this.handleSelectBlur = this.handleSelectBlur.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    // The radio around a select causes it to blur when the select dropdown in
    // opened, causing it to close immediately. Therefore, give it focus again
    // so that the dropdown stays open.
    private handleSelectBlur(e: FocusEvent<HTMLElement>): void {
        if (this.isOpen) {
            this.selectRef.current!.focus();
        }
    }

    private handleDropdownChange(isOpen: boolean): void {
        this.isOpen = isOpen;
    }

    private handleTypeChange(e: RadioChangeEvent): void {
        // If type changed, send default value for that type.
        const type = e.target.value;
        let value: string = "";
        if (this.props.syntax === "formula" && type === "current") {
            value = this.props.showTime ? NOW_FORMULA : TODAY_FORMULA;
        } else if (this.props.syntax === "simple" && type === "current") {
            value = this.props.showTime ? NOW_SIMPLE : TODAY_SIMPLE;
        } else if (this.props.syntax === "formula" && type === "ytd") {
            value = YTD_FORMULA;
        } else if (this.props.syntax === "simple" && type === "ytd") {
            value = YTD_SIMPLE;
        } else if (this.props.syntax === "formula" && type === "relative") {
            value = this.props.showTime ? "=timeadd(now(), 'h', -1)" : "=dateadd(today(), 'D', -1)";
        } else if (this.props.syntax === "simple" && type === "relative") {
            value = this.props.showTime ? "1h" : "1d";
        } else if (type === "absolute") {
            value = this.toIsoDate(moment());
        }
        this.props.onChange(value, type);
    }

    private handlePickerChange(date: moment.Moment | null): void {
        if (date) {
            const value = this.toIsoDate(date);
            this.props.onChange(value, "absolute");
        }
    }

    private handleSignChange(checked: boolean): void {
        const sign = checked ? "-" : "";
        let value: string;
        if (this.props.syntax === "formula") {
            value = this.toDateAdd(sign, this.data.relative.offset, this.data.relative.unit);
        } else if (this.props.syntax === "simple") {
            value = this.toDateOffset(sign, this.data.relative.offset, this.data.relative.unit);
        } else {
            return;
        }
        this.props.onChange(value, "relative");
    }

    private handleOffsetChange(offset: number): void {
        if (!offset) {
            return;
        }
        let value;
        if (this.props.syntax === "formula") {
            value = this.toDateAdd(this.data.relative.sign, offset, this.data.relative.unit);
        } else if (this.props.syntax === "simple") {
            value = this.toDateOffset(this.data.relative.sign, offset, this.data.relative.unit);
        } else {
            return;
        }
        this.props.onChange(value, "relative");
    }

    private handleUnitChange(unit: string): void {
        let value;
        if (this.props.syntax === "formula") {
            value = this.toDateAdd(this.data.relative.sign, this.data.relative.offset, unit);
        } else if (this.props.syntax === "simple") {
            value = this.toDateOffset(this.data.relative.sign, this.data.relative.offset, unit);
        } else {
            return;
        }
        this.props.onChange(value, "relative");
    }

    private toDateAdd(sign: string, offset: number, unit: string): string {
        return this.props.showTime ? `=timeadd(now(), '${unit}', ${sign}${offset})` : `=dateadd(today(), '${unit}', ${sign}${offset})`;
    }

    private toDateOffset(sign: string, offset: number, unit: string): string {
        // Always minus current data/time.
        return `${offset}${unit.toLowerCase()}`
    }

    private parseValue(value: any): DateTimeData {
        const data = {
            type: "none",
            absolute: moment(),
            relative: {
                sign: "-",
                offset: 1,
                unit: this.props.showTime ? "h" : "D"
            },
            custom: {}
        } as DateTimeData;
        if (CoreUtils.isEmpty(value)) {
            data.type = "none";
        } else if (value === NOW_FORMULA || value === NOW_SIMPLE || value === TODAY_FORMULA || value === TODAY_SIMPLE) {
            data.type = "current";
        } else if (value === YTD_FORMULA || value === YTD_SIMPLE) {
            data.type = "ytd";
        } else if (value instanceof Date || value instanceof Time) {
            data.type = "absolute";
            data.absolute = this.toMoment(value.toIso());
        } else if (value && typeof value === "string" && (value.match(ISOTIME_PATTERN) || value.match(ISODATE_PATTERN))) {
            data.type = "absolute";
            data.absolute = this.toMoment(value);
        } else if (this.props.syntax === "formula") {
            const match = value && typeof value === "string" && (value.match(TIME_OFFSET_FORMULA_PATTERN) || value.match(DATE_ADD_FORMULA_PATTERN));
            if (match) {
                data.type = "relative";
                data.relative = {
                    sign: match[2],
                    offset: parseInt(match[3]),
                    unit: match[1]
                }
            }
        } else {
            const match = value && typeof value === "string" && (value.match(TIME_OFFSET_SIMPLE_PATTERN) || value.match(DATE_OFFSET_SIMPLE_PATTERN));
            if (match) {
                data.type = "relative";
                data.relative = {
                    sign: "-",
                    offset: parseInt(match[1]),
                    unit: this.props.showTime ? match[2] : match[2].toUpperCase()
                }
            }
        }
        return data;
    }

    private toIsoDate(moment: moment.Moment): string {
        return this.props.showTime ? moment.format(Constants.TIME_ISO_FORMAT) : moment.format(Constants.DATE_ISO_FORMAT);
    }

    private toMoment(value: string): moment.Moment {
        return this.props.showTime ? moment(value, Constants.TIME_ISO_FORMAT) : moment(value, Constants.DATE_ISO_FORMAT);
    }

    public render(): ReactElement {
        this.data = this.parseValue(this.props.value);
        return (
            <div className={classNames("x-datetimeselector", this.props.className)}>
                <Radio.Group
                    className="x-datetimeselector-radio-group"
                    value={this.data.type}
                    onChange={this.handleTypeChange}
                >
                    <Spacer direction="vertical">
                        {this.props.options.includes("current") &&
                            <Radio
                                className="x-datetimeselector-radio"
                                value="current"
                            >
                                {this.props.showTime ? "Current Time" : "Current Date"}
                            </Radio>
                        }
                        {this.props.options.includes("ytd") && !this.props.showTime &&
                            <Radio
                                className="x-datetimeselector-radio"
                                value="ytd"
                            >
                                Start of Year
                            </Radio>
                        }
                        {this.props.options.includes("relative") &&
                            <Radio
                                className="x-datetimeselector-radio"
                                value="relative"
                            >
                                <Spacer alignment="middle">
                                    <span>{this.props.showTime ? "Now" : "Today"}</span>
                                    <span>&minus;</span>
                                    <IntegerInput
                                        natural={true}
                                        disabled={this.data.type !== "relative"}
                                        value={this.data.relative.offset}
                                        onChange={this.handleOffsetChange}
                                    />
                                    <Select
                                        ref={this.selectRef}
                                        className="x-datetimeselector-unit"
                                        disabled={this.data.type !== "relative"}
                                        value={this.data.relative.unit}
                                        onDropdownVisibleChange={this.handleDropdownChange}
                                        onBlur={this.handleSelectBlur}
                                        onChange={this.handleUnitChange}
                                    >
                                        {this.props.showTime &&
                                            <React.Fragment>
                                                <Select.Option value="h">Hours</Select.Option>
                                                <Select.Option value="m">Minutes</Select.Option>
                                            </React.Fragment>
                                        }
                                        {!this.props.showTime &&
                                            <React.Fragment>
                                                <Select.Option value="Y">Years</Select.Option>
                                                <Select.Option value="M">Months</Select.Option>
                                                <Select.Option value="D">Days</Select.Option>
                                            </React.Fragment>
                                        }
                                    </Select>
                                </Spacer>
                            </Radio>
                        }
                        {this.props.options.includes("absolute") &&
                            <Radio
                                className="x-datetimeselector-radio x-datetimeselector-absolute"
                                value="absolute"
                            >
                                {this.props.showTime &&
                                    <TimePicker
                                        className="x-datetimeselector-picker"
                                        use12Hours
                                        disabled={this.data.type !== "absolute"}
                                        format={Constants.TIME_ISO_FORMAT}
                                        value={this.data.absolute}
                                        onChange={this.handlePickerChange}
                                    />
                                }
                                {!this.props.showTime &&
                                    <DatePicker
                                        className="x-datetimeselector-picker"
                                        disabled={this.data.type !== "absolute"}
                                        format={Constants.DATE_ISO_FORMAT}
                                        value={this.data.absolute}
                                        onChange={this.handlePickerChange}
                                    />
                                }
                            </Radio>
                        }
                    </Spacer>
                </Radio.Group>
            </div>
        )
    }

}
