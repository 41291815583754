import { ReactElement } from 'react';
import { FormInstance } from 'antd';
import { Address, Contacts, Phone } from '@methodset/entity-client-ts';
import { AddressEditor } from '../AddressEditor/AddressEditor';
import { PhoneEditor } from '../PhoneEditor/PhoneEditor';
import update from 'immutability-helper';
import './ContactsEditor.less';

export type ChangeCallback = (contacts: Contacts) => void;

export type ContactsEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    contacts?: Contacts,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    contacts: {}
}

export const ContactsEditor = (props: ContactsEditorProps): ReactElement => {

    const handleAddressChange = (address: Address): void => {
        const contacts = update(props.contacts, {
            address: { $set: address }
        });
        props.onChange(contacts);
    }

    const handlePhoneChange = (phone: Phone): void => {
        const contacts = update(props.contacts, {
            phone: { $set: phone }
        });
        props.onChange(contacts);
    }

    return (
        <>
            <AddressEditor
                formRef={props.formRef}
                id={props.id}
                address={props.contacts.address}
                onChange={(address) => handleAddressChange(address)}
            />
            <PhoneEditor
                formRef={props.formRef}
                id={props.id}
                phone={props.contacts.phone}
                onChange={(phone) => handlePhoneChange(phone)}
            />
        </>
    )
}

ContactsEditor.defaultProps = defaultProps;
