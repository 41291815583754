import { ReferenceParser } from "@methodset/calculator-ts";
import { CartesianWidgetConfiguration, Widget } from '@methodset/model-client-ts';

export class CartesianWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as CartesianWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.xAxis.label));
        refs.push(...ReferenceParser.extract(configuration.yAxis.label));
        for (const dataset of configuration.datasets) {
            refs.push(...ReferenceParser.extract(dataset.name));
            refs.push(...ReferenceParser.extract(dataset.labelRangeId, true));
            refs.push(...ReferenceParser.extract(dataset.valueRangeId, true));
        }
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as CartesianWidgetConfiguration;
        configuration.xAxis.label = ReferenceParser.update(configuration.xAxis.label, target, replacement);
        configuration.yAxis.label = ReferenceParser.update(configuration.yAxis.label, target, replacement);
        for (const dataset of configuration.datasets) {
            dataset.name = ReferenceParser.update(dataset.name, target, replacement);
            dataset.labelRangeId = ReferenceParser.update(dataset.labelRangeId, target, replacement, true);
            dataset.valueRangeId = ReferenceParser.update(dataset.valueRangeId, target, replacement, true);
        }
    }

}
