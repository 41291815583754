import { String } from '@methodset/commons-shared-ts';
import { Calculator, CellComponents, Coordinate, FormulaError, ParameterComponents, Range, RangeComponents, ReferenceParser, RefType } from "@methodset/calculator-ts";
import { Globals } from "constants/Globals";
import { CoreUtils } from "./CoreUtils";

export class WidgetUtils {

    public static toKey(id: string, version: number | undefined, key?: string): string {
        // if (CoreUtils.isEmpty(version)) {
        //     version = Globals.SNAPSHOT_VERSION;
        // }
        return key ? `${id}:${version}:${key}` : `${id}:${version}`;
    }

    public static parseKey(key: string): [string, number | undefined, string | undefined] {
        const parts = key.split(":");
        if (parts.length === 1) {
            return [parts[0], undefined, undefined];
        } else if (parts.length === 2) {
            return [parts[0], parseInt(parts[1]), undefined];
        } else {
            return [parts[0], parseInt(parts[1]), parts[2]];
        }
    }

    public static replaceCellRefs = (calculator: Calculator, text: string | undefined | null): string => {
        if (!text) {
            return "";
        }
        const refs = ReferenceParser.extract(text);
        for (const ref of refs) {
            let cell;
            let value;
            try {
                const element = ReferenceParser.parse(ref);
                if (!element) {
                    value = Globals.ERROR_REF;
                } else {
                    if (element.type === RefType.CELL) {
                        const components = element.components as CellComponents;
                        const sheet = calculator.sheets.get(components.sheetId);
                        cell = sheet.getCell(components.cellId, false);
                    } else if (element.type === RefType.PARAMETER) {
                        const components = element.components as ParameterComponents;
                        cell = calculator.parameters.get(components.variable, false);
                    } else {
                        value = Globals.ERROR_SYN;
                    }
                }
                if (cell) {
                    // Use the formatted value if available.
                    value = cell.formattedValue;
                    if (CoreUtils.isEmpty(value)) {
                        // Fall back to the regular value if no formatted value.
                        value = cell.value;
                    }
                    // Show "not available" if no value.
                    if (CoreUtils.isEmpty(value)) {
                        value = "N/A";
                    }
                } else {
                    value = Globals.ERROR_REF;
                }
            } catch (e) {
                value = Globals.ERROR_REF;
            }
            text = ReferenceParser.replace(text, ref, value);
        }
        return !!text ? text : "";
    }

    public static buildRange = (calculator: Calculator, rangeId: string | undefined, throwIfInvalid: boolean = false): Range | undefined => {
        if (!rangeId) {
            return undefined;
        }
        try {
            const ref = ReferenceParser.parse(rangeId, throwIfInvalid);
            if (ref && ref.type === RefType.RANGE) {
                const components = ref.components as RangeComponents;
                const sheet = calculator.sheets.get(components.sheetId);
                return Range.fromId(sheet, components.rangeId);
            } else {
                return undefined;
            }
        } catch (e) {
            return undefined;
        }
    }

    public static buildVector = (calculator: Calculator, rangeId: string | undefined, index: number, throwIfInvalid: boolean = false): Range | undefined => {
        if (!rangeId) {
            return undefined;
        }
        try {
            // 1-based to 0-based.
            index -= 1;
            const ref = ReferenceParser.parse(rangeId, throwIfInvalid);
            if (ref && ref.type === RefType.RANGE) {
                const components = ref!.components as RangeComponents;
                const sheet = calculator.sheets.get(components.sheetId);
                let upper = Coordinate.fromCellId(components.upperId);
                let lower = Coordinate.fromCellId(components.lowerId);
                if (index < 0 || index > lower.col - upper.col) {
                    return undefined;
                }
                index += upper.col;
                upper = Coordinate.fromRowCol(upper.row, index);
                lower = Coordinate.fromRowCol(lower.row, index);
                return Range.fromCoordinates(sheet, upper, lower);
            }
        } catch (e) {
            return undefined;
        }
    }

    public static findValue = (calculator: Calculator, valueId: string | undefined): any => {
        if (!valueId) {
            return valueId;
        }
        let value;
        try {
            const ref = ReferenceParser.parse(valueId);
            if (ref) {
                if (ref.type === RefType.CELL) {
                    const components = ref.components as CellComponents;
                    const sheet = calculator.sheets.get(components.sheetId);
                    const cell = sheet.getCell(components.cellId, false);
                    if (cell) {
                        value = cell.formattedValue ? cell.formattedValue : cell.value;
                    } else {
                        value = Globals.ERROR_REF;
                    }
                } else if (ref.type === RefType.PARAMETER) {
                    const parameters = calculator.parameters;
                    const components = ref.components as ParameterComponents;
                    const parameter = parameters.get(components.variable, false);
                    if (parameter) {
                        value = parameter.formattedValue ? parameter.formattedValue : parameter.value;
                    } else {
                        value = Globals.ERROR_REF;
                    }
                }
            }
        } catch (e) {
            value = Globals.ERROR_REF;
        }
        if (!CoreUtils.isEmpty(value)) {
            if (FormulaError.isError(value)) {
                const error = value as FormulaError;
                value = error.type;
            } else if (!String.isString(value)) {
                value = value.toString();
            }
        }
        return value;
    }

}
