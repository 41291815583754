import { ReactElement } from 'react';
import { Progress, ProgressConfig } from '@ant-design/plots';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { green, red, grey } from '@ant-design/colors';
import { CoreUtils } from 'utils/CoreUtils';
import './ValueDisplay.less';

export type ValueType = "sign" | "direction" | "percent";

export type ValueDisplayProps = {
    value?: number | string,
    type?: ValueType
} & typeof defaultProps;

const defaultProps = {
    type: "sign"
}

export const ValueDisplay = (props: ValueDisplayProps): ReactElement => {

    const isPositive = (value: any): boolean => {
        if (CoreUtils.isNumber(value)) {
            return value >= 0;
        } else if (CoreUtils.isString(value)) {
            return value.startsWith("-") ? false : true;
        } else {
            return true;
        }
    }

    const formattedValue = (value: any): string | undefined => {
        if (CoreUtils.isEmpty(value)) {
            return undefined;
        } else if (!CoreUtils.isString(value)) {
            value = value.toString();
        }
        return value;
    }

    const percentValue = (value: any): number | undefined => {
        if (CoreUtils.isEmpty(value)) {
            return undefined;
        } else if (CoreUtils.isString(value) && value.endsWith("%")) {
            value = value.substring(0, value.length - 1);
            value = parseFloat(value);
            if (Number.isNaN(value)) {
                return undefined;
            }
            value /= 100.0;
        } else if (!CoreUtils.isNumber(value)) {
            return undefined;
        }
        return value >= 0 ? value : -value;
    }

    const signView = (): ReactElement => {
        const positive = isPositive(props.value);
        let value = formattedValue(props.value);
        if (positive) {
            value = `+${value}`;
        }
        return (
            <div>
                {!CoreUtils.isEmpty(value) && positive &&
                    <span className="x-valuedisplay-positive">
                        {value}
                    </span>
                }
                {!CoreUtils.isEmpty(value) && !positive &&
                    <span className="x-valuedisplay-negative">
                        {value}
                    </span>
                }
            </div>
        );
    }

    const directionView = (): ReactElement => {
        const positive = isPositive(props.value);
        const value = formattedValue(props.value);
        return (
            <div className="x-valuedisplay-arrow">
                {!CoreUtils.isEmpty(value) && positive &&
                    <span className="x-valuedisplay-positive">
                        <ArrowUpOutlined />
                        {value}
                    </span>
                }
                {!CoreUtils.isEmpty(value) && !positive &&
                    <span className="x-valuedisplay-negative">
                        <ArrowDownOutlined />
                        {value}
                    </span>
                }
            </div>
        );
    }

    const percentView = (): ReactElement => {
        const positive = isPositive(props.value);
        const value = percentValue(props.value);
        const config = {
            width: 100,
            height: 40,
            percent: value,
            color: [positive ? green[7] : red[7], grey[2]],
        } as ProgressConfig;
        return <Progress {...config} />;
    }

    const buildView = (): ReactElement => {
        if (props.type === "sign") {
            return signView();
        } else if (props.type === "direction") {
            return directionView();
        } else if (props.type === "percent") {
            return percentView();
        } else {
            return <>{props.value}</>
        }
    }

    return (
        <div className="x-valuedisplay">
            {buildView()}
        </div>
    );
}

ValueDisplay.defaultProps = defaultProps;
