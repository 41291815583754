import React, { ChangeEvent, Component, ReactElement } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Empty, FormInstance, Input, List, Row, Switch } from 'antd';
import { Clickable } from 'components/Clickable/Clickable';
import { FormItem, ValidateStatus } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { CartesianDataset, CartesianWidgetConfiguration, ChartAxis, ChartDataset, WidgetType } from '@methodset/model-client-ts';
import { Calculator, Range, Vector, RefType, ReferenceParser, RangeComponents } from '@methodset/calculator-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { Justify } from 'components/Justify/Justify';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import update from 'immutability-helper';
import './CartesianEditor.less';

type FormError = {
    validateStatus: ValidateStatus,
    help: string
};

export type EditCallback = (isEditing: boolean) => void;
export type ChangeCallback = (configuration: CartesianWidgetConfiguration, isEditing?: boolean) => void;

export type CartesianEditorProps = typeof CartesianEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    type?: WidgetType,
    extra: ReactElement,
    configuration?: CartesianWidgetConfiguration,
    calculator: Calculator,
    onEdit?: EditCallback,
    onChange: ChangeCallback
}

export type CartesianEditorState = {
    editIndex?: number,
    editDataset?: ChartDataset,
    nameError?: FormError
    labelError?: FormError,
    valueError?: FormError,
}

export class CartesianEditor extends Component<CartesianEditorProps, CartesianEditorState> {

    static DefaultConfiguration = {
        type: 'LINE_CHART',  // default
        height: 300,
        xAxis: {} as ChartAxis,
        yAxis: {} as ChartAxis,
        datasets: [] as ChartDataset[]
    } as CartesianWidgetConfiguration;

    static defaultProps = {
        configuration: CartesianEditor.DefaultConfiguration
    }

    constructor(props: CartesianEditorProps) {
        super(props);
        this.state = {
            editIndex: undefined,
            editDataset: undefined,
        }
        this.handleXLabelChange = this.handleXLabelChange.bind(this);
        this.handleYLabelChange = this.handleYLabelChange.bind(this);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleHeadersChange = this.handleHeadersChange.bind(this);
        this.handleDatasetSave = this.handleDatasetSave.bind(this);
        this.handleDatasetAdd = this.handleDatasetAdd.bind(this);
        this.handleDatasetEdit = this.handleDatasetEdit.bind(this);
        this.handleDatasetCancel = this.handleDatasetCancel.bind(this);
        this.handleDatasetRemove = this.handleDatasetRemove.bind(this);
    }

    private handleXLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const configuration = update(this.props.configuration, {
            xAxis: {
                label: { $set: label }
            }
        });
        this.props.onChange(configuration);
    }

    private handleYLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const configuration = update(this.props.configuration, {
            yAxis: {
                label: { $set: label }
            }
        });
        this.props.onChange(configuration);
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const editDataset = update(this.state.editDataset, {
            name: { $set: name }
        });
        this.setState({
            editDataset: editDataset,
            nameError: undefined
        });
    }

    private handleHeadersChange(valueHeaders: boolean): void {
        const editDataset = update(this.state.editDataset, {
            valueHeaders: { $set: valueHeaders }
        });
        this.setState({ editDataset: editDataset });
    }

    private handleLabelChange(labelRangeId: string | undefined): void {
        const editDataset = update(this.state.editDataset, {
            labelRangeId: { $set: labelRangeId! }
        });
        this.setState({
            editDataset: editDataset,
            labelError: undefined
        });
    }

    private handleValueChange(valueRangeId: string | undefined): void {
        const editDataset = update(this.state.editDataset, {
            valueRangeId: { $set: valueRangeId! }
        });
        this.setState({
            editDataset: editDataset,
            valueError: undefined
        });
    }

    private handleDatasetSave(): void {
        let nameError: FormError | undefined = undefined;
        let labelError: FormError | undefined = undefined;
        let valueError: FormError | undefined = undefined;
        const dataset = this.state.editDataset!;

        if (!dataset.name) {
            nameError = {
                validateStatus: "error",
                help: "Please enter a dataset name."
            }
        }

        let labelDimension;
        let labelRange: Range | undefined = undefined;
        const labelRef = ReferenceParser.parse(dataset.labelRangeId);
        if (labelRef) {
            const components = labelRef.components as RangeComponents;
            if (!components.sheetId) {
                labelError = {
                    validateStatus: "error",
                    help: "Please select a label sheet."
                }
            } else {
                const sheet = this.props.calculator.sheets.get(components.sheetId, false);
                if (!sheet) {
                    labelError = {
                        validateStatus: "error",
                        help: "Label sheet does not exist."
                    }
                } else {
                    try {
                        labelRange = Range.fromId(sheet, components.rangeId);
                        labelDimension = Vector.findDimension(labelRange);
                    } catch (e) {
                        labelError = {
                            validateStatus: "error",
                            help: "Invalid range specification."
                        }
                    }
                }
            }
        } else {
            labelError = {
                validateStatus: "error",
                help: "Please enter a complete label range."
            }
        }
        // let valueDimension;
        let valueRange: Range | undefined = undefined;
        const valueRef = ReferenceParser.parse(dataset.valueRangeId);
        if (valueRef) {
            const components = valueRef.components as RangeComponents;
            if (!components.sheetId) {
                valueError = {
                    validateStatus: "error",
                    help: "Please select a value sheet."
                }
            } else {
                const sheet = this.props.calculator.sheets.get(components.sheetId, false);
                if (!sheet) {
                    valueError = {
                        validateStatus: "error",
                        help: "Value sheet does not exist."
                    }
                } else {
                    try {
                        valueRange = Range.fromId(sheet, components.rangeId);
                        //valueDimension = Vector.findDimension(valueRange);
                    } catch (e) {
                        valueError = {
                            validateStatus: "error",
                            help: "Invalid range specification."
                        }
                    }
                }
            }
        } else {
            valueError = {
                validateStatus: "error",
                help: "Please enter a valid value range."
            }
        }

        // if (!valueError && labelDimension !== valueDimension) {
        //     valueError = {
        //         validateStatus: "error",
        //         help: "Label and value ranges must both be rows or columns."
        //     }
        // }

        // if (!valueError && labelDimension === DimensionType.ROW &&
        //     (labelRange?.upper.col !== valueRange?.upper.col || labelRange?.lower.col !== valueRange?.lower.col)) {
        //     valueError = {
        //         validateStatus: "error",
        //         help: "Label and value row ranges are not aligned."
        //     }
        // } else if (!valueError && labelDimension === DimensionType.COLUMN &&
        //     (labelRange?.upper.row !== valueRange?.upper.row || labelRange?.lower.row !== valueRange?.lower.row)) {
        //     valueError = {
        //         validateStatus: "error",
        //         help: "Label and value column ranges are not aligned."
        //     }
        // }

        if (nameError || labelError || valueError) {
            this.setState({
                nameError: nameError,
                labelError: labelError,
                valueError: valueError
            });
            return;
        }

        let configuration;
        if (this.state.editIndex === -1) {
            // Add a new dataset.
            configuration = update(this.props.configuration, {
                datasets: {
                    $push: [this.state.editDataset!]
                }
            });
        } else {
            // Update an existing dataset.
            configuration = update(this.props.configuration, {
                datasets: {
                    [this.state.editIndex!]: { $set: this.state.editDataset! }
                }
            });
        }
        this.setState({
            editIndex: undefined,
            editDataset: undefined
        });
        this.props.onChange(configuration, false);
    }

    private handleDatasetAdd(): void {
        const dataset: ChartDataset = {
            name: undefined as any,
            labelRangeId: undefined as any,
            valueHeaders: false,
            valueRangeId: undefined as any
        }
        this.setState({
            editIndex: -1,
            editDataset: dataset,
            nameError: undefined,
            labelError: undefined,
            valueError: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleDatasetEdit(index: number): void {
        const dataset = this.props.configuration.datasets[index];
        this.setState({
            editIndex: index,
            editDataset: dataset,
            nameError: undefined,
            labelError: undefined,
            valueError: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleDatasetRemove(index: number): void {
        const configuration = update(this.props.configuration, {
            datasets: {
                $splice: [[index, 1]]
            }
        });
        this.setState({ editIndex: -1 });
        this.props.onChange(configuration);
    }

    private handleDatasetCancel(): void {
        this.setState({
            editIndex: undefined,
            editDataset: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    public componentDidMount(): void {
        if (!this.props.type) {
            this.props.onChange(this.props.configuration);
        }
    }

    public shouldComponentUpdate(nextProps: Readonly<CartesianEditorProps>): boolean {
        if (nextProps.type && nextProps.type !== this.props.type) {
            const configuration = update(nextProps.configuration, {
                type: { $set: nextProps.type }
            });
            this.props.onChange(configuration);
            return false;
        }
        return true;
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.extra}
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="X-Axis Label"
                        name="x-axis-label"
                        info="The label for the x-axis"
                        // rules={[{
                        //     required: true,
                        //     message: 'Please enter an x-axis label.'
                        // }]}
                    >
                        <Input
                            placeholder="Enter the x-axis label."
                            value={this.props.configuration.xAxis.label}
                            onChange={this.handleXLabelChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Y-Axis Label"
                        name="y-axis-label"
                        info="The label for the y-axis"
                        // rules={[{
                        //     required: true,
                        //     message: 'Please enter a y-axis label.'
                        // }]}
                    >
                        <Input
                            placeholder="Enter the y-axis label."
                            value={this.props.configuration.yAxis.label}
                            onChange={this.handleYLabelChange}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    {!this.state.editDataset &&
                        <>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Datasets"
                                name="datasets"
                                required={true}
                                info="The datasets to use to generate the chart."
                                rules={[{
                                    required: true,
                                    validator: (rule: any, dataset: CartesianDataset) => {
                                        return this.props.configuration.datasets.length === 0 ?
                                            Promise.reject('Please configure at least one dataset.') :
                                            Promise.resolve();
                                    }
                                }]}
                            >
                                <List
                                    className="x-cartesianeditor-dataset-list"
                                    itemLayout="horizontal"
                                >
                                    {this.props.configuration.datasets.length === 0 &&
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description={<span>No datasets.</span>}
                                        />
                                    }
                                    {this.props.configuration.datasets.length > 0 &&
                                        this.props.configuration.datasets.map((dataset, index) => (
                                            <List.Item
                                                className="x-cartesianeditor-dataset-item"
                                                key={index}
                                            >
                                                <span className="x-cartesianeditor-dataset-name">{dataset.name}</span>
                                                <Spacer justification="right">
                                                    <Clickable data={index} onClick={this.handleDatasetEdit}>
                                                        <EditOutlined />
                                                    </Clickable>
                                                    <Clickable data={index} onClick={this.handleDatasetRemove}>
                                                        <DeleteOutlined />
                                                    </Clickable>
                                                </Spacer>
                                            </List.Item>
                                        ))}
                                </List>
                            </FormItem>
                            <Justify justification="right">
                                <Button onClick={this.handleDatasetAdd}>
                                    Add Dataset
                                </Button>
                            </Justify>
                        </>
                    }
                    {this.state.editDataset &&
                        <>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Dataset Name"
                                name="name"
                                required={true}
                                validateStatus={this.state.nameError?.validateStatus}
                                help={this.state.nameError?.help}
                            >
                                <Input
                                    placeholder="Enter a dataset name."
                                    value={this.state.editDataset.name}
                                    onChange={this.handleNameChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                required={true}
                                label="Label Range"
                                name="label"
                                info="The range for the labels. Must be one dimensional range, i.e., a column or row."
                                validateStatus={this.state.labelError?.validateStatus}
                                help={this.state.labelError?.help}
                            >
                                <RefEditor
                                    formRef={this.props.formRef}
                                    index={0}
                                    //noError={true}
                                    calculator={this.props.calculator}
                                    refTypes={[RefType.RANGE]}
                                    refId={this.state.editDataset.labelRangeId}
                                    onChange={this.handleLabelChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                required={true}
                                label="Value Range"
                                name="value"
                                info="The range for the values. Must be one dimensional range, i.e., a column or row."
                                validateStatus={this.state.valueError?.validateStatus}
                                help={this.state.valueError?.help}
                            >
                                <RefEditor
                                    formRef={this.props.formRef}
                                    index={1}
                                    //noError={true}
                                    calculator={this.props.calculator}
                                    refTypes={[RefType.RANGE]}
                                    refId={this.state.editDataset.valueRangeId}
                                    onChange={this.handleValueChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Includes Value Headers"
                                name="headers"
                                info="True if the value range has header values in the first row or column. These will be used to label the value datasets."
                                valuePropName="checked"
                                required={true}
                            >
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    checked={this.state.editDataset.valueHeaders}
                                    onChange={this.handleHeadersChange}
                                />
                            </FormItem>
                            <Justify className="x-cartesianeditor-action" justification="right">
                                <Spacer>
                                    <Button onClick={this.handleDatasetCancel}>Cancel Edit</Button>
                                    <Button type="primary" onClick={this.handleDatasetSave}>Save Dataset</Button>
                                </Spacer>
                            </Justify>
                        </>
                    }
                </Col>
            </Row>
        );
    }

}
