import { ReactElement } from 'react';
import { Button } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { DeleteOutlined } from '@ant-design/icons';
import { Applet, Widget } from '@methodset/model-client-ts';
import { WidgetSelector } from 'containers/Components/Widgets/Selectors/WidgetSelector';
import './WidgetSelectorEditor.less';

export type ChangeCallback = (widgetId: string, index: number) => void;
export type RemoveCallback = (index: number) => void;

export type WidgetSelectorEditorProps = {
    index: number,
    applet: Applet,
    widget?: Widget,
    widgetId?: string,
    onChange: ChangeCallback,
    onRemove: RemoveCallback
}

export const WidgetSelectorEditor = (props: WidgetSelectorEditorProps): ReactElement => {

    const handleWidgetChange = (widgetId: string): void => {
        props.onChange(widgetId, props.index);
    }

    const handleWidgetRemove = (): void => {
        props.onRemove(props.index);
    }

    return (
        <Spacer fill>
            <WidgetSelector
                applet={props.applet}
                widget={props.widget}
                widgetId={props.widgetId}
                onChange={handleWidgetChange}
            />
            <Button
                icon={<DeleteOutlined />}
                onClick={handleWidgetRemove}
            >
            </Button>
        </Spacer>
    );

}
