import { Calculator } from '@methodset/calculator-ts';
import { ReactElement } from 'react';
import { SelectType, SelectWidgetConfiguration } from '@methodset/model-client-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import { Radio, Select } from 'antd';
import { Align } from 'components/Align/Align';
import { Label } from 'components/Label/Label';
import { CoreUtils } from 'utils/CoreUtils';
import './SelectWidgetViewer.less';
import { Justification, Justify } from 'components/Justify/Justify';

export type SelectWidgetViewerProps = {
    calculator: Calculator,
    configuration: SelectWidgetConfiguration
};

export const SelectWidgetViewer = (props: SelectWidgetViewerProps): ReactElement => {

    const handleOptionChange = (value: any): void => {
        const parameters = props.calculator.parameters;
        const parameter = parameters.get(props.configuration.variable, false);
        if (parameter) {
            parameter.value = value;
        }
    }

    const buildDropdown = (value: any): ReactElement => {
        return (
            <Select className="x-selectwidgetviewer-dropdown" value={value} onChange={(value) => handleOptionChange(value)}>
                {props.configuration.options.map(option => (
                    < Select.Option key={option.value} value={option.value} > {option.label}</Select.Option >
                ))}
            </Select >
        )
    }

    const buildRadio = (value: any): ReactElement => {
        return (
            <Radio.Group value={value} onChange={(e) => handleOptionChange(e.target.value)}>
                {props.configuration.options.map(option => (
                    <Radio key={option.value} value={option.value}>
                        {option.label}
                    </Radio>
                ))}
            </Radio.Group>
        )
    }

    const buildButtons = (value: any): ReactElement => {
        return (
            <Radio.Group className="x-selectwidgetviewer-buttons" buttonStyle="solid" value={value} onChange={(e) => handleOptionChange(e.target.value)}>
                {props.configuration.options.map(option => (
                    <Radio.Button key={option.value} value={option.value}>
                        {option.label}
                    </Radio.Button>
                ))}
            </Radio.Group>
        )
    }

    const buildView = (value: any): ReactElement => {
        if (props.configuration.layout === SelectType.DROPDOWN) {
            return buildDropdown(value);
        } else if (props.configuration.layout === SelectType.RADIO) {
            return buildRadio(value);
        } else if (props.configuration.layout === SelectType.BUTTONS) {
            return buildButtons(value);
        } else {
            return <div>Invalid select layout.</div>
        }
    }

    const parameterValue = (): any => {
        const parameters = props.calculator.parameters;
        const parameter = parameters.get(props.configuration.variable, false);
        if (!parameter) {
            return undefined;
        }
        const value = parameter.value;
        return !CoreUtils.isEmpty(value) ? value.toString() : undefined;
    }

    const value = parameterValue();
    const label = WidgetUtils.replaceCellRefs(props.calculator, props.configuration.label);
    const justification = props.configuration.justification ?
        props.configuration.justification.toLowerCase() as Justification :
        undefined;

    return (
        <Justify justification={justification}>
            <Align fill={props.configuration.layout === SelectType.DROPDOWN} alignment="center">
                <Label
                    fill={true}
                    placement="left"
                    label={label}
                >
                    {buildView(value)}
                </Label>
            </Align>
        </Justify>
    );

}
