import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { Button, FormInstance } from 'antd';
import { Address } from '@methodset/entity-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { FormDivider } from 'components/FormDivider/FormDivider';
import { AddressEditor } from '../AddressEditor/AddressEditor';
import update from 'immutability-helper';
import './RealEstateEditor.less';

export type ChangeCallback = (realEstate: Address[]) => void;

export type RealEstateEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    realEstate?: Address[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    realEstate: [] as Address[]
}

export const RealEstateEditor = (props: RealEstateEditorProps): ReactElement => {

    const handleAddressAdd = (): void => {
        const address = {} as Address;
        const realEstate = update(props.realEstate, {
            $push: [address]
        });
        props.onChange(realEstate);
    }

    const handleAddressRemove = (index: number): void => {
        const realEstate = update(props.realEstate, {
            $splice: [[index, 1]]
        });
        props.onChange(realEstate);
    }

    const handleAddressChange = (address: Address, index: number): void => {
        const realEstate = update(props.realEstate, {
            [index]: { $set: address }
        });
        props.onChange(realEstate);
    }

    return (
        <Spacer direction="vertical">
            {props.realEstate.map((address, index) => (
                <div key={index}>
                    <FormDivider
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        label={`Address ${index + 1}`}
                        fill={true}
                        bold={true}
                        colon={false}
                        inline={true}
                        onDelete={() => handleAddressRemove(index)}
                    />
                    <AddressEditor
                        key={index}
                        formRef={props.formRef}
                        id={`real-estate-${index + 1}`}
                        address={address}
                        onChange={(address) => handleAddressChange(address, index)}
                    />
                </div>
            ))}
            <Button onClick={() => handleAddressAdd()}>Add Address</Button>
        </Spacer>
    )
}

RealEstateEditor.defaultProps = defaultProps;
