import React, { PureComponent, ReactElement } from 'react';
import { Form, FormInstance } from 'antd';
import { Input } from 'antd';
import { Modal } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { ModelContext } from 'context/ModelContext';
import './NameEditor.less';
import { Globals } from 'constants/Globals';

export type ChangeCallback = (name: string) => void;
export type CancelCallback = () => void;

export type NameEditorState = {
    name: string
}

export type NameEditorProps = {
    name: string,
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export class NameEditor extends PureComponent<NameEditorProps, NameEditorState> {

    static contextType = ModelContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: NameEditorProps) {
        super(props);
        this.state = {
            name: this.props.name
        };
        this.handleOk = this.handleOk.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleNameChange(e: any): void {
        const name = e.target.value;
        this.setState({name: name});
    }

    private handleOk(): void {
        this.formRef.current?.submit();
    }

    private handleFormFinish(): void {
        this.props.onChange(this.state.name);
    }

    public render(): ReactElement {
        return (
            <Modal
                className='x-nameeditor'
                width={Globals.DIALOG_WIDTH_SHORT}
                title="Change Name"
                onOk={this.handleOk}
                onCancel={this.props.onCancel}
                visible={true}
            >
                <Form ref={this.formRef} onFinish={this.handleFormFinish}>
                    <FormItem
                        formRef={this.formRef}
                        name="name"
                        rules={[{
                            required: true,
                            message: 'Please enter a sheet name.'
                        },{
                            validator: (rule: any, sheetId: string) => {
                                if (this.props.name === sheetId || !this.context.calculator.sheets.has(sheetId)) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject("Sheet name is already in use.");
                                }
                            }
                        }]}
                    >
                        <Input
                            id="sheet-name"
                            placeholder="Please enter a sheet name."
                            value={this.state.name}
                            onChange={this.handleNameChange}
                        />
                    </FormItem>
                </Form>
            </Modal>
        );
    }

}
