import React, { PureComponent, ReactElement } from 'react';
import { FormInstance } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { ChangeListWidgetConfiguration, WidgetType } from '@methodset/model-client-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import update from 'immutability-helper';
import './ChangeListWidgetEditor.less';

export type ChangeCallback = (configuration: ChangeListWidgetConfiguration) => void;

export type ChangeListWidgetEditorProps = typeof ChangeListWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    configuration?: ChangeListWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class ChangeListWidgetEditor extends PureComponent<ChangeListWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.CHANGE_LIST
    }

    static defaultProps = {
        configuration: ChangeListWidgetEditor.DefaultConfiguration
    }

    constructor(props: ChangeListWidgetEditorProps) {
        super(props);
        // this.handleTextChange = this.handleTextChange.bind(this);
        // this.handleSubtextChange = this.handleSubtextChange.bind(this);
        // this.handleValueChange = this.handleValueChange.bind(this);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleChangeChange = this.handleChangeChange.bind(this);
    }

    // private handleTextChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    //     const text = e.target.value;
    //     const configuration = update(this.props.configuration, {
    //         text: { $set: text }
    //     });
    //     this.props.onChange(configuration);
    // }

    // private handleSubtextChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    //     const subtext = e.target.value;
    //     const configuration = update(this.props.configuration, {
    //         subtext: { $set: subtext }
    //     });
    //     this.props.onChange(configuration);
    // }

    // private handleValueChange(valueId: string | undefined): void {
    //     const configuration = update(this.props.configuration, {
    //         valueId: { $set: valueId as any }
    //     });
    //     this.props.onChange(configuration);
    // }

    private handleLabelChange(labelRangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            labelRangeId: { $set: labelRangeId! }
        });
        this.props.onChange(configuration);
    }

    private handleChangeChange(changeRangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            changeRangeId: { $set: changeRangeId! }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === ChangeListWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <>
                {this.props.extra}
                {/* <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Text"
                    name="text"
                    info="Text to add to the widget."
                >
                    <Input.TextArea
                        rows={3}
                        value={this.props.configuration.text}
                        onChange={this.handleTextChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Subtext"
                    name="subtext"
                    info="Subtext to add to the widget."
                >
                    <Input.TextArea
                        rows={3}
                        value={this.props.configuration.subtext}
                        onChange={this.handleSubtextChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    required={true}
                    noError={true}
                    label="Latest Value"
                    name="value"
                    info="The cell or parameter that holds the latest value."
                >
                    <RefEditor
                        formRef={this.props.formRef}
                        index={0}
                        calculator={this.props.calculator}
                        refTypes={[RefType.CELL, RefType.PARAMETER]}
                        refId={this.props.configuration.valueId}
                        onChange={this.handleValueChange}
                    />
                </FormItem> */}
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    required={true}
                    //noError={true}
                    label="Label Range"
                    name="labels"
                    info="The range that contains the label values. The range must be a row or column."
                >
                    <RefEditor
                        formRef={this.props.formRef}
                        index={0}
                        vector={true}
                        calculator={this.props.calculator}
                        refTypes={[RefType.RANGE]}
                        refId={this.props.configuration.labelRangeId}
                        onChange={this.handleLabelChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    required={true}
                    //noError={true}
                    label="Change Range"
                    name="changes"
                    info="The range that contains the change values. The range must be a row or column."
                >
                    <RefEditor
                        formRef={this.props.formRef}
                        index={1}
                        vector={true}
                        calculator={this.props.calculator}
                        refTypes={[RefType.RANGE]}
                        refId={this.props.configuration.changeRangeId}
                        onChange={this.handleChangeChange}
                    />
                </FormItem>
            </>
        );
    }

}
