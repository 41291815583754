import { PureComponent } from 'react';
import { Button, Collapse } from 'antd';
import { Layout } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { Parameters } from './Parameters/Parameters';
import { Sheets } from './Sheets/Sheets';
import { ModelContext } from 'context/ModelContext';
import { FormulaEditor } from './FormulaEditor/FormulaEditor';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Tip } from 'components/Tip/Tip';
import { Queries } from './Queries/Queries';
import { Pivots } from './Pivots/Pivots';
import { Spacer } from 'components/Spacer/Spacer';
import { CellTracker } from './CellTracker/CellTracker';
import classNames from 'classnames';
import './ModelCalculator.less';

export type ModelCalculatorProps = {
    className?: string
};

export type ModelCalculatorState = {
    showSidebar: boolean,
    formulaEditor?: FormulaEditor,
    sidebarHeight: number,
}

export class ModelCalculator extends PureComponent<ModelCalculatorProps, ModelCalculatorState> {

    static contextType = ModelContext;

    private sidebarElement?: Element | null;

    constructor(props: ModelCalculatorProps) {
        super(props);
        this.state = {
            showSidebar: true,
            sidebarHeight: 0
        }
        this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
        this.handleSizeChange = this.handleSizeChange.bind(this);
    }

    private handleSidebarToggle(): void {
        this.setState({ showSidebar: !this.state.showSidebar });
    }

    private handleSizeChange(): void {
        if (!this.sidebarElement) {
            this.sidebarElement = document.getElementById("modeleditor-sidebar");
        }
        let sidebarHeight = 300; // default if can't find element (error)
        if (this.sidebarElement) {
            let clientRect = this.sidebarElement.getBoundingClientRect();
            sidebarHeight = window.innerHeight - clientRect.top - 10;  // padding
        }
        this.setState({ sidebarHeight: sidebarHeight });
    }

    componentDidMount(): void {
        window.addEventListener("resize", this.handleSizeChange);
        this.handleSizeChange();
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleSizeChange);
    }

    public render() {
        if (!this.context.calculator) {
            return (
                <div className="x-modelcalculator-message">
                    <div>No model loaded.</div>
                </div>
            )
        } else {
            return (
                <div className={classNames('x-modelcalculator', this.props.className)}>
                    <CSSTransition
                        in={this.state.showSidebar}
                        timeout={500}
                        classNames="x-modelcalculator-sidebar-slider"
                    >
                        <Layout className="x-modelcalculator-sidebar-slider" hasSider={true}>
                            <Layout.Content className="x-modelcalculator-sheets">
                                <Spacer alignment="middle" className="x-modelcalculator-header">
                                    <FormulaEditor excludes={["query", "pivot"]} />
                                    <CellTracker />
                                    <Tip title="Toggle parameters view.">
                                        <Button
                                            icon={this.state.showSidebar ? <RightCircleOutlined /> : <LeftCircleOutlined />}
                                            onClick={this.handleSidebarToggle}
                                        />
                                    </Tip>
                                </Spacer>
                                <Sheets
                                    calculator={this.context.calculator}
                                />
                            </Layout.Content>
                            <Layout.Sider
                                id="modeleditor-sidebar"
                                className="x-modelcalculator-sidebar"
                                width={310}
                                style={{ height: this.state.sidebarHeight }}
                            >
                                <Collapse accordion={false}>
                                    <Collapse.Panel
                                        header="Variables"
                                        key="variables"
                                    >
                                        <Parameters />
                                    </Collapse.Panel>
                                    <Collapse.Panel
                                        header="Queries"
                                        key="queries"
                                    >
                                        <Queries />
                                    </Collapse.Panel>
                                    <Collapse.Panel
                                        header="Pivots"
                                        key="pivots"
                                    >
                                        <Pivots />
                                    </Collapse.Panel>
                                </Collapse>
                            </Layout.Sider>
                        </Layout>
                    </CSSTransition>
                </div>
            )
        }
    }

}
