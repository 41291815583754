import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { FormInstance, Input, Select } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { JustificationType, ValueWidgetConfiguration, ValueWidgetLayoutType, WidgetType } from '@methodset/model-client-ts';
import { RefEditor } from '../../RefEditor/RefEditor';
import update from 'immutability-helper';
import './ValueWidgetEditor.less';
import { JustificationSelector } from 'containers/Components/Widgets/Selectors/JustificationSelector';

export type ChangeCallback = (configuration: ValueWidgetConfiguration) => void;

export type ValueWidgetEditorProps = typeof ValueWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    configuration?: ValueWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class ValueWidgetEditor extends PureComponent<ValueWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.VALUE,
        layout: ValueWidgetLayoutType.VERTICAL
    } as ValueWidgetConfiguration;

    static defaultProps = {
        configuration: ValueWidgetEditor.DefaultConfiguration
    }

    constructor(props: ValueWidgetEditorProps) {
        super(props);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleChangeChange = this.handleChangeChange.bind(this);
        this.handleJustificationChange = this.handleJustificationChange.bind(this);
    }

    private handleLayoutChange(layout: ValueWidgetLayoutType): void {
        const configuration = update(this.props.configuration, {
            layout: { $set: layout }
        });
        this.props.onChange(configuration);
    }

    private handleLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const configuration = update(this.props.configuration, {
            label: { $set: label }
        });
        this.props.onChange(configuration);
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const configuration = update(this.props.configuration, {
            description: { $set: description }
        });
        this.props.onChange(configuration);
    }

    private handleValueChange(valueId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            valueId: { $set: valueId! }
        });
        this.props.onChange(configuration);
    }

    private handleChangeChange(changeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            changeId: { $set: changeId }
        });
        this.props.onChange(configuration);
    }

    private handleJustificationChange(justification: JustificationType): void {
        const configuration = update(this.props.configuration, {
            justification: { $set: justification }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === ValueWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <>
                {this.props.extra}
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Layout"
                    name="layout"
                    info="The widget layout."
                    rules={[{
                        required: true,
                        message: "Select a layout."
                    }]}
                >
                    <Select
                        value={this.props.configuration.layout}
                        onChange={this.handleLayoutChange}>
                        <Select.Option value={ValueWidgetLayoutType.VERTICAL}>Vertical</Select.Option>
                        <Select.Option value={ValueWidgetLayoutType.HORIZONTAL}>Horizontal</Select.Option>
                        <Select.Option value={ValueWidgetLayoutType.SPLIT}>Split</Select.Option>
                    </Select>
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Label"
                    name="label"
                    info="Label for the value."
                >
                    <Input
                        value={this.props.configuration.label}
                        onChange={this.handleLabelChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Description"
                    name="description"
                    info="Description of the value."
                >
                    <Input.TextArea
                        rows={3}
                        value={this.props.configuration.description}
                        onChange={this.handleDescriptionChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    valuePropName="refId"
                    //required={false}
                    //noError={true}
                    label="Value"
                    name="value"
                    info="The cell or parameter that holds the data value."
                >
                    <RefEditor
                        formRef={this.props.formRef}
                        required={false}
                        index={0}
                        calculator={this.props.calculator}
                        refTypes={[RefType.CELL, RefType.PARAMETER]}
                        refId={this.props.configuration.valueId}
                        onChange={this.handleValueChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    valuePropName="refId"
                    //required={false}
                    //noError={true}
                    label="Change"
                    name="change"
                    info="The cell or parameter that holds the change value."
                >
                    <RefEditor
                        formRef={this.props.formRef}
                        required={false}
                        index={1}
                        calculator={this.props.calculator}
                        refTypes={[RefType.CELL, RefType.PARAMETER]}
                        refId={this.props.configuration.changeId}
                        onChange={this.handleChangeChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Justification"
                    name="justification"
                    info="Justification of the widget."
                >
                    <JustificationSelector
                        value={this.props.configuration.justification}
                        onChange={this.handleJustificationChange}
                    />
                </FormItem>
            </>
        );
    }

}
