import { Format, Formatter } from "@methodset/calculator-ts";
import { DataPoint } from "containers/Components/Widgets/ChartWidgetViewer/ChartWidgetViewer";
import { CoreUtils } from "./CoreUtils";

// Maps series name to index (0-based).
type SeriesMap = { [key: string]: number };

export class ChartUtils {

    /**
     * Calculates the limits of the y-axis.
     * 
     * @param dataPoints The data points in the chart.
     * @returns An array with minimum, maximum, and precision.
     */
    public static calcLimits = (dataPoints: DataPoint[], aggregate: boolean = false): [number | undefined, number | undefined, number] => {
        if (dataPoints.length === 0) {
            return [undefined, undefined, 0];
        }
        // Find the min and max for each dataset.
        let min = Number.MAX_VALUE;
        let max = Number.MIN_VALUE;
        if (aggregate) {
            const sums: { [key: string | number]: number } = {};
            // let first: string | undefined;
            for (const dataPoint of dataPoints) {
                if (CoreUtils.isNumber(dataPoint.y)) {
                    let key = dataPoint.x;
                    let sum = sums[key];
                    if (!sum) {
                        sums[key] = 0;
                    }
                    // Sum up the values for each y point.
                    sums[key] += dataPoint.y;
                }
            }
            // Iterate all y sums and find the max.
            for (const [key, sum] of Object.entries(sums)) {
                if (sum > max) {
                    max = sum;
                }
            }
            min = max < 0 ? max : 0;
        } else {
            for (const dataPoint of dataPoints) {
                if (CoreUtils.isNumber(dataPoint.y)) {
                    if (dataPoint.y < min) {
                        min = dataPoint.y;
                    }
                    if (dataPoint.y > max) {
                        max = dataPoint.y;
                    }
                }
            }
        }

        if (min === Number.MAX_VALUE && max === Number.MIN_VALUE) {
            return [undefined, undefined, 0];
        } else if (CoreUtils.isString(min) || CoreUtils.isString(max)) {
            return [min, max, 0];
        }

        const diff = max - min;
        const offset = diff * .10; // +/- 10%
        const minPrecision = ChartUtils.toPrecision(min);
        const maxPrecision = ChartUtils.toPrecision(max);
        const precision = Math.max(minPrecision, maxPrecision);
        //const precision = Math.min(2, maxPrecision);
        // Set the min to 0 if the offset would push it below 0.
        if (min >= 0.0 && min - offset < 0.0) {
            min = 0.0;
        } else {
            min -= offset;
        }
        max += offset;
        min = parseFloat(min.toFixed(precision));
        max = parseFloat(max.toFixed(precision));
        return [min, max, precision];
    }

    private static toPrecision(value: number): number {
        const parts = (value + "").split(".");
        return parts.length <= 1 ? 0 : parts[1].length;
    }

    /**
    * Calculates reasonable x and y label offsets. The x axis offset
    * is fixed since the labels are horizontal and do not vary vertically 
    * by the contents of the labels.
    * 
    * @param max The maximum data value.
    * @returns An array with the x and y offset values.
    */
    public static calcOffsets(format: Format | undefined, max: number | string | undefined): [number, number] {
        if (!max) {
            return [45, 45];
        }
        let text;
        if (CoreUtils.isString(max)) {
            text = max as string;
        } else if (format) {
            text = Formatter.format(max, format);
            if (!text) {
                return [45, 45];
            }
        } else {
            text = max.toString();
        }
        const offset = Math.round((text.length * 6.5) + 30);
        // const extra = text.length < 5 ? 20 : 0;
        // const offset = (text.length * 10) + extra;
        // const thins = text.split("").reduce((count: number, char: string) => {
        //     return char === "1" || char === "." ? count + 1 : count;
        // }, 0);
        // const wides = text.length - thins;
        // const offset = (wides * 10) + (thins * 10);
        return [45, offset];
    }

    /**
     * Creates a map of series name to index. Used to map series name to color.
     * 
     * @param dataPoints The data points in the chart to determine the map.
     * @returns A map of series name to index (0-based).
     */
    public static colorIndex(dataPoints: DataPoint[]): SeriesMap {
        const map: SeriesMap = {};
        let series;
        let index = 0;
        for (let dataPoint of dataPoints) {
            if (dataPoint.series !== series) {
                series = dataPoint.series;
                map[series] = index++;
            }
        }
        return map;
    }

}
