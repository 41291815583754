import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Form, FormInstance, Input, Modal, Result } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { RestUtils } from 'utils/RestUtils';
import { Globals } from 'constants/Globals';
import entityService from 'services/EntityService';
import './InviteDialog.less';

export type CloseCallback = () => void;

export type InviteDialogState = {
    emailAddress?: string,
    isSent: boolean,
    isLoading: boolean,
    errorMessage?: string,
}

export type InviteDialogProps = {
    onClose: CloseCallback
}

export class InviteDialog extends PureComponent<InviteDialogProps, InviteDialogState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: InviteDialogProps) {
        super(props);
        this.state = {
            emailAddress: undefined,
            isSent: false,
            isLoading: false,
            errorMessage: undefined,
        };
        this.formRef = React.createRef();
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleInviteCancel = this.handleInviteCancel.bind(this);
        this.handleInviteValidate = this.handleInviteValidate.bind(this)
        this.handleInviteSubmit = this.handleInviteSubmit.bind(this);
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>): void {
        const emailAddress = e.target.value;
        this.setState({ emailAddress: emailAddress });
    }

    private handleInviteCancel(): void {
        this.props.onClose();
    }

    private handleInviteValidate(): void {
        this.formRef.current!.validateFields().then(values => {
            this.handleInviteSubmit();
        });
    }

    private handleInviteSubmit(): void {
        const emailAddress = this.state.emailAddress!;
        this.inviteUserRequest(emailAddress);
    }

    private inviteUserRequest(emailAddress: string): Promise<any> {
        this.setState({
            isLoading: true,
            errorMessage: undefined
        });
        const request = {
            emailAddress: emailAddress
        };
        return entityService.inviteUser(request,
            (response: any) => this.inviteUserResponse(response),
            (error: Error) => this.inviteUserException(error),
            true
        );
    }

    private inviteUserResponse(response: any): void {
        this.setState({
            isSent: true,
            isLoading: false,
            errorMessage: undefined
        });
    }

    private inviteUserException(error: Error): void {
        const errorMessage = RestUtils.getError(error);
        this.setState({
            isLoading: false,
            errorMessage: errorMessage
        });
    }

    public render(): ReactElement {
        return (
            <Modal
                centered
                title="Invite A User"
                visible={true}
                confirmLoading={this.state.isLoading}
                width={Globals.DIALOG_WIDTH_STANDARD}
                okText="Invite"
                okButtonProps={{
                    style: {
                        display: this.state.isSent ? 'none' : 'inline'
                    }
                }}
                onOk={this.handleInviteValidate}
                cancelText={this.state.isSent ? "Close" : "Cancel"}
                cancelButtonProps={{
                    type: this.state.isSent ? "primary" : "default"
                }}
                onCancel={this.handleInviteCancel}
            >
                {this.state.isSent &&
                    <Result
                        status="success"
                        title="Your invitation has been sent!"
                        subTitle={`A message has been emailed to ${this.state.emailAddress} with a request to join your organization.`}
                    />
                }
                {!this.state.isSent &&
                    <Form
                        ref={this.formRef}
                        onFinish={this.handleInviteSubmit}
                    >
                        <p>Enter the email address of the person you would like to join your organization.</p>
                        <FormItem
                            formRef={this.formRef}
                            name="email"
                            noLabel={true}
                            rules={[{
                                required: true,
                                message: 'Please enter an email.'
                            }, {
                                type: 'email',
                                message: 'The email address is invalid.'
                            }]}
                        >
                            <Input
                                id="email"
                                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Email address."
                                value={this.state.emailAddress}
                                onChange={this.handleEmailChange}
                            />
                        </FormItem>
                        <div className="x-invitedialog-error">{this.state.errorMessage}</div>
                    </Form>
                }
            </Modal>
        );
    }

}
