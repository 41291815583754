import React, { ChangeEvent, ReactElement } from 'react';
import { Button, FormInstance, Input } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import './WhitelistEditor.less';

export type ChangeCallback = (address: string, index: number) => void;
export type RemoveCallback = (index: number) => void;

export type WhitelistEditorProps = {
    formRef: React.RefObject<FormInstance>,
    addresses?: string[],
    onChange: ChangeCallback,
    onRemove: RemoveCallback
} & typeof defaultProps;

const defaultProps = {
    addresses: [] as string[]
}

export const WhitelistEditor = (props: WhitelistEditorProps): ReactElement => {

    const handleAddressChange = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
        const value = e.target.value;
        props.onChange(value, index);
    }

    const handleAddressRemove = (index: number): void => {
        props.onRemove(index);
    }

    return (
        <div className="x-whitelisteditor">
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label={"Sender Whitelist"}
                direction="vertical"
                name="whitelist"
                info="Allowed email addresses from which trigger emails can be sent. Leaving empty will allow all addresses to send."
                noError={true}
            >
                {props.addresses.map((address, index) => (
                    <FormItem
                        key={index}
                        formRef={props.formRef}
                        noLabel={true}
                        name={`address-${index}`}
                        rules={[{
                            // required: true,
                            // message: 'Please enter an email address.'
                        }, {
                            type: 'email',
                            message: 'The email address is invalid.'
                        }, {
                            validator: (rule: any, value: string) => {
                                if (index > 0 && !value) {
                                    return Promise.reject("Please enter an email address.");
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }]}
                        postfix={
                            <Button
                                icon={<CloseCircleOutlined />}
                                onClick={() => handleAddressRemove(index)}
                            />
                        }
                    >
                        <Input
                            className="x-address-field"
                            placeholder="Enter an email address."
                            value={address}
                            onChange={(e) => handleAddressChange(e, index)}
                        />
                    </FormItem>
                ))}
            </FormItem>
        </div>
    );

}

WhitelistEditor.defaultProps = defaultProps;
