import { PropertyUtils } from "@methodset/commons-shared-ts";
import { TenantType } from "@methodset/entity-client-ts";
import { ValueWidgetLayoutType } from "@methodset/model-client-ts";
import { RouteComponentProps } from "react-router-dom";

export class RouteBuilder {

    static HOME = "/";
    static CONSOLE = "/console";
    static CONSOLE_INSTITUTIONS = `${RouteBuilder.CONSOLE}/institutions`;
    static CONSOLE_INSTITUTION = `${RouteBuilder.CONSOLE_INSTITUTIONS}/:institutionId`;
    static CONSOLE_DASHBOARDS = `${RouteBuilder.CONSOLE}/dashboards`;
    static CONSOLE_DASHBOARD = `${RouteBuilder.CONSOLE_DASHBOARDS}/:dashboardId`;
    static CONSOLE_APPLETS = `${RouteBuilder.CONSOLE_DASHBOARD}/applets`;
    static CONSOLE_DATASETS = `${RouteBuilder.CONSOLE}/queries`;
    static CONSOLE_DATASET = `${RouteBuilder.CONSOLE_DATASETS}/:queryId`;
    static CONSOLE_WORKFLOWS = `${RouteBuilder.CONSOLE}/workflows`;
    static CONSOLE_WORKFLOW = `${RouteBuilder.CONSOLE_WORKFLOWS}/:workflowId`;
    static CONSOLE_CREDENTIALS = `${RouteBuilder.CONSOLE}/authentications`;
    static CONSOLE_CREDENTIAL = `${RouteBuilder.CONSOLE_CREDENTIALS}/:authenticationId`;
    static CONSOLE_ACCOUNTS = `${RouteBuilder.CONSOLE}/accounts`;
    static CONSOLE_ACCOUNT = `${RouteBuilder.CONSOLE_ACCOUNTS}/:accountId`;
    static CONSOLE_SCHEDULES = `${RouteBuilder.CONSOLE}/schedules`;
    static CONSOLE_SCHEDULE = `${RouteBuilder.CONSOLE_SCHEDULES}/:scheduleId`;
    static CONSOLE_APIS = `${RouteBuilder.CONSOLE}/apis`;
    static CONSOLE_API = `${RouteBuilder.CONSOLE_APIS}/:queryId`;
    static CONSOLE_API_KEYS = `${RouteBuilder.CONSOLE_APIS}/keys`;
    static CONSOLE_ALERTS = `${RouteBuilder.CONSOLE}/alerts`;
    static CONSOLE_ALERT = `${RouteBuilder.CONSOLE_ALERTS}/:alertId`;
    static CONSOLE_MODELS = `${RouteBuilder.CONSOLE}/models`;
    static CONSOLE_MODEL = `${RouteBuilder.CONSOLE_MODELS}/:modelId`;
    static CONSOLE_MODEL_SHEETS = `${RouteBuilder.CONSOLE_MODEL}/sheets`;
    static CONSOLE_MODEL_APPLETS = `${RouteBuilder.CONSOLE_MODEL}/applets`;
    static CONSOLE_MONITOR = `${RouteBuilder.CONSOLE}/monitor`;
    static CONSOLE_EXECUTIONS = `${RouteBuilder.CONSOLE_MONITOR}/:workflowId`;
    static CONSOLE_GROUPS = `${RouteBuilder.CONSOLE}/groups`;
    static CONSOLE_GROUP = `${RouteBuilder.CONSOLE_GROUPS}/:groupId`;
    static CONSOLE_GROUP_PROPERTIES = `${RouteBuilder.CONSOLE_GROUP}/properties`;
    static CONSOLE_GROUP_ADD_MEMBER = `${RouteBuilder.CONSOLE_GROUP}/members/add`;
    static CONSOLE_GROUP_MEMBERSHIPS = `${RouteBuilder.CONSOLE_GROUPS}/memberships`;
    static CONSOLE_ORGANIZATION = `${RouteBuilder.CONSOLE}/organization`;

    static MAIN = "/main";
    static MAIN_PERSONAL = `${RouteBuilder.MAIN}/personal`;
    static MAIN_BUSINESS = `${RouteBuilder.MAIN}/business`;
    static MAIN_PRICING = `${RouteBuilder.MAIN}/pricing`;
    static MAIN_SERVICES = `${RouteBuilder.MAIN}/services`;
    static MAIN_WAITLIST = `${RouteBuilder.MAIN}/waitlist`;
    static MAIN_DEMOS = `${RouteBuilder.MAIN}/demos`;
    static MAIN_USER = `${RouteBuilder.MAIN}/user`;
    static MAIN_LOGIN = `${RouteBuilder.MAIN_USER}/login`;
    static MAIN_REGISTER = `${RouteBuilder.MAIN_USER}/register`;
    static MAIN_REGISTER_CONFIRM = `${RouteBuilder.MAIN_REGISTER}/confirm/:userId`;
    static MAIN_REGISTER_RESEND = `${RouteBuilder.MAIN_REGISTER}/resend`;
    static MAIN_ORGANIZATION = `${RouteBuilder.MAIN_USER}/organization`;
    static MAIN_RESET_PASSWORD = `${RouteBuilder.MAIN_USER}/reset_password`;
    static MAIN_RESET_PASSWORD_CONFIRM = `${RouteBuilder.MAIN_RESET_PASSWORD}/confirm/:userId`;
    static MAIN_CHANGE_PASSWORD = `${RouteBuilder.MAIN_USER}/change_password`;
    static MAIN_CHANGE_EMAIL = `${RouteBuilder.MAIN_USER}/change_email`;
    static MAIN_CHANGE_EMAIL_CONFIRM = `${RouteBuilder.MAIN_CHANGE_EMAIL}/confirm`;
    static MAIN_EDIT_PROFILE = `${RouteBuilder.MAIN_USER}/profile`;

    static APPLICATION = "/application";
    static APPLICATION_STORE = `${RouteBuilder.APPLICATION}/store`;
    static APPLICATION_BOARDS = `${RouteBuilder.APPLICATION}/board`;
    static APPLICATION_BOARD = `${RouteBuilder.APPLICATION_BOARDS}/:boardId`;
    static APPLICATION_USER = `${RouteBuilder.APPLICATION}/user`;
    static APPLICATION_LOGIN = `${RouteBuilder.APPLICATION_USER}/login`;
    static APPLICATION_REGISTER = `${RouteBuilder.APPLICATION_USER}/register`;
    static APPLICATION_REGISTER_CONFIRM = `${RouteBuilder.APPLICATION_REGISTER}/confirm/:userId`;
    static APPLICATION_REGISTER_RESEND = `${RouteBuilder.APPLICATION_REGISTER}/resend`;
    static APPLICATION_ORGANIZATION = `${RouteBuilder.APPLICATION_USER}/organization`;
    static APPLICATION_RESET_PASSWORD = `${RouteBuilder.APPLICATION_USER}/reset_password`;
    static APPLICATION_RESET_PASSWORD_CONFIRM = `${RouteBuilder.APPLICATION_RESET_PASSWORD}/confirm/:userId`;
    static APPLICATION_CHANGE_PASSWORD = `${RouteBuilder.APPLICATION_USER}/change_password`;
    static APPLICATION_CHANGE_EMAIL = `${RouteBuilder.APPLICATION_USER}/change_email`;
    static APPLICATION_CHANGE_EMAIL_CONFIRM = `${RouteBuilder.APPLICATION_CHANGE_EMAIL}/confirm`;
    static APPLICATION_EDIT_PROFILE = `${RouteBuilder.APPLICATION_USER}/profile`;
    static APPLICATION_SWITCH_GROUP = `${RouteBuilder.APPLICATION_USER}/switch_group`;
    static APPLICATION_FAMILY = `${RouteBuilder.APPLICATION}/family`;
    static APPLICATION_FAMILY_ADD_MEMBER = `${RouteBuilder.APPLICATION_FAMILY}/add`;
    static APPLICATION_ACCOUNTS = `${RouteBuilder.APPLICATION}/accounts`;
    static APPLICATION_ACCOUNT = `${RouteBuilder.APPLICATION_ACCOUNTS}/:accountId`;

    static USER = "/:type/user";
    static USER_LOGIN = `${RouteBuilder.USER}/login`;
    static USER_REGISTER = `${RouteBuilder.USER}/register`;
    static USER_REGISTER_CONFIRM = `${RouteBuilder.USER_REGISTER}/confirm/:userId`;
    static USER_REGISTER_RESEND = `${RouteBuilder.USER_REGISTER}/resend`;
    static USER_ORGANIZATION = `${RouteBuilder.USER}/organization`;
    static USER_RESET_PASSWORD = `${RouteBuilder.USER}/reset_password`;
    static USER_RESET_PASSWORD_CONFIRM = `${RouteBuilder.USER_RESET_PASSWORD}/confirm/:userId`;
    static USER_CHANGE_PASSWORD = `${RouteBuilder.USER}/change_password`;
    static USER_CHANGE_EMAIL = `${RouteBuilder.USER}/change_email`;
    static USER_CHANGE_EMAIL_CONFIRM = `${RouteBuilder.USER_CHANGE_EMAIL}/confirm`;
    static USER_EDIT_PROFILE = `${RouteBuilder.USER}/profile`;
    static USER_SWITCH_GROUP = `${RouteBuilder.USER}/switch_group`;

    static isPageTracked(path: string): boolean {
        if (PropertyUtils.isProduction()) {
            switch (path) {
                case RouteBuilder.HOME:
                case RouteBuilder.MAIN_BUSINESS:
                case RouteBuilder.MAIN_PERSONAL:
                case RouteBuilder.MAIN_PRICING:
                case RouteBuilder.MAIN_SERVICES:
                case RouteBuilder.MAIN_WAITLIST:
                case RouteBuilder.MAIN_DEMOS:
                case RouteBuilder.MAIN_LOGIN:
                case RouteBuilder.MAIN_REGISTER:
                case RouteBuilder.MAIN_ORGANIZATION:
                case RouteBuilder.MAIN_EDIT_PROFILE:
                case RouteBuilder.APPLICATION_LOGIN:
                case RouteBuilder.APPLICATION_REGISTER:
                case RouteBuilder.APPLICATION_ORGANIZATION:
                case RouteBuilder.APPLICATION_EDIT_PROFILE:
                case RouteBuilder.APPLICATION_SWITCH_GROUP:
                case RouteBuilder.CONSOLE_GROUPS:
                case RouteBuilder.CONSOLE_CREDENTIALS:
                case RouteBuilder.CONSOLE_ACCOUNTS:
                case RouteBuilder.CONSOLE_APIS:
                case RouteBuilder.CONSOLE_MODELS:
                case RouteBuilder.CONSOLE_DATASETS:
                case RouteBuilder.CONSOLE_WORKFLOWS:
                case RouteBuilder.CONSOLE_MONITOR:
                case RouteBuilder.CONSOLE_DASHBOARDS:
                    return true;
                default:
                    return false;
            }
        } else {
            return false;
        }
    }

    static tenant(props: RouteComponentProps<any>): TenantType | undefined {
        const pathname = props.location.pathname;
        const match = pathname.match(/([^/]+)/);
        if (match) {
            const value = match[1];
            switch (value) {
                case "console":
                    return TenantType.DEVELOPMENT;
                case "application":
                    return TenantType.CLIENT;
                default:
                    return undefined;
            }
        }
        return undefined;
    }

    static user(props: RouteComponentProps<any>, path: string): string {
        const pathname = props.location.pathname;
        //const match = pathname.match(/([^/]+)\/.*/);
        const match = pathname.match(/([^/]+)/);
        const type = match ? match[1] : "main";
        return path.replace(":type", type);
    }

    static requestSubmitted(page: string): string {
        return `${page}?state=submitted`;
    }

    static registerConfirm(userId: string): string {
        return RouteBuilder.MAIN_REGISTER_CONFIRM.replace(":userId", userId);
    }

    static resetPasswordConfirm(userId: string): string {
        return RouteBuilder.MAIN_RESET_PASSWORD_CONFIRM.replace(":userId", userId);
    }

    static group(groupId: string): string {
        return RouteBuilder.CONSOLE_GROUP.replace(":groupId", groupId);
    }

    static groupProperties(groupId: string): string {
        return RouteBuilder.CONSOLE_GROUP_PROPERTIES.replace(":groupId", groupId);
    }

    static groupAddMember(groupId: string): string {
        return RouteBuilder.CONSOLE_GROUP_ADD_MEMBER.replace(":groupId", groupId);
    }

    static authentication(authenticationId: string): string {
        return RouteBuilder.CONSOLE_CREDENTIAL.replace(":authenticationId", authenticationId);
    }

    static account(accountId: string): string {
        return RouteBuilder.CONSOLE_ACCOUNT.replace(":accountId", accountId);
    }

    static query(queryId: string): string {
        return RouteBuilder.CONSOLE_DATASET.replace(":queryId", queryId);
    }

    static workflow(workflowId: string): string {
        return RouteBuilder.CONSOLE_WORKFLOW.replace(":workflowId", workflowId);
    }

    static institution(institutionId: string): string {
        return RouteBuilder.CONSOLE_INSTITUTION.replace(":institutionId", institutionId);
    }

    static dashboard(dashboardId: string): string {
        return RouteBuilder.CONSOLE_DASHBOARD.replace(":dashboardId", dashboardId);
    }

    static applets(dashboardId: string): string {
        return RouteBuilder.CONSOLE_APPLETS.replace(":dashboardId", dashboardId);
    }

    static api(queryId: string): string {
        return RouteBuilder.CONSOLE_API.replace(":queryId", queryId);
    }

    static executions(workflowId: string): string {
        return RouteBuilder.CONSOLE_EXECUTIONS.replace(":workflowId", workflowId);
    }

    static model(modelId: string, viewType: "sheets" | "applets" | "widgets" = "sheets"): string {
        return `${RouteBuilder.CONSOLE_MODEL.replace(":modelId", modelId)}/${viewType}`;
    }

    static schedule(scheduleId: string): string {
        return RouteBuilder.CONSOLE_SCHEDULE.replace(":scheduleId", scheduleId);
    }

    static applicationBoard(boardId: string): string {
        return RouteBuilder.APPLICATION_BOARD.replace(":boardId", boardId);
    }

    static applicationAccount(accountId: string): string {
        return RouteBuilder.APPLICATION_ACCOUNT.replace(":accountId", accountId);
    }

}
