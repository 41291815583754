import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { Button, FormInstance } from 'antd';
import { Pension } from '@methodset/entity-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { FormDivider } from 'components/FormDivider/FormDivider';
import { PensionEditor } from './PensionEditor/PensionEditor';
import update from 'immutability-helper';
import './PensionsEditor.less';

export type ChangeCallback = (pensions: Pension[]) => void;

export type PensionsEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    pensions?: Pension[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    pensions: [] as Pension[]
}

export const PensionsEditor = (props: PensionsEditorProps): ReactElement => {

    const handlePensionAdd = (): void => {
        const pension = {} as Pension;
        const pensions = update(props.pensions, {
            $push: [pension]
        });
        props.onChange(pensions);
    }

    const handlePensionRemove = (index: number): void => {
        const pensions = update(props.pensions, {
            $splice: [[index, 1]]
        });
        props.onChange(pensions);
    }

    const handlePensionChange = (pension: Pension, index: number): void => {
        const pensions = update(props.pensions, {
            [index]: { $set: pension }
        });
        props.onChange(pensions);
    }

    return (
        <Spacer direction="vertical">
            {props.pensions.map((pension, index) => (
                <>
                    <FormDivider
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        label={`Pension ${index + 1}`}
                        fill={true}
                        bold={true}
                        colon={false}
                        inline={true}
                        onDelete={() => handlePensionRemove(index)}
                    />

                    <PensionEditor
                        key={index}
                        formRef={props.formRef}
                        id={`pension-${index + 1}`}
                        pension={pension}
                        onChange={(pensions) => handlePensionChange(pensions, index)}
                    />
                </>
            ))}
            <Button onClick={() => handlePensionAdd()}>Add Pension</Button>
        </Spacer>
    )
}

PensionsEditor.defaultProps = defaultProps;
