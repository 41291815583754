import React from 'react';
import { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { Assignment, Component, Configuration, ConfigurationSpec, Processor, ProcessorHeader, ProcessorRef, ProcessorType } from '@methodset/endpoint-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { ConfigurationEditor } from '../../ConfigurationEditor/ConfigurationEditor';
import { EditorHeader } from '../../EditorHeader/EditorHeader';
import { v4 as uuid } from "uuid";
import { CoreUtils } from 'utils/CoreUtils';
import { Spacer } from 'components/Spacer/Spacer';
import { Info } from 'components/Info/Info';
import { Panel } from 'components/Panel/Panel';
import { AssignmentsEditor } from './AssignmentsEditor/AssignmentsEditor';
import { ProcessorLoader } from '../ProcessorLoader/ProcessorLoader';
import update from 'immutability-helper';
import './ProcessorEditor.less';

export type CancelCallback = () => void;
export type DoneCallback = (component: Component, index: number) => void;

export type ProcessorEditorProps = typeof ProcessorEditor.defaultProps & {
    // Types of processors to include in selection list.
    types?: ProcessorType[],
    // The variables for the configuration.
    variableSpecs?: ConfigurationSpec[],
    // Processor headers.
    headers?: ProcessorHeader[],
    // A list of all components in the query.
    components: Component[],
    // The component being edited.
    component: Component | null,
    // The index of the component.
    index: number,
    // True to use for creating serial queries.
    serialFlow: boolean,
    // Allow variables to be assigned from processor outputs.
    allowAssignment?: boolean,
    // Called when editing is done.
    onDone: DoneCallback
    // Called with editing is canceled.
    onCancel: CancelCallback,
}

export type ProcessorEditorState = {
    // Status of loading data.
    status: string,
    // True if loading data.
    loading: boolean,
    // The processor for this component.
    processor?: Processor
    // The component being edited.
    component: Component
}

export class ProcessorEditor extends PureComponent<ProcessorEditorProps, ProcessorEditorState> {

    private formRef = React.createRef<FormInstance>();

    static defaultProps = {
        headers: [] as ProcessorHeader[],
        types: CoreUtils.enumToKeys(ProcessorType),
        serialFlow: false,
        allowAssignment: true
    }

    constructor(props: ProcessorEditorProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            loading: false,
            // Make a deep copy of the component and store as state.
            // Since the configuration is an arbitrary nested object,
            // it has be be modified directly. The copy will be worked on
            // and if editing is canceled, it will be thrown away, as will
            // all of its modifications.
            component: props.component ? JSON.parse(JSON.stringify(props.component)) : ProcessorEditor.newComponent()
        };
        // this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleProcessorLoaded = this.handleProcessorLoaded.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
        this.handleAssignmentsChange = this.handleAssignmentsChange.bind(this);
        this.handleEditDone = this.handleEditDone.bind(this);
        this.handleEditError = this.handleEditError.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
    }

    public static newComponent(id?: string): Component {
        return {
            id: id ? id : uuid(),
            name: undefined as any,
            type: undefined as any,
            processor: {} as ProcessorRef,
            configuration: {} as Configuration
        } as Component;
    }

    private handleTypeChange(processorId: string) {
        const component = ProcessorEditor.newComponent();
        component.id = this.state.component.id;
        component.processor = {
            id: processorId
        } as ProcessorRef;
        this.setState({
            component: component,
            processor: undefined
        });
    }

    private handleVersionChange(processorVersion: number): void {
        const component = update(this.state.component, {
            processor: {
                version: { $set: processorVersion },
            }
        });
        this.setState({ component: component });
    }

    private handleProcessorLoaded(processor: Processor): void {
        const name = this.state.component.name ? this.state.component.name : processor.name;
        const component = update(this.state.component, {
            type: { $set: processor.type },
            name: { $set: name }
        });
        this.setState({
            processor: processor,
            component: component
        });
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const component = update(this.state.component, {
            name: { $set: name }
        });
        this.setState({ component: component });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const component = update(this.state.component, {
            description: { $set: description }
        });
        this.setState({ component: component });
    }

    private handleConfigurationChange(configuration: Configuration): void {
        const component = update(this.state.component, {
            configuration: { $set: configuration }
        });
        this.setState({ component: component });
    }

    private handleAssignmentsChange(assignments: Assignment[]): void {
        const component = update(this.state.component, {
            assignments: { $set: assignments }
        });
        this.setState({ component: component });
    }

    private handleEditDone(): void {
        this.props.onDone(this.state.component, this.props.index);
    }

    private handleEditError(e: any): void {
    }

    private handleEditCancel(): void {
        this.props.onCancel();
    }

    private buildOptGroups(): ReactElement[] {
        let headers;
        if (this.props.serialFlow) {
            headers = this.props.headers.filter(
                header => this.props.components.length === 0 || this.props.index === 0 ?
                    header.type === ProcessorType.READER :
                    this.props.types.includes(header.type) && header.type !== ProcessorType.READER
            );
        } else {
            headers = this.props.headers.filter(header => this.props.types.includes(header.type));
        }
        const groups = [];
        let i = 0;
        while (i < headers.length) {
            const header = headers[i];
            const type = header.type!;
            const data = this.buildOptions(headers, i, type);
            const options = data[0];
            if (options.length > 0) {
                const group = (
                    <Select.OptGroup
                        key={i}
                        label={type}
                    >
                        {options}
                    </Select.OptGroup>
                );
                groups.push(group);
            }
            i = data[1];
        }
        return groups;
    }

    private buildOptions(headers: ProcessorHeader[], i: number, type: string): [ReactElement[], number] {
        const options = [];
        while (i < headers.length) {
            const header = headers[i];
            if (type === header.type) {
                const option = (
                    <Select.Option
                        key={header.id}
                        value={header.id}
                    >
                        {header.name}
                    </Select.Option>
                );
                options.push(option);
                i++;
            } else {
                break;
            }
        }
        return [options, i];
    }

    public render(): ReactElement {
        return (
            <Form
                ref={this.formRef}
                className="x-processoreditor"
                onFinish={this.handleEditDone}
                onFinishFailed={this.handleEditError}
            >
                <EditorHeader
                    title="Processor Editor"
                    onCancel={this.handleEditCancel}
                />
                <Row gutter={[32, 0]}>
                    <Col span={12}>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Type"
                            name="processor-type"
                            info={
                                this.state.processor ?
                                    this.state.processor.description :
                                    "A component that accesses or manipulates data."
                            }
                            rules={[{
                                required: true,
                                message: "Please select a processor."
                            }]}
                        >
                            <Select
                                placeholder="Select a processor."
                                value={this.state.component.processor.id}
                                onChange={this.handleTypeChange}
                            >
                                {this.buildOptGroups()}
                            </Select>
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Name"
                            name="name"
                            info="The processor name."
                            rules={[{
                                required: true,
                                message: `Please select a processor name.`
                            }]}
                        >
                            <Input
                                placeholder="Enter a name."
                                value={this.state.component.name}
                                onChange={this.handleNameChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Description"
                            name="description"
                            info="The processor description."
                        >
                            <Input.TextArea
                                placeholder="Enter a description."
                                rows={3}
                                value={this.state.component.description}
                                onChange={this.handleDescriptionChange}
                            />
                        </FormItem>
                        {this.state.component.processor.id &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                hidden={!!this.state.processor}
                            >
                                <ProcessorLoader
                                    processorId={this.state.component.processor.id}
                                    processorVersion={this.state.component.processor.version}
                                    onLoaded={this.handleProcessorLoaded}
                                />
                            </FormItem>
                        }
                        {this.state.processor &&
                            <ConfigurationEditor
                                formRef={this.formRef}
                                configuration={this.state.component.configuration}
                                configurationSpecs={this.state.processor.configurationSpecs}
                                variableSpecs={this.props.variableSpecs}
                                onChange={this.handleConfigurationChange}
                            />
                        }
                    </Col>
                    <Col span={12}>
                        {this.state.processor &&
                            <Spacer direction="vertical" size="large" alignment="middle">
                                <Panel className="x-processoreditor-panel" title="Inputs">
                                    <Row>
                                        {this.state.processor.inputSpecs.length > 0 && this.state.processor.inputSpecs.map(spec => (
                                            <div key={spec.key} className="x-processoreditor-row">
                                                <Col span={12}>
                                                    <Info label={spec.name} info={spec.description} />
                                                </Col>
                                                <Col span={12}>
                                                    {CoreUtils.listToString(spec.types)}
                                                </Col>
                                            </div>
                                        ))}
                                        {this.state.processor.inputSpecs.length === 0 &&
                                            <Col span={12}>
                                                None
                                            </Col>
                                        }
                                    </Row>
                                </Panel>
                                <Panel className="x-processoreditor-panel" title="Outputs">
                                    <Row>
                                        {this.state.processor.outputSpecs.length > 0 && this.state.processor.outputSpecs.map(spec => (
                                            <div key={spec.key} className="x-processoreditor-row">
                                                <Col span={12}>
                                                    <Info label={spec.name} info={spec.description} />
                                                </Col>
                                                <Col span={12}>
                                                    {spec.type}
                                                </Col>
                                            </div>
                                        ))}
                                        {this.state.processor.outputSpecs.length === 0 &&
                                            <Col span={12}>
                                                None
                                            </Col>
                                        }
                                    </Row>
                                </Panel>
                                {this.props.allowAssignment && this.props.variableSpecs && this.props.variableSpecs.length > 0 &&
                                    <Panel className="x-processoreditor-panel" title="Assigned Variables">
                                        <AssignmentsEditor
                                            formRef={this.formRef}
                                            outputSpecs={this.state.processor.outputSpecs}
                                            variableSpecs={this.props.variableSpecs}
                                            assignments={this.state.component.assignments}
                                            onChange={this.handleAssignmentsChange}
                                        />
                                    </Panel>
                                }
                            </Spacer>
                        }
                    </Col>
                </Row>
            </Form >
        );
    }

}
