import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Button, Col, Form, FormInstance, Input, Radio, RadioChangeEvent, Row, Select, Switch } from 'antd';
import { Formula } from '@methodset/calculator-ts';
import { Configuration, ConfigurationSpec, Field, FormatType, PrivacyType, Query, QueryHeader } from '@methodset/endpoint-client-ts';
import { ApiKey } from '@methodset/entity-client-ts';
import { Globals } from 'constants/Globals';
import { ModelContext } from 'context/ModelContext';
import { FormItem } from 'components/FormItem/FormItem';
import { QueryParams, RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { QueryVersionPicker } from 'containers/Console/ConfigurationEditor/QuerySelector/QueryVersionPicker/QueryVersionPicker';
import { QueryLoader } from 'containers/Console/ConfigurationEditor/QuerySelector/QueryLoader/QueryLoader';
import { Justify } from 'components/Justify/Justify';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { SyntaxType } from 'components/DateTimeSelector/DateTimeSelector';
import { ConfigurationEditor } from 'containers/Console/ConfigurationEditor/ConfigurationEditor';
import { Spacer } from 'components/Spacer/Spacer';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import axios from 'axios';
import classNames from 'classnames';
import endpointService from 'services/EndpointService';
import entityService from 'services/EntityService';
import update from 'immutability-helper';
import './QueryBuilder.less';
import { FormPass } from 'components/FormPass/FormPass';

export type ParamType = "id" | "version" | "format" | "api_key" | "limit" | "rows" | "reverse" | "headers" | "fields";
export type ResultType = "formula" | "url";

export type StandardParams = {
    version?: number,
    format?: FormatType,
    apiKey?: string,
    limit?: number,
    rows?: string,
    reverse?: boolean,
    headers?: boolean,
    fields?: string[],
    query?: string
}

export type ChangeCallback = (queryId: string, configuration: Configuration, queryParams: QueryParams) => void;
export type TouchCallback = () => void;
export type LoadedCallback = (query: Query) => void;

export type QueryBuilderProps = typeof QueryBuilder.defaultProps & {
    className?: string,
    queryId?: string,
    queryString?: Formula | string,
    configuration?: Configuration,
    queryParams?: QueryParams,
    variableSpecs?: ConfigurationSpec[],
    excludes: ParamType[],
    result: ResultType,
    timeSyntax?: SyntaxType,
    allowExpressions?: boolean,
    wideLayout: boolean,
    onChange: ChangeCallback,
    onTouch?: TouchCallback,
    onLoaded?: LoadedCallback
}

export type QueryBuilderState = {
    status: string,
    showAdvanced?: boolean,
    queryId?: string,
    query?: Query,
    configuration?: Configuration,
    standardParams: StandardParams,
    queries: QueryHeader[],
    apiKeys: ApiKey[]
}

export class QueryBuilder extends PureComponent<QueryBuilderProps, QueryBuilderState> {

    static defaultProps = {
        syntaxType: "formula",
        allowExpressions: true,
        wideLayout: false,
    }

    static contextType = ModelContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: QueryBuilderProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            showAdvanced: false,
            queryId: props.queryId,
            standardParams: this.createStandardParams(props.queryParams),
            configuration: props.configuration,
            queries: [],
            apiKeys: []
        }
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleQueryLoaded = this.handleQueryLoaded.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleFormatChange = this.handleFormatChange.bind(this);
        this.handleAllFieldsChange = this.handleAllFieldsChange.bind(this);
        this.handleFieldsChange = this.handleFieldsChange.bind(this);
        this.handleLimitChange = this.handleLimitChange.bind(this);
        this.handleRowsChange = this.handleRowsChange.bind(this);
        this.handleHeadersChange = this.handleHeadersChange.bind(this);
        this.handleOrderChange = this.handleOrderChange.bind(this);
        this.handleApiKeyChange = this.handleApiKeyChange.bind(this);
        this.handleAdvancedClick = this.handleAdvancedClick.bind(this);
        this.handleEditComplete = this.handleEditComplete.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleQueryLoaded(query: Query): void {
        this.setState({ query: query });
        if (this.props.onLoaded) {
            this.props.onLoaded(query);
        }
    }

    private handleQueryChange(queryId: string): void {
        this.setState({
            queryId: queryId,
            query: undefined
        });
        this.sendTouch();
    }

    private handleVersionChange(version: number): void {
        const standardParams = update(this.state.standardParams, {
            version: { $set: version }
        });
        this.setState({
            standardParams: standardParams,
            query: undefined
        });
        this.sendTouch();
    }

    private handleFormatChange(format: FormatType): void {
        const standardParams = update(this.state.standardParams, {
            format: { $set: format }
        });
        this.setState({ standardParams: standardParams });
        this.sendTouch();
    }

    private handleAllFieldsChange(allFields: boolean): void {
        let standardParams: StandardParams | undefined;
        if (allFields) {
            standardParams = update(this.state.standardParams, {
                $unset: ["fields"]
            });
        } else {
            standardParams = update(this.state.standardParams, {
                fields: { $set: [] }
            });
        }
        this.setState({ standardParams: standardParams });
        this.sendTouch();
    }

    private handleFieldsChange(fields: string[]): void {
        const standardParams = update(this.state.standardParams, {
            fields: { $set: fields }
        });
        this.setState({ standardParams: standardParams });
        this.sendTouch();
    }

    private handleLimitChange(limit: number): void {
        const standardParams = update(this.state.standardParams, {
            limit: { $set: limit }
        });
        this.setState({ standardParams: standardParams });
        this.sendTouch();
    }

    private handleRowsChange(e: ChangeEvent<HTMLInputElement>): void {
        let rows: any = e.target.value;
        const standardParams = update(this.state.standardParams, {
            rows: { $set: rows }
        });
        this.setState({ standardParams: standardParams });
        this.sendTouch();
    }

    private handleHeadersChange(headers: boolean): void {
        let standardParams;
        if (headers) {
            // When no headers are set means all headers.
            standardParams = update(this.state.standardParams, {
                $unset: ["headers"]
            });
        } else {
            standardParams = update(this.state.standardParams, {
                headers: { $set: false }
            });
        }
        this.setState({ standardParams: standardParams });
        this.sendTouch();
    }

    private handleOrderChange(e: RadioChangeEvent): void {
        const reverse = e.target.value;
        const standardParams = update(this.state.standardParams, {
            reverse: { $set: reverse }
        });
        this.setState({ standardParams: standardParams });
        this.sendTouch();
    }

    private handleApiKeyChange(secretKey: string): void {
        const standardParams = update(this.state.standardParams, {
            apiKey: { $set: secretKey }
        });
        this.setState({ standardParams: standardParams });
        this.sendTouch();
    }

    private handleConfigurationChange(configuration: Configuration): void {
        this.setState({ configuration: configuration });
        this.sendTouch();
    }

    private handleAdvancedClick(): void {
        this.setState({ showAdvanced: !this.state.showAdvanced });
    }

    private handleEditComplete(): void {
        const queryParams = this.createQueryParams();
        const configuration = this.state.configuration ? this.state.configuration : {};
        this.props.onChange(this.state.queryId!, configuration, queryParams);
    }

    private sendTouch(): void {
        if (this.props.onTouch) {
            this.props.onTouch();
        }
    }

    private readQueryHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readQueryHeaders(request,
            (response: any) => this.readQueryHeadersResponse(response),
            undefined, true
        );
    }

    private readQueryHeadersResponse(response: any): void {
        const queries = response.data.headers;
        this.setState({ queries: queries });
    }

    private readApiKeysRequest(): Promise<any> {
        // API keys not needed for internal dataset requests.
        if (this.props.excludes.includes("api_key")) {
            return Promise.resolve();
        }
        const request = {};
        return entityService.readApiKeys(request,
            (response: any) => this.readApiKeysResponse(response),
            undefined, true
        );
    }

    private readApiKeysResponse(response: any): void {
        const apiKeys = response.data.apiKeys;
        this.setState({ apiKeys: apiKeys });
    }

    private createStandardParams(queryParams?: QueryParams): StandardParams {
        const standardParams: StandardParams = {};
        if (!queryParams) {
            return standardParams;
        }
        for (const [key, value] of Object.entries(queryParams)) {
            if (CoreUtils.isEmpty(value) || !CoreUtils.isString(value)) {
                continue;
            }
            switch (key) {
                case "version":
                    standardParams.version = parseInt(value);
                    break;
                case "format":
                    standardParams.format = value.toUpperCase() as FormatType;
                    break;
                case "api_key":
                    standardParams.apiKey = value;
                    break;
                case "limit":
                    standardParams.limit = parseInt(value);
                    break;
                case "rows":
                    standardParams.rows = value;
                    break;
                case "reverse":
                    standardParams.reverse = value === "true" ? true : false;
                    break;
                case "headers":
                    standardParams.headers = value === "false" ? false : true;
                    break;
                case "fields":
                    standardParams.fields = value?.split(",");
                    break;
                default:
            }
        }
        return standardParams;
    }

    private createQueryParams(): QueryParams {
        const queryParams: QueryParams = {};
        if (!CoreUtils.isEmpty(this.state.standardParams.version)) {
            queryParams['version'] = this.state.standardParams.version!.toString();
        }
        if (!CoreUtils.isEmpty(this.state.standardParams.format)) {
            queryParams['format'] = FormatType[this.state.standardParams.format!].toLowerCase();
        }
        if (!CoreUtils.isEmpty(this.state.standardParams.limit)) {
            queryParams['limit'] = this.state.standardParams.limit!.toString();
        }
        if (!CoreUtils.isEmpty(this.state.standardParams.rows)) {
            queryParams['rows'] = this.state.standardParams.rows!;
        }
        if (!CoreUtils.isEmpty(this.state.standardParams.reverse)) {
            // Default is false.
            queryParams['reverse'] = this.state.standardParams.reverse ? "true" : "false";
        }
        if (!CoreUtils.isEmpty(this.state.standardParams.headers)) {
            // Default is true.
            queryParams['headers'] = !this.state.standardParams.headers ? "false" : "true";
        }
        if (this.state.standardParams.fields && this.state.standardParams.fields.length > 0) {
            queryParams['fields'] = this.state.standardParams.fields.join();
        }
        if (!CoreUtils.isEmpty(this.state.standardParams.apiKey)) {
            queryParams['api_key'] = this.state.standardParams.apiKey!;
        }
        return queryParams;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readQueryHeadersRequest());
        requests.push(this.readApiKeysRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={3}
                status={isLoading ? "loading" : "failed"}
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    public submit(): void {
        this.formRef.current!.submit();
    }

    private buildOptions(headers: QueryHeader[], i: number, type: PrivacyType): [ReactElement[], number] {
        const options = [];
        while (i < headers.length) {
            const header = headers[i];
            if (type === header.privacyType) {
                const option = (
                    <Select.Option
                        key={header.id}
                        value={header.id}
                    >
                        {header.name}
                    </Select.Option>
                );
                options.push(option);
                i++;
            } else {
                break;
            }
        }
        return [options, i];
    }

    private buildOptGroups(): ReactElement[] {
        const headers = this.state.queries;
        const groups = [];
        let i = 0;
        while (i < headers.length) {
            const header = headers[i];
            const type = header.privacyType!;
            const data = this.buildOptions(headers, i, type);
            const options = data[0];
            if (options.length > 0) {
                const group = (
                    <Select.OptGroup
                        key={i}
                        label={type}
                    >
                        {options}
                    </Select.OptGroup>
                );
                groups.push(group);
            }
            i = data[1];
        }
        return groups;
    }

    private buildQueryView(): ReactElement {
        return (
            <Form ref={this.formRef} onFinish={this.handleEditComplete}>
                <Row gutter={[Globals.FORM_GUTTER_COL, Globals.FORM_GUTTER_ROW]}>
                    <Col span={this.props.wideLayout ? 12 : 24}>
                        {!this.props.excludes.includes("id") &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="Dataset"
                                name="dataset"
                                info="The dataset to query for."
                                rules={[{
                                    required: true,
                                    message: 'Please select a dataset.'
                                }]}
                            >
                                <Select
                                    placeholder="Select a dataset."
                                    allowClear={true}
                                    value={this.state.queryId}
                                    onChange={this.handleQueryChange}
                                >
                                    {this.buildOptGroups()}
                                </Select>
                            </FormItem>
                        }
                        {!this.props.excludes.includes("version") &&
                            <QueryVersionPicker
                                formRef={this.formRef}
                                queryId={this.state.queryId}
                                version={this.state.standardParams.version}
                                onChange={this.handleVersionChange}
                            />
                        }
                        <QueryLoader
                            formRef={this.formRef}
                            visible={!!this.state.query}
                            queryId={this.state.queryId}
                            queryVersion={this.state.standardParams.version}
                            onLoaded={this.handleQueryLoaded}
                        />
                        {this.state.queryId && this.state.query &&
                            <ConfigurationEditor
                                formRef={this.formRef}
                                configuration={this.state.configuration}
                                configurationSpecs={this.state.query.configurationSpecs}
                                variableSpecs={this.props.variableSpecs}
                                timeSyntax={this.props.timeSyntax}
                                showEmpty={true}
                                showExpressions={this.props.allowExpressions}
                                onChange={this.handleConfigurationChange}
                            />
                        }
                        {!this.props.excludes.includes("format") &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="Format"
                                name="format"
                                info="The format of the returned dataset."
                                rules={[{
                                    required: true,
                                    message: 'Please select a dataset format.'
                                }]}
                            >
                                <Select
                                    placeholder="Select a dataset format."
                                    allowClear={true}
                                    value={this.state.standardParams.format}
                                    onChange={this.handleFormatChange}
                                >
                                    <Select.Option value={FormatType.CSV}>CSV</Select.Option>
                                    <Select.Option value={FormatType.JSON}>JSON</Select.Option>
                                    <Select.Option value={FormatType.EXCEL_JSON}>Excel Import</Select.Option>
                                </Select>
                            </FormItem>
                        }
                        {!this.props.excludes.includes("api_key") &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="API Key"
                                name="apikey"
                                info="One of your keys is required to access the API. If you do not have any keys, please generate one first."
                                rules={[{
                                    required: true,
                                    message: 'Please select a key to access the API.'
                                }]}
                            >
                                <Select
                                    className="x-querybuilder-key"
                                    placeholder="Select an API key."
                                    allowClear={true}
                                    value={this.state.standardParams.apiKey}
                                    onChange={this.handleApiKeyChange}>
                                    {this.state.apiKeys.map((apiKey, index) => (
                                        <Select.Option
                                            key={index}
                                            value={apiKey.secretKey}
                                        >
                                            {apiKey.secretKey}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        }
                    </Col>
                    <Col span={this.props.wideLayout ? 12 : 24}>
                        {!this.props.wideLayout &&
                            <Justify justification="right">
                                <Button
                                    className="x-querybuilder-advanced"
                                    type="link"
                                    onClick={this.handleAdvancedClick}
                                >
                                    {`${this.state.showAdvanced ? 'Hide' : 'Show'} advanced options`}
                                </Button>
                            </Justify>
                        }
                        {(this.state.showAdvanced || this.props.wideLayout) &&
                            <>
                                {!this.props.excludes.includes("fields") &&
                                    <Spacer alignment="middle">
                                        <FormItem
                                            {...Globals.FORM_LAYOUT}
                                            formRef={this.formRef}
                                            label="Columns"
                                            name="fields"
                                            info="Columns to return as part of the dataset."
                                        >
                                            <Spacer
                                                fill
                                                alignment="middle"
                                                value={this.state.standardParams.fields}
                                            >
                                                <Select
                                                    allowClear
                                                    mode="multiple"
                                                    disabled={!this.state.standardParams.fields}
                                                    placeholder={!this.state.standardParams.fields ? "Include all columns in dataset." : "Select columns to include in dataset."}
                                                    value={this.state.standardParams.fields}
                                                    onChange={this.handleFieldsChange}
                                                >
                                                    {this.state.query?.schema.fields.map((field: Field) => (
                                                        <Select.Option
                                                            key={field.key}
                                                            value={field.key}
                                                        >
                                                            {field.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                                <Switch
                                                    checked={!this.state.standardParams.fields}
                                                    onChange={this.handleAllFieldsChange}
                                                />
                                            </Spacer>
                                        </FormItem>
                                    </Spacer>
                                }
                                {!this.props.excludes.includes("headers") &&
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.formRef}
                                        label="Headers"
                                        name="headers"
                                        info="Include the headers with the dataset."
                                    >
                                        <Switch
                                            checkedChildren="Include"
                                            unCheckedChildren="Exclude"
                                            checked={CoreUtils.isEmpty(this.state.standardParams.headers) || this.state.standardParams.headers}
                                            onChange={this.handleHeadersChange}
                                        />
                                    </FormItem>
                                }
                                {!this.props.excludes.includes("limit") &&
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.formRef}
                                        label="Max Records"
                                        name="limit"
                                        info="The maximum number of records to return in the dataset."
                                    >
                                        <IntegerInput
                                            fill={true}
                                            placeholder="# of Rows"
                                            value={this.state.standardParams.limit}
                                            onChange={this.handleLimitChange}
                                        />
                                    </FormItem>
                                }

                                {!this.props.excludes.includes("rows") &&
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.formRef}
                                        label="Rows"
                                        name="rows"
                                        info="The rows to return, e.g., 2, 2-5, 1,4."
                                    >
                                        <Input
                                            placeholder="Specify rows or a range of rows."
                                            value={this.state.standardParams.rows}
                                            onChange={this.handleRowsChange}
                                        />
                                    </FormItem>
                                }

                                {!this.props.excludes.includes("reverse") &&
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.formRef}
                                        label="Order"
                                        name="order"
                                        info="The order of returned records in the dataset."
                                    >
                                        <Radio.Group
                                            className="x-querybuilder-radio-group"
                                            value={!!this.state.standardParams.reverse}
                                            onChange={this.handleOrderChange}>
                                            <Radio
                                                value={false}
                                            >
                                                Default
                                            </Radio>
                                            <Radio
                                                value={true}
                                            >
                                                Reverse
                                            </Radio>
                                        </Radio.Group>
                                    </FormItem>
                                }
                            </>
                        }
                    </Col>
                </Row>
            </Form>
        )
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildQueryView();
        }
        return (
            <div className={classNames("x-querybuilder", this.props.className)}>
                {view}
            </div>
        );
    }

}
