import { PureComponent, ReactElement } from 'react';
import { Button, Modal } from 'antd';
import { Specs } from 'containers/Console/Specs/Specs';
import { ConfigurationType, VariableSpec } from '@methodset/endpoint-client-ts';
import { SpecType } from 'containers/Console/Specs/SpecsEditor/SpecEditor';
import { ModelContext } from 'context/ModelContext';
import { Parameter } from '@methodset/calculator-ts';
import './AppletInputs.less';

export type ChangeCallback = (variableSpecs: VariableSpec[]) => void;
export type CancelCallback = () => void;

export type AppletInputsProps = typeof AppletInputs.defaultProps & {
    variableSpecs: VariableSpec[],
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export type AppletInputsState = {
    isEditing: boolean,
    variableSpecs: VariableSpec[]
}

export class AppletInputs extends PureComponent<AppletInputsProps, AppletInputsState> {

    static defaultProps = {
    }

    static contextType = ModelContext;

    constructor(props: AppletInputsProps) {
        super(props);
        this.state = {
            isEditing: false,
            variableSpecs: props.variableSpecs
        };
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleSpecsChange = this.handleSpecsChange.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
    }

    private handleOkClick(): void {
        this.props.onChange(this.state.variableSpecs);
    }

    private handleSpecsChange(variableSpecs: VariableSpec[]): void {
        this.setState({ variableSpecs: variableSpecs });
    }

    private handleEditChange(isEditing: boolean): void {
        this.setState({ isEditing: isEditing });
    }

    private buildParameters(): Parameter[] {
        const parameters: Parameter[] = [];
        const calculator = this.context.calculator;
        calculator.parameters.forEach((parameter: Parameter) => {
            parameters.push(parameter);
        });
        return parameters;
    }

    public render(): ReactElement {
        return (
            <Modal
                wrapClassName="x-widgetspecs"
                centered
                title="Configure Inputs"
                visible={true}
                width={900}
                onCancel={this.props.onCancel}
                footer={(
                    <>
                        <Button disabled={this.state.isEditing} onClick={this.props.onCancel}>Cancel</Button>
                        <Button type="primary" disabled={this.state.isEditing} onClick={this.handleOkClick}>OK</Button>
                    </>
                )}
            >
                <Specs
                    specType={SpecType.REFERENCE}
                    buttonLabel="Export Variable"
                    parameters={this.buildParameters()}
                    excludes={[ConfigurationType.CREDENTIALS]}
                    variableSpecs={this.state.variableSpecs}
                    onEdit={this.handleEditChange}
                    onChange={this.handleSpecsChange}
                />
            </Modal>
        );
    }

}
