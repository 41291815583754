import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Col, Collapse, Input, Row, Switch, FormInstance, Form } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { SchemaEditor } from './SchemaEditor/SchemaEditor';
import { Globals } from 'constants/Globals';
import { 
    AuthenticationHeader, 
    Component, 
    Configuration, 
    ConfigurationSpec, 
    Field, 
    Flow, 
    InputSpec,
    IoMap,
    OutputSpec,
    ProcessorHeader, 
    Schema,
    UserQuery
} from '@methodset/endpoint-client-ts';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { Processors } from '../../../Processors/Processors';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { Specs } from 'containers/Console/Specs/Specs';
import update from 'immutability-helper';
import classNames from 'classnames';
import axios from 'axios';
import endpointService from 'services/EndpointService';
import './DatasetEditor.less';

export type TouchCallback = () => void;
export type SaveCallback = (query: UserQuery) => void;
export type ErrorCallback = (error: Error) => void;

export type DatasetEditorProps = typeof DatasetEditor.defaultProps & {
    // Class to style the form.
    className?: string,
    // The id of the query to load and edit.
    queryId?: string,
    // Called when the query is changed.
    onTouch: TouchCallback,
    // Called when the query is saved.
    onSaved: SaveCallback,
    // Called when there is an error processing the query.
    onError: ErrorCallback
}

export type DatasetEditorState = {
    // Status of loading query.
    status: string,
    // The processor headers.
    processors: ProcessorHeader[],
    // The query being edited.
    userQuery: UserQuery,
    // True if user has API publishing enabled.
    apiEnabled: boolean,
    // The list of user authentications.
    authentications: AuthenticationHeader[],
    // Configuration (variable values) needed to run processors and schema.
    configuration: Configuration,
}

export class DatasetEditor extends PureComponent<DatasetEditorProps, DatasetEditorState> {

    static defaultProps = {
    }

    private propertiesRef = React.createRef<FormInstance>();
    private schemaRef = React.createRef<FormInstance>();

    // Original value used to check for changes on save.
    private origUserQuery?: UserQuery;

    constructor(props: DatasetEditorProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            processors: [],
            authentications: [] as AuthenticationHeader[],
            userQuery: {
                id: undefined as any,
                version: undefined as any,
                type: undefined as any,
                name: undefined as any,
                description: undefined as any,
                publisher: undefined as any,
                configurationSpecs: [] as ConfigurationSpec[],
                inputSpecs: [] as InputSpec[],
                outputSpecs: [] as OutputSpec[],
                defaultOutput: undefined as any,
                schema: {
                    fields: [] as Field[]
                } as Schema,
                serviceName: undefined as any,
                methodName: undefined as any,
                components: [] as Component[],
                flows: [] as Flow[],
                resultMappings: {} as IoMap
            },
            configuration: {},
            apiEnabled: false,
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleApiEnabledChange = this.handleApiEnabledChange.bind(this);
        this.handleServiceChange = this.handleServiceChange.bind(this);
        this.handleMethodChange = this.handleMethodChange.bind(this);
        this.handlePublisherChange = this.handlePublisherChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleVariableSpecsChange = this.handleVariableSpecsChange.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
        this.handleComponentsChange = this.handleComponentsChange.bind(this);
        this.handleSchemaChange = this.handleSchemaChange.bind(this);
        this.saveQuery = this.saveQuery.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.value;
        const userQuery = update(this.state.userQuery, {
            name: { $set: name },
        });
        this.setState({ userQuery: userQuery });
        this.props.onTouch();
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const userQuery = update(this.state.userQuery, {
            description: { $set: description }
        });
        this.setState({ userQuery: userQuery });
        this.props.onTouch();
    }

    private handleApiEnabledChange(apiEnabled: boolean): void {
        if (apiEnabled) {
            const methodName = CoreUtils.toCodeName(this.state.userQuery.name, "-", true);
            const userQuery = update(this.state.userQuery, {
                methodName: { $set: methodName }
            });
            this.setState({ userQuery: userQuery });
        } else if (this.state.userQuery.name) {
            const query = update(this.state.userQuery, {
                $unset: ["methodName", "serviceName"]
            });
            this.setState({ userQuery: query });
        }
        this.setState({ apiEnabled: apiEnabled });
        this.props.onTouch();
    }

    private handleServiceChange(e: ChangeEvent<HTMLInputElement>): void {
        let serviceName = e.target.value;
        serviceName = CoreUtils.toCodeName(serviceName);
        const userQuery = update(this.state.userQuery, {
            serviceName: { $set: serviceName }
        });
        this.setState({ userQuery: userQuery });
        this.props.onTouch();
    }

    private handleMethodChange(e: ChangeEvent<HTMLInputElement>): void {
        let methodName = e.target.value;
        methodName = CoreUtils.toCodeName(methodName);
        const userQuery = update(this.state.userQuery, {
            methodName: { $set: methodName }
        });
        this.setState({ userQuery: userQuery });
        this.props.onTouch();
    }

    private handlePublisherChange(e: ChangeEvent<HTMLInputElement>): void {
        const publisher = e.target.value;
        const userQuery = update(this.state.userQuery, {
            publisher: { $set: publisher }
        });
        this.setState({ userQuery: userQuery });
        this.props.onTouch();
    }

    private handleComponentsChange(components: Component[]): void {
        const userQuery = update(this.state.userQuery, {
            components: { $set: components }
        });
        this.setState({ userQuery: userQuery });
        this.props.onTouch();
    }

    private handleSchemaChange(schema: Schema): void {
        const userQuery = update(this.state.userQuery, {
            schema: { $set: schema }
        });
        this.origUserQuery = userQuery;
        this.setState({ userQuery: userQuery });
        this.props.onTouch();
    }

    private handleVariableSpecsChange(variableSpecs: ConfigurationSpec[]): void {
        const userQuery = update(this.state.userQuery, {
            configurationSpecs: { $set: variableSpecs }
        })
        this.setState({ userQuery: userQuery });
        this.props.onTouch();
    }

    private handleConfigurationChange(configuration: Configuration): void {
        this.setState({ configuration: configuration });
        this.props.onTouch();
    }

    public saveQuery(callback: () => void): void {
        // Validate the properties and schema sections. Variables and processors 
        // have already been validated when they were edited (panels only show the
        // results of sub-editors).
        this.propertiesRef.current?.validateFields().then(values => {
            const userQuery = this.state.userQuery;
            if (!userQuery.schema.fields || userQuery.schema.fields.length === 0) {
                this.props.onError(new Error('Please load schema fields.'));
                return;
            }
            if (this.origUserQuery) {
                // If parts the the query have changed that would affect the
                // result fields, force the user to reload the schema so that
                // the updated values can be saved in the query.
                if (this.origUserQuery.components !== this.state.userQuery.components ||
                    this.origUserQuery?.configurationSpecs !== this.state.userQuery.configurationSpecs) {
                    this.props.onError(new Error('Configuration has changed, please sync schema fields and then save.'));
                    return;
                }
            }
            this.schemaRef.current?.validateFields().then(values => {
                if (userQuery.id) {
                    this.updateQueryRequest(userQuery);
                } else {
                    this.createQueryRequest(userQuery);
                }
                callback();
            }).catch(error => {
                this.props.onError(new Error('Please fill in all fields in the schema section.'));
            });
        }).catch(error => {
            this.props.onError(new Error('Please fill in all fields in the properties section.'));
        });
    }

    private readAuthenticationHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readAuthenticationHeaders(request,
            (response: any) => this.readAuthenticationHeadersResponse(response),
            undefined, true
        );
    }

    private readAuthenticationHeadersResponse(response: any): any {
        const authentications = response.data.headers;
        this.setState({ authentications: authentications });
    }

    private readProcessorHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readProcessorHeaders(request,
            (response: any) => this.readProcessorHeadersResponse(response),
            undefined, true
        );
    }

    private readProcessorHeadersResponse(response: any): void {
        let processors = response.data.headers;
        this.setState({ processors: processors });
    }

    private readQueryRequest(): Promise<any> {
        const queryId = this.props.queryId;
        if (!queryId || queryId === 'create') {
            return Promise.resolve(true);
        }
        const request = {
            queryId: queryId
        };
        return endpointService.readUserQuery(request,
            (response: any) => this.readQueryResponse(response),
            undefined, true
        );
    }

    private readQueryResponse(response: any): void {
        const userQuery = response.data.userQuery;
        const apiEnabled = !!userQuery.serviceName && !!userQuery.methodName;
        this.origUserQuery = userQuery;
        this.setState({
            userQuery: userQuery,
            apiEnabled: apiEnabled
        });
    }

    private createQueryRequest(query: UserQuery): Promise<any> {
        const request = {
            name: query.name,
            description: query.description,
            publisher: query.publisher,
            serviceName: this.state.apiEnabled ? query.serviceName : undefined,
            methodName: this.state.apiEnabled ? query.methodName : undefined,
            configurationSpecs: query.configurationSpecs,
            components: query.components,
            schema: query.schema
        };
        return endpointService.createUserQuery(request,
            (response: any) => this.createQueryResponse(response),
            (response: any) => this.saveException(response),
            true
        );
    }

    private createQueryResponse(response: any): void {
        const query = response.data.query;
        this.props.onSaved(query);
    }

    private updateQueryRequest(query: UserQuery): Promise<any> {
        const request = {
            queryId: query.id,
            name: query.name,
            description: query.description,
            publisher: query.publisher,
            serviceName: this.state.apiEnabled ? query.serviceName : undefined,
            methodName: this.state.apiEnabled ? query.methodName : undefined,
            configurationSpecs: query.configurationSpecs,
            components: query.components,
            schema: query.schema
        };
        return endpointService.updateUserQuery(request,
            (response: any) => this.updateQueryResponse(response),
            (response: any) => this.saveException(response),
            true
        );
    }

    private updateQueryResponse(response: any): void {
        const query = response.data.userQuery;
        this.props.onSaved(query);
    }

    private saveException(response: any): void {
        const message = RestUtils.getError(response);
        this.props.onError(new Error(message));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={4}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load query."
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    private buildFormView(): ReactElement {
        return (
            <Collapse defaultActiveKey={["properties"]}>
                <Collapse.Panel key="properties" header="Properties" forceRender={true}>
                    <Form ref={this.propertiesRef}>
                        <Row gutter={[Globals.FORM_GUTTER_COL, Globals.FORM_GUTTER_ROW]}>
                            <Col span={12}>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Name"
                                    name="name"
                                    info="The name of the dataset."
                                    rules={[{
                                        required: true,
                                        message: 'Please enter a name.'
                                    }]}
                                >
                                    <Input
                                        placeholder="Dataset name."
                                        value={this.state.userQuery.name}
                                        onChange={this.handleNameChange}
                                    />
                                </FormItem>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Description"
                                    name="description"
                                    info="A description of the dataset."
                                >
                                    <Input.TextArea
                                        placeholder="Dataset description."
                                        rows={3}
                                        value={this.state.userQuery.description}
                                        onChange={this.handleDescriptionChange}
                                    />
                                </FormItem>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Publish API"
                                    name="api"
                                    info="Publishing allows external API access to the dataset."
                                >
                                    <Switch
                                        checked={this.state.apiEnabled}
                                        onChange={this.handleApiEnabledChange}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="API Service"
                                    name="service"
                                    info="Specifies an API service name for this dataset. Use a value to associate a group 
                                            of related datasets."
                                    hidden={!this.state.apiEnabled}
                                    rules={[{
                                        required: !!this.state.apiEnabled,
                                        message: 'Please enter an API service name.'
                                    }]}
                                >
                                    <Input
                                        placeholder="API service name."
                                        value={this.state.userQuery.serviceName}
                                        onChange={this.handleServiceChange}
                                    />
                                </FormItem>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="API Method"
                                    name="method"
                                    info="Specifies an API method name for this dataset. Use a value that is meaningful 
                                            to the data fetched from this dataset."
                                    hidden={!this.state.apiEnabled}
                                    rules={[{
                                        required: !!this.state.apiEnabled,
                                        message: 'Please enter an API method name.'
                                    }]}
                                >
                                    <Input
                                        placeholder="API method name."
                                        value={this.state.userQuery.methodName}
                                        onChange={this.handleMethodChange}
                                    />
                                </FormItem>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Publisher"
                                    name="publisher"
                                    info="The publishing source of the data."
                                    hidden={!this.state.apiEnabled}
                                >
                                    <Input
                                        placeholder="Data publisher."
                                        value={this.state.userQuery.publisher}
                                        onChange={this.handlePublisherChange}
                                    />
                                </FormItem>

                            </Col>
                        </Row>
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel key="variables" header="Variables" forceRender={true}>
                    <Specs
                        variableSpecs={this.state.userQuery.configurationSpecs}
                        onChange={this.handleVariableSpecsChange}
                    />
                </Collapse.Panel>
                <Collapse.Panel key="processors" header="Processors" forceRender={true}>
                    <Processors
                        headers={this.state.processors}
                        components={this.state.userQuery.components}
                        variableSpecs={this.state.userQuery.configurationSpecs}
                        configuration={this.state.configuration}
                        authentications={this.state.authentications}
                        allowAssignment={false}
                        allowTest={true}
                        serialFlow={true}
                        onUpdate={this.handleConfigurationChange}
                        onChange={this.handleComponentsChange}
                    />
                </Collapse.Panel>
                <Collapse.Panel key="schema" header="Schema" forceRender={true}>
                    <Form ref={this.schemaRef}>
                        <Row>
                            <Col span={24}>
                                <SchemaEditor
                                    formRef={this.schemaRef}
                                    components={this.state.userQuery.components}
                                    configuration={this.state.configuration}
                                    configurationSpecs={this.state.userQuery.configurationSpecs}
                                    authentications={this.state.authentications}
                                    schema={this.state.userQuery.schema}
                                    onUpdate={this.handleConfigurationChange}
                                    onChange={this.handleSchemaChange}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Collapse.Panel>
            </Collapse>
        );
    }

    private loadData() {
        const requests = [];
        requests.push(this.readQueryRequest());
        requests.push(this.readProcessorHeadersRequest());
        requests.push(this.readAuthenticationHeadersRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1, r2, r3) => {
            if (RestUtils.isOk(r1, r2, r3)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount() {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render() {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildFormView();
        }
        return (
            <div className={classNames('x-dataseteditor', this.props.className)}>
                {view}
            </div>
        );
    }

}
