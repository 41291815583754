import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CodeOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, message } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RestUtils } from 'utils/RestUtils';
import authService from 'services/AuthService';
import update from 'immutability-helper';
import './RegisterConfirm.less';

interface FormData {
    confirmationCode: string
}

type MatchParams = {
    userId: string
}

export type RegisterConfirmProps = RouteComponentProps<MatchParams> & {}

export type RegisterConfirmState = {
    errorMessage?: string,
    isSubmitting: boolean,
    formData: FormData
}

export class RegisterConfirm extends PureComponent<RegisterConfirmProps, RegisterConfirmState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: RegisterConfirmProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isSubmitting: false,
            formData: {} as FormData
        };
        this.formRef = React.createRef();
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleResendClick = this.handleResendClick.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleCodeChange(e: ChangeEvent<HTMLInputElement>): void {
        const confirmationCode = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                confirmationCode: { $set: confirmationCode }
            })
        });
    }

    private handleResendClick(): void {
        const { params } = this.props.match;
        this.resendConfirmationCodeRequest(params.userId);
    }

    private handleFormFinish(): void {
        const { params } = this.props.match;
        this.confirmRegistrationRequest(params.userId, this.state.formData.confirmationCode);
    }

    private confirmRegistrationRequest(userId: string, confirmationCode: string): void {
        this.setState({
            isSubmitting: true,
            errorMessage: undefined
        });
        authService.confirmRegistration(userId, confirmationCode,
            (response: any) => this.confirmRegistrationResponse(response),
            (error: Error) => this.confirmRegistrationException(error));
    }

    private confirmRegistrationResponse(response: any): void {
        this.setState({ isSubmitting: false });
        this.props.history.push(RouteBuilder.user(this.props, RouteBuilder.USER_LOGIN));
    }

    private confirmRegistrationException(error: Error): void {
        this.setState({
            isSubmitting: false,
            errorMessage: RestUtils.getError(error.message)
        });
    }

    private resendConfirmationCodeRequest(userId: string): void {
        this.setState({
            isSubmitting: true,
            errorMessage: undefined
        });
        authService.resendConfirmationCode(userId,
            (response: any) => this.resendConfirmationCodeResponse(response),
            (error: Error) => this.resendConfirmationCodeException(error));
    }

    private resendConfirmationCodeResponse(response: any): void {
        this.setState({ isSubmitting: false });
        message.success("Your confirmation code has been sent!");
    }

    private resendConfirmationCodeException(error: Error): void {
        this.setState({
            isSubmitting: false,
            errorMessage: RestUtils.getError(error)
        });
    }

    public render(): ReactElement {
        return (
            <FormFrame
                ref={this.formRef}
                title="Register"
                description="A confirmation code was sent to your email address. Please 
                    enter it below to complete the registration process. You will then 
                    be asked to login with your new credentials."
                error={this.state.errorMessage}
                hideFooter={true}
                onOk={this.handleFormFinish}
            >
                <FormItem
                    formRef={this.formRef}
                    name="code"
                    rules={[{
                        required: true,
                        message: 'Please enter the confirmation code.'
                    }]}
                >
                    <Input
                        id="code"
                        prefix={<CodeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Confirmation code."
                        value={this.state.formData.confirmationCode}
                        onChange={this.handleCodeChange}
                    />
                </FormItem>
                <Button
                    className="x-registerconfirm-submit"
                    type="primary"
                    htmlType="submit"
                    loading={this.state.isSubmitting}
                >
                    Confirm
                </Button>
                <span className="x-registerconfirm-question">Didn't get your code?</span>
                <Link to="#" onClick={this.handleResendClick}>Resend now.</Link>
            </FormFrame>
        );
    }

}
