import React, { ChangeEvent, PureComponent } from 'react';
import { Form, FormInstance, Input, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ItemEditor } from 'containers/Console/ItemEditor/ItemEditor';
import { RestUtils } from 'utils/RestUtils';
import { Link, RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { AccessRules, Organization, Tenant, TenantType } from '@methodset/entity-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { ItemTable } from '../ItemTable/ItemTable';
import { EditOutlined } from '@ant-design/icons';
import { CoreUtils } from 'utils/CoreUtils';
import { RulesData, RulesEditor } from 'containers/Main/User/Organization/RulesEditor/RulesEditor';
import entityService from 'services/EntityService';
import axios from 'axios';
import update from 'immutability-helper';
import { v4 as uuid } from "uuid";
import './OrganizationItem.less';

type MatchParams = {}

export type OrganizationItemProps = typeof OrganizationItem.defaultProps & RouteComponentProps<MatchParams> & {
    organization?: Organization
}

export type OrganizationItemState = {
    status: string,
    organization: Organization,
    errorMessage: string | undefined,
    isLoading: boolean,
    editTenant?: Tenant
    editRules?: RulesData
}

export class OrganizationItem extends PureComponent<OrganizationItemProps, OrganizationItemState> {

    static defaultProps = {
        organization: {} as Organization
    }

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();
    private tenantRef = React.createRef<FormInstance>();
    private modalCount = 0;

    constructor(props: OrganizationItemProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            organization: props.organization,
            errorMessage: undefined,
            isLoading: false
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleRulesChange = this.handleRulesChange.bind(this);
        this.handleTenantOk = this.handleTenantOk.bind(this);
        this.handleTenantAdd = this.handleTenantAdd.bind(this);
        this.handleTenantEdit = this.handleTenantEdit.bind(this);
        this.handleTenantSave = this.handleTenantSave.bind(this);
        this.handleTenantCancel = this.handleTenantCancel.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleSaveClick(): void {
        this.updateOrganizationRequest();
    }

    private handleCancelClick(): void {
        this.toPrevPage();
    }

    private handleTenantOk() {
        this.tenantRef.current?.submit();
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const organization = update(this.state.organization, {
            name: { $set: name }
        });
        this.setState({ organization: organization });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const organization = update(this.state.organization, {
            description: { $set: description }
        });
        this.setState({ organization: organization });
    }

    private handleRulesChange(rules: RulesData): void {
        this.setState({ editRules: rules });
    }

    private handleTenantEdit(tenant: Tenant): void {
        const rules = {
            isEnabled: tenant.rules.isEnabled,
            whitelist: this.toList(tenant.rules.whitelist),
            blacklist: this.toList(tenant.rules.blacklist),
            domain: tenant.rules.domain
        }
        this.setState({
            editTenant: tenant,
            editRules: rules
        });
        this.modalCount++;
    }

    private handleTenantAdd(info: any): void {
        const editTenant = {
            id: uuid(),
            type: info.key,
            rules: {}
        } as Tenant;
        this.setState({
            editTenant: editTenant,
            editRules: {}
        });
    }

    private handleTenantSave(): void {
        const index = this.state.organization.tenants.findIndex(tenant => tenant.id === this.state.editTenant!.id);
        if (index === -1) {
            return;
        }
        const tenant = {
            id: this.state.editTenant?.id,
            type: this.state.editTenant?.type,
            rules: {
                isEnabled: this.state.editRules?.isEnabled,
                whitelist: this.toItems(this.state.editRules?.whitelist),
                blacklist: this.toItems(this.state.editRules?.blacklist),
                domain: this.state.editRules?.domain
            } as AccessRules
        } as Tenant;
        const organization = update(this.state.organization, {
            tenants: {
                [index]: { $set: tenant }
            }
        });
        this.setState({
            editTenant: undefined,
            editRules: undefined,
            organization: organization
        });
    }

    private handleTenantCancel(): void {
        this.setState({
            editTenant: undefined,
            editRules: undefined
        });
    }

    private readOrganizationRequest(): Promise<any> {
        const request = {};
        return entityService.readOrganization(request,
            (response: any) => this.readOrganizationResponse(response),
            undefined, true
        );
    }

    private readOrganizationResponse(response: any): void {
        const organization = response.data.organization;
        this.setState({ organization: organization });
    }

    private updateOrganizationRequest(): Promise<any> {
        this.setState({
            errorMessage: undefined,
            isLoading: true
        });
        const request = {
            organizationId: this.state.organization.id,
            name: this.state.organization.name,
            description: this.state.organization.description,
            tenants: this.state.organization.tenants
        };
        return entityService.updateOrganization(request,
            (response: any) => this.updateOrganizationResponse(response),
            (response: any) => this.updateOrganizationException(response),
            true
        );
    }

    private updateOrganizationResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isLoading: false
        });
        this.toPrevPage();
    }

    private updateOrganizationException(response: any): void {
        this.setState({
            errorMessage: RestUtils.getError(response),
            isLoading: false
        });
    }

    private toPrevPage(): void {
        this.props.history.go(-(this.modalCount+1));
    }

    private toItems(value: string | undefined): string[] {
        return value ? value.split(/[, ]+/) : [];
    }

    private toList(value: string[] | undefined): string | undefined {
        return value ? value.join(", ") : undefined;
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'type',
            title: 'Type',
            dataIndex: 'type',
            render: (value, tenant) => {
                return <Link to="#" onClick={() => this.handleTenantEdit(tenant)}>{CoreUtils.toProper(value)}</Link>
            },
        }];
        return columns;
    }

    private buildData(): any[] {
        return this.state.organization.tenants;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readOrganizationRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    render() {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit environment",
            callback: this.handleTenantEdit
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <>
                <ItemEditor
                    className="x-organizationitem"
                    ref={this.formRef}
                    title="Organization"
                    status={this.state.status}
                    loading={this.state.isLoading}
                    okText="Save"
                    onSave={this.handleSaveClick}
                    onCancel={this.handleCancelClick}
                    onRetry={this.handleRetryLoad}
                >
                    <div className="x-organizationitem-intro">Update your organization.</div>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Name"
                        name="name"
                        info="The name of the organization."
                        rules={[{
                            required: true,
                            message: 'Please enter a name.'
                        }]}
                    >
                        <Input
                            placeholder="Organization name."
                            value={this.state.organization.name}
                            onChange={this.handleNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Description"
                        name="description"
                        info="The description of the model."
                    >
                        <Input.TextArea
                            placeholder="Dashboard description."
                            rows={5}
                            value={this.state.organization.description}
                            onChange={this.handleDescriptionChange}
                        />
                    </FormItem>
                    <ItemTable
                        title="Environments"
                        status={this.state.status}
                        columns={columns}
                        items={data}
                        actions={actions}
                        pagination={false}
                        showBack={false}
                        onLoad={this.handleRetryLoad}
                    />
                    <div className="x-organizationitem-error">{this.state.errorMessage}</div>
                </ItemEditor>
                {this.state.editTenant &&
                    <Modal
                        centered
                        title="Edit Environment"
                        visible={true}
                        closable={false}
                        width={Globals.DIALOG_WIDTH}
                        onOk={this.handleTenantOk}
                        onCancel={this.handleTenantCancel}
                    >
                        <Form
                            ref={this.tenantRef}
                            onFinish={this.handleTenantSave}
                        >
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.tenantRef}
                                label="Type"
                                name="type"
                                info="The type of environment."
                            >
                                <Input
                                    value={CoreUtils.toProper(this.state.editTenant.type)}
                                    disabled={true}
                                />
                            </FormItem>
                            <RulesEditor
                                formRef={this.tenantRef}
                                rules={this.state.editRules}
                                onChange={this.handleRulesChange}
                            />
                        </Form>
                    </Modal>
                }
            </>
        );
    }

}
