import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, message, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RestUtils } from 'utils/RestUtils';
import authService from 'services/AuthService';
import update from 'immutability-helper';
import { RouteComponentProps } from 'react-router-dom';
import './ChangePassword.less';

interface FormData {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
}

type MatchParams = {
    userId: string
}

export type ChangePasswordState = {
    errorMessage?: string,
    isSubmitting: boolean,
    formData: FormData
}

export type ChangePasswordProps = RouteComponentProps<MatchParams> & {}

export class ChangePassword extends PureComponent<ChangePasswordProps, ChangePasswordState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: ChangePasswordProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isSubmitting: false,
            formData: {} as FormData
        };
        this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    private handleOldPasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        const oldPassword = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                oldPassword: { $set: oldPassword }
            })
        });
    }

    private handleNewPasswordChange(e: ChangeEvent<HTMLInputElement>) {
        const newPassword = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                newPassword: { $set: newPassword }
            })
        });
    }

    private handleConfirmPasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        const confirmPassword = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                confirmPassword: { $set: confirmPassword }
            })
        });
    }

    private handleFormFinish(): void {
        const { params } = this.props.match;
        this.changePasswordRequest(params.userId);
    }

    private handleCancelClick(): void {
        this.props.history.goBack();
    }

    private changePasswordRequest(userId: string): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: true
        });
        const oldPassword = this.state.formData.oldPassword
        const newPassword = this.state.formData.newPassword
        authService.changePassword(oldPassword, newPassword,
            (response: any) => this.changePasswordResponse(response),
            (error: Error) => this.changePasswordException(error)
        );
    }

    private changePasswordResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: false
        });
        message.success('Your password has been updated.');
        this.props.history.goBack();
    }

    private changePasswordException(error: Error): void {
        this.setState({
            errorMessage: RestUtils.getError(error),
            isSubmitting: false
        });
    }

    private buildResetForm(): ReactElement {
        return (
            <>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Old Password"
                    name="old-password"
                    rules={[{
                        required: true,
                        message: 'Please enter current password.'
                    }]}
                >
                    <Input.Password
                        id="old-password"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Old password."
                        value={this.state.formData.oldPassword}
                        onChange={this.handleOldPasswordChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="New Password"
                    name="new-password"
                    rules={[{
                        required: true,
                        message: 'Please enter a new password.'
                    }, {
                        validator: (rule: any, value: string) => {
                            const message = authService.validatePassword(this.state.formData.newPassword);
                            return message ? Promise.reject(message) : Promise.resolve();
                        }
                    }]}
                >
                    <Input.Password
                        id="new-password"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="New password."
                        value={this.state.formData.newPassword}
                        onChange={this.handleNewPasswordChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Confirm"
                    name="confirm"
                    rules={[{
                        required: true,
                        message: 'Please enter the new password again.'
                    },
                    ({ getFieldValue }: { getFieldValue: Function }) => ({
                        validator(rule: any, value: string) {
                            if (value && value !== getFieldValue('new-password')) {
                                return Promise.reject('The confirm password does not match.');
                            } else {
                                return Promise.resolve();
                            }
                        }
                    })
                    ]}
                >
                    <Input.Password
                        id="confirm"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Confirm new password."
                        //onBlur={this.handleConfirmPasswordBlur}
                        value={this.state.formData.confirmPassword}
                        onChange={this.handleConfirmPasswordChange}
                    />
                </FormItem>
            </>
        );
    }

    public render(): ReactElement {
        const view = this.buildResetForm();
        return (
            <FormFrame
                ref={this.formRef}
                title="Change Password"
                error={this.state.errorMessage}
                isLoading={this.state.isSubmitting}
                okLabel="Update"
                onCancel={this.handleCancelClick}
                onOk={this.handleFormFinish}
            >
                {view}
            </FormFrame>
        )
    }

}
