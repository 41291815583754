import { PureComponent, ReactElement } from 'react';
import { DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Space, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { AuthenticationHeader, CredentialsType } from '@methodset/endpoint-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils, ColorMap } from 'utils/CoreUtils';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import endpointService from 'services/EndpointService';
import './Authentications.less';

export type AuthenticationsState = {
    status: string,
    headers: AuthenticationHeader[]
}

export type AuthenticationsProps = RouteComponentProps & {
    className?: string
}

export class Authentications extends PureComponent<AuthenticationsProps, AuthenticationsState> {

    private colorMap = CoreUtils.toColorMap(CoreUtils.enumToKeys(CredentialsType));

    constructor(props: AuthenticationsProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            headers: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleAuthenticationCreate = this.handleAuthenticationCreate.bind(this);
        this.handleAuthenticationEdit = this.handleAuthenticationEdit.bind(this);
        this.handleAuthenticationDelete = this.handleAuthenticationDelete.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleAuthenticationCreate(): void {
        const url = RouteBuilder.authentication("create");
        this.props.history.push(url);
    }

    private handleAuthenticationEdit(authentication: AuthenticationHeader): void {
        const url = RouteBuilder.authentication(authentication.id)
        this.props.history.push(url);
    }

    private handleAuthenticationDelete(authentication: AuthenticationHeader): void {
        this.deleteAuthenticationRequest(authentication.id);
    }

    // private readCredentialsTypesRequest(): Promise<any> {
    //     const request = {};
    //     return endpointService.readCredentialsTypes(request,
    //         (response: any) => this.readCredentialsTypesResponse(response),
    //         undefined, true
    //     );
    // }

    // private readCredentialsTypesResponse(response: any): void {
    //     const credentialsTypes = response.data.credentialsTypes;
    //     this.colorMap = CoreUtils.toColorMap(credentialsTypes, 'NONE');
    // }

    private readAuthenticationHeadersRequest(): Promise<any> {
        const request = {
            //credentialsTypes: [CredentialsType.API, CredentialsType.FTP, CredentialsType.WEB]
        };
        return endpointService.readAuthenticationHeaders(request,
            (response: any) => this.readAuthenticationHeadersResponse(response),
            undefined, true
        );
    }

    private readAuthenticationHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private deleteAuthenticationRequest(authenticationId: string): Promise<any> {
        const request = {
            authenticationId: authenticationId
        };
        return endpointService.deleteAuthentication(request,
            (response: any) => this.deleteAuthenticationResponse(response)
        );
    }

    private deleteAuthenticationResponse(response: any): void {
        const authenticationId = response.data.authenticationId;
        const index = this.state.headers.findIndex(header => header.id === authenticationId);
        this.setState({
            headers: update(this.state.headers, {
                $splice: [[index, 1]]
            })
        });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            render: (value, record) => {
                return <Link to={RouteBuilder.authentication(record.id)}>{value}</Link>
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            dataIndex: 'credentialsType',
            render: (value, record) => {
                return (
                    <Tag color={this.colorMap![value ? value : 'NONE']}>
                        {CoreUtils.toProper(value ? value : 'NONE', '_', ' ', ['api', 'ftp'])}
                    </Tag>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.credentialsType, b.credentialsType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            dataIndex: 'updateTime',
            render: (value, record) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(value)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readAuthenticationHeadersRequest());
        //requests.push(this.readCredentialsTypesRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit credentials",
            callback: this.handleAuthenticationEdit
        }, {
            icon: <DeleteOutlined />,
            label: "Delete credentials",
            confirm: "Are you sure you want to delete the credentials?",
            callback: this.handleAuthenticationDelete
        }];
        const columns = this.buildColumns();
        const data = this.state.headers;
        return (
            <ItemTable
                className={classNames('x-authentications', this.props.className)}
                title="Credentials"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={
                    <Space>
                        <Button onClick={this.handleAuthenticationCreate}>New Authentication</Button>
                        <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                    </Space>
                }
                actions={actions}
                onLoad={this.handleRetryLoad}
            />
        );
    }

}
