import React, { Component, ReactElement } from 'react';
import { FormInstance, Radio, RadioChangeEvent } from 'antd';
import { Calculator, CellComponents, Coordinate, ParameterComponents, Range, RangeComponents, ReferenceParser, RefType } from '@methodset/calculator-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { CellData, CellRefEditor } from './CellRefEditor/CellRefEditor';
import { ParameterData, ParameterRefEditor } from './ParameterRefEditor/ParameterRefEditor';
import { RangeData, RangeRefEditor } from './RangeRefEditor/RangeRefEditor';
import { FormItem } from 'components/FormItem/FormItem';
import './RefEditor.less';

export type ChangeCallback = (refId: string | undefined) => void;

export type RefEditorProps = typeof RefEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    // Index to differentiate refs in same form.
    index?: number,
    // The value is required.
    required?: boolean,
    // Remove the space to place the error message.
    //noError?: boolean,
    // Remove style from form item.
    //noStyle?: boolean,
    // True if the range should validate as a vector.
    vector?: boolean,
    // The reference id, i.e., Sheet1!A1, Sheet1!A1:B2, ticker.
    refId?: string,
    // The types of references to allow.
    refTypes?: RefType[],
    // The calculator the reference is in.
    calculator: Calculator,
    // Called when the ref changes.
    onChange: ChangeCallback
}

export type RefEditorState = {
    type?: RefType,
    sheetId?: string,
    cellId?: string,
    rangeId?: string,
    variable?: string
}

export class RefEditor extends Component<RefEditorProps, RefEditorState> {

    static defaultProps = {
        index: 0,
        required: true,
        vector: false,
        refTypes: [RefType.CELL, RefType.PARAMETER, RefType.RANGE]
    }

    constructor(props: RefEditorProps) {
        super(props);
        this.state = {
            type: props.refTypes.length === 1 ? props.refTypes[0] : RefType.PARAMETER
        }
        this.handleBlur = this.handleBlur.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleCellChange = this.handleCellChange.bind(this);
        this.handleParameterChange = this.handleParameterChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
    }

    private handleBlur(): void {
        if (this.state.type === RefType.CELL) {
            if (this.state.sheetId && this.state.cellId) {
                const refId = `${ReferenceParser.quote(this.state.sheetId)}!${this.state.cellId}`;
                this.props.onChange(refId);
            } else if (!this.state.sheetId || !this.state.cellId) {
                this.props.onChange(undefined);
            }
        } else if (this.state.type === RefType.PARAMETER) {
            this.props.onChange(this.state.variable);
        } else if (this.state.type === RefType.RANGE) {
            if (this.state.sheetId && this.state.rangeId) {
                const refId = `${ReferenceParser.quote(this.state.sheetId)}!${this.state.rangeId}`;
                this.props.onChange(refId);
            } else if (!this.state.sheetId || !this.state.rangeId) {
                this.props.onChange(undefined);
            }
        }
    }

    private handleTypeChange(e: RadioChangeEvent): void {
        const type = e.target.value;
        this.setState({
            type: type,
            sheetId: undefined,
            cellId: undefined,
            variable: undefined,
            rangeId: undefined
        });
    }

    private handleCellChange(data: CellData, isCleared: boolean): void {
        this.setState({
            sheetId: data.sheetId,
            cellId: data.cellId
        }, () => {
            if (isCleared) {
                this.handleBlur();
            }
        });
    }

    private handleParameterChange(data: ParameterData, isCleared: boolean): void {
        this.setState({
            variable: data.variable
        }, () => {
            if (isCleared) {
                this.handleBlur();
            }
        });
    }

    private handleRangeChange(data: RangeData, isCleared: boolean): void {
        this.setState({
            sheetId: data.sheetId,
            rangeId: data.rangeId
        }, () => {
            if (isCleared) {
                this.handleBlur();
            }
        });
    }

    private parseRef(refId: string | undefined): void {
        if (!refId) {
            this.setState({
                sheetId: undefined,
                cellId: undefined,
                variable: undefined,
                rangeId: undefined
            });
            return;
        }
        const ref = ReferenceParser.parse(refId);
        if (ref) {
            if (ref.type === RefType.CELL) {
                const components = ref.components as CellComponents;
                this.setState({
                    type: RefType.CELL,
                    sheetId: components.sheetId,
                    cellId: components.cellId
                });
            } else if (ref.type === RefType.PARAMETER) {
                const components = ref.components as ParameterComponents;
                this.setState({
                    type: RefType.PARAMETER,
                    variable: components.variable
                });
            } else if (ref.type === RefType.RANGE) {
                const components = ref.components as RangeComponents;
                this.setState({
                    type: RefType.RANGE,
                    sheetId: components.sheetId,
                    rangeId: components.rangeId
                });
            }
        }
    }

    public componentDidMount(): void {
        if (this.props.refId) {
            this.parseRef(this.props.refId);
        }
    }

    public shouldComponentUpdate(nextProps: RefEditorProps, nextState: RefEditorState): boolean {
        if (this.props.refId !== nextProps.refId) {
            this.parseRef(nextProps.refId);
            return false;
        }
        if (this.state.sheetId !== nextState.sheetId ||
            this.state.cellId !== nextState.cellId ||
            this.state.variable !== nextState.variable ||
            this.state.rangeId !== nextState.rangeId) {
            return true;
        }
        return true;
    }

    public render(): ReactElement {
        const sheets = this.props.calculator.sheets;
        const parameters = this.props.calculator.parameters;
        return (
            <Spacer direction="vertical" alignment="top" fill={true}>
                {this.props.refTypes.length > 1 &&
                    <Radio.Group
                        className="x-refeditor-type"
                        value={this.state.type}
                        onChange={this.handleTypeChange}
                    >
                        <Spacer direction="horizontal" justification="left" fill={true}>
                            {this.props.refTypes.includes(RefType.CELL) &&
                                <Radio value={RefType.CELL}>Cell</Radio>
                            }
                            {this.props.refTypes.includes(RefType.PARAMETER) &&
                                <Radio value={RefType.PARAMETER}>Parameter</Radio>
                            }
                            {this.props.refTypes.includes(RefType.RANGE) &&
                                <Radio value={RefType.RANGE}>Range</Radio>
                            }
                        </Spacer>
                    </Radio.Group>
                }
                {this.state.type === RefType.CELL &&
                    <FormItem
                        formRef={this.props.formRef}
                        fill={true}
                        noLabel={true}
                        //noError={this.props.noError}
                        //noStyle={this.props.noStyle}
                        noStyle={true}
                        name={`cell-${this.props.index}`}
                        required={this.props.required}
                        rules={[{
                            validator: (rule: any, data: CellData) => {
                                if (!this.props.required && (!data.sheetId && !data.cellId)) {
                                    return Promise.resolve();
                                } else if (!this.props.required && (!data.sheetId || !data.cellId)) {
                                    return Promise.reject(new Error('Please enter or remove the cell.'));
                                } else if (!data.sheetId) {
                                    return Promise.reject(new Error('Please select a sheet.'));
                                } else if (!data.cellId) {
                                    return Promise.reject(new Error('Please enter a cell.'));
                                }
                                try {
                                    Coordinate.validateId(data.cellId);
                                    return Promise.resolve();
                                } catch (e) {
                                    return Promise.reject(new Error('Invalid cell value.'));
                                }
                            }
                        }]}
                    >
                        <CellRefEditor
                            value={{ sheetId: this.state.sheetId, cellId: this.state.cellId } as CellData}
                            sheets={sheets}
                            onBlur={this.handleBlur}
                            onChange={this.handleCellChange}
                        />
                    </FormItem>
                }
                {this.state.type === RefType.PARAMETER &&
                    <FormItem
                        formRef={this.props.formRef}
                        fill={true}
                        noLabel={true}
                        //noError={this.props.noError}
                        //noStyle={this.props.noStyle}
                        noStyle={true}
                        name={`parameter-${this.props.index}`}
                        required={this.props.required}
                        rules={[{
                            validator: (rule: any, data: ParameterData) => {
                                if (this.props.required && !data.variable) {
                                    return Promise.reject(new Error('Please enter a parameter.'));
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        <ParameterRefEditor
                            value={{ variable: this.state.variable } as ParameterData}
                            parameters={parameters}
                            onBlur={this.handleBlur}
                            onChange={this.handleParameterChange}
                        />
                    </FormItem>
                }
                {this.state.type === RefType.RANGE &&
                    <FormItem
                        formRef={this.props.formRef}
                        fill={true}
                        noLabel={true}
                        //noError={this.props.noError}
                        //noStyle={this.props.noStyle}
                        noStyle={true}
                        name={`range-${this.props.index}`}
                        required={this.props.required}
                        rules={[{
                            validator: (rule: any, data: RangeData) => {
                                if (!this.props.required && (!data.sheetId && !data.rangeId)) {
                                    return Promise.resolve();
                                } else if (!this.props.required && (!data.sheetId || !data.rangeId)) {
                                    return Promise.reject(new Error('Please enter or remove the range.'));
                                } else if (!data.sheetId) {
                                    return Promise.reject(new Error('Please select a sheet.'));
                                } else if (!data.rangeId) {
                                    return Promise.reject(new Error('Please enter a range.'));
                                }
                                try {
                                    if (this.props.vector) {
                                        if (!Range.isVector(data.rangeId)) {
                                            return Promise.reject(new Error('Range is not a row or column.'));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    } else {
                                        Range.validateId(data.rangeId);
                                        return Promise.resolve();
                                    }
                                } catch (e) {
                                    return Promise.reject(new Error('Invalid range value.'));
                                }
                            }
                        }]}
                    >
                        <RangeRefEditor
                            value={{ sheetId: this.state.sheetId, rangeId: this.state.rangeId } as RangeData}
                            sheets={sheets}
                            onBlur={this.handleBlur}
                            onChange={this.handleRangeChange}
                        />
                    </FormItem>
                }
            </Spacer>
        );
    }

}
