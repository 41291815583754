import { Calculator } from '@methodset/calculator-ts';
import { ReactElement, useState } from 'react';
import { InputWidgetConfiguration, Model, ModelSetup } from '@methodset/model-client-ts';
import { Justification, Justify } from 'components/Justify/Justify';
import { Align } from 'components/Align/Align';
import { Label, Location } from 'components/Label/Label';
import { Configuration, ConfigurationType, MultiOptionConfigurationSpec, NumberConfigurationSpec, NumberType, OptionConfigurationSpec, VariableSpec } from '@methodset/endpoint-client-ts';
import { Button, Popover, Select, Switch } from 'antd';
import { DecimalInput } from 'components/DecimalInput/DecimalInput';
import { DateTimeSelector, DateTimeSelectorUtils } from 'components/DateTimeSelector/DateTimeSelector';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import { CoreUtils } from '@methodset/commons-shared-ts';
import { TextInput } from 'components/TextInput/TextInput';
import { Spacer } from 'components/Spacer/Spacer';
import './InputWidgetViewer.less';

export type InputWidgetViewerProps = {
    configuration: InputWidgetConfiguration,
    calculator: Calculator,
    modelId: string,
    version?: number
    // model: Model,
    // modelSetup?: ModelSetup,
    appletConfiguration?: Configuration,
    variableSpecs?: VariableSpec[]
};

export const InputWidgetViewer = (props: InputWidgetViewerProps): ReactElement => {

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleInputChange = (spec: VariableSpec, value: any): void => {
        // Set the parameter value.
        const parameters = props.calculator.parameters;
        const parameter = parameters.get(spec.key, false);
        if (parameter) {
            if (CoreUtils.isFormula(value)) {
                parameter.formula = value;
            } else {
                parameter.value = value;
            }
        }
        // Store the value in the model state.
        // if (props.modelSetup) {
        //     const configuration = props.modelSetup.configuration;
        //     configuration[spec.key] = value;
        // }

        if (props.appletConfiguration) {
            props.appletConfiguration[spec.key] = value;
        }

    }

    const variableValue = (spec: VariableSpec): any => {
        // if (props.modelSetup) {
        //     // Get the variable value from the user model state.
        //     const data = props.modelSetup.configuration[spec.key];
        //     if (data) {
        //         return data.formula ? data.formula : data.value;
        //     } else {
        //         return undefined;
        //     }
        if (props.appletConfiguration) {
            // Get the variable value from the user model state.
            const data = props.appletConfiguration[spec.key];
            if (data) {
                return data.formula ? data.formula : data.value;
            } else {
                return undefined;
            }
        } else {
            // If there is no model setup (i.e., during edit),
            // get the current value from the parameter.
            const parameters = props.calculator.parameters;
            const parameter = parameters.get(spec.key, false);
            if (parameter) {
                return parameter.formula ? parameter.formula : parameter.value;
            } else {
                return undefined;
            }
        }
    }

    const findLabel = (): string | undefined => {
        const spec = findSpec();
        return spec ? spec.name : undefined;
    }

    const findInfo = (): string | undefined => {
        const spec = findSpec();
        return spec ? spec.description : undefined;
    }

    const findSpec = (): VariableSpec | undefined => {
        // If variable specs are passed in (i.e., editing widget will pass in resolved
        // variables), use them. Otherwise, use the variable specs from the model, which
        // will already be resolved.
        //const specs = props.variableSpecs ? props.variableSpecs : props.model.variableSpecs;
        const specs = props.variableSpecs ? props.variableSpecs : [];
        const variable = props.configuration.variable;
        return specs.find(spec => spec.key === variable);
    }

    const buildView = (): ReactElement => {
        const spec = findSpec();
        if (!spec) {
            return <div>{`ERROR: Variable ${props.configuration.variable} is not exported.`}</div>;
        }
        if (spec.queryId) {
            return <div>{`ERROR: Variable ${props.configuration.variable} is not resolved.`}</div>;
        }
        const value = variableValue(spec);
        switch (spec.type) {
            case ConfigurationType.TEXT: {
                return (
                    <TextInput
                        value={value}
                        onChange={(value) => handleInputChange(spec, value)}
                    />
                )
            }
            case ConfigurationType.NUMBER: {
                const cs = spec as NumberConfigurationSpec;
                if (cs.numberType === NumberType.INTEGER) {
                    return (
                        <IntegerInput
                            fill
                            natural={cs.isPositive}
                            value={value}
                            onChange={(value) => handleInputChange(spec, value)}
                        />
                    )
                } else {
                    return (
                        <DecimalInput
                            fill
                            value={value}
                            onChange={(value) => handleInputChange(spec, value)}
                        />
                    )
                }
            }
            case ConfigurationType.BOOLEAN: {
                return (
                    <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={value}
                        onChange={(value) => handleInputChange(spec, value)}
                    />
                )
            }
            case ConfigurationType.TIME:
            case ConfigurationType.DATE: {
                const selector = (
                    <Spacer direction="vertical" size="large">
                        <DateTimeSelector
                            className="x-inputwidgetviewer-popup"
                            value={value}
                            syntax="formula"
                            showTime={spec.type === ConfigurationType.TIME}
                            onChange={(value) => handleInputChange(spec, value)}
                        />
                        <Justify justification="right">
                            <Button type="primary" onClick={() => setIsVisible(false)}>Ok</Button>
                        </Justify>
                    </Spacer>
                )
                return (
                    <Popover
                        content={selector}
                        trigger="click"
                        placement="bottomLeft"
                        visible={isVisible}
                        onVisibleChange={(visible) => setIsVisible(visible)}
                    >
                        <span className="x-inputwidgetviewer-date">
                            {DateTimeSelectorUtils.toString(value, "formula")}
                        </span>
                    </Popover>
                )
            }
            case ConfigurationType.OPTION: {
                const cs = spec as OptionConfigurationSpec;
                const options = cs.options;
                return (
                    <Select
                        //className="x-inputwidgetviewer-fill"
                        className="x-inputwidgetviewer-width"
                        allowClear={true}
                        value={!CoreUtils.isEmpty(value) ? value.toString() : value}
                        onChange={(value) => handleInputChange(spec, value)}
                    >
                        {options.map(option => (
                            <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                        ))}
                    </Select>
                )
            }
            case ConfigurationType.MULTI_OPTION: {
                const cs = spec as MultiOptionConfigurationSpec;
                const options = cs.options;
                return (
                    <Select
                        className="x-inputwidgetviewer-width"
                        mode="multiple"
                        allowClear={true}
                        value={!CoreUtils.isEmpty(value) ? value.toString() : value}
                        onChange={(value) => handleInputChange(spec, value)}
                    >
                        {options.map(option => (
                            <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                        ))}
                    </Select>
                )
            }
            default:
                return <div>{`Invalid input type ${spec.type}.`}</div>
        }
    }

    const location = props.configuration.labelLocation ?
        props.configuration.labelLocation.toLowerCase() as Location :
        undefined;
    const justification = props.configuration.justification ?
        props.configuration.justification.toLowerCase() as Justification :
        undefined;
    const label = findLabel();
    const info = findInfo();

    return (
        <Justify justification={justification}>
            <Align className="x-inputwidgetviewer-width" alignment="center">
            {props.configuration.labelLocation && label &&
                <Label
                    className="x-inputwidgetviewer-width"
                    placement={location}
                    label={label}
                    info={info}
                >
                    {buildView()}
                </Label>
            }
            {!props.configuration.labelLocation &&
                buildView()
            }
            </Align>
        </Justify>
    );

}
