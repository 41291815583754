import { PureComponent, ReactElement } from 'react';
import { Select } from 'antd';
import { Globals } from 'constants/Globals';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import { ModelHeader } from '@methodset/model-client-ts';
import axios from 'axios';
import classNames from 'classnames';
import modelService from 'services/ModelService';
import './ModelPicker.less';

export type ChangeCallback = (modelId: string) => void;

export type ModelPickerState = {
    status: string,
    models: ModelHeader[]
}

export type ModelPickerProps = {
    className?: string,
    modelId?: string,
    onChange: ChangeCallback
}

export class ModelPicker extends PureComponent<ModelPickerProps, ModelPickerState> {

    constructor(props: ModelPickerProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            models: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleModelChange(modelId: string): void {
        this.props.onChange(modelId);
    }

    private readModelsRequest(): Promise<any> {
        const request = {
        };
        return modelService.readModelHeaders(request,
            (response: any) => this.readModelHeadersResponse(response),
            undefined, true
        );
    }

    private readModelHeadersResponse(response: any): void {
        const models = response.data.models;
        this.setState({ models: models });
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readModelsRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-modelpicker-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load models."
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private buildModelView(): ReactElement {
        return (
            <Select
                placeholder="Select a model."
                value={this.props.modelId}
                onChange={this.handleModelChange}
            >
                {this.state.models.map(model => (
                    <Select.Option key={model.id} value={model.id}>{model.name}</Select.Option>
                ))}
            </Select>
        );
    }

    public componentDidMount(): void {
        this.loadData();
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildModelView();
        }
        return (
            <div className={classNames('x-modelpicker', this.props.className)}>
                {view}
            </div>
        );
    }

}
