import React, { Component, ReactElement } from 'react';
import { DeleteOutlined, ExclamationCircleOutlined, InfoCircleOutlined, LockOutlined, LogoutOutlined, MailOutlined, MenuOutlined, SwapOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, message, Modal, Select, Space } from 'antd';
import { Image } from 'components/Image/Image';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { Group, TenantType } from '@methodset/entity-client-ts';
import { RestUtils } from 'utils/RestUtils';
import { InviteDialog } from './InviteDialog/InviteDialog';
import authService from 'services/AuthService';
import entityService from 'services/EntityService';
import logo from 'images/methodset_blue_logo.png';
import gears from 'images/gears_32x32.png';
import update from 'immutability-helper';
import './NavBar.less';

export type NavBarProps = RouteComponentProps & {
    onToggleMenu: any
}

export type NavBarState = {
    modal: any,
    showInvite: boolean
}

export class NavBar extends Component<NavBarProps, NavBarState> {

    static contextType = EntityContext;

    constructor(props: NavBarProps) {
        super(props);
        this.state = {
            modal: {},
            showInvite: false
        }
        this.handleHomeClick = this.handleHomeClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleProfileClick = this.handleProfileClick.bind(this);
        this.handlePasswordClick = this.handlePasswordClick.bind(this);
        this.handleEmailClick = this.handleEmailClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleClientClick = this.handleClientClick.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleConfirmChange = this.handleConfirmChange.bind(this);
        this.handleInviteClick = this.handleInviteClick.bind(this);
        this.handleInviteClose = this.handleInviteClose.bind(this);
    }

    private handleHomeClick(): void {
        const isLoggedIn = this.context.user ? true : false;
        if (isLoggedIn) {
            this.props.history.push(RouteBuilder.CONSOLE);
        } else {
            this.props.history.push(RouteBuilder.HOME);
        }
    }

    private handleLogoutClick(): void {
        authService.logoutUser(
            () => this.handleLogout(),
            () => this.handleLogout()
        );
    }

    private handleLogout(): void {
        authService.clearUser();
        this.context.clearUser();
        this.props.history.push(RouteBuilder.HOME);
    }

    private handleProfileClick(): void {
        this.props.history.push(RouteBuilder.MAIN_EDIT_PROFILE);
    }

    private handlePasswordClick(): void {
        this.props.history.push(RouteBuilder.MAIN_CHANGE_PASSWORD);
        //this.props.history.push(RouteBuilder.user(this.props, RouteBuilder.USER_CHANGE_PASSWORD));
    }

    private handleEmailClick(): void {
        this.props.history.push(RouteBuilder.MAIN_CHANGE_EMAIL);
        //this.props.history.push(RouteBuilder.user(this.props, RouteBuilder.USER_CHANGE_EMAIL));
    }

    private handleDeleteClick(): void {
        const config = {
            title: "Delete Your Account",
            okText: "Delete Account",
            okButtonProps: {
                disabled: true
            },
            cancelText: "Cancel",
            closable: true,
            icon: (
                <ExclamationCircleOutlined className="x-navbar-confirm-icon" />
            ),
            content: (
                <div>
                    <div>
                        Are you sure you want to delete
                        your account? You will lose access
                        to all your data.
                    </div>
                    <div className="x-navbar-confirm-info">
                        Please enter the word "confirm" to
                        acknowledge that you want to delete
                        your account.
                    </div>
                    <Input
                        className="x-navbar-confirm-input"
                        value={this.state.modal.confirm}
                        onChange={this.handleConfirmChange}
                    />
                </div>
            ),
            onOk: this.handleOkClick,
            onCancel: this.handleCancelClick
        };
        const ref = Modal.confirm(config);
        const modal = update(this.state.modal, {
            ref: { $set: ref }
        });
        this.setState({ modal: modal });
    }

    private handleOkClick(): Promise<any> {
        if (this.state.modal.confirm !== 'confirm') {
            return Promise.reject();
        }
        const request = {};
        return entityService.deleteUser(request,
            (response: any) => this.deleteUserResponse(response),
            (response: any) => this.deleteUserException(response),
            true
        );
    }

    private handleCancelClick(): void {
        this.setState({ modal: {} });
    }

    private handleClientClick(): void {
        this.props.history.push(RouteBuilder.APPLICATION);
    }

    private handleGroupChange(groupId: string): void {
        this.context.saveGroupId(groupId);
    }

    private handleConfirmChange(e: any): void {
        const confirm = e.target.value;
        const disabled = confirm !== 'confirm';
        this.state.modal.ref.update({
            okButtonProps: {
                disabled: disabled
            }
        });
        const modal = update(this.state.modal, {
            confirm: { $set: confirm }
        });
        this.setState({ modal: modal });
    }

    private handleInviteClick(): void {
        this.setState({ showInvite: true });
    }

    private handleInviteClose(): void {
        this.setState({ showInvite: false });
    }

    private readGroupsRequest(): Promise<any> {
        const request = {};
        return entityService.readGroups(request,
            (response: any) => this.readGroupsResponse(response),
            (error: any) => this.readGroupsException(error),
            true
        );
    }

    private readGroupsResponse(response: any): void {
        const groups = response.data.groups;
        this.context.saveGroups(groups);
    }

    private readGroupsException(error: any): void {
        message.error("Failed to load groups.");
    }

    private deleteUserResponse(response: any): void {
        message.success("Your account has been deleted.");
        this.setState({ modal: {} });
        authService.clearUser();
        this.context.clearUser();
        this.props.history.push(RouteBuilder.HOME);
    }

    private deleteUserException(response: any): void {
        Modal.error({
            title: 'Delete Error',
            content: RestUtils.getError(response),
        });
    }

    public componentDidMount(): void {
        this.readGroupsRequest();
    }

    public render(): ReactElement {
        const user = this.context.user;
        const groups = this.context.groups;
        const groupId = this.context.groupId;
        const environment = this.context.environment;
        const userMenu = (
            <Menu>
                {user &&
                    <>
                        <div className="x-navbar-section x-navbar-label">
                            Welcome {user.firstName} {user.lastName}
                        </div>
                        <Menu.Divider />
                    </>
                }
                {environment === TenantType.DEVELOPMENT &&
                    <Menu.Item
                        key="view"
                        icon={<SwapOutlined />}
                        onClick={this.handleClientClick}
                    >
                        Client View
                    </Menu.Item>
                }
                <Menu.Item
                    key="invite"
                    icon={<UsergroupAddOutlined />}
                    onClick={this.handleInviteClick}
                >
                    Invite User
                </Menu.Item>
                <Menu.Item
                    key="profile"
                    icon={<UserOutlined />}
                    onClick={this.handleProfileClick}
                >
                    Update Profile
                </Menu.Item>
                <Menu.Item
                    key="password"
                    icon={<LockOutlined />}
                    onClick={this.handlePasswordClick}
                >
                    Change Password
                </Menu.Item>
                <Menu.Item
                    key="email"
                    icon={<MailOutlined />}
                    onClick={this.handleEmailClick}
                >
                    Change Email
                </Menu.Item>
                <Menu.Item
                    key="delete"
                    icon={<DeleteOutlined />}
                    onClick={this.handleDeleteClick}
                >
                    Delete Account
                </Menu.Item>
                <Menu.SubMenu
                    key="info"
                    icon={<InfoCircleOutlined />}
                    title="Account Info"
                >
                    <div className="x-navbar-section">
                        <div className="x-navbar-label">Organization Id:</div>
                        <div className="x-navbar-id">{user?.organizationId}</div>
                        <div className="x-navbar-label">Tenant Id:</div>
                        <div className="x-navbar-id">{user?.tenantId}</div>
                    </div>
                </Menu.SubMenu>
                {user &&
                    <React.Fragment>
                        <Menu.Divider />
                        <Menu.Item
                            key="logout"
                            icon={<LogoutOutlined />}
                            onClick={this.handleLogoutClick}
                        >
                            Logout
                        </Menu.Item>
                    </React.Fragment>
                }
            </Menu>
        );
        return (
            <div className="x-navbar">
                <div className="x-navbar-main">
                    <Button
                        type="link"
                        icon={<MenuOutlined />}
                        onClick={this.props.onToggleMenu}
                    />
                    <div className="x-navbar-brand">
                        <Image src={gears} className="x-navbar-gears" alt="gears" />
                        <Image src={logo} className="x-navbar-logo" alt="logo" onClick={this.handleHomeClick} />
                    </div>
                </div>
                <div className="x-navbar-actions">
                    <Space size="middle">
                        {user &&
                            <>
                                {/* <span className="x-navbar-username">
                                    {`${user.firstName} ${user.lastName}`}
                                </span> */}
                                <Dropdown overlay={userMenu} trigger={['click']}>
                                    <Button className="x-navbar-profile" shape="round">
                                        <UserOutlined />
                                    </Button>
                                </Dropdown>
                                <Select
                                    className="x-navbar-group"
                                    value={groups.length > 0 ? groupId : undefined}
                                    onSelect={this.handleGroupChange}
                                >
                                    {groups.map((group: Group, index: number) => (
                                        <Select.Option
                                            key={group.id}
                                            value={group.id ? group.id : index}
                                        >
                                            {group.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </>
                        }
                    </Space>
                </div>
                {this.state.showInvite &&
                    <InviteDialog onClose={this.handleInviteClose} />
                }
            </div>
        );
    }

}
