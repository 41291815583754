import { useState, createContext, FC, useContext, ReactElement } from 'react';
import { Group, Organization, TenantType, User } from '@methodset/entity-client-ts';
import update from 'immutability-helper';

interface EntityState {
    user?: User;
    groups: Group[];
    groupId?: string;
    environment?: TenantType;
    clearUser: () => void;
    saveUser: (user: User | undefined) => void;
    saveGroups: (groups: Group[]) => void;
    saveGroupId: (groupId: string | undefined) => void;
    addGroup: (group: Group) => void;
    removeGroup: (groupId: string) => void;
    saveEnvironment: (environment: TenantType | undefined) => void;
}

const defaultState: EntityState = {
    user: undefined,
    groups: [],
    groupId: undefined,
    environment: undefined,
    clearUser: () => {},
    saveUser: () => {},
    saveGroups: () => {},
    saveGroupId: () => {},
    addGroup: () => {},
    removeGroup: () => {},
    saveEnvironment: () => {}
};

// Static version of entity information to access in non-components.
export class CurrentEntity {
    public static user: User | undefined;
    public static groups: Group[];
    public static groupId: string | undefined;
    public static environment: TenantType | undefined;
}

export const EntityContext = createContext<EntityState>(defaultState);

export const EntityProvider: FC = ({ children }): ReactElement => {

    const [user, setUser] = useState(defaultState.user);
    const [groups, setGroups] = useState(defaultState.groups);
    const [groupId, setGroupId] = useState(defaultState.groupId);
    const [environment, setEnvironment] = useState(defaultState.environment);

    const clearUser = () => {
        CurrentEntity.user = undefined;
        CurrentEntity.groups = [];
        CurrentEntity.groupId = undefined;
        setUser(CurrentEntity.user);
        setGroups(CurrentEntity.groups);
        setGroupId(CurrentEntity.groupId);
        saveEnvironment(CurrentEntity.environment);
    }

    const saveUser = (user: User | undefined) => {
        CurrentEntity.user = user;
        CurrentEntity.groupId = user ? user.id : undefined;
        setUser(CurrentEntity.user);
        setGroupId(CurrentEntity.groupId);
    }

    const saveGroups = (groups: Group[]) => {
        CurrentEntity.groups = groups;
        setGroups(CurrentEntity.groups);
    }

    const saveGroupId = (groupId: string | undefined) => {
        CurrentEntity.groupId = groupId;
        setGroupId(CurrentEntity.groupId);
    }

    const addGroup = (group: Group) => {
        const updated = update(groups, {
            $push: [group]
        });
        setGroups(updated);
    }

    const removeGroup = (groupId: string) => {
        const index = groups.findIndex((group: Group) => group.id === groupId);
        const updated = update(groups, {
            $splice: [[index, 1]]
        });
        setGroups(updated);
    }

    const saveEnvironment = (environment: TenantType | undefined) => {
        CurrentEntity.environment = environment;
        setEnvironment(CurrentEntity.environment);
    }

    return (
        <EntityContext.Provider
            value={{
                user,
                groups,
                groupId,
                environment,
                clearUser,
                saveUser,
                saveGroups,
                saveGroupId,
                addGroup,
                removeGroup,
                saveEnvironment
            }}
        >
            {children}
        </EntityContext.Provider>
    );

};

export const useEntityContext = () => useContext(EntityContext)