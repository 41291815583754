import React, { Component, ReactElement } from 'react';
import { Button, Empty, FormInstance, Input, message, Radio, Select, Switch } from 'antd';
import { Globals } from 'constants/Globals';
import {
    AuthenticationHeader,
    BooleanConfigurationSpec,
    Configuration,
    ConfigurationSpec,
    ConfigurationType,
    CredentialsConfigurationSpec,
    Data,
    DataList,
    DataMap,
    DateConfigurationSpec,
    DisplayType,
    IoType,
    ListConfigurationSpec,
    MapConfigurationSpec,
    MultiOptionConfigurationSpec,
    NumberConfigurationSpec,
    NumberType,
    OptionConfigurationSpec,
    OptionType,
    QueryConfigurationSpec,
    QueryRef,
    RangeConfigurationSpec,
    RangeRef,
    RequirementType,
    SpecUtils,
    TextConfigurationSpec,
    TimeConfigurationSpec,
    TimeZoneConfigurationSpec,
    UuidConfigurationSpec
} from '@methodset/endpoint-client-ts';
import { ModelHeader } from '@methodset/model-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { TimezoneSelector } from 'components/TimezoneSelector/TimezoneSelector';
import { FormDivider } from 'components/FormDivider/FormDivider';
import { CoreUtils } from 'utils/CoreUtils';
import { CloseCircleOutlined } from '@ant-design/icons';
import { RangeSelector } from './RangeSelector/RangeSelector';
import { ExpressionInput } from './ExpressionInput/ExpressionInput';
import { DateTimeSelector, SyntaxType, ValueType } from 'components/DateTimeSelector/DateTimeSelector';
import { QuerySelector } from './QuerySelector/QuerySelector';
import { DecimalInput } from 'components/DecimalInput/DecimalInput';
import { RestUtils } from 'utils/RestUtils';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import { Justify } from 'components/Justify/Justify';
import { Spacer } from 'components/Spacer/Spacer';
import CopyToClipboard from 'react-copy-to-clipboard';
import axios from 'axios';
import endpointService from 'services/EndpointService';
import { v4 as uuid } from "uuid";
import './ConfigurationEditor.less';

// A configuration value parent is either a list or a map.
// The reference into the parent is either a key (for a map) or an index (for a list).
export type Key = string;
export type Index = number;
export type Parent = DataList | DataMap;
export type Ref = Key | Index;

export type DeleteCallback = (spec: ConfigurationSpec) => void;
export type ChangeCallback = (configuration: Configuration) => void;

export type ConfigurationEditorState = {
    status: string,
    models: ModelHeader[],
    authentications: AuthenticationHeader[]
}

export type ConfigurationEditorProps = typeof ConfigurationEditor.defaultProps & {
    // Reference to form object.
    formRef: React.RefObject<FormInstance>,
    // Class to style the form.
    className?: string,
    // The processor configuration data.
    configuration?: Configuration,
    // The processor to configure.
    configurationSpecs?: ConfigurationSpec[],
    // The variables for the configuration.
    variableSpecs?: ConfigurationSpec[],
    // Display a message if there are no specs.
    showEmpty?: boolean,
    // True to show an expression that accepts variables.
    showExpressions?: boolean,
    // Syntax to use for date and time fields.
    timeSyntax?: SyntaxType,
    // Authentications for credentials specs.
    authentications?: AuthenticationHeader[],
    // Called when an expression is removed.
    onDelete?: DeleteCallback,
    // Called when the processor configuration is changed.
    onChange: ChangeCallback
}

export class ConfigurationEditor extends Component<ConfigurationEditorProps, ConfigurationEditorState> {

    static defaultProps = {
        showEmpty: false,
        showExpressions: true,
        timeSyntax: "simple",
        configuration: {},
        configurationSpecs: [] as ConfigurationSpec[]
    }

    // True if need to load authentications (not passed in).
    private loadAuthentications;
    // A copy of the configuration to work on so the original is unchanged.
    private configuration;

    constructor(props: ConfigurationEditorProps) {
        super(props);
        this.loadAuthentications = !props.authentications;
        this.configuration = CoreUtils.clone(props.configuration);
        this.state = {
            status: Globals.STATUS_INIT,
            models: [],
            authentications: props.authentications ? props.authentications : []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private readAuthenticationHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readAuthenticationHeaders(request,
            (response: any) => this.readAuthenticationHeadersResponse(response),
            undefined, true
        );
    }

    private readAuthenticationHeadersResponse(response: any): any {
        const authentications = response.data.headers;
        this.setState({ authentications: authentications });
    }

    private handleExpressionChange(parent: Parent, ref: Ref, type: IoType, value?: string, data?: Data): void {
        this.updateData(type, parent, ref, value, undefined, data);
        this.sendUpdate();
    }

    private handleTextChange(parent: Parent, ref: Ref, value: string, data?: Data): void {
        this.updateData(IoType.TEXT, parent, ref, value, undefined, data);
        this.sendUpdate();
    }

    private handleNumberChange(parent: Parent, ref: Ref, value: number, data?: Data): void {
        this.updateData(IoType.NUMBER, parent, ref, value, undefined, data);
        this.sendUpdate();
    }

    private handleBooleanChange(parent: Parent, ref: Ref, value: boolean, data?: Data): void {
        this.updateData(IoType.BOOLEAN, parent, ref, value, undefined, data);
        this.sendUpdate();
    }

    private handleDateChange(parent: Parent, ref: Ref, value: string, type: ValueType, data?: Data): void {
        let ioType;
        let dateValue;
        let formula;
        if (type === "absolute") {
            ioType = IoType.DATE;
            dateValue = value;
        } else {
            if (this.props.timeSyntax === "formula") {
                ioType = IoType.DATE;
                dateValue = undefined;
                formula = value;
            } else {
                ioType = IoType.DATE_REF;
                dateValue = value;
                formula = undefined;
            }
        }
        this.updateData(ioType, parent, ref, dateValue, formula, data);
        this.sendUpdate();
    }

    private handleTimeChange(parent: Parent, ref: Ref, value: string, type: ValueType, data?: Data): void {
        let ioType;
        let timeValue;
        let formula;
        if (type === "absolute") {
            ioType = IoType.TIME;
            timeValue = value;
        } else {
            if (this.props.timeSyntax === "formula") {
                ioType = IoType.TIME;
                timeValue = undefined;
                formula = value;
            } else {
                ioType = IoType.TIME_REF;
                timeValue = value;
                formula = undefined;
            }
        }
        this.updateData(ioType, parent, ref, timeValue, formula, data);
        this.sendUpdate();
    }

    private handleOptionChange(spec: OptionConfigurationSpec, parent: Parent, ref: Ref, value: string | number, type: IoType, data?: Data) {
        if (SpecUtils.hasOptionItems(spec)) {
            if (spec.optionType === OptionType.OBJECT) {
                const map = {
                    type: { type: type, value: value },
                } as DataMap;
                this.updateData(IoType.MAP, parent, ref, map, undefined, data);
            } else if (spec.optionType === OptionType.HOIST || spec.optionType === OptionType.VALUE) {
                this.updateData(type, parent, ref, value, undefined, data);
            }
        } else {
            this.updateData(type, parent, ref, value, undefined, data);
        }
        this.sendUpdate();
    }

    private handleMultiOptionChange(spec: MultiOptionConfigurationSpec, parent: Parent, ref: Ref, value: string | number, type: IoType, data?: Data): void {
        this.updateData(type, parent, ref, value, undefined, data);
        this.sendUpdate();
    }

    private handleRangeChange(parent: Parent, ref: Ref, value: RangeRef, data?: Data): void {
        this.updateData(IoType.RANGE_DEF, parent, ref, value, undefined, data);
        this.sendUpdate();
    }

    private handleQueryChange(parent: Parent, ref: Ref, value: QueryRef, data?: Data): void {
        this.updateData(IoType.QUERY_DEF, parent, ref, value, undefined, data);
        this.sendUpdate();
    }

    private handleCredentialsChange(parent: Parent, ref: Ref, value: string, data?: Data): void {
        this.updateData(IoType.CREDENTIALS_REF, parent, ref, value, undefined, data);
        this.sendUpdate();
    }

    private handleListAdd(spec: ListConfigurationSpec, list: DataList, type: IoType, render: boolean = true): void {
        let data;
        if (SpecUtils.hasOptionItems(spec.itemSpec)) {
            data = { type: IoType.MAP, value: null } as Data;
        } else {
            data = { type: type, value: null } as Data;
        }
        list.push(data);
        if (render) {
            this.sendUpdate();
        }
    }

    private handleListDelete(index: number, data?: any) {
        const list = data! as any[];
        list.splice(index, 1);
        this.sendUpdate();
    }

    private sendUpdate(): void {
        this.props.onChange(this.configuration);
    }

    private uuidElement(spec: UuidConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        if (!data) {
            this.handleTextChange(parent, ref, uuid(), data);
        }
        return (
            <Spacer
                fill={true}
                value={data?.value}
            >
                <Input
                    value={data?.value}
                    disabled={true}
                    onChange={(e) => this.handleTextChange(parent, ref, e.target.value, data)}
                />
                <CopyToClipboard text={data?.value}
                    onCopy={() => message.success("Copied Identifier!", 2)}
                >
                    <Button>
                        Copy
                    </Button>
                </CopyToClipboard>
            </Spacer>
        );
    }

    private textElement(spec: TextConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        data = this.checkDefault(spec, parent, ref, data);
        return (
            <ExpressionInput
                key={ref}
                spec={spec}
                type={data?.type}
                value={data?.value}
                variableSpecs={this.props.variableSpecs}
                showExpression={this.props.showExpressions}
                onDelete={this.props.onDelete}
                onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
            >
                {CoreUtils.isNumber(spec.rows) && spec.rows > 0 &&
                    <Input.TextArea
                        rows={spec.rows}
                        value={data?.value}
                        onChange={(e) => this.handleTextChange(parent, ref, e.target.value, data)}
                    />
                }
                {(!CoreUtils.isNumber(spec.rows) || spec.rows <= 0) &&
                    <Input
                        value={data?.value}
                        onChange={(e) => this.handleTextChange(parent, ref, e.target.value, data)}
                    />
                }
            </ExpressionInput>
        );
    }

    private numberElement(spec: NumberConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        data = this.checkDefault(spec, parent, ref, data);
        if (spec.numberType === NumberType.INTEGER) {
            return (
                <ExpressionInput
                    key={ref}
                    spec={spec}
                    type={data?.type}
                    value={data?.value}
                    variableSpecs={this.props.variableSpecs}
                    showExpression={this.props.showExpressions}
                    onDelete={this.props.onDelete}
                    onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
                >
                    <IntegerInput
                        fill
                        natural={spec.isPositive}
                        value={data?.value}
                        onChange={(value) => this.handleNumberChange(parent, ref, value, data)}
                    />
                </ExpressionInput>
            );
        } else {
            return (
                <ExpressionInput
                    key={ref}
                    spec={spec}
                    type={data?.type}
                    value={data?.value}
                    variableSpecs={this.props.variableSpecs}
                    showExpression={this.props.showExpressions}
                    onDelete={this.props.onDelete}
                    onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
                >
                    <DecimalInput
                        fill
                        value={data?.value}
                        onChange={(value) => this.handleNumberChange(parent, ref, value, data)}
                    />
                </ExpressionInput>
            );
        }
    }

    private booleanElement(spec: BooleanConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        data = this.checkDefault(spec, parent, ref, data);
        return (
            <ExpressionInput
                key={ref}
                spec={spec}
                type={data?.type}
                value={data?.value}
                variableSpecs={this.props.variableSpecs}
                showExpression={this.props.showExpressions}
                onDelete={this.props.onDelete}
                onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
            >
                <Switch
                    className="x-configurationeditor-switch"
                    checkedChildren="True"
                    unCheckedChildren="False"
                    checked={data?.value}
                    onChange={(value) => this.handleBooleanChange(parent, ref, value, data)}
                />
            </ExpressionInput>
        );
    }

    private dateElement(spec: DateConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        data = this.checkDefault(spec, parent, ref, data);
        return (
            <ExpressionInput
                key={ref}
                spec={spec}
                type={data?.type}
                //value={data?.value}
                value={data?.formula ? data.formula : data?.value}
                variableSpecs={this.props.variableSpecs}
                showExpression={this.props.showExpressions}
                onDelete={this.props.onDelete}
                onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
            >
                <DateTimeSelector
                    value={data?.formula ? data.formula : data?.value}
                    syntax={this.props.timeSyntax}
                    showTime={false}
                    onChange={(value, type) => this.handleDateChange(parent, ref, value, type, data)}
                />
            </ExpressionInput>
        );
    }

    private timeElement(spec: TimeConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        data = this.checkDefault(spec, parent, ref, data);
        return (
            <ExpressionInput
                key={ref}
                spec={spec}
                type={data?.type}
                value={data?.formula ? data.formula : data?.value}
                variableSpecs={this.props.variableSpecs}
                showExpression={this.props.showExpressions}
                onDelete={this.props.onDelete}
                onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
            >
                <DateTimeSelector
                    value={data?.formula ? data.formula : data?.value}
                    syntax={this.props.timeSyntax}
                    showTime={true}
                    onChange={(value, type) => this.handleTimeChange(parent, ref, value, type, data)}
                />
            </ExpressionInput>
        );
    }

    private timezoneElement(spec: TimeZoneConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        data = this.checkDefault(spec, parent, ref, data);
        return (
            <ExpressionInput
                key={ref}
                spec={spec}
                type={data?.type}
                value={data?.value}
                variableSpecs={this.props.variableSpecs}
                showExpression={this.props.showExpressions}
                onDelete={this.props.onDelete}
                onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
            >
                <TimezoneSelector
                    value={data?.value}
                    onChange={(value) => this.handleTextChange(parent, ref, value, data)}
                />
            </ExpressionInput>
        );
    }

    private optionElement(id: number, spec: OptionConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement | null {
        data = this.checkDefault(spec, parent, ref, data);
        const options = spec.options;
        if (!options) {
            return null;
        }
        let doFlatten = false;
        let optionSpec = null;
        let value: string | number | undefined;
        if (data && !CoreUtils.isEmpty(data.value)) {
            if (spec.optionType === OptionType.VALUE) {
                value = data.value;
            } else {
                if (spec.optionType === OptionType.OBJECT) {
                    // Get the value for the object type.
                    value = data.value.type.value;
                } else if (spec.optionType === OptionType.HOIST) {
                    value = data.value;
                    doFlatten = true;
                }
                const option = options.find(option => option.value === value);
                optionSpec = option?.itemSpec;
            }
        } else {
            value = data?.value;
        }
        const entries = Object.entries(options);
        if (spec.displayType === DisplayType.SELECT) {
            return (
                <React.Fragment key={ref}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label={spec.name}
                        name={`${spec.key}-${id}`}
                        info={spec.description}
                        rules={[{
                            required: spec.requirementType === RequirementType.REQUIRED,
                            message: `Please select a ${spec.name.toLowerCase()}.`
                        }]}
                    >
                        <ExpressionInput
                            key={ref}
                            spec={spec}
                            type={data?.type}
                            value={value}
                            variableSpecs={this.props.variableSpecs}
                            showExpression={this.props.showExpressions}
                            onDelete={this.props.onDelete}
                            onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
                        >
                            <Select
                                allowClear={true}
                                value={value}
                                onChange={(value => this.handleOptionChange(spec, parent, ref, value, spec.ioType, data))}
                            >
                                {entries.map(([key, option]) => (
                                    <Select.Option key={key} value={option.value}>{option.label}</Select.Option>
                                ))}
                            </Select>
                        </ExpressionInput>
                    </FormItem>
                    {optionSpec && this.buildElement(id++, optionSpec, parent, ref, data!, false, doFlatten)}
                </React.Fragment>
            )
        } else if (spec.displayType === DisplayType.RADIO) {
            return (
                <React.Fragment key={ref}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label={spec.name}
                        name={`${spec.key}-${id}`}
                        info={spec.description}
                        rules={[{
                            required: spec.requirementType === RequirementType.REQUIRED,
                            message: this.errorMessage(spec)
                        }]}
                    >
                        <ExpressionInput
                            key={ref}
                            spec={spec}
                            type={data?.type}
                            value={value}
                            variableSpecs={this.props.variableSpecs}
                            showExpression={this.props.showExpressions}
                            onDelete={this.props.onDelete}
                            onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
                        >
                            <Radio.Group
                                value={value}
                                onChange={(e => this.handleOptionChange(spec, parent, ref, e.target.value, spec.ioType, data))}
                            >
                                {entries.map(([key, option]) => (
                                    <Radio key={key} value={option.value}>{option.label}</Radio>
                                ))}
                            </Radio.Group>
                        </ExpressionInput>
                    </FormItem>
                    {optionSpec && this.buildElement(id++, optionSpec, parent, ref, data!, false, doFlatten)}
                </React.Fragment>
            )
        } else {
            return null;
            //return <></>;
        }
    }

    private multiOptionElement(spec: MultiOptionConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        data = this.checkDefault(spec, parent, ref, data);
        //const value = data?.value;
        const options = spec.options;
        const entries = Object.entries(options);
        return (
            <ExpressionInput
                key={ref}
                spec={spec}
                type={data?.type}
                value={data?.value}
                variableSpecs={this.props.variableSpecs}
                showExpression={this.props.showExpressions}
                onDelete={this.props.onDelete}
                onChange={(type, value) => this.handleExpressionChange(parent, ref, type, value, data)}
            >
                <Select
                    mode="multiple"
                    allowClear={true}
                    value={data?.value}
                    onChange={(value => this.handleMultiOptionChange(spec, parent, ref, value, spec.ioType, data))}
                >
                    {entries.map(([key, option]) => (
                        <Select.Option key={key} value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
            </ExpressionInput>
        )
    }

    private listElement(id: number, spec: ListConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        if (!data || CoreUtils.isEmpty(data.value)) {
            const list = [] as DataList;
            data = this.updateData(IoType.LIST, parent, ref, list, undefined, data);
        }
        const itemSpec = spec.itemSpec;
        const list = data.value as DataList;
        // If list is empty and required, add one value.
        if (list.length === 0 && spec.requirementType === RequirementType.REQUIRED) {
            this.handleListAdd(spec, list, itemSpec.ioType, false);
        }
        const hasMaps = SpecUtils.hasSubMaps(spec);
        //const isOption = SpecUtils.isListOfOptionsWithMaps(spec);
        return (
            <div key={ref} className="x-configurationeditor-list">
                {list?.map((item, index) => (
                    <React.Fragment key={index}>
                        {hasMaps &&
                            <>
                                <FormDivider
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.props.formRef}
                                    label={`${itemSpec.name} ${index + 1}`}
                                    index={index}
                                    bold={true}
                                    colon={false}
                                    //inline={isOption}
                                    inline={true}
                                    //info={itemSpec.description}
                                    required={itemSpec.requirementType === RequirementType.REQUIRED}
                                    onDelete={(index) => this.handleListDelete(index, list)}
                                />
                                {this.buildElement(id++, itemSpec, list, index, item, false)}
                            </>
                        }
                        {!hasMaps &&
                            <>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.props.formRef}
                                    key={index}
                                    label={index === 0 ? itemSpec.name : undefined}
                                    name={`${itemSpec.key}-${index}-${id}`}
                                    info={itemSpec.description}
                                    alignment="top"
                                    rules={[{
                                        required: itemSpec.requirementType === RequirementType.REQUIRED,
                                        message: this.errorMessage(spec)
                                    }]}
                                    postfix={(itemSpec.requirementType === RequirementType.OPTIONAL ||
                                        (itemSpec.requirementType === RequirementType.REQUIRED && index > 0)) &&
                                        <Button
                                            icon={<CloseCircleOutlined />}
                                            onClick={() => this.handleListDelete(index, list)}
                                        />
                                    }
                                >
                                    {this.buildElement(id++, itemSpec, list, index, item, false)}
                                </FormItem>
                            </>
                        }
                    </React.Fragment>
                ))}
                <div className="x-configurationeditor-list-add">
                    <Button
                        onClick={() => this.handleListAdd(spec, list, itemSpec.ioType)}
                    >
                        {`Add ${itemSpec.name}`}
                    </Button>
                </div>
            </div>
        );
    }

    private mapElement(id: number, spec: MapConfigurationSpec, parent: Parent, ref: Ref, data?: Data, doFlatten: boolean = false): ReactElement {
        if (!data || CoreUtils.isEmpty(data.value)) {
            const map = {} as DataMap;
            data = this.updateData(IoType.MAP, parent, ref, map, undefined, data);
        }
        const map = data.value as DataMap;
        const itemSpecs = spec.itemSpecs;
        const entries = Object.entries(itemSpecs);
        return (
            <div key={ref}>
                {entries.map(([key, itemSpec]) => {
                    const hasMaps = SpecUtils.hasSubMaps(itemSpec);
                    const object = doFlatten ? parent as DataMap : map;
                    const item = object[key];
                    const showLabel = !hasMaps || !item || CoreUtils.isEmpty(item.value) || item.value.length === 0;
                    return (
                        this.buildElement(id++, itemSpec, object, key, item, showLabel)
                    )
                })}
            </div>
        );
    }

    private rangeElement(spec: RangeConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        return (
            <RangeSelector
                key={ref}
                formRef={this.props.formRef}
                rangeDef={data?.value}
                variables={this.props.variableSpecs}
                onChange={(value) => this.handleRangeChange(parent, ref, value, data)}
            />
        );
    }

    private queryElement(spec: QueryConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        return (
            <QuerySelector
                key={ref}
                formRef={this.props.formRef}
                queryDef={data?.value}
                onChange={(value) => this.handleQueryChange(parent, ref, value, data)}
            />
        );
    }

    private credentialsElement(spec: CredentialsConfigurationSpec, parent: Parent, ref: Ref, data?: Data): ReactElement {
        data = this.checkDefault(spec, parent, ref, data);
        // Do not enable expressions to be inserted since the value must be selected from the credentials list.
        return (
            <Select
                value={data?.value}
                onChange={(value) => this.handleCredentialsChange(parent, ref, value, data)}
            >
                {this.state.authentications!
                    .filter(authentication => authentication.credentialsType === spec.credentialsType)
                    .map(authentication => (
                        <Select.Option key={authentication.id} value={authentication.id}>{authentication.name}</Select.Option>
                    ))
                }
            </Select>
        );
    }

    private checkDefault(spec: ConfigurationSpec, parent: Parent, ref: Ref, data?: Data): Data | undefined {
        if (!data) {
            const value = spec.defaultData?.value;
            if (!CoreUtils.isEmpty(value)) {
                data = this.updateData(spec.ioType, parent, ref, value);
            }
        }
        return data;
    }

    private updateData(type: IoType, parent: Parent, ref: Ref, value?: any, formula?: string, data?: Data): Data {
        if (!data) {
            // Create the data and add to the parent.
            data = { type: type } as Data;
            if (typeof ref === "string") {
                const dm = parent as DataMap;
                dm[ref as string] = data;
            } else {
                const dl = parent as DataList;
                dl[ref as number] = data;
            }
        } else {
            data.type = type;
        }
        if (!CoreUtils.isEmpty(value)) {
            data.value = value;
        } else {
            delete data.value;
        }
        if (formula) {
            data.formula = formula;
        } else {
            delete data.formula;
        }
        if (CoreUtils.isEmpty(data.value) && !data.formula) {
            if (typeof ref === "string") {
                const dm = parent as DataMap;
                delete dm[ref as string];
            }
        }
        return data;
    }

    private getData(parent: Parent, ref: Ref): Data {
        if (typeof ref === "string") {
            const dm = parent as DataMap;
            return dm[ref as string];
        } else {
            const dl = parent as DataList;
            return dl[ref as number];
        }
    }

    private buildElement(id: number, spec: ConfigurationSpec, parent: Parent, ref: Ref, data: Data, useForm: boolean = true, doFlatten: boolean = false): ReactElement | null {
        let element = null;
        switch (spec.type) {
            case ConfigurationType.UUID:
                element = this.uuidElement(spec as UuidConfigurationSpec, parent, ref, data);
                break;
            case ConfigurationType.TEXT:
                element = this.textElement(spec as TextConfigurationSpec, parent, ref, data);
                break;
            case ConfigurationType.NUMBER:
                element = this.numberElement(spec as NumberConfigurationSpec, parent, ref, data);
                break;
            case ConfigurationType.BOOLEAN:
                element = this.booleanElement(spec as BooleanConfigurationSpec, parent, ref, data);
                break;
            case ConfigurationType.DATE:
                element = this.dateElement(spec as DateConfigurationSpec, parent, ref, data);
                break;
            case ConfigurationType.TIME:
                element = this.timeElement(spec as TimeConfigurationSpec, parent, ref, data);
                break;
            case ConfigurationType.TIME_ZONE:
                element = this.timezoneElement(spec as TimeZoneConfigurationSpec, parent, ref, data);
                break;
            case ConfigurationType.OPTION:
                element = this.optionElement(id++, spec as OptionConfigurationSpec, parent, ref, data);
                useForm = false;
                break;
            case ConfigurationType.MULTI_OPTION:
                element = this.multiOptionElement(spec as MultiOptionConfigurationSpec, parent, ref, data);
                break;
            case ConfigurationType.LIST:
                element = this.listElement(id++, spec as ListConfigurationSpec, parent, ref, data);
                data = this.getData(parent, ref);
                useForm = false;
                break;
            case ConfigurationType.MAP:
                element = this.mapElement(id++, spec as MapConfigurationSpec, parent, ref, data, doFlatten);
                break;
            case ConfigurationType.RANGE:
                element = this.rangeElement(spec as RangeConfigurationSpec, parent, ref, data);
                useForm = false;
                break;
            case ConfigurationType.QUERY:
                element = this.queryElement(spec as QueryConfigurationSpec, parent, ref, data);
                useForm = false;
                break;
            case ConfigurationType.CREDENTIALS:
                element = this.credentialsElement(spec as CredentialsConfigurationSpec, parent, ref, data);
                break;
            default:
                console.error(`Invalid spec type '${spec.type}' could not be rendered in configuration editor.`);
                return <div key="error"></div>
        }
        if (useForm) {
            return (
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    key={ref}
                    label={spec.name}
                    name={`${spec.key}-${id}`}
                    info={spec.description}
                    rules={[{
                        required: spec.requirementType === RequirementType.REQUIRED,
                        message: this.errorMessage(spec)
                    }]}
                >
                    {element}
                </FormItem>
            );
        } else {
            return element;
        }
    }

    private errorMessage(spec: ConfigurationSpec): string {
        const name = spec.name.toLowerCase();
        if (!name) {
            return "Please enter a value.";
        }
        const vowels = "aeiou";
        return `Please enter ${vowels.includes(name[0]) ? "an" : "a"} ${name}.`;
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={this.props.formRef}
            >
                <LoadSpinner
                    className="x-configurationeditor-loading"
                    status={isLoading ? "loading" : "failed"}
                    failedMessage="Failed to load configuration."
                    onRetry={this.handleRetryLoad}
                />
            </FormItem>
        );
    }

    private buildConfigurationView(): ReactElement {
        let id = 0;
        const configuration = this.configuration;
        return (
            <div>
                {this.props.configurationSpecs && this.props.configurationSpecs.length > 0 &&
                    this.props.configurationSpecs.map(spec => (
                        this.buildElement(id++, spec, configuration, spec.key, configuration[spec.key])
                    ))
                }
                {(!this.props.configurationSpecs || this.props.configurationSpecs.length === 0) && this.props.showEmpty &&
                    <Justify justification="center">
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>No input variables.</span>}
                        />
                    </Justify>
                }
            </div>
        )
    }

    private loadData(): void {
        const requests = [];
        if (this.loadAuthentications) {
            requests.push(this.readAuthenticationHeadersRequest());
            this.setState({ status: Globals.STATUS_LOADING });
            axios.all(requests).then(axios.spread((r1) => {
                if (RestUtils.isOk(r1)) {
                    this.setState({ status: Globals.STATUS_READY });
                } else {
                    this.setState({ status: Globals.STATUS_FAILED });
                }
            }));
        } else {
            this.setState({ status: Globals.STATUS_READY });
        }
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public shouldComponentUpdate(nextProps: ConfigurationEditorProps): boolean {
        // Make a copy since otherwise the same object root would 
        // be returned in the callbacks. The configuration object
        // can be deeply nested and updates would otherwise change
        // the original object. The 'update' function cannot be used
        // which would create a new root object on any changes.
        this.configuration = CoreUtils.clone(nextProps.configuration);
        return true;
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildConfigurationView();
        }
        return (
            <div className="x-configurationeditor">
                {view}
            </div>
        );
    }

}
