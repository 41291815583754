import { ReactElement, useContext, useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { NavBar } from './NavBar/NavBar';
import { RouteBuilder } from 'utils/RouteBuilder';
import { PageNotFound } from 'containers/Components/PageNotFound/PageNotFound';
import { ResponsiveBox } from 'components/ResponsiveBox/ResponsiveBox';
import { User } from './User/User';
import { Store } from './Store/Store';
import { ApplicationContext, ApplicationProvider } from 'context/ApplicationContext';
import { RestUtils } from 'utils/RestUtils';
import serviceWarmer from 'utils/ServiceWarmer';
import modelService from 'services/ModelService';
import axios from 'axios';
import './Application.less';
import { Globals } from 'constants/Globals';
import { Applets } from './Applets/Applets';
import { Family } from './Family/Family';
import { AddMember } from './Family/AddMember/AddMember';
import { Accounts } from './Accounts/Accounts';
import { AccountItem } from './Accounts/AccountItem/AccountItem';
import { BoardViewer } from './BoardViewer/BoardViewer';
import { Splash } from './Splash/Splash';

export type ApplicationProps = RouteComponentProps;

export const Application = (props: ApplicationProps): ReactElement => {
    return (
        <ApplicationProvider>
            <ApplicationWrapped {...props} />
        </ApplicationProvider>
    )
}

export type ApplicationWrappedProps = RouteComponentProps;

const ApplicationWrapped = (props: ApplicationWrappedProps): ReactElement => {

    const context = useContext(ApplicationContext);

    const [status, setStatus] = useState<string>(Globals.STATUS_INIT);

    useEffect(() => {
        loadData();
        serviceWarmer.start();
        return () => {
            serviceWarmer.stop();
        }
    }, []);

    const readBoardsRequest = (): Promise<any> => {
        const request = {};
        return modelService.readBoards(request,
            (response: any) => readBoardsResponse(response),
            undefined, true
        );
    }

    const readBoardsResponse = (response: any): void => {
        const boards = response.data.boards;
        context.saveBoards(boards);
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readBoardsRequest());
        setStatus(Globals.STATUS_LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(Globals.STATUS_READY);
            } else {
                setStatus(Globals.STATUS_FAILED);
            }
        }));
    }

    // TODO: spinner during loading?

    return (
        <ResponsiveBox
            className="x-application"
            xl={{ className: 'x-application-xl' }}
            xs={{ className: 'x-application-xs' }}
        >
            <Layout>
                <Layout.Header className="x-application-header">
                    <NavBar {...props} />
                </Layout.Header>
                <Layout>
                    <Layout.Content className="x-application-body">
                        <Switch>
                            <Route exact path={RouteBuilder.APPLICATION} component={Splash} />
                            <Route path={RouteBuilder.APPLICATION_USER} component={User} />
                            <Route exact path={RouteBuilder.APPLICATION_STORE} component={Store} />
                            <Route exact path={RouteBuilder.APPLICATION_FAMILY} component={Family} />
                            <Route exact path={RouteBuilder.APPLICATION_FAMILY_ADD_MEMBER} component={AddMember} />
                            <Route exact path={RouteBuilder.APPLICATION_BOARDS} component={Applets} />
                            <Route path={RouteBuilder.APPLICATION_BOARD} component={BoardViewer} />
                            <Route exact path={RouteBuilder.APPLICATION_ACCOUNTS} component={Accounts} />
                            <Route path={RouteBuilder.APPLICATION_ACCOUNT} component={AccountItem} />
                            <Route component={PageNotFound} />
                        </Switch>
                    </Layout.Content>
                </Layout>
            </Layout>
        </ResponsiveBox>
    );

}
