import React, { PureComponent, ReactElement } from 'react';
import { Button, Col, Empty, FormInstance, Input, List, Row, Switch } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem, ValidateStatus } from 'components/FormItem/FormItem';
import { ColumnDef, ColumnSet, DisplayType, ListWidgetConfiguration, RowLink, SizeType, WidgetType } from '@methodset/model-client-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import { VariableSelector } from 'containers/Components/Widgets/Selectors/VariableSelector';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import { Spacer } from 'components/Spacer/Spacer';
import { Clickable } from 'components/Clickable/Clickable';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Justify } from 'components/Justify/Justify';
import { StyleData, WidgetStyle } from 'containers/Components/Widgets/WidgetStyle/WidgetStyle';
import update from 'immutability-helper';
import './ListWidgetEditor.less';
import { CoreUtils } from 'utils/CoreUtils';

type FormError = {
    status: ValidateStatus,
    help: string
};

type ColumnErrors = {
    name?: FormError,
    primary?: FormError,
    secondary?: FormError
}

export type EditCallback = (isEditing: boolean) => void;
export type ChangeCallback = (configuration: ListWidgetConfiguration, isEditing?: boolean) => void;

export type ListWidgetEditorProps = typeof ListWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    configuration?: ListWidgetConfiguration,
    calculator: Calculator,
    onEdit: EditCallback,
    onChange: ChangeCallback
}

export type ListWidgetEditorState = {
    editIndex?: number,
    editColumnSet?: ColumnSet,
    columnErrors: ColumnErrors
}

export class ListWidgetEditor extends PureComponent<ListWidgetEditorProps, ListWidgetEditorState> {

    static DefaultConfiguration = {
        type: WidgetType.LIST,
        columnSets: [] as ColumnSet[]
    }

    static defaultProps = {
        configuration: ListWidgetEditor.DefaultConfiguration
    }

    constructor(props: ListWidgetEditorProps) {
        super(props);
        this.state = {
            editIndex: undefined,
            editColumnSet: undefined,
            columnErrors: {}
        }
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleHeadersToggle = this.handleHeadersToggle.bind(this);
        this.handleLinkToggle = this.handleLinkToggle.bind(this);
        this.handleLinkVariableChange = this.handleLinkVariableChange.bind(this);
        this.handleLinkIndexChange = this.handleLinkIndexChange.bind(this);
        this.handleColumnSetEdit = this.handleColumnSetEdit.bind(this);
        this.handleColumnSetChange = this.handleColumnSetChange.bind(this);
        this.handleColumnSetAdd = this.handleColumnSetAdd.bind(this);
        this.handleColumnSetRemove = this.handleColumnSetRemove.bind(this);
        this.handleColumnSetCancel = this.handleColumnSetCancel.bind(this);
        this.handleColumnSetSave = this.handleColumnSetSave.bind(this);
        this.handleSecondaryToggle = this.handleSecondaryToggle.bind(this);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            rangeId: { $set: rangeId as any }
        });
        this.props.onChange(configuration);
    }

    private handleHeadersToggle(hasHeaders: boolean): void {
        const configuration = update(this.props.configuration, {
            hasHeaders: { $set: hasHeaders }
        });
        this.props.onChange(configuration);
    }

    private handleLinkToggle(hasLink: boolean): void {
        const rowLink = hasLink ? {} as RowLink : undefined;
        const configuration = update(this.props.configuration, {
            rowLink: { $set: rowLink }
        });
        this.props.onChange(configuration);
    }

    private handleLinkVariableChange(variable: string): void {
        const configuration = update(this.props.configuration, {
            rowLink: {
                variable: { $set: variable }
            }
        });
        this.props.onChange(configuration);
    }

    private handleLinkIndexChange(index: number): void {
        const configuration = update(this.props.configuration, {
            rowLink: {
                index: { $set: index }
            }
        });
        this.props.onChange(configuration);
    }

    private handleColumnSetEdit(editIndex: any): void {
        const editColumnSet = this.props.configuration.columnSets[editIndex];
        this.setState({
            editIndex: editIndex,
            editColumnSet: editColumnSet
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleColumnSetChange(columnSet: ColumnSet): void {
        this.setState({ editColumnSet: columnSet })
    }

    private handleColumnSetAdd(): void {
        const columnSet: ColumnSet = {
            name: undefined as any,
            columnDefs: [{
                index: undefined as any,
                size: SizeType.MED
            }]
        }
        this.setState({
            editIndex: -1,
            editColumnSet: columnSet
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleColumnSetRemove(index: number): void {
        const configuration = update(this.props.configuration, {
            columnSets: {
                $splice: [[index, 1]]
            }
        });
        this.setState({ editIndex: -1 });
        this.props.onChange(configuration);
    }

    private handleColumnSetCancel(): void {
        this.setState({
            editIndex: undefined,
            editColumnSet: undefined,
            columnErrors: {}
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private handleColumnSetSave(): void {
        const columnSet = this.state.editColumnSet!;
        const columnErrors: ColumnErrors = {};
        if (!columnSet.name) {
            columnErrors.name = {
                status: "error",
                help: "Please enter a column name."
            }
        }
        const primaryDef = columnSet.columnDefs[0];
        if (primaryDef && CoreUtils.isEmpty(primaryDef.index)) {
            columnErrors.primary = {
                status: "error",
                help: "Please enter a column index."
            };
        }
        const secondaryDef = columnSet.columnDefs[1];
        if (secondaryDef && CoreUtils.isEmpty(secondaryDef.index)) {
            columnErrors.secondary = {
                status: "error",
                help: "Please enter a column index."
            };
        }

        if (columnErrors.name || columnErrors.primary || columnErrors.secondary) {
            this.setState({ columnErrors: columnErrors });
            return;
        }

        let configuration;
        if (this.state.editIndex === -1) {
            // Add a new column set.
            configuration = update(this.props.configuration, {
                columnSets: {
                    $push: [this.state.editColumnSet!]
                }
            });
        } else {
            // Update an existing column set.
            configuration = update(this.props.configuration, {
                columnSets: {
                    [this.state.editIndex!]: { $set: this.state.editColumnSet! }
                }
            });
        }
        this.setState({
            editIndex: undefined,
            editColumnSet: undefined,
            columnErrors: {}
        });
        this.props.onChange(configuration, false);
    }

    private handleNameChange(name: string, index: number): void {
        const columnSet = update(this.state.editColumnSet, {
            name: { $set: name }
        });
        this.setState({ editColumnSet: columnSet });
    }

    private handleStyleChange(value: StyleData, index: number): void {
        const columnSet = update(this.state.editColumnSet, {
            columnDefs: {
                [index]: {
                    weight: { $set: value.weight },
                    size: { $set: value.size },
                    color: { $set: value.color },
                    justification: { $set: value.justification },
                    display: { $set: value.display }
                }
            }
        });
        this.setState({ editColumnSet: columnSet });
    }

    private handleIndexChange(value: number, index: number): void {
        const columnSet = update(this.state.editColumnSet, {
            columnDefs: {
                [index]: {
                    index: { $set: value }
                }
            }
        });
        this.setState({ editColumnSet: columnSet });

    }

    private handleSecondaryToggle(hasSecondary: boolean): void {
        let columnSet;
        if (hasSecondary) {
            columnSet = update(this.state.editColumnSet, {
                columnDefs: {
                    $push: [{
                        display: DisplayType.NORMAL
                    } as ColumnDef]
                }
            });
        } else {
            columnSet = update(this.state.editColumnSet, {
                columnDefs: {
                    $splice: [[1, 1]]
                }
            });
        }
        this.setState({ editColumnSet: columnSet });
    }

    public componentDidMount(): void {
        if (this.props.configuration === ListWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.extra}
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        label="Data Range"
                        name="range"
                        info="The range that includes the data that can be added to the list."
                    >
                        <RefEditor
                            formRef={this.props.formRef}
                            index={0}
                            calculator={this.props.calculator}
                            refTypes={[RefType.RANGE]}
                            refId={this.props.configuration.rangeId}
                            onChange={this.handleRangeChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Show Column Names"
                        name="header"
                        info="Enable to show column names at the top of each list column."
                        valuePropName="checked"
                    >
                        <Switch
                            checked={this.props.configuration.hasHeaders}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={this.handleHeadersToggle}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Add Row Link"
                        name="link"
                        info="Enable configuration of a link when a row is selected."
                        valuePropName="checked"
                    >
                        <Switch
                            checked={!!this.props.configuration.rowLink}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={this.handleLinkToggle}
                        />
                    </FormItem>
                    {!!this.props.configuration.rowLink &&
                        <>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Link Variable"
                                name="link-variable"
                                info="The variable to change when a row is selected."
                                valuePropName="variable"
                                rules={[{
                                    required: true,
                                    message: "Please select a variable."
                                }]}
                            >
                                <VariableSelector
                                    calculator={this.props.calculator}
                                    variable={this.props.configuration.rowLink.variable}
                                    onChange={this.handleLinkVariableChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Link Column Index"
                                name="link-index"
                                info="The column index (1-based) that includes the values that will be used to set the variable."
                                rules={[{
                                    required: true,
                                    message: "Please enter a column index."
                                }]}
                            >
                                <IntegerInput
                                    fill={true}
                                    natural={true}
                                    placeholder="Enter an index."
                                    value={this.props.configuration.rowLink.index}
                                    onChange={this.handleLinkIndexChange}
                                />
                            </FormItem>
                        </>
                    }
                </Col>
                <Col span={12}>
                    {!this.state.editColumnSet &&
                        <>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Columns"
                                name="columns"
                                required={true}
                                info="The columns to use to generate the list."
                                rules={[{
                                    required: true,
                                    validator: (rule: any) => {
                                        return this.props.configuration.columnSets.length === 0 ?
                                            Promise.reject('Please configure at least one column.') :
                                            Promise.resolve();
                                    }
                                }]}
                            >
                                <List
                                    className="x-listwidgeteditor-columnset-list"
                                    itemLayout="horizontal"
                                >
                                    {this.props.configuration.columnSets.length === 0 &&
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description={<span>No columns.</span>}
                                        />
                                    }
                                    {this.props.configuration.columnSets.length > 0 &&
                                        this.props.configuration.columnSets.map((columnSet, index) => (
                                            <List.Item
                                                className="x-listwidgeteditor-columnset-item"
                                                key={index}
                                            >
                                                <span className="x-listwidgeteditor-columnset-name">{columnSet.name}</span>
                                                <Spacer justification="right">
                                                    <Clickable data={index} onClick={this.handleColumnSetEdit}>
                                                        <EditOutlined />
                                                    </Clickable>
                                                    <Clickable data={index} onClick={this.handleColumnSetRemove}>
                                                        <DeleteOutlined />
                                                    </Clickable>
                                                </Spacer>
                                            </List.Item>
                                        ))}
                                </List>
                            </FormItem>
                            <Justify justification="right">
                                <Button onClick={this.handleColumnSetAdd}>
                                    Add Column
                                </Button>
                            </Justify>
                        </>
                    }
                    {this.state.editColumnSet &&
                        <>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Column Name"
                                name="column-name"
                                info="The name of the column."
                                required={true}
                                validateStatus={this.state.columnErrors.name?.status}
                                help={this.state.columnErrors.name?.help}
                            >
                                <Input
                                    placeholder="Enter a column name."
                                    value={this.state.editColumnSet.name}
                                    onChange={(e) => this.handleNameChange(e.target.value, 0)}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Primary Row Column"
                                name="primary-index"
                                info="The column that contains the primary row data (1-based)."
                                required={true}
                                validateStatus={this.state.columnErrors.primary?.status}
                                help={this.state.columnErrors.primary?.help}
                            >
                                <IntegerInput
                                    placeholder="Column index."
                                    fill={true}
                                    natural={true}
                                    value={this.state.editColumnSet.columnDefs[0].index}
                                    onChange={(value) => this.handleIndexChange(value, 0)}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Primary Row Formatting"
                                name="primary-formatting"
                                info="The formatting for the row column."
                            >
                                <WidgetStyle
                                    value={{
                                        weight: this.state.editColumnSet.columnDefs[0].weight,
                                        size: this.state.editColumnSet.columnDefs[0].size,
                                        color: this.state.editColumnSet.columnDefs[0].color,
                                        justification: this.state.editColumnSet.columnDefs[0].justification,
                                        display: this.state.editColumnSet.columnDefs[0].display
                                    }}
                                    styles={["size", "weight", "color", "justification" , "display"]}
                                    onChange={(data) => this.handleStyleChange(data, 0)}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Add Secondary Row"
                                name="secondary"
                                info="Defined a secondary row for the column."
                                valuePropName="checked"
                            >
                                <Switch
                                    checked={this.state.editColumnSet.columnDefs.length > 1}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={this.handleSecondaryToggle}
                                />
                            </FormItem>
                            {this.state.editColumnSet.columnDefs.length > 1 &&
                                <>
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.props.formRef}
                                        label="Secondary Row Column"
                                        name="secondary-index"
                                        info="The column that contains the secondary row data (1-based)."
                                        required={true}
                                        validateStatus={this.state.columnErrors.secondary?.status}
                                        help={this.state.columnErrors.secondary?.help}
                                    >
                                        <IntegerInput
                                            placeholder="Column index."
                                            fill={true}
                                            natural={true}
                                            value={this.state.editColumnSet.columnDefs[1].index}
                                            onChange={(value) => this.handleIndexChange(value, 1)}
                                        />
                                    </FormItem>
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.props.formRef}
                                        label="Secondary Row Formatting"
                                        name="secondary-formatting"
                                        info="The formatting for the row column."
                                    >
                                        <WidgetStyle
                                            value={{
                                                weight: this.state.editColumnSet.columnDefs[1].weight,
                                                size: this.state.editColumnSet.columnDefs[1].size,
                                                color: this.state.editColumnSet.columnDefs[1].color,
                                                justification: this.state.editColumnSet.columnDefs[1].justification,
                                                display: this.state.editColumnSet.columnDefs[1].display
                                            }}
                                            styles={["size", "weight", "color", "justification" , "display"]}
                                            onChange={(data) => this.handleStyleChange(data, 1)}
                                        />
                                    </FormItem>
                                </>
                            }
                            <Justify className="x-listwidgeteditor-action" justification="right">
                                <Spacer>
                                    <Button onClick={this.handleColumnSetCancel}>Cancel Edit</Button>
                                    <Button type="primary" onClick={this.handleColumnSetSave}>Save Column</Button>
                                </Spacer>
                            </Justify>
                        </>
                    }
                </Col>
            </Row>
        );
    }

}
