import { PureComponent, ReactElement } from 'react';
import { Select } from 'antd';
import { InputKey, Model } from '@methodset/model-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { ModelMap, AppletMap } from 'containers/Console/Dashboards/DashboardItem/DashboardItem';
import { WidgetUtils } from 'utils/WidgetUtils';
import update from 'immutability-helper';
import './InputKeyEditor.less';
import { UsedSpecMap } from '../../InputLinks';

export type ChangeCallback = (inputKey: InputKey, index: number) => void;

export type InputKeyEditorProps = typeof InputKeyEditor.defaultProps & {
    index: number,
    modelMap: ModelMap,
    widgetMap: AppletMap,
    inputKey: InputKey,
    usedSpecs: UsedSpecMap,
    onChange: ChangeCallback
}

export class InputKeyEditor extends PureComponent<InputKeyEditorProps> {

    static defaultProps = {
        index: 0
    }

    constructor(props: InputKeyEditorProps) {
        super(props);
        this.handleWidgetChange = this.handleWidgetChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    private handleWidgetChange(key: string): void {
        const [modelId, version, widgetId] = key.split(":");
        const inputKey = update(this.props.inputKey, {
            modelId: { $set: modelId },
            version: { $set: parseInt(version) },
            widgetId: { $set: widgetId },
            specKey: { $set: undefined as any }
        });
        this.props.onChange(inputKey, this.props.index);
    }

    private handleInputChange(specKey: string): void {
        const inputKey = update(this.props.inputKey, {
            specKey: { $set: specKey }
        });
        this.props.onChange(inputKey, this.props.index);
    }

    private buildWidgets(): ReactElement[] | null {
        const options = [];
        const entries = Object.entries(this.props.widgetMap);
        for (const [key, widget] of entries) {
            const option = (
                <Select.Option
                    key={key}
                    value={key}
                >
                    {widget.name}
                </Select.Option>
            );
            options.push(option);
        }
        return options;
    }

    private buildInputs(inputKey: InputKey): ReactElement[] | null {
        const key = WidgetUtils.toKey(inputKey.modelId, inputKey.version);
        const model = this.props.modelMap[key];
        if (!model) {
            return null;
        }
        const variableSpecs = model.variableSpecs;
        const options = [];
        for (const variableSpec of variableSpecs) {
            const option = (
                <Select.Option
                    key={variableSpec.key}
                    value={variableSpec.key}
                    disabled={this.isSpecUsed(model, variableSpec.key)}
                >
                    {variableSpec.name}
                </Select.Option>
            )
            options.push(option);
        }
        return options;
    }

    private isSpecUsed(model: Model, specKey: string): boolean {
        const key = WidgetUtils.toKey(model.id, model.version, specKey);
        return !!this.props.usedSpecs[key];
    }

    private toInputKey(inputKey: InputKey): string | undefined {
        if (!inputKey.modelId || CoreUtils.isEmpty(inputKey.version) || !inputKey.widgetId) {
            return undefined;
        }
        return WidgetUtils.toKey(inputKey.modelId, inputKey.version, inputKey.widgetId);
    }

    public render(): ReactElement {
        return (
            <Spacer direction="vertical" fill={true}>
                <Select
                    className="x-inputkeyeditor-fill"
                    placeholder="Select a widget."
                    value={this.toInputKey(this.props.inputKey)}
                    onChange={this.handleWidgetChange}
                >
                    {this.buildWidgets()}
                </Select>
                <Select
                    className="x-inputkeyeditor-fill"
                    placeholder="Select an input."
                    value={this.props.inputKey.specKey}
                    onChange={this.handleInputChange}
                >
                    {this.buildInputs(this.props.inputKey)}
                </Select>
            </Spacer>
        );
    }

}
