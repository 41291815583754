import React, { PureComponent, ReactElement } from 'react';
import { FormInstance } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { InputWidgetConfiguration, JustificationType, LocationType, WidgetType } from '@methodset/model-client-ts';
import { LocationSelector } from 'containers/Components/Widgets/Selectors/LocationSelector';
import { JustificationSelector } from 'containers/Components/Widgets/Selectors/JustificationSelector';
import { VariableSpecSelector } from 'containers/Components/Widgets/Selectors/VariableSpecSelector';
import update from 'immutability-helper';
import './InputWidgetEditor.less';

export type ChangeCallback = (configuration: InputWidgetConfiguration) => void;

export type InputWidgetEditorProps = typeof InputWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    calculator: Calculator,
    configuration?: InputWidgetConfiguration,
    onChange: ChangeCallback
}

export class InputWidgetEditor extends PureComponent<InputWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.INPUT,
        variable: undefined as any,
        labelLocation: undefined as any,
        justification: JustificationType.LEFT
    } as InputWidgetConfiguration;

    static defaultProps = {
        configuration: InputWidgetEditor.DefaultConfiguration
    }

    constructor(props: InputWidgetEditorProps) {
        super(props);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleJustificationChange = this.handleJustificationChange.bind(this);
    }

    private handleVariableChange(variable: string): void {
        const configuration = update(this.props.configuration, {
            variable: { $set: variable }
        });
        this.props.onChange(configuration);
    }

    private handleLocationChange(labelLocation: LocationType): void {
        const configuration = update(this.props.configuration, {
            labelLocation: { $set: labelLocation }
        });
        this.props.onChange(configuration);
    }

    private handleJustificationChange(justification: JustificationType): void {
        const configuration = update(this.props.configuration, {
            justification: { $set: justification }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === InputWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <>
                {this.props.extra}
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Variable"
                    name="variable"
                    info="The variable to change when a selection is chosen."
                    valuePropName="variable"
                    rules={[{
                        required: true,
                        message: 'Please select a variable.'
                    }]}
                >
                    <VariableSpecSelector
                        calculator={this.props.calculator}
                        variable={this.props.configuration.variable}
                        onChange={this.handleVariableChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Add Label"
                    name="label"
                    info="Adds a label to the given location, unselect to remove the label."
                >
                    <LocationSelector
                        includes={[LocationType.LEFT, LocationType.TOP]}
                        value={this.props.configuration.labelLocation}
                        onChange={this.handleLocationChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Justification"
                    name="justification"
                    info="Justification of the widget."
                >
                    <JustificationSelector
                        value={this.props.configuration.justification}
                        onChange={this.handleJustificationChange}
                    />
                </FormItem>
            </>
        );
    }

}
