import React, { PureComponent, ReactElement } from 'react';
import { FormInstance, Switch } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { TableWidgetConfiguration, WidgetType } from '@methodset/model-client-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import update from 'immutability-helper';
import './TableWidgetEditor.less';

export type ChangeCallback = (configuration: TableWidgetConfiguration) => void;

export type TableWidgetEditorProps = typeof TableWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    configuration?: TableWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class TableWidgetEditor extends PureComponent<TableWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.TABLE
    } as TableWidgetConfiguration;

    static defaultProps = {
        configuration: TableWidgetEditor.DefaultConfiguration
    }

    constructor(props: TableWidgetEditorProps) {
        super(props);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleHeadersToggle = this.handleHeadersToggle.bind(this);
        // this.handleLinksToggle = this.handleLinksToggle.bind(this);
        // this.handleLinkVariableChange = this.handleLinkVariableChange.bind(this);
        // this.handleLinkIndexChange = this.handleLinkIndexChange.bind(this);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            rangeId: { $set: rangeId as any }
        });
        this.props.onChange(configuration);
    }

    private handleHeadersToggle(hasHeaders: boolean): void {
        const configuration = update(this.props.configuration, {
            hasHeaders: { $set: hasHeaders }
        });
        this.props.onChange(configuration);
    }

    // private handleLinksToggle(hasLinks: boolean): void {
    //     const rowLink = hasLinks ? {} as RowLink : undefined;
    //     const configuration = update(this.props.configuration, {
    //         rowLink: { $set: rowLink },
    //     });
    //     this.props.onChange(configuration);
    // }

    // private handleLinkVariableChange(variable: string): void {
    //     const configuration = update(this.props.configuration, {
    //         rowLink: {
    //             variable: { $set: variable }
    //         }
    //     });
    //     this.props.onChange(configuration);
    // }

    // private handleLinkIndexChange(index: number): void {
    //     const configuration = update(this.props.configuration, {
    //         rowLink: {
    //             index: { $set: index }
    //         }
    //     });
    //     this.props.onChange(configuration);
    // }

    public componentDidMount(): void {
        if (this.props.configuration === TableWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <>
                {this.props.extra}
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    required={true}
                    //noError={true}
                    label="Data Range"
                    name="range"
                    info="The cell range to include in the table."
                >
                    <RefEditor
                        formRef={this.props.formRef}
                        //required={true}
                        index={0}
                        calculator={this.props.calculator}
                        refTypes={[RefType.RANGE]}
                        refId={this.props.configuration.rangeId}
                        onChange={this.handleRangeChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Includes Header Row"
                    name="header"
                    info="Enable if the first row in the data range contains column headers."
                    valuePropName="checked"
                >
                    <Switch
                        checked={this.props.configuration.hasHeaders}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={this.handleHeadersToggle}
                    />
                </FormItem>
                {/* <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Add Table Links"
                    name="links"
                    info="Enable configuration of links in one of the columns."
                    valuePropName="checked"
                >
                    <Switch
                        checked={!!this.props.configuration.rowLink}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={this.handleLinksToggle}
                    />
                </FormItem>
                {!!this.props.configuration.rowLink &&
                    <>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.props.formRef}
                            label="Link Variable"
                            name="link-variable"
                            info="The variable to change when a selection is chosen."
                            valuePropName="variable"
                            rules={[{
                                required: true,
                                message: "Please select a variable."
                            }]}
                        >
                            <VariableSelector
                                calculator={this.props.calculator}
                                variable={this.props.configuration.rowLink.variable}
                                onChange={this.handleLinkVariableChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.props.formRef}
                            label="Link Column Index"
                            name="link-index"
                            info="The column index that includes the values that will be used to set the variable."
                            valuePropName="refId"
                            rules={[{
                                required: true,
                                message: "Please enter a column index."
                            }]}
                        >
                            <IntegerInput
                                natural={true}
                                value={this.props.configuration.rowLink.index}
                                onChange={this.handleLinkIndexChange}
                            />
                        </FormItem>
                    </>
                } */}
            </>
        );
    }

}
