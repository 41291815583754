import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Select } from 'antd';
import { FilingStatus, Taxes } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { CoreUtils } from 'utils/CoreUtils';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import update from 'immutability-helper';
import './TaxesEditor.less';

export type ChangeCallback = (taxes: Taxes) => void;

export type TaxesEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    taxes?: Taxes,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    taxes: {}
}

export const TaxesEditor = (props: TaxesEditorProps): ReactElement => {

    const handleStatusChange = (filingStatus: FilingStatus): void => {
        const taxes = update(props.taxes, {
            filingStatus: { $set: filingStatus }
        });
        props.onChange(taxes);
    }

    const handleAgiChange = (adjustedGrossIncome: number): void => {
        const taxes = update(props.taxes, {
            adjustedGrossIncome: { $set: adjustedGrossIncome }
        });
        props.onChange(taxes);
    }

    const handleStudentLoanChange = (studentLoanAdjustment: number): void => {
        const taxes = update(props.taxes, {
            studentLoanAdjustment: { $set: studentLoanAdjustment }
        });
        props.onChange(taxes);
    }

    const handleForeignIncomeChange = (foreignEarnedIncomeExclusion: number): void => {
        const taxes = update(props.taxes, {
            foreignEarnedIncomeExclusion: { $set: foreignEarnedIncomeExclusion }
        });
        props.onChange(taxes);
    }

    const handleForeignHousingChange = (foreignHousingDeduction: number): void => {
        const taxes = update(props.taxes, {
            foreignHousingDeduction: { $set: foreignHousingDeduction }
        });
        props.onChange(taxes);
    }

    const handleExcludableInterestChange = (excludableSavingsBondInterest: number): void => {
        const taxes = update(props.taxes, {
            excludableSavingsBondInterest: { $set: excludableSavingsBondInterest }
        });
        props.onChange(taxes);
    }

    const handleExcludedBenefitsChange = (excludedEmployerAdoptionBenefits: number): void => {
        const taxes = update(props.taxes, {
            excludedEmployerAdoptionBenefits: { $set: excludedEmployerAdoptionBenefits }
        });
        props.onChange(taxes);
    }

    const handleRothConversionsChange = (incomeFromRothConversions: number): void => {
        const taxes = update(props.taxes, {
            incomeFromRothConversions: { $set: incomeFromRothConversions }
        });
        props.onChange(taxes);
    }

    const handleIraDeductionChange = (traditionalIraDeduction: number): void => {
        const taxes = update(props.taxes, {
            traditionalIraDeduction: { $set: traditionalIraDeduction }
        });
        props.onChange(taxes);
    }

    return (
        <>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Filing Status"
                name={`${props.id}-filing-status`}
            >
                <Select
                    allowClear={true}
                    value={props.taxes.filingStatus}
                    onChange={(filingStatus) => handleStatusChange(filingStatus)}
                >
                    {CoreUtils.enumToKeys(FilingStatus).map(key => (
                        <Select.Option key={key} value={key}>{CoreUtils.toProper(key, "_")}</Select.Option>
                    ))}
                </Select>
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Adjusted Gross Income (AGI)"
                name={`${props.id}-agi`}
                info="Form 1040, 1040-SR, or Form 1040-NR, line 11."
            >
                <IntegerInput
                    fill={true}
                    placeholder="Adjusted gross income."
                    value={props.taxes.adjustedGrossIncome}
                    onChange={(adjustedGrossIncome) => handleAgiChange(adjustedGrossIncome)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Student Loan Adjustment"
                name={`${props.id}-student-loan`}
                info="Form 1040, 1040-SR, or Form 1040-NR, line 11."
            >
                <IntegerInput
                    fill={true}
                    placeholder="Student loan adjustment."
                    value={props.taxes.studentLoanAdjustment}
                    onChange={(studentLoanAdjustment) => handleStudentLoanChange(studentLoanAdjustment)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Foreign Earned Income Exclusion"
                name={`${props.id}-foreign-income`}
                info="Form 2555, line 45."
            >
                <IntegerInput
                    fill={true}
                    placeholder="Foreign earned income exclusion."
                    value={props.taxes.foreignEarnedIncomeExclusion}
                    onChange={(foreignEarnedIncomeExclusion) => handleForeignIncomeChange(foreignEarnedIncomeExclusion)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Foreign Housing Deduction"
                name={`${props.id}-foreign-housing`}
                info="Form 2555, line 45."
            >
                <IntegerInput
                    fill={true}
                    placeholder="Foreign housing deduction."
                    value={props.taxes.foreignHousingDeduction}
                    onChange={(foreignHousingDeduction) => handleForeignHousingChange(foreignHousingDeduction)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Excludable Savings Bond Interest"
                name={`${props.id}-excludable-interest`}
                info="Form 8815, line 14."
            >
                <IntegerInput
                    fill={true}
                    placeholder="Excludable savings bond interest."
                    value={props.taxes.excludableSavingsBondInterest}
                    onChange={(excludableSavingsBondInterest) => handleExcludableInterestChange(excludableSavingsBondInterest)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Excluded Employer Adoption Benefits"
                name={`${props.id}-excluded-benefits`}
                info="Form 8839, line 28."
            >
                <IntegerInput
                    fill={true}
                    placeholder="Excluded employer adoption benefits."
                    value={props.taxes.excludedEmployerAdoptionBenefits}
                    onChange={(excludedEmployerAdoptionBenefits) => handleExcludedBenefitsChange(excludedEmployerAdoptionBenefits)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Income From Roth Conversions"
                name={`${props.id}-roth-conversion`}
                info="Form 1040, 1040-SR, or 1040-NR, line 4b and line 5b."
            >
                <IntegerInput
                    fill={true}
                    placeholder="Income from Roth conversions."
                    value={props.taxes.incomeFromRothConversions}
                    onChange={(incomeFromRothConversions) => handleRothConversionsChange(incomeFromRothConversions)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Traditional IRA Deduction"
                name={`${props.id}-ira-deduction`}
                info="Schedule 1 (Form 1040), line 20."
            >
                <IntegerInput
                    fill={true}
                    placeholder="Traditional IRA deduction."
                    value={props.taxes.traditionalIraDeduction}
                    onChange={(traditionalIraDeduction) => handleIraDeductionChange(traditionalIraDeduction)}
                />
            </FormItem>
        </>
    )
}

TaxesEditor.defaultProps = defaultProps;
