import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { Button, FormInstance } from 'antd';
import { Income, LumpSum } from '@methodset/entity-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { FormDivider } from 'components/FormDivider/FormDivider';
import { FormItem } from 'components/FormItem/FormItem';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import { LumpSumEditor } from './LumpSumEditor/LumpSumEditor';
import update from 'immutability-helper';
import './IncomeEditor.less';

export type ChangeCallback = (income: Income) => void;

export type IncomeEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    income?: Income,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    income: {
        annualSalary: undefined as any,
        otherIncome: []
    } as Income
}

export const IncomeEditor = (props: IncomeEditorProps): ReactElement => {

    const handleSalaryChange = (annualSalary: number): void => {
        const income = update(props.income, {
            annualSalary: { $set: annualSalary }
        });
        props.onChange(income);
    }

    const handleIncomeAdd = (): void => {
        const lumpSum = {} as LumpSum;
        const income = update(props.income, {
            otherIncome: {
                $push: [lumpSum]
            }
        });
        props.onChange(income);
    }

    const handleIncomeRemove = (index: number): void => {
        const income = update(props.income, {
            otherIncome: {
                $splice: [[index, 1]]
            }
        });
        props.onChange(income);
    }

    const handleIncomeChange = (lumpSum: LumpSum, index: number): void => {
        const income = update(props.income, {
            otherIncome: {
                [index]: { $set: lumpSum }
            }
        });
        props.onChange(income);
    }

    return (
        <>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Annual Salary"
                name={`${props.id}-annual-salary`}
                // rules={[{
                //     required: true,
                //     message: 'Please enter the annual salary.'
                // }]}
            >
                <IntegerInput
                    fill={true}
                    placeholder="Annual salary."
                    value={props.income.annualSalary}
                    onChange={(annualSalary) => handleSalaryChange(annualSalary)}
                />
            </FormItem>
            <Spacer direction="vertical">
                {props.income.otherIncome?.map((lumpSum, index) => (
                    <>
                        <FormDivider
                            {...Globals.FORM_LAYOUT}
                            formRef={props.formRef}
                            label={`Other Income ${index + 1}`}
                            fill={true}
                            bold={true}
                            colon={false}
                            inline={true}
                            onDelete={() => handleIncomeRemove(index)}
                        />
                        <LumpSumEditor
                            formRef={props.formRef}
                            id={`other-income-${index}`}
                            lumpSum={lumpSum}
                            onChange={(lumpSum) => handleIncomeChange(lumpSum, index)}
                        />
                    </>
                ))}
                <Button onClick={() => handleIncomeAdd()}>Add Other Income</Button>
            </Spacer>
        </>
    )
}

IncomeEditor.defaultProps = defaultProps;
