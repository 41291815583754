import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { FormInstance, Input, Switch } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ColorType, JustificationType, SizeType, TextWidgetConfiguration, ValueLink, WeightType, WidgetType } from '@methodset/model-client-ts';
import { VariableSelector } from 'containers/Components/Widgets/Selectors/VariableSelector';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import { StyleData, WidgetStyle } from 'containers/Components/Widgets/WidgetStyle/WidgetStyle';
import update from 'immutability-helper';
import './TextWidgetEditor.less';

export type ChangeCallback = (configuration: TextWidgetConfiguration) => void;

export type TextWidgetEditorProps = typeof TextWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    configuration?: TextWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class TextWidgetEditor extends PureComponent<TextWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.TEXT,
        size: SizeType.MED,
        color: ColorType.DARKGRAY,
        weight: WeightType.NORMAL,
        justification: JustificationType.LEFT
    } as TextWidgetConfiguration;

    static defaultProps = {
        configuration: TextWidgetEditor.DefaultConfiguration
    }

    constructor(props: TextWidgetEditorProps) {
        super(props);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleLinkToggle = this.handleLinkToggle.bind(this);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleStyleChange = this.handleStyleChange.bind(this);
    }

    private handleTextChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const text = e.target.value;
        const configuration = update(this.props.configuration, {
            text: { $set: text }
        });
        this.props.onChange(configuration);
    }

    private handleLinkToggle(hasLink: boolean): void {
        const valueLink = hasLink ? {} as ValueLink : undefined;
        const configuration = update(this.props.configuration, {
            valueLink: { $set: valueLink },
        });
        this.props.onChange(configuration);
        this.setState({ hasLinks: hasLink });
    }

    private handleVariableChange(variable: string): void {
        const configuration = update(this.props.configuration, {
            valueLink: {
                variable: { $set: variable }
            }
        });
        this.props.onChange(configuration);
    }

    private handleValueChange(valueId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            valueLink: {
                valueId: { $set: valueId as any }
            }
        });
        this.props.onChange(configuration);
    }

    private handleStyleChange(value: StyleData): void {
        const configuration = update(this.props.configuration, {
            weight: { $set: value.weight },
            size: { $set: value.size },
            color: { $set: value.color },
            justification: { $set: value.justification }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === TextWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <>
                {this.props.extra}
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Text"
                    name="text"
                    info="Text to add to the widget."
                    rules={[{
                        required: true,
                        message: 'Please enter the test.'
                    }]}
                >
                    <Input.TextArea
                        rows={3}
                        value={this.props.configuration.text}
                        onChange={this.handleTextChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Add Text Link"
                    name="link"
                    info="Enable configuration of a link on the text value."
                    valuePropName="checked"
                >
                    <Switch
                        checked={!!this.props.configuration.valueLink}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={this.handleLinkToggle}
                    />
                </FormItem>
                {!!this.props.configuration.valueLink &&
                    <>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.props.formRef}
                            label="Variable"
                            name="variable"
                            info="The variable to change when the link is clicked."
                            valuePropName="variable"
                        >
                            <VariableSelector
                                calculator={this.props.calculator}
                                variable={this.props.configuration.valueLink?.variable}
                                onChange={this.handleVariableChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.props.formRef}
                            valuePropName="refId"
                            label="Link Value"
                            name="link-value"
                            info="The cell or parameter that holds the value to set the variable to."
                        >
                            <RefEditor
                                formRef={this.props.formRef}
                                required={false}
                                index={1}
                                calculator={this.props.calculator}
                                refTypes={[RefType.CELL, RefType.PARAMETER]}
                                refId={this.props.configuration.valueLink?.valueId}
                                onChange={this.handleValueChange}
                            />
                        </FormItem>
                    </>
                }
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Text Formatting"
                    name="formatting"
                    info="The formatting for the text."
                >
                    <WidgetStyle
                        value={{
                            weight: this.props.configuration.weight,
                            size: this.props.configuration.size,
                            color: this.props.configuration.color,
                            justification: this.props.configuration.justification,
                        }}
                        styles={["size", "weight", "color", "justification"]}
                        onChange={this.handleStyleChange}
                    />
                </FormItem>
            </>
        );
    }

}
