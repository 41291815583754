import { PureComponent, ReactElement } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { AvatarProps, PageHeader, Table, TablePaginationConfig } from 'antd';
import { Globals } from 'constants/Globals';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { MenuButton } from 'components/MenuButton/MenuButton';
import { Spacer } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import './ItemTable.less';

// Function to return the key of the row.
export type RowKeyFn = (record: any) => string;

export type ChangeCallback = (pagination: TablePaginationConfig) => void;
export type LoadCallback = () => void;

export type DisabledFunction = (item: any, index: number) => boolean;
export type ActionCallback = (item: any, index: number) => void;

export interface ActionSpec {
    icon?: ReactElement,
    label: string,
    confirm?: string,
    disabled?: DisabledFunction,
    callback: ActionCallback
}

export type ItemTableProps = typeof ItemTable.defaultProps & {
    // Classname to style the element.
    className?: string
    // Image to place to the left of the header title.
    icon?: AvatarProps,
    // The title in the header.
    title?: string,
    // Optional sub-title in the header.
    subtitle?: string,
    // True to show a reloading spinner.
    loading?: boolean,
    // The status of the initial data load.
    status?: string,
    // The field in the record that contains the item id.
    rowKey?: string | RowKeyFn,
    // Optional actions to place in the table.
    actions?: ActionSpec[],
    // Size of table.
    size?: SizeType,
    // A pagination object to configure paging.
    pagination?: false | TablePaginationConfig | undefined,
    // An extra component to place into the right side of header.
    extra?: ReactElement | boolean,
    // The table column definition.
    columns: ColumnsType<any>,
    // The table data. If undefined, no table will be rendered.
    items?: any[],
    // Show the header for the table, default to true.
    showHeader?: boolean,
    // Show the arrow to return to the previous page.
    showBack?: boolean,
    // A function to decide if the actions need to be disabled.
    disabled?: DisabledFunction,
    // Called if the table is changed (new data loaded).
    onChange?: ChangeCallback,
    // Called if the table is being re-loaded (after a fail).
    onLoad?: LoadCallback
};

export class ItemTable extends PureComponent<ItemTableProps> {

    static defaultProps = {
        loading: false,
        showHeader: true,
        showBack: true,
        pagination: {
            showSizeChanger: false
        } as TablePaginationConfig,
        status: Globals.STATUS_READY
    }

    private buildData() {
        return this.props.items;
    }

    private buildColumns(): ColumnsType<any> {
        if (this.props.actions) {
            const actions: ColumnType<any> = {
                key: "actions",
                title: "Actions",
                align: "center",
                width: 100,
                render: (value: any, record: any, index: number) => {
                    if (this.props.disabled && this.props.disabled(record, index)) {
                        return <span>{Globals.EMPTY_FIELD}</span>
                    } else {
                        return this.buildActions(record, index);
                    }
                }
            }
            this.props.columns.push(actions);
        }
        return this.props.columns;
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={8}
                spacer="large"
                status={isLoading ? "loading" : "failed"}
                onRetry={this.props.onLoad!}
            >
                <LoadSkeleton.Input length="medium" />
                <LoadSkeleton.Input length="fill" />
                <LoadSkeleton.Input length="short" />
                <LoadSkeleton.Input length="short" />
                <LoadSkeleton.Input length="medium" />
                <LoadSkeleton.Input length="short" />
            </LoadSkeleton>
        );
    }

    private buildTableView(): ReactElement {
        const columns = this.buildColumns();
        const data = this.buildData();
        const rowKey = !this.props.rowKey ? "id" : typeof this.props.rowKey === "function" ?
            this.props.rowKey : (record: any) => record[this.props.rowKey as string];
        return (
            <div>
                {this.props.items &&
                    <Table
                        size={this.props.size}
                        loading={this.props.loading}
                        pagination={this.props.pagination}
                        columns={columns}
                        dataSource={data}
                        rowKey={rowKey}
                        onChange={this.props.onChange}
                    />
                }
            </div>
        );
    }

    private buildActions(record: any, index: number): ReactElement | null {
        const actions = this.props.actions;
        if (!actions) {
            return null;
        }
        const items = actions.map((action: ActionSpec) => {
            return {
                icon: action.icon,
                label: action.label,
                confirm: action.confirm,
                disabled: action.disabled ? (record: any) => action.disabled!(record, index) : undefined,
                onSelect: (record: any) => action.callback(record, index)
            }
        });
        return <MenuButton data={record} items={items} />;
    }

    public render(): ReactElement {
        let view;
        if (this.props.status === Globals.STATUS_LOADING && this.props.onLoad) {
            view = this.buildLoadingView(true);
        } else if (this.props.status === Globals.STATUS_FAILED && this.props.onLoad) {
            view = this.buildLoadingView(false);
        } else if (this.props.status === Globals.STATUS_READY) {
            view = this.buildTableView();
        }
        return (
            <div className={classNames("x-itemtable", this.props.className)}>
                {this.props.showHeader &&
                    <PageHeader
                        className="x-itemtable-header"
                        avatar={this.props.icon}
                        title={this.props.title}
                        subTitle={this.props.subtitle}
                        extra={this.props.extra}
                        onBack={this.props.showBack ? () => window.history.back() : undefined}
                    />
                }
                {!this.props.showHeader && this.props.extra &&
                    <Spacer className="x-itemtable-header" justification="right">
                        {this.props.extra}
                    </Spacer>
                }
                <div className="x-itemtable-body">
                    {view}
                </div>
            </div>
        );
    }

}
