import { ReactElement } from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import './Tip.less';

export type TipProps = {
    className?: string,
    placement?: TooltipPlacement,
    title: string,
    children: any,
    mouseEnterDelay: number
} & typeof defaultProps;

const defaultProps = {
    mouseEnterDelay: 0.5
}

/**
 * Creates a tooltip with default values.
 */
export const Tip = (props: TipProps): ReactElement => {
    const {placement, mouseEnterDelay, ...rest} = props;
    return (
        <Tooltip 
            className="x-tip" 
            mouseEnterDelay={props.mouseEnterDelay} 
            placement={placement} 
            {...rest}
        >
            {props.children}
        </Tooltip>
    )
}

Tip.defaultProps = defaultProps;
