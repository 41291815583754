import { PureComponent, ReactElement } from 'react';
import { Empty, message } from 'antd';
import { PivotEditor } from './PivotEditor/PivotEditor';
import { ActiveCell, ModelContext } from 'context/ModelContext';
import { Cell, ElementChangeType, FunctionChangeEvent, FunctionChangeType, Parameter, Sheet, SheetChangeEvent } from "@methodset/calculator-ts";
import { PivotItem } from './PivotItem/PivotItem';
import { FunctionCell } from '../FunctionCell/FunctionCell';
import update from 'immutability-helper';
import './Pivots.less';

export type PivotProps = {
}

export type PivotState = {
    pivotCells: Cell[],
    editCell?: ActiveCell
}

export class Pivots extends PureComponent<PivotProps, PivotState> {

    static contextType = ModelContext;

    constructor(props: PivotProps) {
        super(props);
        this.state = {
            pivotCells: []
        };
        this.handleSheetChange = this.handleSheetChange.bind(this);
        this.handleFunctionChange = this.handleFunctionChange.bind(this);
        this.handleEditorClose = this.handleEditorClose.bind(this);
        this.handlePivotEdit = this.handlePivotEdit.bind(this);
        this.handlePivotAdd = this.handlePivotAdd.bind(this);
        this.handlePivotRemove = this.handlePivotRemove.bind(this);
    }

    private handleSheetChange(event: SheetChangeEvent): void {
        if (event.type === ElementChangeType.ADD) {
            // Get sheet name updates when a new sheet is added.
            event.sheet.addCallback("Change", this.handleSheetChange);
            // Function add or remove events.
            event.sheet.addCallback("FunctionChange", this.handleFunctionChange);
        } else if (event.type === ElementChangeType.REMOVE) {
            event.sheet.removeCallback("Change", this.handleSheetChange);
            event.sheet.removeCallback("FunctionChange", this.handleFunctionChange);
            this.removePivots(event.sheet);
            this.forceUpdate();
        } else if (event.type === ElementChangeType.RENAME) {
            // Sheet name change.
            this.forceUpdate();
        }
    }

    private handleFunctionChange(event: FunctionChangeEvent): void {
        if (event.fn !== "pivot") {
            return;
        }
        let pivotCells;
        if (event.type === FunctionChangeType.ADD) {
            pivotCells = update(this.state.pivotCells, {
                $push: [event.cell]
            });
            this.setState({ pivotCells: pivotCells });
        } else {
            const index = this.state.pivotCells.findIndex((cell: Cell) => cell.uid === event.cell.uid);
            if (event.type === FunctionChangeType.REMOVE) {
                pivotCells = update(this.state.pivotCells, {
                    $splice: [[index, 1]]
                });
            } else if (event.type === FunctionChangeType.UPDATE) {
                pivotCells = update(this.state.pivotCells, {
                    [index]: { $set: event.cell }
                });
            }
        }
        if (pivotCells) {
            this.setState({ pivotCells: pivotCells });
        }
    }

    private handleEditorClose(): void {
        this.setState({ editCell: undefined });
    }

    private handlePivotEdit(cell: Cell): void {
        const editCell: ActiveCell = {
            id: cell.id,
            sheet: cell.sheet
        }
        this.setState({editCell: editCell});
    }

    private handlePivotAdd(): void {
        const editCell = this.context.active.cell;
        if (!editCell) {
            return;
        }
        if (Parameter.isParameterRef(editCell)) {
            message.error("Parameters cannot contain pivots.");
        } else {
            this.setState({editCell: editCell});
        }
    }

    private handlePivotRemove(cell: Cell): void {
        cell.clear();
    }

    private removePivots(sheet: Sheet): void {
        const cells = sheet.tracker.cellsWith("pivot");
        let pivotCells;
        for (let cell of cells) {
            const index = this.state.pivotCells.findIndex((pivotCell: Cell) => pivotCell.uid === cell.uid);
            pivotCells = update(this.state.pivotCells, {
                $splice: [[index, 1]]
            });
        }
        if (pivotCells) {
            this.setState({ pivotCells: pivotCells });
        }
    }

    private buildPivots(): ReactElement {
        if (this.state.pivotCells.length === 0) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No pivots.</span>}
                />
            );
        } else {
            return (
                <div className="x-pivots-items">
                    {this.state.pivotCells.map((cell: Cell) =>
                        <PivotItem
                            key={cell.uid}
                            cell={cell}
                            onEdit={this.handlePivotEdit}
                            onRemove={this.handlePivotRemove}
                        />
                    )}
                </div>
            );
        }
    }

    public componentDidMount(): void {
        const pivotCells: Cell[] = [];
        const sheets = this.context.calculator!.sheets;
        // Sheet add or remove events to attach for name changes.
        sheets.addCallback("Change", this.handleSheetChange);
        sheets.forEach((sheet: Sheet) => {
            // Sheet name change events.
            sheet.addCallback("Change", this.handleSheetChange);
            // Function add or remove events.
            sheet.addCallback("FunctionChange", this.handleFunctionChange);
            const cells = sheet.tracker.cellsWith("pivot");
            cells.forEach((cell: Cell) => {
                pivotCells.push(cell);
            });
        });
        this.setState({ pivotCells: pivotCells });
    }

    public componentWillUnmount(): void {
        const sheets = this.context.calculator!.sheets;
        sheets.removeCallback("Change", this.handleSheetChange);
        sheets.forEach((sheet: Sheet) => {
            sheet.removeCallback("Change", this.handleSheetChange);
            sheet.removeCallback("FunctionChange", this.handleFunctionChange);
        });
    }

    public render(): ReactElement {
        return (
            <div>
                {!this.state.editCell &&
                    <div>
                        {this.buildPivots()}
                        <FunctionCell
                            fn="pivot"
                            onAdd={this.handlePivotAdd}
                            onEdit={this.handlePivotEdit}
                        />
                    </div>
                }
                {this.state.editCell &&
                    <PivotEditor
                        key={this.state.editCell.sheet.id}
                        cell={this.state.editCell}
                        onClose={this.handleEditorClose}
                    />
                }
            </div>
        )
    }

}
