import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { FormInstance, Input, Switch } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ColorType, DividerWidgetConfiguration, JustificationType, WidgetType } from '@methodset/model-client-ts';
import { JustificationSelector } from 'containers/Components/Widgets/Selectors/JustificationSelector';
import { ColorSelector } from 'containers/Components/Widgets/Selectors/ColorSelector';
import update from 'immutability-helper';
import './DividerWidgetEditor.less';

export type ChangeCallback = (configuration: DividerWidgetConfiguration) => void;

export type DividerWidgetEditorProps = typeof DividerWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    configuration?: DividerWidgetConfiguration,
    onChange: ChangeCallback
}

export class DividerWidgetEditor extends PureComponent<DividerWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.DIVIDER,
        color: ColorType.DARKGRAY,
        justification: JustificationType.CENTER
    
    } as DividerWidgetConfiguration;

    static defaultProps = {
        configuration: DividerWidgetEditor.DefaultConfiguration
    }

    constructor(props: DividerWidgetEditorProps) {
        super(props);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleDashedChange = this.handleDashedChange.bind(this);
        this.handleJustificationChange = this.handleJustificationChange.bind(this);
    }

    private handleTitleChange(e: ChangeEvent<HTMLInputElement>): void {
        const title = e.target.value;
        const configuration = update(this.props.configuration, {
            title: { $set: title }
        });
        this.props.onChange(configuration);
    }

    private handleColorChange(color: ColorType): void {
        const configuration = update(this.props.configuration, {
            color: { $set: color }
        });
        this.props.onChange(configuration);
    }

    private handleDashedChange(dashed: boolean): void {
        const configuration = update(this.props.configuration, {
            dashed: { $set: dashed }
        });
        this.props.onChange(configuration);
    }

    private handleJustificationChange(justification: JustificationType): void {
        const configuration = update(this.props.configuration, {
            justification: { $set: justification }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === DividerWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <>
                {this.props.extra}
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Title"
                    name="title"
                    info="Text to include in the divider."
                >
                    <Input
                        value={this.props.configuration.title}
                        onChange={this.handleTitleChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Color"
                    name="color"
                    info="Color of the title."
                >
                    <ColorSelector
                        value={this.props.configuration.color}
                        onChange={this.handleColorChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Dashed Line"
                    name="dashed"
                    info="Display the divider as a dashed line."
                    valuePropName="checked"
                >
                    <Switch
                        checked={this.props.configuration.dashed}
                        defaultChecked={false}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={this.handleDashedChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Justification"
                    name="justification"
                    info="Justification of the title in the divider."
                >
                    <JustificationSelector
                        value={this.props.configuration.justification}
                        onChange={this.handleJustificationChange}
                    />
                </FormItem>
            </>
        );
    }

}
