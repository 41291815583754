import { PureComponent, ReactElement } from 'react';
import { Button, Tag } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { SpecEditor, SpecType } from './SpecsEditor/SpecEditor';
import { Parameter } from '@methodset/calculator-ts';
import { ConfigurationType, VariableSpec } from '@methodset/endpoint-client-ts';
import { ButtonLink } from 'components/ButtonLink/ButtonLink';
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { Globals } from 'constants/Globals';
import update from 'immutability-helper';
import './Specs.less';

const COLOR_MAP = CoreUtils.toColorMap(CoreUtils.enumToKeys(ConfigurationType));

export type EditCallback = (isEditing: boolean) => void;
export type ChangeCallback = (variableSpecs: VariableSpec[]) => void;

export type SpecsProps = typeof Specs.defaultProps & {
    className?: string,
    // The label to place on the button.
    buttonLabel?: string,
    // True to disallow the user from manually adding specs (system will determine).
    auto?: boolean
    // Variables to select from. If undefined, the user will be able to type in the variable.
    parameters?: Parameter[],
    // Configuration specs to exclude from the list of types.
    excludes?: ConfigurationType[],
    // The type of spec to accept.
    specType?: SpecType,
    // The existing specs to edit.
    variableSpecs?: VariableSpec[],
    // Called when editing is starting and ending.
    onEdit?: EditCallback,
    // Called when specs have been changed.
    onChange: ChangeCallback
};

export type SpecsState = {
    // Configuration spec being edited.
    variableSpec?: VariableSpec,
    // The index of the edited spec.
    index?: number
}

export class Specs extends PureComponent<SpecsProps, SpecsState> {

    static defaultProps = {
        buttonLabel: "Add Variable",
        auto: false,
        excludes: [] as ConfigurationType[],
        variableSpecs: [] as VariableSpec[]
    }

    constructor(props: SpecsProps) {
        super(props);
        this.state = {
        }
        this.handleSpecAdd = this.handleSpecAdd.bind(this);
        this.handleSpecDelete = this.handleSpecDelete.bind(this);
        this.handleSpecMoveUp = this.handleSpecMoveUp.bind(this);
        this.handleSpecMoveDown = this.handleSpecMoveDown.bind(this);
        this.handleSpecEdit = this.handleSpecEdit.bind(this);
        this.handleEditorDone = this.handleEditorDone.bind(this);
        this.handleEditorCancel = this.handleEditorCancel.bind(this);
    }

    private handleSpecAdd(): void {
        this.setState({ index: -1 });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleSpecDelete(variableSpec: VariableSpec, index: number): void {
        const variableSpecs = update(this.props.variableSpecs, {
            $splice: [[index, 1]]
        });
        this.props.onChange(variableSpecs);
    }

    private handleSpecMoveUp(variableSpec: VariableSpec, index: number): void {
        const variableSpecs = update(this.props.variableSpecs, {
            $splice: [[index, 1], [index - 1, 0, variableSpec]]
        });
        this.props.onChange(variableSpecs);
    }

    private handleSpecMoveDown(variableSpec: VariableSpec, index: number): void {
        const variableSpecs = update(this.props.variableSpecs, {
            $splice: [[index, 1], [index + 1, 0, variableSpec]]
        });
        this.props.onChange(variableSpecs);
    }

    private handleSpecEdit(variableSpec: VariableSpec, index: number): void {
        this.setState({
            variableSpec: variableSpec,
            index: index
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleEditorDone(variableSpec: VariableSpec, index: number): void {
        let variableSpecs;
        if (index !== -1) {
            variableSpecs = update(this.props.variableSpecs, {
                [index]: { $set: variableSpec }
            });
        } else {
            variableSpecs = update(this.props.variableSpecs, {
                $push: [variableSpec]
            });
        }
        this.props.onChange(variableSpecs);
        this.setState({
            variableSpec: undefined,
            index: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private handleEditorCancel(): void {
        this.setState({
            variableSpec: undefined,
            index: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'variable',
            title: 'Variable',
            width: 150,
            render: (_, spec: VariableSpec, index: number) => {
                return (
                    <ButtonLink
                        onClick={() => this.handleSpecEdit(spec, index)}
                    >
                        {spec.key}
                    </ButtonLink>
                )
            },
        }, {
            key: 'name',
            title: 'Name',
            width: 200,
            render: (spec: VariableSpec) => {
                return (
                    <span>{spec.name}</span>
                );
            },
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            render: (spec: VariableSpec) => {
                return (
                    <span>{spec.description ? spec.description : Globals.EMPTY_FIELD}</span>
                );
            },
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: 150,
            render: (spec: VariableSpec) => {
                return (
                    <Tag color={COLOR_MAP[spec.type]}>
                        {spec.type.replace(/_/g, " ")}
                    </Tag>
                );
            },
        }];
        return columns;
    }

    private buildData(): any {
        return this.props.variableSpecs;
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: `Edit variable`,
            callback: this.handleSpecEdit
        }, {
            icon: <DeleteOutlined />,
            label: `Delete variable`,
            confirm: `Are you sure you want to delete the variable?`,
            callback: this.handleSpecDelete
        }, {
            icon: <UpOutlined />,
            label: "Move up",
            disabled: (variableSpec: VariableSpec, index: number) => {
                return index === 0;
            },
            callback: this.handleSpecMoveUp
        }, {
            icon: <DownOutlined />,
            label: "Move down",
            disabled: (variableSpec: VariableSpec, index: number) => {
                return index === this.props.variableSpecs.length - 1;
            },
            callback: this.handleSpecMoveDown
        }];
        return (
            <div className="x-specs">
                {CoreUtils.isEmpty(this.state.index) &&
                    <div>
                        <ItemTable
                            className="x-specs"
                            rowKey="key"
                            size="small"
                            pagination={false}
                            columns={this.buildColumns()}
                            items={this.buildData()}
                            showHeader={false}
                            actions={actions}
                        />
                        {!this.props.auto &&
                            <div className="x-specs-add">
                                <Button onClick={this.handleSpecAdd}>
                                    {this.props.buttonLabel}
                                </Button>
                            </div>
                        }
                    </div>
                }
                {!CoreUtils.isEmpty(this.state.index) &&
                    <SpecEditor
                        specType={this.props.specType}
                        parameters={this.props.parameters}
                        spec={this.state.variableSpec}
                        specs={this.props.variableSpecs}
                        index={this.state.index!}
                        excludes={this.props.excludes}
                        onDone={this.handleEditorDone}
                        onCancel={this.handleEditorCancel}
                    />
                }
            </div>
        );
    }

}
