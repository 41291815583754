import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CodeOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, message } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteBuilder } from 'utils/RouteBuilder';
import authService from 'services/AuthService';
import update from 'immutability-helper';
import './ResetPasswordConfirm.less';

interface FormData {
    confirmationCode?: string,
    newPassword?: string,
    confirmPassword?: string
}

type MatchParams = {
    userId: string
}

export type ResetPasswordConfirmProps = RouteComponentProps<MatchParams> & {}

export type ResetPasswordConfirmState = {
    errorMessage?: string,
    isSubmitting: boolean,
    formData: FormData
}

export class ResetPasswordConfirm extends PureComponent<ResetPasswordConfirmProps, ResetPasswordConfirmState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: ResetPasswordConfirmProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isSubmitting: false,
            formData: {}
        };
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleCodeChange(e: ChangeEvent<HTMLInputElement>): void {
        const confirmationCode = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                confirmationCode: { $set: confirmationCode }
            })
        });
    }

    private handlePasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        const newPassword = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                newPassword: { $set: newPassword }
            })
        });
    }

    private handleConfirmPasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        const confirmPassword = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                confirmPassword: { $set: confirmPassword }
            })
        });
    }

    private handleFormFinish(): void {
        const { params } = this.props.match;
        this.confirmPasswordRequest(params.userId);
    }

    private confirmPasswordRequest(userId: string): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: true
        });
        const confirmationCode = this.state.formData.confirmationCode!;
        const newPassword = this.state.formData.newPassword!;
        authService.confirmPassword(userId, confirmationCode, newPassword,
            (response: any) => this.confirmPasswordResponse(response),
            (response: any) => this.confirmPasswordException(response)
        );
    }

    private confirmPasswordResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isSubmitting: false
        });
        message.success('Your password has been reset.');
        this.props.history.go(-2);
    }

    private confirmPasswordException(response: any): void {
        this.setState({
            errorMessage: response.message,
            isSubmitting: false
        });
    }

    public render(): ReactElement {
        return (
            <FormFrame
                ref={this.formRef}
                title="Reset Password"
                description="Please enter your confirmation code below and select a new password."
                error={this.state.errorMessage}
                hideFooter={true}
                onOk={this.handleFormFinish}
            >
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Code"
                    name="code"
                    rules={[{
                        required: true,
                        message: 'Please enter the confirmation code.'
                    }]}
                >
                    <Input
                        id="code"
                        prefix={<CodeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Confirmation code."
                        value={this.state.formData.confirmationCode}
                        onChange={this.handleCodeChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Password"
                    name="new-password"
                    rules={[{
                        required: true,
                        message: 'Please enter a password.'
                    }, {
                        validator: (rule: any, value: string) => {
                            const message = authService.validatePassword(this.state.formData.newPassword);
                            return message ? Promise.reject(message) : Promise.resolve();
                        }
                    }]}
                >
                    <Input.Password
                        id="new-password"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Password."
                        value={this.state.formData.newPassword}
                        onChange={this.handlePasswordChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Confirm"
                    name="confirm"
                    rules={[{
                        required: true,
                        message: 'Please enter the password again.'
                    },
                    ({ getFieldValue }: { getFieldValue: Function }) => ({
                        validator(rule: any, value: string) {
                            if (value && value !== getFieldValue('new-password')) {
                                return Promise.reject('The confirm password does not match.');
                            } else {
                                return Promise.resolve();
                            }
                        }
                    })
                    ]}
                >
                    <Input.Password
                        id="confirm"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Confirm password."
                        //onBlur={this.handleConfirmPasswordBlur}
                        value={this.state.formData.confirmPassword}
                        onChange={this.handleConfirmPasswordChange}
                    />
                </FormItem>
                <Button
                    className="x-resetpasswordconfirm-submit"
                    type="primary"
                    htmlType="submit"
                    loading={this.state.isSubmitting}
                >
                    Submit
                </Button>
                <span className="x-resetpasswordconfirm-question">Didn't get your confirmation code?</span>
                <Link to={RouteBuilder.user(this.props, RouteBuilder.USER_RESET_PASSWORD)}>Send again.</Link>
            </FormFrame>
        );
    }

}
