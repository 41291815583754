import { ReactElement } from 'react';
import { Select } from 'antd';
import { Calculator } from '@methodset/calculator-ts';

export type ChangeCallback = (variable: string) => void;

export type VariableSelectorProps = {
    calculator: Calculator,
    variable?: string,
    onChange: ChangeCallback
};

export const VariableSelector = (props: VariableSelectorProps): ReactElement => {
    const parameters = props.calculator.parameters;
    return (
        <Select
            allowClear={true}
            placeholder="Select a variable."
            value={props.variable}
            onChange={(variable) => props.onChange(variable)}
        >
            {parameters.map(parameter => (
                <Select.Option key={parameter.variable} value={parameter.variable}>{parameter.name}</Select.Option>
            ))}
        </Select>
    )
}
