import { ReactElement, useContext } from 'react';
import { Select } from 'antd';
import { ModelContext } from 'context/ModelContext';

export type ChangeCallback = (appletId: string) => void;

export type AppletSelectorProps = {
    className?: string,
    appletId?: string,
    onChange: ChangeCallback
};

export const AppletSelector = (props: AppletSelectorProps): ReactElement => {

    const context = useContext(ModelContext);

    return (
        <Select
            className={props.className}
            allowClear={true}
            placeholder="Select an applet."
            value={props.appletId}
            onChange={(appletId) => props.onChange(appletId)}
        >
            {context.model?.applets.map(applet => (
                <Select.Option key={applet.id} value={applet.id}>{applet.name}</Select.Option>
            ))}
        </Select>
    )
}
