import { ReactElement, useContext } from 'react';
import { Select } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { ModelContext } from 'context/ModelContext';

export type ChangeCallback = (variable: string) => void;

export type VariableSpecSelectorProps = {
    calculator: Calculator,
    variable?: string,
    onChange: ChangeCallback
};

export const VariableSpecSelector = (props: VariableSpecSelectorProps): ReactElement => {
    
    const context = useContext(ModelContext);

    const parameters = props.calculator.parameters;
    return (
        <Select
            allowClear={true}
            placeholder="Select an exported variable."
            value={props.variable}
            onChange={(variable) => props.onChange(variable)}
        >
            {context.model?.variableSpecs.map(spec => (
                <Select.Option key={spec.key} value={spec.key}>{spec.name}</Select.Option>
            ))}
        </Select>
    )
}
