import React, { ChangeEvent, KeyboardEvent, PureComponent, ReactElement } from 'react';
import { Input } from 'antd';
import { FunctionOutlined } from '@ant-design/icons';
import { KeyUtils } from 'utils/KeyUtils';
import { ModelContext } from 'context/ModelContext';
import { EmitterEvent } from 'utils/EventEmitter';
import { CoreUtils } from 'utils/CoreUtils';
import './FormulaEditor.less';

export type MountCallback = (instance: FormulaEditor) => void;

export type FormulaEditorProps = typeof FormulaEditor.defaultProps & {
    // Functions to ignore (do not allow editing).
    excludes?: string[]
}

export type FormulaEditorState = {
    value?: string,
    isDisabled: boolean
}

export class FormulaEditor extends PureComponent<FormulaEditorProps, FormulaEditorState> {

    static contextType = ModelContext;

    private inputRef = React.createRef<any>();
    private hasShift = false;
    private hasCtrl = false;

    static defaultProps = {
        excludes: [] as string[]
    }

    constructor(props: FormulaEditorProps) {
        super(props);
        this.state = {
            isDisabled: false
        };
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChangeEvent = this.handleInputChangeEvent.bind(this);
    }

    private handleKeyUp(e: KeyboardEvent<HTMLTextAreaElement>): void {
        //console.log(`up: ${e.code}`);
        const code = e.code;
        if (KeyUtils.isShiftCode(code)) {
            this.hasShift = false;
        } else if (KeyUtils.isCtrlCode(code)) {
            this.hasCtrl = false;
        }
    }

    private handleKeyDown(e: KeyboardEvent<HTMLTextAreaElement>): void {
        //console.log(`down: ${e.code}`);
        const code = e.code;
        if (KeyUtils.isShiftCode(code)) {
            this.hasShift = true;
        } else if (KeyUtils.isCtrlCode(code)) {
            this.hasCtrl = true;
        } else if (KeyUtils.isExitCode(code)) {
            // if (code === KeyCode.ENTER && this.hasShift) {
            //     return;
            // }
            const data = {
                key: e.key,
                code: code,
                value: this.state.value
            }
            e.preventDefault();
            this.context.sendEvent("FormulaEditFinish", data);
        }
    }

    private handleInputChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const value = e.target.value;
        this.setState({ value: value });
        const data = {
            value: value
        }
        this.context.sendEvent("FormulaEditChange", data);
    }

    private handleInputChangeEvent(event: EmitterEvent): void {
        const value = event.data.value;
        let isDisabled = false;
        for (let ignore of this.props.excludes) {
            const fn = `=${ignore}`;
            if (CoreUtils.isString(value) && value.startsWith(fn)) {
                isDisabled = true;
                break;
            }
        }
        this.setState({
            value: value,
            isDisabled: isDisabled
        });
    }

    public componentDidMount(): void {
        this.context.addCallback("CellEditChange", this.handleInputChangeEvent);
    }

    public componentWillUnmount(): void {
        this.context.removeCallback("CellEditChange", this.handleInputChangeEvent);
    }

    public render(): ReactElement {
        return (
            <div className="x-formulaeditor">
                <FunctionOutlined
                    className="x-formulaeditor-img"
                />
                <Input.TextArea
                    className="x-formulaeditor-input"
                    ref={this.inputRef}
                    rows={1}
                    disabled={this.state.isDisabled}
                    value={this.state.value}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    onKeyUp={this.handleKeyUp}
                />
            </div>
        );

    }
}
