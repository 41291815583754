import React, { Component, ReactElement } from 'react';
import { Col, FormInstance, Row } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { ChartDataset, PolarWidgetConfiguration, WidgetType } from '@methodset/model-client-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import update from 'immutability-helper';
import './PolarEditor.less';

export type ChangeCallback = (configuration: PolarWidgetConfiguration) => void;

export type PolarEditorProps = typeof PolarEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    type?: WidgetType,
    extra: ReactElement,
    configuration?: PolarWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class PolarEditor extends Component<PolarEditorProps> {

    static DefaultConfiguration = {
        type: 'PIE_CHART',  // default
        height: 300,
        dataset: {} as ChartDataset
    } as PolarWidgetConfiguration;

    static defaultProps = {
        configuration: PolarEditor.DefaultConfiguration
    }

    constructor(props: PolarEditorProps) {
        super(props);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    private handleLabelChange(labelRangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                labelRangeId: { $set: labelRangeId! }
            }
        });
        this.props.onChange(configuration);
    }

    private handleValueChange(valueRangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                valueRangeId: { $set: valueRangeId! }
            }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (!this.props.type) {
            this.props.onChange(this.props.configuration);
        }
    }

    public shouldComponentUpdate(nextProps: Readonly<PolarEditorProps>): boolean {
        if (nextProps.type && nextProps.type !== this.props.type) {
            const configuration = update(nextProps.configuration, {
                type: { $set: nextProps.type }
            });
            this.props.onChange(configuration);
            return false;
        }
        return true;
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.extra}
                </Col>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        //noError={true}
                        label="Labels Range"
                        name="labels"
                        info="The range for the labels. Must be vector, i.e., a column or row."
                    >
                        <RefEditor
                            formRef={this.props.formRef}
                            index={0}
                            vector={true}
                            //required={true}
                            calculator={this.props.calculator}
                            refTypes={[RefType.RANGE]}
                            refId={this.props.configuration.dataset.labelRangeId}
                            onChange={this.handleLabelChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        //noError={true}
                        label="Values Range"
                        name="values"
                        info="The range for the values. Must be vector, i.e., a column or row."
                    >
                        <RefEditor
                            formRef={this.props.formRef}
                            index={1}
                            vector={true}
                            //required={true}
                            calculator={this.props.calculator}
                            refTypes={[RefType.RANGE]}
                            refId={this.props.configuration.dataset.valueRangeId}
                            onChange={this.handleValueChange}
                        />
                    </FormItem>
                </Col>
            </Row>
        );
    }

}
