import { ReactElement, useState } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Select } from 'antd';
import { CoreUtils } from 'utils/CoreUtils';
import moment from 'moment';
import './DateSelector.less';

export type ChangeCallback = (value: moment.Moment | undefined) => void;

export type DateSelectorProps = {
    value?: moment.Moment,
    onChange: ChangeCallback
};

export const DateSelector = (props: DateSelectorProps): ReactElement => {

    const parseDate = (date: moment.Moment | undefined): (number|undefined)[] => {
        if (!date) {
            //let year = moment().year();
            //return [year, 0, 1];
            return [undefined, undefined, undefined];
        } else {
            return [date.year(), date.month(), date.day()];
        }
    }

    const date = parseDate(props.value);

    const [year, setYear] = useState<number|undefined>(date[0]);
    const [month, setMonth] = useState<number|undefined>(date[1]);
    const [day, setDay] = useState<number|undefined>(date[2]);

    const buildDays = (): number[] => {
        const days: number[] = [];
        if (!CoreUtils.isNumber(month)) {
            return days;
        }
        const dim = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        const count = dim[month] + 1;
        for (let i = 1; i < count; i++) {
            days.push(i)
        }
        return days;
    }

    const buildYears = (): number[] => {
        let year = moment().year();
        const years = [];
        for (let i = 100; i >= 0; i--) {
            years.push(year--);
        }
        return years;
    }

    const handleYearChange = (year: number): void => {
        setYear(year);
        sendUpdate(year, month, day);
    }

    const handleMonthChange = (month: number): void => {
        setMonth(month);
        setDay(undefined);
        sendUpdate(year, month, day);
    }

    const handleDayChange = (day: number): void => {
        setDay(day);
        sendUpdate(year, month, day);
    }

    const sendUpdate = (year: number | undefined, month: number | undefined, day: number | undefined): void => {
        if (CoreUtils.isNumber(year) && CoreUtils.isNumber(month) && CoreUtils.isNumber(day)) {
            const date = moment([year, month, day]);
            props.onChange(date);
        } else {
            props.onChange(undefined);
        }
    }

    return (
        <Spacer fill>
            <Select
                className="x-dateselector-month"
                placeholder="Month."
                allowClear={true}
                value={month}
                onChange={handleMonthChange}
            >
                <Select.Option key={0} value={0}>January</Select.Option>
                <Select.Option key={1} value={1}>February</Select.Option>
                <Select.Option key={2} value={2}>March</Select.Option>
                <Select.Option key={3} value={3}>April</Select.Option>
                <Select.Option key={4} value={4}>May</Select.Option>
                <Select.Option key={5} value={5}>June</Select.Option>
                <Select.Option key={6} value={6}>July</Select.Option>
                <Select.Option key={7} value={7}>August</Select.Option>
                <Select.Option key={8} value={8}>September</Select.Option>
                <Select.Option key={9} value={9}>October</Select.Option>
                <Select.Option key={10} value={10}>November</Select.Option>
                <Select.Option key={11} value={11}>December</Select.Option>
            </Select>
            <Select
                className="x-dateselector-day"
                placeholder="Day."
                allowClear={true}
                value={day}
                onChange={handleDayChange}
            >
                {buildDays().map(day => (
                    <Select.Option key={day} value={day}>{day}</Select.Option>
                ))}
            </Select>
            <Select
                className="x-dateselector-year"
                placeholder="Year."
                allowClear={true}
                value={year}
                onChange={handleYearChange}
            >
                {buildYears().map(year => (
                    <Select.Option key={year} value={year}>{year}</Select.Option>
                ))}
            </Select>
        </Spacer>
    )
}
