import React, { Component, ReactElement } from 'react';
import { FormInstance, TimePicker } from 'antd';
import { DaysOfWeekSelector } from 'components/DaysOfWeekSelector/DaysOfWeekSelector';
import { DaysOfMonthSelector } from 'components/DaysOfMonthSelector/DaysOfMonthSelector';
import { FormItem } from 'components/FormItem/FormItem';
import { MonthPeriod, MonthsSelector } from 'components/MonthsSelector/MonthsSelector';
import { TimezoneSelector } from 'components/TimezoneSelector/TimezoneSelector';
import { Constants } from 'components/Constants';
import { Cron, FrequencyType, TimePoint } from '@methodset/schedule-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import update from 'immutability-helper';
import './PointEditor.less';

export type PointPeriod = FrequencyType.YEAR | FrequencyType.BIANNUAL | FrequencyType.QUARTER | FrequencyType.MONTH | FrequencyType.DAY;
export type ChangeCallback = (cron: Cron) => void;

export type PointEditorProps = typeof PointEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    cron?: Cron,
    layout: any
    period: PointPeriod
    onChange: ChangeCallback
}

export class PointEditor extends Component<PointEditorProps> {

    static defaultProps = {
        cron: {}
    }

    constructor(props: PointEditorProps) {
        super(props);
        this.handleMonthsChange = this.handleMonthsChange.bind(this);
        this.handleDaysOfMonthChange = this.handleDaysOfMonthChange.bind(this);
        this.handleDaysOfWeekChange = this.handleDaysOfWeekChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
    }

    private defaultCron(period: PointPeriod): Cron {
        const cron = {
            frequencyType: period,
            months: [] as number[],
            daysOfMonth: [] as number[],
            daysOfWeek: [] as number[],
            timePoint: {
                time: undefined as any,
                timezone: "UTC"
            } as TimePoint
        } as Cron;
        if (period !== FrequencyType.DAY) {
            cron.daysOfMonth!.push(1);
        }
        return cron;
    }

    private handleMonthsChange(months: number[]): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            months: { $set: months }
        });
        this.props.onChange(cron);
    }

    private handleDaysOfMonthChange(daysOfMonth: number[]): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            daysOfMonth: { $set: daysOfMonth }
        });
        this.props.onChange(cron);
    }

    private handleDaysOfWeekChange(daysOfWeek: number[]): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            daysOfWeek: { $set: daysOfWeek }
        });
        this.props.onChange(cron);
    }

    private handleTimeChange(time: moment.Moment | null): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            timePoint: {
                time: { $set: CoreUtils.toStringTime(time) }
            }
        });
        this.props.onChange(cron);
    }

    private handleTimezoneChange(timezone: string): void {
        const frequencyType = this.props.period.toUpperCase();
        const cron = update(this.props.cron, {
            frequencyType: { $set: frequencyType as FrequencyType },
            timePoint: {
                timezone: { $set: timezone }
            }
        });
        this.props.onChange(cron);
    }

    private hasMonths(): boolean {
        return this.props.period === FrequencyType.YEAR ||
            this.props.period === FrequencyType.BIANNUAL ||
            this.props.period === FrequencyType.QUARTER;
    }

    private hasDaysOfMonth(): boolean {
        return this.props.period === FrequencyType.YEAR ||
            this.props.period === FrequencyType.BIANNUAL ||
            this.props.period === FrequencyType.QUARTER ||
            this.props.period === FrequencyType.MONTH;
    }

    private hasDaysOfWeek(): boolean {
        return this.props.period === FrequencyType.DAY;
    }

    private isEmptyCron(): boolean {
        return this.props.cron === PointEditor.defaultProps.cron;
    }

    public componentDidMount(): void {
        if (this.isEmptyCron()) {
            const cron = this.defaultCron(this.props.period)
            this.props.onChange(cron);
        }
    }

    public shouldComponentUpdate(nextProps: PointEditorProps): boolean {
        if (nextProps.period !== this.props.period) {
            const cron = this.defaultCron(nextProps.period)
            this.props.onChange(cron);
        }
        return true;
    }

    public render(): ReactElement {
        // If the cron came in empty, it needs to be set to its default
        // value given its period in componentDidMount(). Skip the 
        // initial render until it has been setup and the change event
        // has been send with the default cron value and re-rendered.
        if (this.isEmptyCron()) {
            return <></>;
        }
        return (
            <div className={classNames('x-pointeditor', this.props.className)}>
                {this.hasMonths() &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Months"
                        name="months"
                        info="One or more months."
                        rules={[{
                            required: true,
                            message: 'Please enter one or more months.'
                        }]}
                    >
                        <MonthsSelector
                            period={this.props.period as MonthPeriod}
                            value={this.props.cron.months}
                            onChange={this.handleMonthsChange}
                        />
                    </FormItem>
                }
                {this.hasDaysOfMonth() &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Day of Month"
                        name="daysofmonth"
                        info="The days of the month."
                        rules={[{
                            required: true,
                            message: 'Please enter a day of the month.'
                        }]}
                    >
                        <DaysOfMonthSelector
                            value={this.props.cron.daysOfMonth}
                            onChange={this.handleDaysOfMonthChange}
                        />
                    </FormItem>
                }
                {this.hasDaysOfWeek() &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Days of Week"
                        name="daysofweek"
                        info="The days of the week."
                        rules={[{
                            required: true,
                            message: 'Please enter the days of week.'
                        }]}
                    >
                        <DaysOfWeekSelector
                            value={this.props.cron.daysOfWeek}
                            onChange={this.handleDaysOfWeekChange}
                        />
                    </FormItem>
                }
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="Time"
                    name="time"
                    info="The time of day"
                    rules={[{
                        required: true,
                        message: 'Please enter a time of day.'
                    }]}
                >
                    <TimePicker
                        className="x-pointeditor-time"
                        use12Hours
                        format={Constants.TIME_DISPLAY_FORMAT}
                        value={CoreUtils.toMomentTime(this.props.cron.timePoint!.time)}
                        onChange={this.handleTimeChange}
                    />
                </FormItem>
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="Time zone"
                    name="timezone"
                    info="The time zone."
                    rules={[{
                        required: true,
                        message: 'Please enter a timezone.'
                    }]}
                >
                    <TimezoneSelector
                        value={this.props.cron.timePoint!.timezone}
                        onChange={this.handleTimezoneChange}
                    />
                </FormItem>
            </div>
        );
    }

}
