import { ReactElement } from 'react';
import { Sheet, SheetList } from '@methodset/calculator-ts';
import { Select } from 'antd';
import { FilterInput } from 'components/FilterInput/FilterInput';
import update from 'immutability-helper';
import './RangeRefEditor.less';

export type RangeData = {
    sheetId?: string,
    rangeId?: string
}
export type BlurCallback = () => void;
export type ChangeCallback = (value: RangeData, isCleared: boolean) => void;

export type RangeRefEditorProps = {
    value: RangeData,
    sheets: SheetList,
    onBlur: BlurCallback,
    onChange: ChangeCallback
};

export const RangeRefEditor = (props: RangeRefEditorProps): ReactElement => {

    const handleSheetChange = (sheetId: string | undefined): void => {
        const value = update(props.value, {
            sheetId: { $set: sheetId }
        });
        props.onChange(value, false);
    }

    const handleRangeChange = (rangeId: string | undefined): void => {
        const value = update(props.value, {
            rangeId: { $set: rangeId }
        });
        props.onChange(value, false);
    }

    const handleClear = (): void => {
        const value = update(props.value, {
            sheetId: { $set: undefined }
        });
        props.onChange(value, true);
    }

    return (
        <>
            <Select
                className="x-rangerefeditor-sheet"
                placeholder="Select a sheet."
                value={props.value.sheetId}
                allowClear={true}
                onBlur={() => props.onBlur()}
                onClear={() => handleClear()}
                onChange={(sheetId) => handleSheetChange(sheetId)}
            >
                {props.sheets.map((sheet: Sheet) => (
                    <Select.Option
                        key={sheet.id}
                        value={sheet.name}
                    >
                        {sheet.name}
                    </Select.Option>
                ))}
            </Select>
            <FilterInput
                placeholder="Enter a range."
                value={props.value.rangeId}
                tester={(value: string) => /^[0-9A-Z:?]*$/.test(value)}
                onBlur={() => props.onBlur()}
                onChange={(e) => handleRangeChange(e)}
            />
        </>
    )
}
