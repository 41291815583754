import { PureComponent, ReactElement } from 'react';
import { DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { Dashboard, DashboardHeader } from '@methodset/model-client-ts';
import { ColumnsType } from 'antd/lib/table';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import modelService from 'services/ModelService';
import './Dashboards.less';

export type DashboardsProps = RouteComponentProps & {
    className?: string
}

export type DashboardsState = {
    status: string,
    headers: DashboardHeader[],
}

export class Dashboards extends PureComponent<DashboardsProps, DashboardsState> {

    constructor(props: DashboardsProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            headers: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleDashboardCreate = this.handleDashboardCreate.bind(this);
        this.handleDashboardEdit = this.handleDashboardEdit.bind(this);
        this.handleDashboardDelete = this.handleDashboardDelete.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleDashboardCreate(): void {
        const url = RouteBuilder.dashboard("create");
        this.props.history.push(url);
    }

    private handleDashboardEdit(dashboard: Dashboard): void {
        const url = RouteBuilder.dashboard(dashboard.id);
        this.props.history.push(url);
    }

    private handleDashboardDelete(dashboard: Dashboard): void {
        this.deleteDashboardRequest(dashboard.id);
    }

    private readDashboardHeadersRequest(): Promise<any> {
        const request = {};
        return modelService.readDashboardHeaders(request,
            (response) => this.readDashboardHeadersResponse(response),
            undefined, true
        );
    }

    private readDashboardHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private deleteDashboardRequest(dashboardId: string): Promise<any> {
        const request = {
            dashboardId: dashboardId
        };
        return modelService.deleteDashboard(request,
            (response: any) => this.deleteDashboardResponse(response)
        );
    }

    private deleteDashboardResponse(response: any): void {
        const dashboardId = response.data.dashboardId;
        const index = this.state.headers.findIndex(header => header.id === dashboardId);
        const headers = update(this.state.headers, {
            $splice: [[index, 1]]
        });
        this.setState({ headers: headers });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            ellipsis: true,
            width: Globals.TABLE_NAME_WIDTH,
            render: (dashboard: Dashboard) => {
                return (
                    <Link to={RouteBuilder.dashboard(dashboard.id)}>{dashboard.name}</Link>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            render: (dashboard: Dashboard) => {
                return (
                    <span>{dashboard.description ? dashboard.description : Globals.EMPTY_FIELD}</span>
                );
            }
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            width: Globals.TABLE_DATE_WIDTH,
            render: (dashboard: Dashboard) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(dashboard.updateTime)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private buildData() {
        return this.state.headers;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readDashboardHeadersRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit dashboard",
            callback: this.handleDashboardEdit
        }, {
            icon: <DeleteOutlined />,
            label: "Delete dashboard",
            confirm: "Are you sure you want to delete the dashboard?",
            callback: this.handleDashboardDelete
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <ItemTable
                className={classNames('x-dashboards', this.props.className)}
                title="Dashboards"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={
                    <Space>
                        <Button onClick={this.handleDashboardCreate}>New Dashboard</Button>
                        <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                    </Space>
                }
                actions={actions}
                onLoad={this.handleRetryLoad}
            />
        );
    }

}

