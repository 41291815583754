import { PureComponent, ReactElement } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { User } from './User/User';
import { PageNotFound } from 'containers/Components/PageNotFound/PageNotFound';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Demos } from './Home/Demos/Demos';
import { Services } from './Home/Services/Services';
import { Waitlist } from './Home/Waitlist/Waitlist';
import { Business } from './Home/Business/Business';
import { Personal } from './Home/Personal/Personal';
import './Main.less';

export type MainProps = RouteComponentProps;

export class Main extends PureComponent<MainProps> {

    public render(): ReactElement {
        return (
            <>
                <Row>
                    <Col
                        xxl={{ span: 16, offset: 4 }}
                        xl={{ span: 20, offset: 2 }}
                        lg={{ span: 24 }}
                    >
                        <Header {...this.props} />
                        <Switch>
                            <Route exact path={RouteBuilder.HOME} component={Personal} />
                            <Route exact path={RouteBuilder.MAIN_BUSINESS} component={Business} />
                            <Route exact path={RouteBuilder.MAIN_SERVICES} component={Services} />
                            <Route exact path={RouteBuilder.MAIN_WAITLIST} component={Waitlist} />
                            <Route exact path={RouteBuilder.MAIN_DEMOS} component={Demos} />
                            <Route path={RouteBuilder.MAIN_USER} component={User} />
                            <Route component={PageNotFound} />
                        </Switch>
                    </Col>
                </Row>
                <Row>
                    <Footer />
                </Row>
            </>
        )
    }

}
