import { PureComponent, ReactElement } from 'react';
import { ApiOutlined, ApartmentOutlined, CalculatorOutlined, DashboardOutlined, LockOutlined, MonitorOutlined, NodeIndexOutlined, TeamOutlined, BankOutlined, DiffOutlined, HomeOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RouteComponentProps } from 'react-router-dom';
import './Explorer.less';

export type ExplorerProps = RouteComponentProps & {}

export class Explorer extends PureComponent<ExplorerProps> {

    constructor(props: ExplorerProps) {
        super(props);
        this.handleAuthenticationsView = this.handleAuthenticationsView.bind(this);
        this.handleAccountsView = this.handleAccountsView.bind(this);
        this.handleDashboardsView = this.handleDashboardsView.bind(this);
        this.handleQueriesView = this.handleQueriesView.bind(this);
        this.handleWorkflowsView = this.handleWorkflowsView.bind(this);
        this.handleModelsView = this.handleModelsView.bind(this);
        this.handleApisView = this.handleApisView.bind(this);
        this.handleMonitorView = this.handleMonitorView.bind(this);
        this.handleOrganizationView = this.handleOrganizationView.bind(this);
        this.handleGroupsView = this.handleGroupsView.bind(this);
        this.handleAlertsView = this.handleAlertsView.bind(this);
        this.handleAppletsView = this.handleAppletsView.bind(this);
    }

    private handleOrganizationView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_ORGANIZATION);
    }

    private handleGroupsView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_GROUPS);
    }

    private handleAuthenticationsView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_CREDENTIALS);
    }

    private handleAccountsView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_ACCOUNTS);
    }

    private handleQueriesView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_DATASETS);
    }

    private handleWorkflowsView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_WORKFLOWS);
    }

    private handleApisView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_APIS);
    }

    private handleMonitorView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_MONITOR);
    }

    private handleModelsView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_MODELS);
    }

    private handleDashboardsView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_DASHBOARDS);
    }

    private handleAlertsView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_ALERTS);
    }

    private handleAppletsView(): void {
        this.props.history.push(RouteBuilder.CONSOLE_APPLETS);
    }

    // private findPage(): string | undefined {
    //     const path = this.props.location.pathname;
    //     const parts = path.split("/");
    //     return parts.length > 2 && parts[1] === "console" ? parts[2] : undefined;
    // }

    public render(): ReactElement {
        // const page = this.findPage();
        // const keys = page ? [page] : undefined;
        return (
            <Menu 
                className="x-explorer"
                theme="dark"
                mode="inline"
                // selectedKeys={keys}
            >
                <Menu.Item
                    key="organization"
                    id="organization"
                    icon={<HomeOutlined />}
                    onClick={this.handleOrganizationView}
                >
                    <span>Organization</span>
                </Menu.Item>
                <Menu.Item
                    key="groups"
                    id="groups"
                    icon={<TeamOutlined />}
                    onClick={this.handleGroupsView}
                >
                    <span>Groups</span>
                </Menu.Item>
                <Menu.Item
                    key="authentications"
                    id="authentications"
                    icon={<LockOutlined />}
                    onClick={this.handleAuthenticationsView}
                >
                    <span>Credentials</span>
                </Menu.Item>
                <Menu.Item
                    key="providers"
                    id="providers"
                    icon={<BankOutlined />}
                    onClick={this.handleAccountsView}
                >
                    <span>Providers</span>
                </Menu.Item>
                <Menu.Item
                    key="apis"
                    id="apis"
                    icon={<ApiOutlined />}
                    onClick={this.handleApisView}
                >
                    <span>APIs</span>
                </Menu.Item>
                <Menu.Item
                    key="models"
                    id="models"
                    icon={<CalculatorOutlined />}
                    onClick={this.handleModelsView}
                >
                    <span>Models</span>
                </Menu.Item>
                <Menu.Item
                    key="datasets"
                    id="datasets"
                    icon={<NodeIndexOutlined />}
                    onClick={this.handleQueriesView}
                >
                    <span>Datasets</span>
                </Menu.Item>
                <Menu.Item
                    key="workflows"
                    id="workflows"
                    icon={<ApartmentOutlined />}
                    onClick={this.handleWorkflowsView}
                >
                    <span>Workflows</span>
                </Menu.Item>
                <Menu.Item
                    key="monitor"
                    id="monitor"
                    icon={<MonitorOutlined />}
                    onClick={this.handleMonitorView}
                >
                    <span>Monitor</span>
                </Menu.Item>
                <Menu.Item
                    key="dashboards"
                    id="dashboards"
                    icon={<DashboardOutlined />}
                    onClick={this.handleDashboardsView}
                >
                    <span>Dashboards</span>
                </Menu.Item>
                {/* <Menu.Item
                    key="applets"
                    id="applets"
                    icon={<DiffOutlined />}
                    onClick={this.handleAppletsView}
                >
                    <span>Applets</span>
                </Menu.Item> */}
            </Menu>
        );
    }

}
