import { ReferenceParser } from "@methodset/calculator-ts";
import { SwitchWidgetConfiguration, Widget } from '@methodset/model-client-ts';

export class SwitchWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as SwitchWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.variable, true));
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as SwitchWidgetConfiguration;
        configuration.variable = ReferenceParser.update(configuration.variable, target, replacement, true);
    }

}
