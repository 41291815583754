import React, { PureComponent, ReactElement } from 'react';
import { FormInstance, Select, Switch } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { DisplayType, RowLink, WatchlistWidgetConfiguration, WidgetType } from '@methodset/model-client-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import { VariableSelector } from 'containers/Components/Widgets/Selectors/VariableSelector';
import { CoreUtils } from 'utils/CoreUtils';
import update from 'immutability-helper';
import './WatchlistWidgetEditor.less';

export type ChangeCallback = (configuration: WatchlistWidgetConfiguration) => void;

export type WatchlistWidgetEditorProps = typeof WatchlistWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    extra: ReactElement,
    configuration?: WatchlistWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class WatchlistWidgetEditor extends PureComponent<WatchlistWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.WATCHLIST,
        changeType: DisplayType.DIRECTION
    } as WatchlistWidgetConfiguration;

    static defaultProps = {
        configuration: WatchlistWidgetEditor.DefaultConfiguration
    }

    constructor(props: WatchlistWidgetEditorProps) {
        super(props);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleNameIndexChange = this.handleNameIndexChange.bind(this);
        this.handleSymbolIndexChange = this.handleSymbolIndexChange.bind(this);
        this.handleValueIndexChange = this.handleValueIndexChange.bind(this);
        this.handleChangeIndexChange = this.handleChangeIndexChange.bind(this);
        this.handleChangeTypeChange = this.handleChangeTypeChange.bind(this);
        this.handleTimeIndexChange = this.handleTimeIndexChange.bind(this);
        this.handleLinkToggle = this.handleLinkToggle.bind(this);
        this.handleLinkVariableChange = this.handleLinkVariableChange.bind(this);
        this.handleLinkIndexChange = this.handleLinkIndexChange.bind(this);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            rangeId: { $set: rangeId as any }
        });
        this.props.onChange(configuration);
    }

    private handleNameIndexChange(nameIndex: number): void {
        const configuration = update(this.props.configuration, {
            nameIndex: { $set: nameIndex }
        });
        this.props.onChange(configuration);
    }

    private handleSymbolIndexChange(symbolIndex: number): void {
        const configuration = update(this.props.configuration, {
            symbolIndex: { $set: symbolIndex }
        });
        this.props.onChange(configuration);
    }

    private handleValueIndexChange(valueIndex: number): void {
        const configuration = update(this.props.configuration, {
            valueIndex: { $set: valueIndex }
        });
        this.props.onChange(configuration);
    }

    private handleChangeIndexChange(changeIndex: number): void {
        const configuration = update(this.props.configuration, {
            changeIndex: { $set: changeIndex }
        });
        this.props.onChange(configuration);
    }

    private handleChangeTypeChange(changeType: DisplayType): void {
        const configuration = update(this.props.configuration, {
            changeType: { $set: changeType }
        });
        this.props.onChange(configuration);
    }

    private handleTimeIndexChange(timeIndex: number): void {
        const configuration = update(this.props.configuration, {
            timeIndex: { $set: timeIndex }
        });
        this.props.onChange(configuration);
    }

    private handleLinkToggle(hasLink: boolean): void {
        const rowLink = hasLink ? {} as RowLink : undefined;
        const configuration = update(this.props.configuration, {
            rowLink: { $set: rowLink }
        });
        this.props.onChange(configuration);
    }

    private handleLinkVariableChange(variable: string): void {
        const configuration = update(this.props.configuration, {
            rowLink: {
                variable: { $set: variable }
            }
        });
        this.props.onChange(configuration);
    }

    private handleLinkIndexChange(index: number): void {
        const configuration = update(this.props.configuration, {
            rowLink: {
                index: { $set: index }
            }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === WatchlistWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <>
                {this.props.extra}
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    required={true}
                    label="Data Range"
                    name="range"
                    info="The range that includes the data that can be added to the watchlist."
                >
                    <RefEditor
                        formRef={this.props.formRef}
                        index={0}
                        calculator={this.props.calculator}
                        refTypes={[RefType.RANGE]}
                        refId={this.props.configuration.rangeId}
                        onChange={this.handleRangeChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Name Index"
                    name="name-index"
                    info="The column index (1-based) in the range that contains the asset name."
                    rules={[{
                        required: true,
                        message: "Please select a column index."
                    }]}
                >
                    <IntegerInput
                        fill={true}
                        natural={true}
                        placeholder="Name column index."
                        value={this.props.configuration.nameIndex}
                        onChange={this.handleNameIndexChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Symbol Index"
                    name="symbol-index"
                    info="The column index (1-based) in the range that contains the asset symbol."
                >
                    <IntegerInput
                        fill={true}
                        natural={true}
                        placeholder="Symbol column index."
                        value={this.props.configuration.symbolIndex}
                        onChange={this.handleSymbolIndexChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Value Index"
                    name="value-index"
                    info="The column index (1-based) in the range that contains the asset value."
                >
                    <IntegerInput
                        fill={true}
                        natural={true}
                        placeholder="Value column index."
                        value={this.props.configuration.valueIndex}
                        onChange={this.handleValueIndexChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Change Index"
                    name="change-index"
                    info="The column index (1-based) in the range that contains the asset change value."
                >
                    <IntegerInput
                        fill={true}
                        natural={true}
                        placeholder="Change value column index."
                        value={this.props.configuration.changeIndex}
                        onChange={this.handleChangeIndexChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Change Display Type"
                    name="display"
                    info="Sets the display type for the change value."
                >
                    <Select
                        placeholder="Select a time zone."
                        value={this.props.configuration.changeType}
                        onChange={this.handleChangeTypeChange}
                    >
                        {Object.entries(DisplayType).map(([key, value]) =>
                            <Select.Option key={key} value={key}>{CoreUtils.toCapital(value)}</Select.Option>
                        )}
                    </Select>
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Time Index"
                    name="time-index"
                    info="The column index (1-based) in the range that contains the asset change time."
                >
                    <IntegerInput
                        fill={true}
                        natural={true}
                        placeholder="Time column index."
                        value={this.props.configuration.timeIndex}
                        onChange={this.handleTimeIndexChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Add Row Link"
                    name="link"
                    info="Enable configuration of a link when a row is selected."
                    valuePropName="checked"
                >
                    <Switch
                        checked={!!this.props.configuration.rowLink}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={this.handleLinkToggle}
                    />
                </FormItem>
                {!!this.props.configuration.rowLink &&
                    <>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.props.formRef}
                            label="Link Variable"
                            name="link-variable"
                            info="The variable to change when a row is selected."
                            valuePropName="variable"
                            rules={[{
                                required: true,
                                message: "Please select a variable."
                            }]}
                        >
                            <VariableSelector
                                calculator={this.props.calculator}
                                variable={this.props.configuration.rowLink.variable}
                                onChange={this.handleLinkVariableChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.props.formRef}
                            label="Link Column Index"
                            name="link-index"
                            info="The column index (1-based) that includes the values that will be used to set the variable."
                            rules={[{
                                required: true,
                                message: "Please enter a column index."
                            }]}
                        >
                            <IntegerInput
                                fill={true}
                                natural={true}
                                placeholder="Enter an index."
                                value={this.props.configuration.rowLink.index}
                                onChange={this.handleLinkIndexChange}
                            />
                        </FormItem>
                    </>
                }
            </>
        );
    }

}
