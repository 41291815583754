import { ReactElement } from 'react';
import { Parameter, ParameterList } from '@methodset/calculator-ts';
import { Select } from 'antd';
import update from 'immutability-helper';

export type ParameterData = {
    variable?: string
}
export type BlurCallback = () => void;
export type ChangeCallback = (value: ParameterData, isCleared: boolean) => void;

export type ParameterRefEditorProps = {
    value: ParameterData,
    parameters: ParameterList,
    onBlur: BlurCallback,
    onChange: ChangeCallback
};

export const ParameterRefEditor = (props: ParameterRefEditorProps): ReactElement => {

    const handleVariableChange = (variable: string | undefined): void => {
        const value = update(props.value, {
            variable: { $set: variable }
        });
        props.onChange(value, false);
    }

    const handleClear = (): void => {
        const value = update(props.value, {
            variable: { $set: undefined }
        });
        props.onChange(value, true);
    }

    return (
        <>
            <Select
                placeholder="Select a parameter."
                value={props.value.variable}
                allowClear={true}
                onBlur={() => props.onBlur()}
                onClear={() => handleClear()}
                onChange={(sheetId) => handleVariableChange(sheetId)}
            >
                {props.parameters.map((parameter: Parameter) => (
                    <Select.Option
                        key={parameter.variable}
                        value={parameter.variable}
                    >
                        {parameter.name}
                    </Select.Option>
                ))}
            </Select>
        </>
    )
}
