import { useEffect, useRef } from 'react';
import { Pie } from '@ant-design/charts';
import { Constants } from 'components/Constants';
import { DataSeries } from '../ChartWidgetViewer';
import { PolarWidgetConfiguration } from '@methodset/model-client-ts';
import classNames from 'classnames';
import './PieChartView.less';

export type PieChartViewProps = {
    className?: string,
    series: DataSeries,
    configuration: PolarWidgetConfiguration,
    height?: number | string,
    width?: number | string
};

export const PieChartView = (props: PieChartViewProps) => {

    const animation = useRef({});

    // Turn off animation for data refreshes.
    useEffect(() => { animation.current = false }, []);

    return (
        <div className={classNames('x-piechartview', props.className)} style={{ width: props.width, height: props.height }}>
            <Pie
                animation={animation.current}
                data={props.series.points}
                colorField="x"
                angleField="y"
                autoFit={true}
                color={Constants.CHART_COLORS}
                padding={24}
                label={{
                    type: "outer",
                    content: "{percentage}"
                    //content: "{name} {percentage}"
                }}
            />
        </div>
    )
}
