import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Switch } from 'antd';
import { Person, Spouse } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { PersonEditor } from '../PersonEditor/PersonEditor';
import update from 'immutability-helper';
import './SpouseEditor.less';

export type ChangeCallback = (spouse: Spouse) => void;

export type SpouseEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    spouse?: Spouse,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    spouse: {}
}

export const SpouseEditor = (props: SpouseEditorProps): ReactElement => {

    const handlePersonChange = (person: Person): void => {
        const spouse = update(props.spouse, {
            $merge: person
        });
        props.onChange(spouse);
    }

    const handleLivedWithSpouseChange = (livedWithSpouseDuringYear: boolean): void => {
        const spouse = update(props.spouse, {
            livedWithSpouseDuringYear: { $set: livedWithSpouseDuringYear }
        });
        props.onChange(spouse);
    }

    return (
        <>
            <PersonEditor
                formRef={props.formRef}
                id="person"
                person={props.spouse}
                onChange={handlePersonChange}
            />
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Spouse Covered By Work Retirement Plan"
                name="spouse-covered"
                valuePropName="checked"
            >
                <Switch
                    checked={props.spouse.livedWithSpouseDuringYear}
                    defaultChecked={false}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(livedWithSpouseDuringYear) => handleLivedWithSpouseChange(livedWithSpouseDuringYear)}
                />
            </FormItem>
        </>
    )
}

SpouseEditor.defaultProps = defaultProps;
