import React, { PureComponent, ReactElement } from 'react';
import { FormInstance } from 'antd';
import { FileTrigger, Trigger } from '@methodset/workflow-client-ts';
import './FileEditor.less';

export type ChangeCallback = (trigger: Trigger) => void;

export type FileEditorProps = {
    formRef: React.RefObject<FormInstance>,
    trigger: FileTrigger,
    onChange: ChangeCallback
}

export class FileEditor extends PureComponent<FileEditorProps> {

    public render(): ReactElement {
        return (
            <div className="x-fileeditor">
            </div>
        );
    }

}
