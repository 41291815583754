import browserService from 'services/BrowserService';
import endpointService from 'services/EndpointService';
import entityService from 'services/EntityService';
import modelService from 'services/ModelService';
import scheduleService from 'services/ScheduleService';
import timeseriesService from 'services/TimeseriesService';
import workflowService from 'services/WorkflowService';
import { Globals } from 'constants/Globals';
import { PropertyUtils } from '@methodset/commons-shared-ts';

/**
 * Calls a set of service Lambdas to start if not already running or keep them 
 * warm if running. This will try to minimize the effects of cold-starts on the 
 * user interface. Services are pinged as long as the user is active.
 */
class ServiceWarmer {

    // Maximum number is instance to try to start.
    private static readonly INSTANCE_COUNT = 1;
    // The interval between warning requests in milliseconds (2 minutes).
    private static readonly WARM_INTERVAL = 2 * 60 * 1000;
    //private static readonly WARM_INTERVAL = 30 * 1000;

    private timestamp: number;

    public constructor() {
        this.keyHandler = this.keyHandler.bind(this);
        this.mouseHandler = this.mouseHandler.bind(this);
        document.addEventListener("keydown", this.keyHandler);
        document.addEventListener("mousemove", this.mouseHandler);
        this.timestamp = 0;
    }

    public start(): void {
        this.checkInterval();
    }

    public stop() {
        document.removeEventListener("keydown", this.keyHandler);
        document.removeEventListener("mousemove", this.mouseHandler);
    }

    private keyHandler(event: KeyboardEvent): void {
        this.checkInterval();
    }

    private mouseHandler(event: MouseEvent): void {
        this.checkInterval();
    }

    private checkInterval(): void {
        const now = Date.now();
        // Ping services every interval while user is active.
        if (now - this.timestamp > ServiceWarmer.WARM_INTERVAL) {
            this.warmServices();
            this.timestamp = now;
        }
    }

    private warmServices(): void {
        //console.log("Warming services...");
        this.warm(
            Globals.SERVICE_BROWSER,
            Globals.SERVICE_ENDPOINT,
            Globals.SERVICE_ENTITY,
            Globals.SERVICE_MODEL,
            Globals.SERVICE_SCHEDULE,
            Globals.SERVICE_TIMESERIES,
            Globals.SERVICE_WORKFLOW
        );
    }

    public warm(...services: string[]): void {
        if (PropertyUtils.isLocal()) {
            // Local services during testing do not run as Lambdas.
            return;
        }
        for (let i = 0; i < services.length; i++) {
            const service = services[i];
            for (let j = 0; j < ServiceWarmer.INSTANCE_COUNT; j++) {
                switch (service) {
                    case Globals.SERVICE_BROWSER:
                        browserService.warm();
                        break;
                    case Globals.SERVICE_ENDPOINT:
                        endpointService.warm();
                        break;
                    case Globals.SERVICE_ENTITY:
                        entityService.warm();
                        break;
                    case Globals.SERVICE_MODEL:
                        modelService.warm();
                        break;
                    case Globals.SERVICE_SCHEDULE:
                        scheduleService.warm();
                        break;
                    case Globals.SERVICE_TIMESERIES:
                        timeseriesService.warm();
                        break;
                    case Globals.SERVICE_WORKFLOW:
                        workflowService.warm();
                        break;
                    default:
                        console.log(`Invalid service name '${service}' to warm.`);
                        break;
                }
            }
        }
    }

}

export default new ServiceWarmer();
