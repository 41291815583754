import { Component, ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import { Justify } from 'components/Justify/Justify';
import { Model } from '@methodset/model-client-ts';
import modelService from 'services/ModelService';
import axios from 'axios';
import './ModelLoader.less';

export type LoadedCallback = (model: Model) => void;

export type ModelLoaderProps = {
    modelId: string,
    modelVersion: number | undefined
    onLoaded: LoadedCallback
}

export type ModelLoaderState = {
    // Status of loading data.
    status: string,
    // True if loading data.
    loading: boolean
}

export class ModelLoader extends Component<ModelLoaderProps, ModelLoaderState> {

    constructor(props: ModelLoaderProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            loading: false,
        }
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData(this.props.modelId, this.props.modelVersion);
    }

    private readModelRequest(id: string, version?: number): Promise<any> {
        const request = {
            modelId: id,
            modelVersion: version
        };
        return modelService.readModel(request,
            (response: any) => this.readModelResponse(response),
            undefined, true
        );
    }

    private readModelResponse(response: any): void {
        const model = response.data.model;
        this.props.onLoaded(model);
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-modelloader-loading"
                status={isLoading ? "loading" : "failed"}
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private loadData(id: string, version?: number) {
        const requests = [];
        requests.push(this.readModelRequest(id, version));
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount() {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData(this.props.modelId, this.props.modelVersion);
        }
    }

    public shouldComponentUpdate(nextProps: ModelLoaderProps): boolean {
        if (nextProps.modelId !== this.props.modelId || nextProps.modelVersion !== this.props.modelVersion) {
            this.loadData(nextProps.modelId, nextProps.modelVersion);
            return false;
        } else {
            return true;
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = null;
        }
        return (
            <Justify justification="center">
                {view}
            </Justify>
        );
    }

}
