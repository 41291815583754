import { PureComponent, ReactElement } from 'react';
import { Label } from 'components/Label/Label';
import { CellChangeEvent, Format, Parameter } from '@methodset/calculator-ts';
import { DeleteOutlined, EditOutlined, FormatPainterOutlined } from '@ant-design/icons';
import { ParameterCell } from './ParameterCell/ParameterCell';
import { ParameterData, ParameterProperties } from '../ParameterProperties/ParameterProperties';
import { ModelContext } from 'context/ModelContext';
import { MenuButton } from 'components/MenuButton/MenuButton';
import { Spacer } from 'components/Spacer/Spacer';
import { FormatMenu } from '../../Sheets/SheetItem/FormatMenu/FormatMenu';
import './ParameterItem.less';

export type UpdateCallback = (parameter: Parameter, update: ParameterData, index: number) => void;
export type DeleteCallback = (parameter: Parameter, index: number) => void;

export type ParameterItemState = {
    isEditing: boolean
}

export type ParameterItemProps = {
    className?: string,
    index: number,
    parameter: Parameter,
    onDelete: DeleteCallback
};

export class ParameterItem extends PureComponent<ParameterItemProps, ParameterItemState> {

    static contextType = ModelContext;

    private parameter: Parameter;

    constructor(props: ParameterItemProps) {
        super(props);
        this.state = {
            isEditing: false
        };
        this.parameter = this.props.parameter;
        this.handleParameterChange = this.handleParameterChange.bind(this);
        this.handleParameterRemove = this.handleParameterRemove.bind(this);
        this.handleParameterEdit = this.handleParameterEdit.bind(this);
        this.handleParameterFormat = this.handleParameterFormat.bind(this);
        this.handlePropertiesChange = this.handlePropertiesChange.bind(this);
        this.handlePropertiesCancel = this.handlePropertiesCancel.bind(this);
    }

    private handleParameterChange(event: CellChangeEvent): void {
        const param = event.cell as Parameter;
        const prev = event.prev as Parameter;
        const activeCell = this.context.active.cell;
        if (activeCell) {
            if (param.variable !== prev.variable && activeCell.variable === prev.variable) {
                // Update the variable name for all listeners.
                this.context.setFocusedCell(param.sheet, param.id, param.variable);
            }
        }
        this.forceUpdate();
    }

    private handleParameterRemove(): void {
        this.props.onDelete(this.parameter, this.props.index);
    }

    private handleParameterEdit(): void {
        this.setState({
            isEditing: true
        });
    }

    private handleParameterFormat(format: Format | undefined): void {
        this.parameter.format = format;
    }

    private handlePropertiesChange(data: ParameterData): void {
        this.parameter.name = data.name!;
        this.parameter.description = data.description;
        this.parameter.variable = data.variable!;
        this.setState({ isEditing: false });
    }

    private handlePropertiesCancel(): void {
        this.setState({ isEditing: false });
    }

    public render(): ReactElement {
        const items = [{
            icon: <EditOutlined />,
            label: "Edit",
            onSelect: this.handleParameterEdit
        }, {
            icon: <FormatPainterOutlined />,
            label: "Format",
            menu: (
                <FormatMenu
                    format={this.parameter.format}
                    onChange={this.handleParameterFormat}
                />
            )
        }, {
            icon: <DeleteOutlined />,
            label: "Remove",
            onSelect: this.handleParameterRemove
        }];
        return (
            <div className="x-parameteritem">
                <Label
                    placement="top"
                    label={this.parameter.name}
                    info={this.parameter.description}
                    extra={`(${this.parameter.variable})`}
                >
                    <Spacer fill>
                        <ParameterCell
                            className="x-parameteritem-cell"
                            cell={this.parameter}
                            onChange={this.handleParameterChange}
                        />
                        <MenuButton items={items} />
                    </Spacer>
                </Label>
                {this.state.isEditing &&
                    <ParameterProperties
                        data={{
                            variable: this.parameter.variable,
                            name: this.parameter.name,
                            description: this.parameter.description
                        }}
                        onChange={this.handlePropertiesChange}
                        onCancel={this.handlePropertiesCancel}
                    />
                }
            </div>
        );
    }
}
