import React, { PureComponent, ReactElement } from 'react';
import { Button, FormInstance } from 'antd';
import { WhitelistEditor } from './WhitelistEditor/WhitelistEditor';
import { EmailTrigger, Trigger } from '@methodset/workflow-client-ts';
import update from 'immutability-helper';
import './EmailEditor.less';

export type ChangeCallback = (trigger: Trigger) => void;

export type EmailEditorProps = typeof EmailEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    trigger: EmailTrigger,
    onChange: ChangeCallback
}

export class EmailEditor extends PureComponent<EmailEditorProps> {

    static defaultProps = {
    }

    constructor(props: EmailEditorProps) {
        super(props);
        this.handleAddressAdd = this.handleAddressAdd.bind(this);
        this.handleAddressRemove = this.handleAddressRemove.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
    }

    private handleAddressAdd(): void {
        const trigger = update(this.props.trigger, {
            whitelist: {
                $push: [null as any]
            }
        });
        this.props.onChange(trigger);
    }

    private handleAddressRemove(index: number): void {
        const trigger = update(this.props.trigger, {
            whitelist: {
                $splice: [[index, 1]]
            }
        });
        this.props.onChange(trigger);
    }

    private handleAddressChange(address: string, index: number): void {
        const trigger = update(this.props.trigger, {
            whitelist: {
                [index]: { $set: address }
            }
        });
        this.props.onChange(trigger);
    }

    public render(): ReactElement {
        return (
            <div className="x-emailteditor">
                <WhitelistEditor
                    formRef={this.props.formRef}
                    addresses={this.props.trigger.whitelist}
                    onChange={this.handleAddressChange}
                    onRemove={this.handleAddressRemove}
                />
                <div className="x-emaileditor-add">
                    <Button onClick={this.handleAddressAdd}>Add Whitelisted Email</Button>
                </div>
            </div>
        );
    }

}
