import React, { ChangeEvent, PureComponent } from 'react';
import { Form, FormInstance, Input, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ModelContext } from 'context/ModelContext';
import { Parameter } from '@methodset/calculator-ts';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import update from 'immutability-helper';
import './ParameterProperties.less';

export type ChangeCallback = (data: ParameterData) => void;
export type CancelCallback = () => void;

// Combination of parameter and input spec.
export type ParameterData = {
    variable?: string,
    name?: string,
    description?: string
}

export type ParameterPropertiesState = {
    data: ParameterData
}

export type ParameterPropertiesProps = {
    className?: string,
    data: ParameterData,
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export class ParameterProperties extends PureComponent<ParameterPropertiesProps, ParameterPropertiesState> {

    static contextType = ModelContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: ParameterPropertiesProps) {
        super(props);
        this.state = {
            data: props.data
        }
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    handleVariableChange(e: ChangeEvent<HTMLInputElement>): void {
        let variable = e.target.value;
        if (!CoreUtils.isVariableName(variable)) {
            return;
        }
        let data = update(this.state.data, {
            variable: { $set: variable }
        });
        this.setState({ data: data });
    }

    handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const data = update(this.state.data, {
            name: { $set: name }
        });
        this.setState({ data: data });
    }

    handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const data = update(this.state.data, {
            description: { $set: description }
        });
        this.setState({ data: data });
    }

    handleOk(): void {
        this.formRef.current?.submit();
    }

    handleCancel(): void {
        this.props.onCancel();
    }

    handleFormFinish() {
        this.props.onChange(this.state.data);
    }

    render() {
        return (
            <Modal
                className={classNames('x-parameterproperties', this.props.className)}
                width={Globals.DIALOG_WIDTH}
                title="Variable Editor"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                visible={true}>
                <Form ref={this.formRef} onFinish={this.handleFormFinish}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        name="variable"
                        label="Variable"
                        info="Variable to use in formulas. Must start with a letter and only contain letters, numbers, or underscores."
                        rules={[{
                            required: true,
                            message: 'Please enter a variable name.'
                        }, {
                            validator: (rule: any, value: string) => {
                                if (!value) {
                                    // Required rule will be applied.
                                    return Promise.resolve();
                                }
                                if (this.props.data.variable === value) {
                                    // Variable has not changed and is already validated.
                                    return Promise.resolve();
                                }
                                try {
                                    Parameter.validateVariable(value, this.context.calculator);
                                } catch (e: any) {
                                    if (e instanceof Error) {
                                        return Promise.reject(e.message);
                                    } else {
                                        return Promise.reject("Invalid variable name.");
                                    }
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        <Input
                            id="variable"
                            placeholder="Variable"
                            value={this.state.data.variable}
                            onChange={this.handleVariableChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        name="name"
                        label="Name"
                        info="The friendly name of the parameter to help identify its meaning."
                        rules={[{
                            required: true,
                            message: 'Please enter a name.'
                        }]}
                    >
                        <Input
                            id="name"
                            placeholder="Name"
                            value={this.state.data.name}
                            onChange={this.handleNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        name="description"
                        label="Description"
                        info="The description of the parameter."
                    >
                        <Input.TextArea
                            id="description"
                            placeholder="Description"
                            rows={3}
                            value={this.state.data.description}
                            onChange={this.handleDescriptionChange}
                        />
                    </FormItem>
                </Form>
            </Modal>
        );
    }

}
