import { PureComponent, ReactElement } from 'react';
import { Button, Col, PageHeader, Row } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { AppletInfo, AppletRef } from '@methodset/model-client-ts';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { Spacer } from 'components/Spacer/Spacer';
import { AppletItem } from './AppletItem/AppletItem';
import modelService from 'services/ModelService';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import './AppletInventory.less';

export type InstallCallback = (appletRefs: AppletRef[]) => void;
export type CancelCallback = () => void;

export type AppletStoreProps = {
    className?: string,
    addError?: Error,
    onInstall: InstallCallback,
    onCancel: CancelCallback
}

export type AppletInventoryState = {
    status: string,
    // All available applets.
    applets: AppletInfo[],
    // Selected applets.
    appletRefs: AppletRef[]
}

export class AppletInventory extends PureComponent<AppletStoreProps, AppletInventoryState> {

    constructor(props: AppletStoreProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            applets: [],
            appletRefs: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleAppletClick = this.handleAppletClick.bind(this);
        this.handleInstallClick = this.handleInstallClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleAppletClick(appletInfo: AppletInfo, selected: boolean): void {
        let appletRefs;
        if (selected) {
            const appletRef = {
                modelId: appletInfo.modelId,
                version: appletInfo.version,
                appletId: appletInfo.id
            }
            appletRefs = update(this.state.appletRefs, {
                $push: [appletRef]
            });
            this.setState({ appletRefs: appletRefs });
        } else {
            const index = this.state.appletRefs.findIndex(ref =>
                ref.appletId === appletInfo.id &&
                ref.modelId === appletInfo.modelId &&
                ref.version === appletInfo.version
            );
            if (index !== -1) {
                appletRefs = update(this.state.appletRefs, {
                    $splice: [[index, 1]]
                });
                this.setState({ appletRefs: appletRefs });
            }
        }
    }

    private handleInstallClick(): void {
        this.props.onInstall(this.state.appletRefs);
    }

    private handleCancelClick(): void {
        this.props.onCancel();
    }

    private readAppletInfosRequest(): Promise<any> {
        const request = {};
        return modelService.readAppletInfos(request,
            (response: any) => this.readAppletInfosResponse(response),
            undefined, true
        );
    }

    private readAppletInfosResponse(response: any): void {
        const infos = response.data.infos;
        this.setState({ applets: infos });
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readAppletInfosRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <Row
                gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}
            >
                {[1, 2, 3, 4, 5, 6].map(index => (
                    <Col
                        key={index}
                        sm={{ span: 24 }}
                        lg={{ span: 12 }}
                        xxl={{ span: 8 }}
                    >
                        <div className={classNames("x-appletinventory-item")}>
                            <LoadSkeleton
                                //count={1}
                                direction="vertical"
                                status={isLoading ? "loading" : "failed"}
                                onRetry={this.handleRetryLoad}
                            >
                                <LoadSkeleton.Input length="medium" />
                                <LoadSkeleton.Input length="fill" />
                                <LoadSkeleton.Input length="short" />
                                <LoadSkeleton.Input length="short" />
                            </LoadSkeleton>
                        </div>
                    </Col>
                ))}
            </Row>
        );
    }

    private buildAppletsView(): ReactElement {
        return (
            <Row gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}>
                {this.state.applets.map((applet, index) => (
                    <Col
                        key={applet.id}
                        sm={{ span: 24 }}
                        lg={{ span: 12 }}
                        xxl={{ span: 8 }}
                    >
                        <AppletItem
                            index={index}
                            appletInfo={applet}
                            onClick={this.handleAppletClick}
                        />
                    </Col>
                ))}
            </Row>
        )
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildAppletsView();
        }
        //view = this.buildLoadingView(true);
        return (
            <div className="x-appletinventory">
                <PageHeader
                    className="x-appletinventory-header"
                    title="Applets"
                    extra={(
                        <Spacer alignment="middle">
                            {this.props.addError &&
                                <span className="x-appletinventory-error">{this.props.addError.message}</span>
                            }
                            {!this.props.addError &&
                                <span className="x-appletinventory-selected">{`${this.state.appletRefs.length} selected`}</span>
                            }
                            <Button
                                onClick={this.handleCancelClick}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                disabled={this.state.appletRefs.length === 0}
                                onClick={this.handleInstallClick}
                            >
                                Install
                            </Button>
                        </Spacer>
                    )}
                />
                <div className="x-appletinventory-body">
                    {view}
                </div>
            </div>
        );
    }

}
