import { PureComponent, ReactElement } from 'react';
import { Select } from 'antd';
import { Globals } from 'constants/Globals';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import classNames from 'classnames';
import axios from 'axios';
import modelService from 'services/ModelService';
import './ModelVersionPicker.less';
import { CoreUtils } from 'utils/CoreUtils';

export type ChangeCallback = (version: number) => void;

export type ModelVersionPickerState = {
    status: string,
    versions: number[]
}

export type ModelVersionPickerProps = {
    className?: string,
    modelId?: string,
    version?: number,
    onChange: ChangeCallback
}

export class ModelVersionPicker extends PureComponent<ModelVersionPickerProps, ModelVersionPickerState> {

    constructor(props: ModelVersionPickerProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            versions: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleVersionChange(version: number): void {
        this.props.onChange(version);
    }

    private readModelVersionsRequest(): Promise<any> {
        if (!this.props.modelId) {
            return Promise.resolve(true);
        }
        const request = {
            modelId: this.props.modelId
        };
        return modelService.readModelVersions(request,
            (response: any) => this.readModelVersionsResponse(response),
            undefined, true
        );
    }

    private readModelVersionsResponse(response: any): void {
        let versions = response.data.versions;
        if (!versions || versions.length === 0) {
            versions = [0];
        }
        this.setState({ versions: versions });
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readModelVersionsRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-modelversionpicker-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load versions."
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private buildModelView(): ReactElement {
        return (
            <Select
                placeholder="Select a version."
                value={this.props.version}
                onChange={this.handleVersionChange}
            >
                {this.state.versions.map(version => (
                    <Select.Option key={version} value={version}>{CoreUtils.toVersion(version)}</Select.Option>
                ))}
            </Select>
        );
    }

    public componentDidMount(): void {
        if (this.props.modelId) {
            this.loadData();
        } else {
            this.setState({ status: Globals.STATUS_READY });
        }
    }

    public componentDidUpdate(prevProps: ModelVersionPickerProps): void {
        if (this.props.modelId && prevProps.modelId !== this.props.modelId) {
            this.setState({ status: Globals.STATUS_INIT });
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildModelView();
        }
        return (
            <div className={classNames('x-modelversionpicker', this.props.className)}>
                {view}
            </div>
        );
    }

}
