import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RouteComponentProps } from 'react-router-dom';
import { RestUtils } from 'utils/RestUtils';
import entityService from 'services/EntityService';
import update from 'immutability-helper';
import './RegisterResend.less';

interface FormData {
    emailAddress?: string
}

export type RegisterResendProps = RouteComponentProps & {}

export type RegisterResendState = {
    errorMessage?: string,
    isSubmitting: boolean,
    formData: FormData
}

export class RegisterResend extends PureComponent<RegisterResendProps, RegisterResendState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: RegisterResendProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isSubmitting: false,
            formData: {}
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
        const emailAddress = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                emailAddress: { $set: emailAddress }
            })
        });
    }

    private handleFormFinish() {
        const emailAddress = this.state.formData.emailAddress!;
        this.resendConfirmationCodeRequest(emailAddress);
    }

    private resendConfirmationCodeRequest(emailAddress: string): void {
        this.setState({
            isSubmitting: true,
            errorMessage: undefined
        });
        const request = {
            emailAddress: emailAddress
        };
        entityService.resendConfirmationCode(request,
            (response: any) => this.resendConfirmationCodeResponse(response),
            (error: Error) => this.resendConfirmationCodeException(error),
            true
        );
    }

    private resendConfirmationCodeResponse(response: any): void {
        const userId = response.data.userId;
        this.setState({ isSubmitting: false });
        this.props.history.push(RouteBuilder.registerConfirm(userId));
    }

    private resendConfirmationCodeException(error: Error): void {
        this.setState({
            isSubmitting: false,
            errorMessage: RestUtils.getError(error)
        });
    }

    public render(): ReactElement {
        return (
            <FormFrame
                ref={this.formRef}
                title="Registration Code"
                description="Please enter your email and a confirmation code will be resent to you in order to
                    verify your address. You will then be asked to enter the confirmation code, after which
                    your registration will be complete."
                error={this.state.errorMessage}
                hideFooter={true}
                onOk={this.handleFormFinish}
            >
                <FormItem
                    formRef={this.formRef}
                    name="email"
                    noLabel={true}
                    rules={[{
                        required: true,
                        message: 'Please enter your email.'
                    }, {
                        type: 'email',
                        message: 'The email address is invalid.'
                    }]}
                >
                    <Input
                        id="email"
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email address."
                        value={this.state.formData.emailAddress}
                        onChange={this.handleEmailChange}
                    />
                </FormItem>
                <Button
                    className="x-registerresend-submit"
                    type="primary"
                    htmlType="submit"
                    loading={this.state.isSubmitting}
                >
                    Resend Confirmation Code
                </Button>
            </FormFrame>
        );
    }

}
