import { PureComponent, ReactElement } from 'react';
import { Button, Dropdown, FormInstance, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Parameter, ParameterList } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { Justify } from 'components/Justify/Justify';
import { Configuration, ConfigurationSpec, ConfigurationType } from '@methodset/endpoint-client-ts';
import { ConfigurationUtils } from 'utils/ConfigurationUtils';
import { FormDivider } from 'components/FormDivider/FormDivider';
import { ConfigurationEditor } from '../../ConfigurationEditor';
import classNames from 'classnames';
import update from 'immutability-helper';
import './OverridesConfiguration.less';

// Map of parameter variable to parameter for overridables.
type ParameterMap = { [key: string]: Parameter };
// Set of parameter keys that can be overridden.
type OverrideSet = Set<string>;

export type ChangeCallback = (configuration: Configuration) => void;

export type OverridesConfigurationProps = typeof OverridesConfiguration.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    variableSpecs?: ConfigurationSpec[],
    configuration?: Configuration,
    parameters: ParameterList,
    onChange: ChangeCallback
}

export type OverridesConfigurationState = {
    configurationSpecs: ConfigurationSpec[]
}

export class OverridesConfiguration extends PureComponent<OverridesConfigurationProps, OverridesConfigurationState> {

    static defaultProps = {
        variableSpecs: [] as ConfigurationSpec[],
        configuration: {} as Configuration
    }

    private overrideSet: OverrideSet;
    private parameterMap: ParameterMap;

    constructor(props: OverridesConfigurationProps) {
        super(props);
        this.overrideSet = this.buildOverrideSet();
        this.parameterMap = this.buildParameterMap();
        this.state = {
            configurationSpecs: this.buildConfigurationSpecs()
        };
        this.handleConfigurationAdd = this.handleConfigurationAdd.bind(this);
        this.handleConfigurationRemove = this.handleConfigurationRemove.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    }

    private handleConfigurationAdd(parameter: Parameter): void {
        this.overrideSet.add(parameter.variable);
        const configurationSpecs = this.buildConfigurationSpecs();
        this.setState({ configurationSpecs: configurationSpecs });
    }

    private handleConfigurationRemove(spec: ConfigurationSpec): void {
        this.overrideSet.delete(spec.key);
        const configurationSpecs = this.buildConfigurationSpecs();
        this.setState({ configurationSpecs: configurationSpecs });
        const configuration = update(this.props.configuration, {
            $unset: [spec.key]
        });
        this.props.onChange(configuration);
    }

    private handleConfigurationChange(configuration: Configuration): void {
        this.props.onChange(configuration);
    }

    private buildOverrideSet(): Set<string> {
        const overrides = new Set<string>();
        for (const key of Object.keys(this.props.configuration)) {
            overrides.add(key);
        }
        return overrides;
    }

    private buildParameterMap(): ParameterMap {
        const parameterMap: ParameterMap = {};
        this.props.parameters.forEach((parameter: Parameter) => {
            parameterMap[parameter.variable] = parameter;
        });
        return parameterMap;
    }

    private buildConfigurationSpecs(): ConfigurationSpec[] {
        const configurationSpecs: ConfigurationSpec[] = [];
        this.props.parameters.forEach((parameter: Parameter) => {
            if (this.overrideSet.has(parameter.variable)) {
                const configurationSpec = ConfigurationUtils.createConfigurationSpec(
                    ConfigurationType.TEXT,
                    parameter.variable,
                    parameter.name,
                    parameter.description
                );
                configurationSpecs.push(configurationSpec!);
            }
        });
        return configurationSpecs;
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-overridesconfiguration', this.props.className)}>
                {this.state.configurationSpecs.length > 0 &&
                    <FormDivider
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Parameter Overrides"
                        bold={true}
                        colon={false}
                        inline={true}
                    />
                }
                {this.state.configurationSpecs.length > 0 &&
                    <ConfigurationEditor
                        formRef={this.props.formRef}
                        configuration={this.props.configuration}
                        configurationSpecs={this.state.configurationSpecs}
                        variableSpecs={this.props.variableSpecs}
                        authentications={[]}
                        showEmpty={true}
                        showExpressions={true}
                        onDelete={this.handleConfigurationRemove}
                        onChange={this.handleConfigurationChange}
                    />
                }
                <Justify justification="right">
                    <Dropdown
                        trigger={['click']}
                        //disabled={this.props.parameterSpecs.length === 0}
                        disabled={Object.keys(this.parameterMap).length === 0}
                        overlay={(
                            <Menu>
                                {Object.values(this.parameterMap).map(parameter => (
                                    <Menu.Item
                                        key={parameter.variable}
                                        disabled={!!this.overrideSet.has(parameter.variable)}
                                        onClick={() => this.handleConfigurationAdd(parameter)}
                                    >
                                        {this.parameterMap[parameter.variable].name}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        )}
                    >
                        <Button>
                            Override Parameter <DownOutlined />
                        </Button>
                    </Dropdown>
                </Justify>
            </div>
        );
    }

}
