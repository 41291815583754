import { PureComponent, ReactElement } from 'react';
import { Card, Empty, Row } from 'antd';
import { Widget, Applet, Model, WidgetType, RowWidgetConfiguration, ColumnWidgetConfiguration } from '@methodset/model-client-ts';
import { Globals } from 'constants/Globals';
import { Item, ItemLayout, ViewItem } from './ItemLayout/ItemLayout';
import { Spacer } from 'components/Spacer/Spacer';
import { ArrowsAltOutlined, CloseOutlined, DragOutlined, EditOutlined, PlusOutlined, ShrinkOutlined } from '@ant-design/icons';
import { WidgetViewer } from 'containers/Components/Widgets/WidgetViewer/WidgetViewer';
import { AppletProperties } from '../AppletProperties/AppletProperties';
import { WidgetEditor } from '../../../../../Components/Widgets/WidgetEditor/WidgetEditor';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { ItemPosition } from './ItemPosition/ItemPosition';
import { Calculator } from '@methodset/calculator-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import { WidgetSyncFactory } from 'sync/WidgetSyncFactory';
import { AppletSync } from 'sync/AppletSync';
import { VariableSpec } from '@methodset/endpoint-client-ts';
import update from 'immutability-helper';
import './AppletEditor.less';
import { ItemMenu } from './ItemMenu/ItemMenu';

export type ChangeCallback = (applet: Applet) => void;
export type RemoveCallback = (applet: Applet) => void;

export type AppletEditorProps = {
    model: Model,
    applet: Applet,
    calculator: Calculator,
    variableSpecs?: VariableSpec[],
    onChange: ChangeCallback,
    onRemove: RemoveCallback
}

export type AppletEditorState = {
    widget?: Widget,
    isExpanded: boolean,
    isEditingApplet: boolean,
    isEditingWidget: boolean,
    isEditingPosition: boolean
}

export class AppletEditor extends PureComponent<AppletEditorProps, AppletEditorState> {

    constructor(props: AppletEditorProps) {
        super(props);
        this.state = {
            isExpanded: false,
            isEditingApplet: false,
            isEditingWidget: false,
            isEditingPosition: false
        };
        this.handleWidgetAdd = this.handleWidgetAdd.bind(this);
        this.handleWidgetEdit = this.handleWidgetEdit.bind(this);
        this.handleWidgetChange = this.handleWidgetChange.bind(this);
        this.handleWidgetCancel = this.handleWidgetCancel.bind(this);
        this.handleWidgetRemove = this.handleWidgetRemove.bind(this);
        this.handlePositionEdit = this.handlePositionEdit.bind(this);
        this.handlePositionChange = this.handlePositionChange.bind(this);
        this.handlePositionCancel = this.handlePositionCancel.bind(this);
        this.handleExpandToggle = this.handleExpandToggle.bind(this);
        this.handleAppletEdit = this.handleAppletEdit.bind(this);
        this.handleAppletRemove = this.handleAppletRemove.bind(this);
        this.handleAppletChange = this.handleAppletChange.bind(this);
        this.handleAppletCancel = this.handleAppletCancel.bind(this);
        this.handleExecutionComplete = this.handleExecutionComplete.bind(this);
    }

    private handleWidgetAdd(): void {
        this.setState({
            widget: undefined,
            isEditingWidget: true
        });
    }

    private handleWidgetEdit(widget: Widget): void {
        this.setState({
            widget: widget,
            isEditingWidget: true
        });
    }

    private handleWidgetChange(widget: Widget): void {
        const widgets = this.props.applet.widgets;
        const index = widgets.findIndex(w => w.id === widget.id);
        let applet;
        if (index !== -1) {
            applet = update(this.props.applet, {
                widgets: {
                    [index]: { $set: widget }
                }
            });
        } else {
            applet = update(this.props.applet, {
                widgets: {
                    $push: [widget]
                }
            });
            const widgetSync = WidgetSyncFactory.createSync(widget.configuration);
            if (widgetSync) {
                const registry = this.props.calculator.registry;
                registry.register(widget.id, widget, widgetSync.parser, widgetSync.updater);
            }
        }
        this.setState({
            widget: undefined,
            isEditingWidget: false
        });
        this.props.onChange(applet);
    }

    private handleWidgetCancel(): void {
        this.setState({
            widget: undefined,
            isEditingWidget: false
        });
    }

    private removeWidget(widget: Widget): void {
        const widgets = this.props.applet.widgets;
        for (const widget of widgets) {
            if (widget.configuration.type === WidgetType.ROW) {
                const configuration = widget.configuration as RowWidgetConfiguration;
                const index = configuration.widgetIds.findIndex(id => id === widget.id);
                if (index !== -1) {

                }

            } else if (widget.configuration.type === WidgetType.COLUMN) {

            }
        }
    }

    private handleWidgetRemove(widget: Widget): void {
        // TODO: remove from rows and columns
        const widgets = this.props.applet.widgets;
        const index = widgets.findIndex(w => w.id === widget.id);
        if (index !== -1) {
            const applet = update(this.props.applet, {
                widgets: {
                    $splice: [[index, 1]]
                }
            });
            // Remove widget references from row and column widgets.
            const widgetId = widget.id;
            for (const widget of widgets) {
                if (widget.configuration.type === WidgetType.ROW) {
                    const configuration = widget.configuration as RowWidgetConfiguration;
                    let index;
                    do {
                        index = configuration.widgetIds.findIndex(id => id === widgetId);
                        if (index !== -1) {
                            configuration.widgetIds.splice(index, 1);
                        }
                    } while (index !== -1);
                } else if (widget.configuration.type === WidgetType.COLUMN) {
                    const configuration = widget.configuration as ColumnWidgetConfiguration;
                    let index;
                    do {
                        index = configuration.widgetIds.findIndex(id => id === widgetId);
                        if (index !== -1) {
                            configuration.widgetIds.splice(index, 1);
                        }
                    } while (index !== -1);
                }
            }
            this.props.onChange(applet);
        }
        const registry = this.props.calculator.registry;
        registry.unregister(widget.id);
    }

    private handlePositionEdit(widget: Widget): void {
        this.setState({
            widget: widget,
            isEditingPosition: true
        });
    }

    private handlePositionChange(item: Item): void {
        const widgets = this.props.applet.widgets;
        const index = widgets.findIndex(w => w.id === this.state.widget!.id);
        if (index !== -1) {
            const applet = update(this.props.applet, {
                widgets: {
                    [index]: {
                        row: { $set: item.row },
                        col: { $set: item.col },
                        span: { $set: item.span }
                    }
                }
            });
            this.props.onChange(applet);
        }
        this.setState({ isEditingPosition: false });
    }

    private handlePositionCancel(): void {
        this.setState({ isEditingPosition: false });
    }

    private handleExpandToggle(): void {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    private handleAppletEdit(): void {
        this.setState({ isEditingApplet: true });
    }

    private handleAppletRemove(): void {
        const applet = this.props.applet;
        this.props.onRemove(applet);
        const registry = this.props.calculator.registry;
        registry.unregister(applet.id);
    }

    private handleAppletChange(applet: Applet): void {
        this.props.onChange(applet);
        this.setState({ isEditingApplet: false });
        const registry = this.props.calculator.registry;
        registry.register(applet.id, applet, AppletSync.parser, AppletSync.updater);
    }

    private handleAppletCancel(): void {
        this.setState({ isEditingApplet: false });
    }

    private handleExecutionComplete(): void {
        this.forceUpdate();
    }

    private findRootWidgets(): Widget[] {
        const childIds = new Set<string>();
        const widgets = this.props.applet.widgets;
        for (const widget of widgets) {
            const type = widget.configuration.type;
            // Find child widgets of row and columns.
            if (type === WidgetType.ROW || type === WidgetType.COLUMN) {
                const configuration = widget.configuration as any;
                const widgetIds = configuration.widgetIds;
                for (const widgetId of widgetIds) {
                    childIds.add(widgetId);
                }
            }
        }
        const rootWidgets = [];
        for (const widget of widgets) {
            if (!childIds.has(widget.id)) {
                rootWidgets.push(widget);
            }
        }
        return rootWidgets;
    }

    private buildItems(): ViewItem[] {
        const calculator = this.props.calculator;
        //const widgets = this.props.applet.widgets;
        const widgets = this.findRootWidgets();
        return widgets.map(widget => {
            // const items: ItemSpec[] = [{
            //     icon: <EditOutlined />,
            //     label: "Edit widget",
            //     onSelect: this.handleWidgetEdit
            // }, {
            //     icon: <DragOutlined />,
            //     label: "Position widget",
            //     onSelect: this.handlePositionEdit
            // }, {
            //     icon: <CloseOutlined />,
            //     label: "Remove widget",
            //     confirm: "Are you sure you want to remove the widget?",
            //     onSelect: this.handleWidgetRemove
            // }];
            // const extra = (
            //     <Spacer>
            //         <MenuButton
            //             items={items}
            //             data={widget}
            //             size={Globals.APPLET_MENU_SIZE}
            //             shape={Globals.APPLET_MENU_SHAPE}
            //         />
            //     </Spacer>
            // );
            const menu = (
                <ItemMenu
                    widget={widget}
                    onEdit={this.handleWidgetEdit}
                    onRemove={this.handleWidgetRemove}
                />
            )
            return {
                row: widget.row,
                col: widget.col,
                span: widget.span,
                element: (
                    <WidgetViewer
                        key={widget.id}
                        //model={this.props.model}
                        applet={this.props.applet}
                        widget={widget}
                        calculator={calculator}
                        modelId={this.props.model.id}
                        version={this.props.model.version}
                        //appletConfiguration={}
                        variableSpecs={this.props.variableSpecs}
                        extra={menu}
                        showHeader={this.state.isExpanded}
                        onEdit={this.handleWidgetEdit}
                        onRemove={this.handleWidgetRemove}
                        onChange={this.handleWidgetChange}
                    />
                )
            }
        });
    }

    private buildWidgetsView(): ReactElement {
        const items = this.buildItems();
        return (
            <>
                {items.map(item => item.element)}
            </>
            // <Row gutter={[Globals.WIDGET_GUTTER_COL, Globals.WIDGET_GUTTER_ROW]}>
            //     {items.map(item => item.element)}
            // </Row>
        )
    }

    public componentDidMount(): void {
        this.props.calculator.addCallback("ExecutionComplete", this.handleExecutionComplete);
    }

    public componentWillUnmount(): void {
        this.props.calculator.removeCallback("ExecutionComplete", this.handleExecutionComplete);
    }

    public render(): ReactElement {
        const calculator = this.props.calculator;
        const items: ItemSpec[] = [{
            icon: <EditOutlined />,
            label: "Edit properties",
            onSelect: this.handleAppletEdit
        }, {
            icon: <PlusOutlined />,
            label: "Add widget",
            onSelect: this.handleWidgetAdd
        }, {
            icon: this.state.isExpanded ? <ShrinkOutlined /> : <ArrowsAltOutlined />,
            label: this.state.isExpanded ? "Preview applet" : "Edit widgets",
            onSelect: this.handleExpandToggle
        }, {
            icon: <CloseOutlined />,
            label: "Remove applet",
            confirm: "Are you sure you want to remove the applet?",
            onSelect: this.handleAppletRemove
        }];
        const extra = (
            <Spacer>
                <MenuButton
                    items={items}
                    size={Globals.APPLET_MENU_SIZE}
                    shape={Globals.APPLET_MENU_SHAPE}
                />
            </Spacer>
        );
        const title = WidgetUtils.replaceCellRefs(calculator, this.props.applet.title);
        return (
            <>
                <Card className="x-appleteditor" size="small" bordered={true} title={title} extra={extra}>
                    {this.props.applet.widgets.length === 0 &&
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>No widgets added.</span>
                            }
                        />
                    }
                    {this.props.applet.widgets.length > 0 &&
                        this.buildWidgetsView()
                        // <ItemLayout items={this.buildItems()} />
                    }
                </Card>
                {this.state.isEditingApplet &&
                    <AppletProperties
                        applet={this.props.applet}
                        onChange={this.handleAppletChange}
                        onCancel={this.handleAppletCancel}
                    />
                }
                {this.state.isEditingWidget &&
                    <WidgetEditor
                        //type={this.state.widget?.configuration.type}
                        applet={this.props.applet}
                        widget={this.state.widget}
                        calculator={calculator}
                        onChange={this.handleWidgetChange}
                        onCancel={this.handleWidgetCancel}
                    />
                }
                {this.state.isEditingPosition && this.state.widget &&
                    <ItemPosition
                        type="widget"
                        rows={Globals.LAYOUT_ROWS}
                        cols={Globals.LAYOUT_COLUMNS}
                        items={this.buildItems()}
                        item={{
                            row: this.state.widget.row,
                            col: this.state.widget.col,
                            span: this.state.widget.span
                        }}
                        onChange={this.handlePositionChange}
                        onCancel={this.handlePositionCancel}
                    />
                }
            </>
        );
    }

}
