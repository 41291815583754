import { ReactElement, ReactNode } from 'react';
import { Button, Dropdown, Menu, Space } from 'antd';
import { Confirm } from 'components/Confirm/Confirm';
import { MoreOutlined } from '@ant-design/icons';
import { ButtonShape, ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import classNames from 'classnames';
import './MenuButton.less';

export type DisabledFunction = (data: any) => boolean;
export type HiddenFunction = (data: any) => boolean;
export type SelectCallback = (data?: any) => void;

export interface ItemSpec {
    label: string,
    icon?: ReactNode,
    confirm?: string,
    menu?: ReactElement,
    disabled?: DisabledFunction,
    hidden?: HiddenFunction,
    onSelect?: SelectCallback
}

export type MenuButtonProps = {
    className?: string,
    items: ItemSpec[],
    type?: ButtonType,
    shape?: ButtonShape,
    icon?: ReactNode,
    size?: SizeType,
    data?: any,
    index?: number
} & typeof defaultProps;

const defaultProps = {
    icon: <MoreOutlined />
}

/**
 * A button with a menu of items in a dropdown when the button is clicked.
 */
export const MenuButton = (props: MenuButtonProps): ReactElement => {

    const menu = (
        <Menu>
            {props.items.map((item, index) => {
                if (item.confirm && item.onSelect && (!item.hidden || !item.hidden(props.data))) {
                    return (
                        <Menu.Item
                            key={index}
                            disabled={item.disabled ? item.disabled(props.data) : false}
                        >
                            <Confirm
                                placement="left"
                                message={item.confirm}
                                onConfirm={() => item.onSelect!(props.data)}
                            >
                                <Space>
                                    <span>{item.icon}</span>
                                    <span>{item.label}</span>
                                </Space>
                            </Confirm>
                        </Menu.Item>
                    )
                } else if (item.menu && (!item.hidden || !item.hidden(props.data))) {
                    return (
                        <Menu.SubMenu
                            key={index}
                            icon={item.icon}
                            title={item.label}
                            disabled={item.disabled ? item.disabled(props.data) : false}
                        >
                            {item.menu}
                        </Menu.SubMenu>
                    )
                } else if (item.onSelect && (!item.hidden || !item.hidden(props.data))) {
                    return (
                        <Menu.Item
                            key={index}
                            disabled={item.disabled ? item.disabled(props.data) : false}
                            onClick={() => item.onSelect!(props.data)}
                        >
                            <Space>
                                <span>{item.icon}</span>
                                <span>{item.label}</span>
                            </Space>
                        </Menu.Item>
                    )
                } else {
                    return null;
                }
            })}
        </Menu>
    );

    return (
        <div className={classNames('x-menubutton', props.className)}>
            <Dropdown
                overlay={menu}
                trigger={['click']}
            >
                <Button
                    type={props.type}
                    shape={props.shape}
                    icon={props.icon}
                    size={props.size}
                />
            </Dropdown>
        </div>
    )
}

MenuButton.defaultProps = defaultProps;
