import { PureComponent, ReactElement } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { ApiItem } from './Apis/ApiItem/ApiItem';
import { ApiKeys } from './Apis/ApiKeys/ApiKeys';
import { Apis } from './Apis/Apis';
import { Authentications } from './Authentications/Authentications';
import { AuthenticationItem } from './Authentications/AuthenticationItem/AuthenticationItem';
import { Accounts } from './Accounts/Accounts';
import { AccountItem } from './Accounts/AccountItem/AccountItem';
import { DatasetItem } from './Datasets/DatasetItem/DatasetItem';
import { Queries } from './Datasets/Datasets';
import { Executions } from './Monitor/Executions/Executions';
import { Explorer } from './Explorer/Explorer';
import { ModelItem } from './Models/ModelItem/ModelItem';
import { Models } from './Models/Models';
import { Monitor } from './Monitor/Monitor';
import { NavBar } from './NavBar/NavBar';
import { PageNotFound } from 'containers/Components/PageNotFound/PageNotFound';
import { Splash } from './Splash/Splash';
import { GroupProperties } from './Groups/GroupItem/GroupProperties/GroupProperties';
import { Groups } from './Groups/Groups';
import { Workflows } from './Workflows/Workflows';
import { RouteBuilder } from 'utils/RouteBuilder';
import { WorkflowItem } from './Workflows/WorkflowItem/WorkflowItem';
import { Dashboards } from './Dashboards/Dashboards';
import { DashboardItem } from './Dashboards/DashboardItem/DashboardItem';
import { AppletInventory } from './Dashboards/DashboardItem/AppletInventory/AppletInventory';
import { GroupAddMember } from './Groups/GroupItem/GroupAddMember/GroupAddMember';
import { GroupMemberships } from './Groups/GroupItem/GroupMemberships/GroupMemberships';
import { ModelProvider } from 'context/ModelContext';
import { EntityContext } from 'context/EntityContext';
import { Globals } from 'constants/Globals';
import authService from 'services/AuthService';
import serviceWarmer from 'utils/ServiceWarmer';
import './Console.less';
import { OrganizationItem } from './OrganizationItem/OrganizationItem';

export type ConsoleState = {
    status: string,
    collapsed: boolean
}

export type ConsoleProps = RouteComponentProps & {
}

export class Console extends PureComponent<ConsoleProps, ConsoleState> {

    static contextType = EntityContext;

    constructor(props: ConsoleProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            collapsed: false
        };
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
    }

    private handleToggleMenu(): void {
        this.setState({ collapsed: !this.state.collapsed });
    }

    public componentWillUnmount(): void {
        serviceWarmer.stop();
    }

    public componentDidMount(): void {
        serviceWarmer.start();
        if (!this.context.user) {
            // If the page got refreshed, may need to reset the user.
            authService.readUser().then(user => {
                this.context.saveUser(user);
                this.setState({status: Globals.STATUS_READY});
            }).catch(e => {
                this.context.clearUser();
                this.props.history.push(RouteBuilder.user(this.props, RouteBuilder.USER_LOGIN));
            });
        } else {
            this.setState({status: Globals.STATUS_READY});
        }
    }    

    public render(): ReactElement {
        if (this.state.status === Globals.STATUS_READY) {
            return (
                <ModelProvider>
                    <Layout className="x-console">
                        <Layout.Header className="x-console-header">
                            <NavBar {...this.props} onToggleMenu={this.handleToggleMenu} />
                        </Layout.Header>
                        <Layout>
                            <Layout.Sider className="x-console-sidebar" width={300} collapsible trigger={null} collapsed={this.state.collapsed}>
                                <Route render={(props) => <Explorer {...props} />} />
                            </Layout.Sider>
                            <Layout.Content className="x-console-body">
                                <Switch>
                                    <Route exact path={RouteBuilder.CONSOLE} component={Splash} />
                                    <Route exact path={RouteBuilder.CONSOLE_ORGANIZATION} component={OrganizationItem} />
                                    <Route exact path={RouteBuilder.CONSOLE_GROUPS} component={Groups} />
                                    <Route exact path={RouteBuilder.CONSOLE_GROUP_PROPERTIES} component={GroupProperties} />
                                    <Route exact path={RouteBuilder.CONSOLE_GROUP_ADD_MEMBER} component={GroupAddMember} />
                                    <Route exact path={RouteBuilder.CONSOLE_GROUP_MEMBERSHIPS} component={GroupMemberships} />
                                    <Route exact path={RouteBuilder.CONSOLE_CREDENTIALS} component={Authentications} />
                                    <Route path={RouteBuilder.CONSOLE_CREDENTIAL} component={AuthenticationItem} />
                                    <Route exact path={RouteBuilder.CONSOLE_ACCOUNTS} component={Accounts} />
                                    <Route path={RouteBuilder.CONSOLE_ACCOUNT} component={AccountItem} />
                                    <Route exact path={RouteBuilder.CONSOLE_DATASETS} component={Queries} />
                                    <Route path={RouteBuilder.CONSOLE_DATASET} component={DatasetItem} />
                                    <Route exact path={RouteBuilder.CONSOLE_WORKFLOWS} component={Workflows} />
                                    <Route path={RouteBuilder.CONSOLE_WORKFLOW} component={WorkflowItem} />
                                    <Route exact path={RouteBuilder.CONSOLE_API_KEYS} component={ApiKeys} />
                                    <Route exact path={RouteBuilder.CONSOLE_APIS} component={Apis} />
                                    <Route path={RouteBuilder.CONSOLE_API} component={ApiItem} />
                                    <Route exact path={RouteBuilder.CONSOLE_MONITOR} component={Monitor} />
                                    <Route path={RouteBuilder.CONSOLE_EXECUTIONS} component={Executions} />
                                    <Route exact path={RouteBuilder.CONSOLE_MODELS} component={Models} />
                                    <Route path={RouteBuilder.CONSOLE_MODEL} component={ModelItem} />
                                    <Route exact path={RouteBuilder.CONSOLE_DASHBOARDS} component={Dashboards} />
                                    <Route exact path={RouteBuilder.CONSOLE_DASHBOARD} component={DashboardItem} />
                                    <Route exact path={RouteBuilder.CONSOLE_APPLETS} component={AppletInventory} />
                                    <Route component={PageNotFound} />
                                </Switch>
                            </Layout.Content>
                        </Layout>
                    </Layout>
                </ModelProvider>
            );
        } else {
            // Setting up user.
            // TODO: spinner
            return <></>;
        }
    }

}
